import React, { useEffect, useState } from 'react';
import { Button, Flex, Icon, ScrollView, Text } from '@aws-amplify/ui-react';
import {Link, Navigate, useLocation} from "react-router-dom";
import { AppNavButton, DropDownQuestionTemplate, TextFieldTemplate } from '../../custom-ui-components';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import DayAvailabilityTemplate from '../../custom-ui-components/form-components/DayAvailabilityTemplate';
import { MdClose} from 'react-icons/md';
import AmendmentTemplate from '../../custom-ui-components/AmendmentTemplate';
import { getDatesForLesson, getDayName, getMatchingWeekAvailabilityFromList, getSessionEndTime, getSessionLength, getSessionLengthMins, getStudentNames, getTutorName, sleep } from '../../App';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { WeekAvailabilityTemplate } from '../../custom-ui-components/form-components/WeekAvailabilityTemplate';
import RadioButtonsTemplate from '../../custom-ui-components/form-components/RadioButtonsTemplate';
import ExistingOrNewAddressTemplate from '../../custom-ui-components/form-components/ExistingOrNewAddressTemplate';
import { validateField } from '../../custom-ui-components/form-components/FormValidation';
import LessonDateTemplate from '../../custom-ui-components/LessonDateTemplate';
import BackButton from '../../custom-ui-components/BackButton';
import TimeRangeTemplate from '../../custom-ui-components/form-components/TimeRangeTemplate';
import { compareObjects } from 'nextdoortutor';

const AmendLesson = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userID = props.userID;
    const width = props.width;
    const APIFunctions = props.APIFunctions;
    const userType = props.userType; 
    const parentsStudents = props.parentsStudents;
    const studentModel = props.studentModel;

    const location = useLocation();
    const extraProps = location.state || {};
    const lessonDictionary = userType == "admin" && props.adminLessonDictionary || props.lessonDictionary;
    const tutorDictionary = props.tutorDictionary;
    const studentDictionary = props.studentDictionary;

    let defaultAddNewAmendment = false;
    if (extraProps.newAmendmentType != null) {
        defaultAddNewAmendment = true;
    }
    const passedNewAmendments = extraProps.newAmendments || [];
    const nextDate = new Date().setDate(new Date().getDate() + 1);

    const [newAmendments, setNewAmendments] = useState(passedNewAmendments);
    const [selectedAmendment, setSelectedAmendment] = useState(extraProps.newAmendmentType || null);
    const [lessonProps, setLessonProps] = useState(null);
    const [setOnlyAtStart, setSetOnlyAtStart] = useState(null);
    const [addNewAmendment, setAddNewAmendment] = useState(defaultAddNewAmendment);
    const [amendmentText, setAmendmentText] = useState(null);
    const [anyErrors, setAnyErrors] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [answersSubmitted, setAnswersSubmitted] = useState(0);
    const [currentAnswerIteration, setCurrentAnswerIteration] = useState(1);
    const [lessonFlex, setLessonFlex] = useState(null);
    const [lessonTextProps, setLessonTextProps] = useState(null);

    const [numNewAmendments, setNumNewAmendments] = useState(0);
    const [submitPopUp, setSubmitPopUp] = useState(false);
    const [preSubmitPopUp, setPreSubmitPopUp] = useState({show:false, text:""});
    const [formSubmissionErrorPopUp, setFormSubmissionErrorPopUp] = useState({show:false, message:""});
    const [answers, setAnswers] = useState(null);
    const [defaultAnswers, setDefaultAnswers] = useState(null);
    const [originalLessonDates, setOriginalLessonDates] = useState(null);
    const [lessonDates, setLessonDates] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [userAddressList, setUserAddressList] = useState([]);
    const [updateDayAvailability, setUpdateDayAvailability] = useState(0);
    const [allPossibleLessonDates, setAllPossibleLessonDates] = useState(null);
    const [selectors, setSelectors] = useState({});
    const [noLessonModel, setNoLessonModel] = useState(null);

    //Set lesson props
    useEffect(() => {
        if (lessonDictionary == null) {
            console.log("Lesson dictionary loading");
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const lessonID = queryParams.get("lessonID");
        if (lessonID == null) {
            console.log("No lessonID found");
            setNoLessonModel(true);
            return;
        }
        const lessonModel = lessonDictionary[lessonID];
        if (lessonModel == null) {
            console.log("No lesson model found");
            setNoLessonModel(true);
            return;
        }

        setLessonProps(lessonModel);

        if (lessonModel.newAmendmentType != null) {
            handleChange("amendmentPicker", lessonModel.newAmendmentType);
        }
        if (lessonModel.amendmentProps != null) {
            setSetOnlyAtStart({...lessonModel.amendmentProps});
        }
    }, [lessonDictionary, studentDictionary, tutorDictionary, window.location]);

    //Called by all question components when updating
    const handleChange = function(questionID, newAnswer, questionHasError) {
        if (questionID == "amendmentPicker") {
            let newSelectedAmendment = null;
            let newAmendmentText = null;
            if (newAnswer == "Add an extra one-off lesson") {
                newSelectedAmendment = "addOneOff";
                newAmendmentText = "Choose new lesson date/time:";
            }
            else if (newAnswer == "Add an extra weekly scheduled lesson") {
                newSelectedAmendment = "addScheduled";
                newAmendmentText = "Details for new lesson:";
            }
            else if (newAnswer == "Cancel single lesson(s)") {
                newSelectedAmendment = "cancelSingle";
                newAmendmentText = "Choose lesson(s) to cancel:";
            }
            else if (newAnswer == "Change lesson day/time") {
                newSelectedAmendment = "changeDayTime";
                newAmendmentText = "Add new availability:";
            }
            else if (newAnswer == "Change lesson location") {
                newSelectedAmendment = "changeLocation";
                newAmendmentText = "Choose new location:";
            }
            else if (newAnswer == "Change lesson type") {
                newSelectedAmendment = "changeType";
                newAmendmentText = "Choose new lesson type:";
            }
            else if (newAnswer == "Set last lesson date") {
                newSelectedAmendment = "setLastDate";
                newAmendmentText = "Choose final lesson:";
            }
            else if (newAnswer == "Cancel all lessons" || newAnswer == "Stop teaching all lessons") {
                newSelectedAmendment = "cancelAll";
                newAmendmentText = "Are you sure you want to cancel all future lessons?";
            }
            setSelectedAmendment(newSelectedAmendment);
            setAmendmentText(newAmendmentText);

            const newDefaultAnswers = {...defaultAnswers};
            if (newAnswer == "Change lesson type") {
                let newDefaultLessonType = null;
                if (lessonProps.lessonType == "In-Person") {
                    newDefaultLessonType = "Online";
                }
                else {
                    newDefaultLessonType = "In-Person";
                }
                newDefaultAnswers.lessonType = newDefaultLessonType;
                newDefaultAnswers.address = defaultAnswers.defaultSameAddress;
            }
            else if  (newAnswer == "Add an extra weekly scheduled lesson") {
                let newDefaultLessonType = null;
                if (lessonProps.lessonType == "In-Person") {
                    newDefaultLessonType = "In-Person";
                }
                else {
                    newDefaultLessonType = "Online";
                }
                newDefaultAnswers.lessonType = newDefaultLessonType;

            }
            else if  (newAnswer == "Change lesson location") {
                newDefaultAnswers.address = defaultAnswers.defaultDifferentAddress;
            }
            if (!compareObjects(defaultAnswers, newDefaultAnswers)) {
                setDefaultAnswers(newDefaultAnswers);
            }
            if (selectedAmendment != null) {
                const defaultAmendment = {...defaultAnswers};
                defaultAmendment.type = newSelectedAmendment;
                defaultAmendment.confirmed = false;
                const validAmendment = validateField(defaultAmendment, [{type: "ValidLessonAmendment", lessonProps: lessonProps}]);
                setErrorText(validAmendment.errorMessage);
                setAnyErrors(validAmendment.hasError);
            }
            else {
                setErrorText(null);
                setAnyErrors(false);
            }
            setAnswers(defaultAnswers);
        }
        else {
            const newAnswers = {...answers};
            newAnswers[questionID] = newAnswer;
            if (questionID == "lessonType") {
                if (newAnswer == "Online") {
                    newAnswers.address = null;
                }
            }
            let forceUpdateDayAvailability = false;
            if (questionID == "datePicker" && defaultAnswers != null && defaultAnswers.weekAvailability != null) {
                const weekDay = getDayName(new Date(newAnswer).getDay());
                const dayAvailability = defaultAnswers.weekAvailability[weekDay];
                if (answers.dayAvailability == null || answers.dayAvailability.length == 0) {
                    newAnswers.dayAvailability = dayAvailability;
                    forceUpdateDayAvailability = true;
                }
            }
            setAnswers(newAnswers);
            if (forceUpdateDayAvailability) {
                setUpdateDayAvailability(updateDayAvailability + 1);
            }
            checkAnswersForErrors(newAnswers);
        }
    }

    //Set lesson dates
    useEffect(() => {
        if (lessonProps != null && props.userType != null) {
            const retrievedLessonDates = getDatesForLesson(lessonProps, false, 1000, userType);
            setOriginalLessonDates(retrievedLessonDates);
            for (const newAmendment of newAmendments) {
                if (newAmendment.type == "cancelSingle") {
                    for (let dateIndex = 0; dateIndex < retrievedLessonDates.length; dateIndex = dateIndex + 1) {
                        const lessonDate = retrievedLessonDates[dateIndex];
                        if (lessonDate.date == newAmendment.date && lessonDate.startTime == newAmendment.startTime) {
                            retrievedLessonDates.splice(dateIndex, 1);
                            dateIndex = dateIndex - 1;
                        }
                    }
                }
            }
            setLessonDates(retrievedLessonDates);
            const retrievedAllPossibleLessonDate = getDatesForLesson(lessonProps, true, 1000, userType);
            setAllPossibleLessonDates(retrievedAllPossibleLessonDate);
            setEndDate(lessonProps.endDate);
        }
    }, [lessonProps, props.userType, newAmendments]);

    //Set user address list
    useEffect(() => {
        const userAttributes = user.attributes;
        const userAddressString = userAttributes["custom:addressList"] || "[]";
        setUserAddressList(JSON.parse(userAddressString));
    }, [user]);

    //Set only at start values
    useEffect(() => {
        if (setOnlyAtStart == null || setOnlyAtStart == false || answers == null || defaultAnswers == null) {
            return;
        }
        let forceUpdateDayAvailability = false
        if (setOnlyAtStart.dayAvailability == "default") {
            forceUpdateDayAvailability = true;
            delete setOnlyAtStart.dayAvailability;
        }
        const newAnswers = {...answers, ...setOnlyAtStart};

        setAnswers(newAnswers);
        setSetOnlyAtStart(false);
        if (forceUpdateDayAvailability) {
            setUpdateDayAvailability(updateDayAvailability + 1);
        }
    }, [setOnlyAtStart, answers, updateDayAvailability, defaultAnswers]);

    //Set the default answers
    useEffect(() => {
        if (lessonProps == null || userAddressList == null) {
            return;
        }
        
        //Set the default availability based on the saved availability
        const blankAvailability = {
            "Monday": [],
            "Tuesday": [],
            "Wednesday": [],
            "Thursday": [],
            "Friday": [],
            "Saturday": [],
            "Sunday": []
        };
        let defaultAvailability = {...blankAvailability};
        if (userType == "parent") {
            let lessonStudentIDs = lessonProps.studentIDs;
            if (lessonStudentIDs == null) {
                lessonStudentIDs = lessonProps.studentID;
            }
            if (parentsStudents == null || parentsStudents == "loading") {
                return;
            }

            let studentDefaultAvailabilitys = [];
            for (const student of parentsStudents) {
                for (const lessonStudentID of lessonStudentIDs) {
                    const studentID = student.id;
                    if (studentID == lessonStudentID) {
                        studentDefaultAvailabilitys.push(student.defaultAvailability);
                    }
                }
            }
            defaultAvailability = getMatchingWeekAvailabilityFromList(studentDefaultAvailabilitys);
        }
        else if (userType == "student") {
            if (studentModel == null || studentModel == "loading") {
                return;
            }
            const studentDefaultAvailability = studentModel.defaultAvailability;
            if (studentDefaultAvailability != null) {
                defaultAvailability = {...studentDefaultAvailability};
            }
        }

        let defaultSameLessonType = null;
        let defaultDifferentLessonType = null;
        let defaultLessonType = null
        if (lessonProps.lessonType == "In-Person") {
            defaultSameLessonType = "In-Person";
            defaultDifferentLessonType = "Online";

        }
        else {
            defaultSameLessonType = "Online";
            defaultDifferentLessonType = "In-Person";
        }
        defaultLessonType = defaultSameLessonType;
        if (selectedAmendment == "addScheduled" && defaultLessonType != defaultSameLessonType) {
            defaultLessonType = defaultSameLessonType;
        }
        else if (selectedAmendment == "changeType" && defaultLessonType != defaultDifferentLessonType) {
            defaultLessonType = defaultDifferentLessonType;
        }
        

        let blankAddress = {
            address1stLine: "",
            postcode: ""
        };

        const newAddressList = [];
        for (let addressIndex = 0; addressIndex < userAddressList.length; addressIndex = addressIndex + 1) {
            const userAddress = userAddressList[addressIndex];
            if (lessonProps.address == null || (userAddress.address1stLine != lessonProps.address.address1stLine && userAddress.postcode != lessonProps.address.postcode)) {
                newAddressList.push(userAddress);
            }
        }

        let defaultSameAddress = blankAddress;
        let defaultDifferentAddress = blankAddress;
        let defaultLessonAddress = blankAddress;

        if (newAddressList.length > 0) {
            defaultDifferentAddress = newAddressList[0];
        }
        if (lessonProps.lessonType != "Online") {
            defaultSameAddress = lessonProps.address;
        }
        else {
            defaultSameAddress = newAddressList[0];
        }
        
        defaultLessonAddress = defaultSameAddress;
        if (selectedAmendment == "addScheduled") {
            defaultLessonAddress = defaultSameAddress;
        }
        else if (selectedAmendment == "changeLocation") {
            defaultLessonAddress = defaultDifferentAddress;
        }

        const newDefaultAnswers = {
            // datePicker: new Date(nextDate).toDateString(),
            datePicker: null,
            sessionLength: "1 Hour",
            dayAvailability: [],
            weekAvailability: {...defaultAvailability},
            lessonType: defaultLessonType,
            sameLessonType: defaultSameLessonType,
            differentLessonType: defaultDifferentLessonType,
            address: defaultLessonAddress,
            sameAddress: defaultSameAddress,
            differentAddress: defaultDifferentAddress,
            selectedLesson: null,
            selectedLessons: {},
            confirmCancel: "NO! Go back!"
        };
        setDefaultAnswers(newDefaultAnswers);
    }, [lessonProps, userAddressList, selectedAmendment,userType, parentsStudents, studentModel]);

    //Set the answers to the default answers
    useEffect(() => {
        if (answers == null && defaultAnswers != null) {
            setAnswers(defaultAnswers);
        }
    }, [answers, defaultAnswers]);

    //Set lesson text props
    useEffect(() => {
        const newLessonTextProps = {...standardTextProps}
        newLessonTextProps.textAlign = "left";
        newLessonTextProps.fontSize = "17px"
        delete newLessonTextProps.maxWidth;
        setLessonTextProps(newLessonTextProps);
    }, [standardTextProps]);
    
    //Generate the basic lesson info
    useEffect(() => {
        if (userType == null || lessonProps == null) {
            return;
        }
        const UIs = [];
        if (userType != "student") {
            let studentNames = lessonProps.studentNames;
            if (userType == "admin") {
                const studentIDs = lessonProps.studentIDs;
                studentNames = getStudentNames(studentIDs, studentDictionary);
            }
            const studentNameText = <Text {...lessonTextProps} key={"studentNameText"}>
                Student: {studentNames} 
            </Text>
            UIs.push(studentNameText);
        }
        if (userType != "tutor") {
            let tutorName = lessonProps.tutorFirstNames + " " + lessonProps.tutorLastName;
            if (userType == "admin") {
                const tutorID = lessonProps.tutorID;
                tutorName = getTutorName(tutorID, tutorDictionary);
            }
            const tutorNameText = <Text {...lessonTextProps} key={"tutorNameText"}>
                Tutor: {tutorName} 
            </Text>
            UIs.push(tutorNameText);
        }
    
        UIs.push(
            <Text {...lessonTextProps} key={"subject"}>
                {lessonProps.subject}
            </Text>,
            <Text {...lessonTextProps} key={"lessonTime"}>
                {lessonProps.lessonDay}s: {lessonProps.startTime} - {lessonProps.endTime}
            </Text>,
            <Text {...lessonTextProps} key={"lessonType"}>
                Lesson type: {lessonProps.lessonType}
            </Text>
        );
        if (lessonProps.lessonType == "In-Person") {
            UIs.push(
                <Text {...lessonTextProps} key={"address1stLine"}>
                    Lesson location: {lessonProps.address.address1stLine}, {lessonProps.address.postcode}
                </Text>
            );
        }
        UIs.push(
            <Text {...lessonTextProps} key={"startDate"}>
                Start date: {lessonProps.startDate}
            </Text>
        );
        let endDateText = "Not set";
        if (lessonProps.endDate != null) {
            endDateText = lessonProps.endDate;
        }
        UIs.push(
            <Text {...lessonTextProps} key={"finalLesson"}>
                Final lesson date: {endDateText}
            </Text>
        );

        setLessonFlex(<Flex direction={"column"} alignItems={"start"} gap={"0px"}>
            {UIs}
        </Flex>);
    }, [lessonProps, userType, lessonTextProps, studentDictionary, tutorDictionary]);


    //SELECTORS --------------------------------------------------------------------

    //Lessons Selector
    useEffect(() => {
        if (allPossibleLessonDates == null || lessonDates == null || answers == null || answers.selectedLessons == null) {
            return;
        }
        let lessonSelector = null;
        if (selectedAmendment == "cancelSingle" || selectedAmendment == "setLastDate") {
            const lessonUIs = [];
            const lessonTextProps = {...standardTextProps}
            lessonTextProps.fontSize = "16px";
            delete lessonTextProps.maxWidth;

            let selectableLessons = [...lessonDates];
            if (selectedAmendment == "setLastDate") {
                selectableLessons = [...allPossibleLessonDates];
            }
            for (const lesson of selectableLessons) {
                
                let showCross = false;
                let backgroundColour = "#e5f0d5"
                if (selectedAmendment == "cancelSingle") {
                    if (answers.selectedLessons[lesson.date + "-" + lesson.startTime] != null) {
                        showCross = true;
                        backgroundColour = "#ff5938"
                    }
                }
                else if (answers.selectedLesson != null) {
                    if (answers.selectedLesson.date == lesson.date && answers.selectedLesson.startTime == lesson.startTime) {
                        showCross = true;
                        backgroundColour = "#ff5938"
                    }
                }
                const lessonUI = <Flex
                    key={lesson.date + "-" + lesson.startTime}
                    style={{cursor:"pointer"}}
                    onClick={() => {
                        if (selectedAmendment == "cancelSingle") {
                            const newSelectedLessons = {...answers.selectedLessons};
                            if (newSelectedLessons[lesson.date + "-" + lesson.startTime] == null) {
                                newSelectedLessons[lesson.date + "-" + lesson.startTime] = lesson;
                            }
                            else {
                                delete newSelectedLessons[lesson.date + "-" + lesson.startTime];
                            }
                            handleChange("selectedLessons", newSelectedLessons);
                        }
                        else {
                            if (answers.selectedLesson != null && answers.selectedLesson.date == lesson.date && answers.selectedLesson.startTime == lesson.startTime) {
                                handleChange("selectedLesson", null);
                            }
                            else {
                                handleChange("selectedLesson", lesson);
                            }
                        }
                    }}
                >
                    <LessonDateTemplate 
                        lesson={lesson} 
                        lessonTextProps={lessonTextProps}
                        showCross={showCross}
                        backgroundColour={backgroundColour}
                    />
                </Flex>
                lessonUIs.push(lessonUI);
            }

            let selectedSuccessString = null;
            let selectedSuccessTextAlign = "center"
            let selectedSuccessTextPaddingLeft = "0vw"
            if (selectedAmendment == "cancelSingle") {
                const selectedLessonsArray = Object.values(answers.selectedLessons);
                selectedSuccessString = "Selected: " + selectedLessonsArray.length + "/" + lessonDates.length;
            }
            else if (selectedAmendment == "setLastDate") {
                selectedSuccessString = "Selected:"
                if (answers.selectedLesson != null) {
                    selectedSuccessString = selectedSuccessString + " " + answers.selectedLesson.date + ", " + answers.selectedLesson.startTime + "-" + answers.selectedLesson.endTime;
                }
                selectedSuccessTextAlign = "flex-start"
                selectedSuccessTextPaddingLeft = "5vw"
            }
            let selectedSuccessText = null;
            if (selectedSuccessString != null) {
                selectedSuccessText = <Text 
                    {...lessonTextProps}
                    alignSelf={selectedSuccessTextAlign}
                    paddingLeft={selectedSuccessTextPaddingLeft}
                >{selectedSuccessString}</Text>
            }

            let scrollMaxWidth = "50vw";
            if (width < 850) {
                scrollMaxWidth = "85vw";
            }
            lessonSelector = <Flex
                direction={"column"} 
                alignItems={"center"}
            >
                <ScrollView 
                    maxHeight={"25vh"}
                    maxWidth={scrollMaxWidth}
                    paddingLeft={"4vw"}
                    paddingRight={"4vw"}
                    paddingTop={"10px"}
                    paddingBottom={"10px"}
                    style={{
                        borderStyle: "solid",
                        borderColor: "#2e66ff",
                        borderRadius: "10px"
                    }}
                >   
                    <Flex
                        direction={"column"} 
                        alignItems={"center"}
                    >
                        {lessonUIs}
                    </Flex>
                </ScrollView>
                {selectedSuccessText}
            </Flex>
        }
        const newSelectors = {...selectors};
        newSelectors.lessonSelector = lessonSelector;
        setSelectors(newSelectors);
    }, [selectedAmendment, answers, lessonTextProps, lessonDates, allPossibleLessonDates, width]);


    //Check all dependencies are loaded
    if (lessonProps == null || lessonDictionary == null || compareObjects(lessonDictionary, {})) {
        return <LoadingSpinnerPage {...props} />
    }
    if (userType == "parent" && (parentsStudents == null || parentsStudents == "loading")) {
        return <LoadingSpinnerPage {...props} />
    }
    if (userType == "student" && (studentModel == null || studentModel == "loading")) {
        return <LoadingSpinnerPage {...props} />
    }

    let from = "ViewLesson";
    if (extraProps.from != null) {
        from = extraProps.from;
    }

    const existingAmendments = lessonProps.amendments || [];
    
    const titleText = <Text {...titleTextProps}>Amend lesson</Text>
    
    let backLink = "/Student/" + from;
    if (userType == "parent") {
        backLink = "/Parent/" + from;
    }
    else if (userType == "parentStudent") {
        backLink = "/Parent/" + from;
    }
    else if (userType == "tutor") {
        backLink = "/Tutor/" + from;
    }
    else if (userType == "admin") {
        backLink = "/Admin/" + from;
    }

    const backButton = <BackButton {...props} defaultBack={backLink} />

    //Cross icon that cancels adding a new amendment
    const cross = <Icon
        key={"closeButton"}
        width={"30px"}
        height={"30px"}
        as={MdClose}
        color={"ff0000"}
        position={"absolute"}
        right={"-40px"}
    />

    //Called when the generic "Request new lesson amendment" button is pressed
    const addExtraAmendment = function() {
        if (!formSubmitted) {
            setAnswers(defaultAnswers);
            checkAnswersForErrors(defaultAnswers);
            if (!addNewAmendment) {
                setAddNewAmendment(true);
            }
            else {
                setAddNewAmendment(false);
                setSelectedAmendment(null);
                setAmendmentText(null);
            }
        }
    }

    let addExtraAmendmentButton = <AppNavButton width={width} maxWidth={"70vw"} fontSize={"17px"} text={"Request new lesson amendment"} onClickFunction={addExtraAmendment} disabled={formSubmitted} />
    let addExtraAmendmentButtonColour = null;
    //If an amendment is being added, change the colour of the button and add the cross next to it
    if (addNewAmendment == true) {
        addExtraAmendmentButtonColour = "#f6fc7e"
        addExtraAmendmentButton = <Flex
            style={{cursor:"pointer"}}
            position={"relative"} 
            alignItems={"center"} 
            onClick={() => {
                addExtraAmendment()
            }}
        >
            <AppNavButton width={width} maxWidth={"70vw"} fontSize={"17px"} text={"Request new lesson amendment"} onClickFunction={addExtraAmendment} backgroundColour={addExtraAmendmentButtonColour} />
            {cross}
        </Flex>
    }

    //Ensure answers are complete before being able to finalise them
    const checkAnswersForErrors = function(newAnswers = answers, givenNewAmendments = newAmendments) {
        let anyBadAnswers = false;
        let newAmendmentsToCheck = [];
        try {
            if (selectedAmendment == "addOneOff") {
                if (userType != "admin" || answers.alreadyConfirmed == "No") {
                    if (newAnswers.datePicker == null) {
                        throw "No date given";
                    }
                    const validDayAvailability = validateField(newAnswers.dayAvailability, [{type: "ValidDayAvailability"}, {type: "Required", questionType: "dayavailability"}]);
                    if (validDayAvailability.hasError) {
                        throw "Invalid day availability";
                    }
                    if (newAnswers.sessionLength == null || newAnswers.sessionLength == "") {
                        throw "No session length given";
                    }
                    const validLessonType = validateField(newAnswers.lessonType, [{type: "ValidLessonType"}]);
                    if (validLessonType.hasError) {
                        throw "Invalid lesson type";
                    }
                    if (newAnswers.lessonType != "Online") {
                        const validAddress = validateField(newAnswers.address, [{type: "ValidAddress"}]);
                        if (validAddress.hasError) {
                            throw "Invalid address";
                        }
                    }

                    newAmendmentsToCheck.push({
                        type: "addOneOff",
                        date: newAnswers.datePicker,
                        lessonType: newAnswers.lessonType,
                        address: newAnswers.address,
                        sessionLength: newAnswers.sessionLength,
                        dayAvailability: newAnswers.dayAvailability,
                        confirmed: false
                    });
                }
                else if (userType == "admin" && answers.alreadyConfirmed == "Yes") {
                    if (newAnswers.datePicker == null) {
                        throw "No date given";
                    }
                    const validLessonType = validateField(newAnswers.lessonType, [{type: "ValidLessonType"}]);
                    if (validLessonType.hasError) {
                        throw "Invalid lesson type";
                    }
                    if (newAnswers.lessonType != "Online") {
                        const validAddress = validateField(newAnswers.address, [{type: "ValidAddress"}]);
                        if (validAddress.hasError) {
                            throw "Invalid address";
                        }
                    }
                    const validLessonTime = validateField(newAnswers.lessonTime, [{type: "ValidTimeRange"}]);
                    if (validLessonTime.hasError) {
                        throw "Invalid lesson time";
                    }

                    newAmendmentsToCheck.push({
                        type: "addOneOff",
                        date: newAnswers.datePicker,
                        lessonType: newAnswers.lessonType,
                        address: newAnswers.address,
                        startTime: newAnswers.lessonTime.startTime,
                        endTime: newAnswers.lessonTime.endTime,
                        confirmed: true
                    });
                }
                else {
                    anyBadAnswers = true;
                }
            }
            else if (selectedAmendment == "addScheduled") {
                if (newAnswers.sessionLength == null || newAnswers.sessionLength == "") {
                    throw "No session length given";
                }
                if (newAnswers.lessonType == null) {
                    throw "No lesson type selected";
                }
                if (newAnswers.lessonType != "Online") {
                    const validAddress = validateField(newAnswers.address, [{type: "ValidAddress"}]);
                    if (validAddress.hasError) {
                        throw "Invalid address";
                    }
                }
                const validWeekAvailability = validateField(newAnswers.weekAvailability, [{type: "ValidWeekAvailability", minSessionLength: answers.sessionLength}, {type: "Required", questionType: "weekavailability"}]);
                if (validWeekAvailability.hasError) {
                    throw "Invalid week availability";
                }
                newAmendmentsToCheck.push({
                    type: "addScheduled",
                    lessonType: newAnswers.lessonType,
                    address: newAnswers.address,
                    sessionLength: newAnswers.sessionLength,
                    weekAvailability: newAnswers.weekAvailability,
                    confirmed: false
                });
            }
            else if (selectedAmendment == "cancelSingle") {
                if (newAnswers.selectedLessons == null || Object.values(newAnswers.selectedLessons).length == 0) {
                    throw "No lessons selected";
                }
                let selectedLessons = [];
                try {
                    selectedLessons = Object.values(newAnswers.selectedLessons);
                } catch {}
                for (const selectedLesson of selectedLessons) {
                    newAmendmentsToCheck.push({
                        type: "cancelSingle",
                        date: selectedLesson.date,
                        startTime: selectedLesson.startTime,
                        endTime: selectedLesson.endTime,
                        confirmed: false
                    });
                }
            }
            else if (selectedAmendment == "setLastDate") {
                if (newAnswers.selectedLesson == null) {
                    throw "No lesson selected";
                }
                const selectedLesson = newAnswers.selectedLesson || {};
                newAmendmentsToCheck.push({
                    type: "setLastDate",
                    date: selectedLesson.date,
                    startTime: selectedLesson.startTime,
                    endTime: selectedLesson.endTime,
                    confirmed: false
                });
            }
            else if (selectedAmendment == "changeDayTime") {
                const sessionLengthMins = getSessionLength(lessonProps.startTime, lessonProps.endTime, true);
                const validWeekAvailability = validateField(newAnswers.weekAvailability, [{type: "ValidWeekAvailability", minSessionLength: sessionLengthMins}, {type: "Required", questionType: "weekavailability"}]);
                if (validWeekAvailability.hasError) {
                    throw "Invalid week availability";
                }
                newAmendmentsToCheck.push({
                    type: "changeDayTime",
                    weekAvailability: newAnswers.weekAvailability,
                    confirmed: false
                });
            }
            else if (selectedAmendment == "changeLocation") {
                const validAddress = validateField(newAnswers.address, [{type: "ValidAddress"}]);
                if (validAddress.hasError) {
                    throw "Invalid address";
                }
                newAmendmentsToCheck.push({
                    type: "changeLocation",
                    address: newAnswers.address,
                    confirmed: false
                });
            }
            else if (selectedAmendment == "changeType") {
                if (newAnswers.lessonType != "Online" && newAnswers.lessonType != "In-Person") {
                    throw "Invalid lesson type";
                }
                if (newAnswers.lessonType == "In-Person") {
                    const validAddress = validateField(newAnswers.address, [{type: "ValidAddress"}]);
                    if (validAddress.hasError) {
                        throw "Invalid address";
                    }
                }
                newAmendmentsToCheck.push({
                    type: "changeType",
                    lessonType: newAnswers.lessonType,
                    address: newAnswers.address,
                    confirmed: false
                });
            }
            else if (selectedAmendment == "cancelAll") {
                if (newAnswers.confirmCancel != "Yes, I'm sure") {
                    throw "Cancel all confimation not given";
                }
                newAmendmentsToCheck.push({
                    type: "cancelAll",
                    confirmed: false
                });
            }

            if (selectedAmendment != null) {
                const allNewAmendments = [...existingAmendments, ...givenNewAmendments, ...newAmendmentsToCheck];
                const validNewAmendment = validateField(allNewAmendments, [{type: "ValidLessonAmendments", lessonProps: lessonProps, checkCompatibility: true}]);
                if (validNewAmendment.hasError) {
                    setErrorText(validNewAmendment.errorMessage);
                    anyBadAnswers = true;
                    console.log(validNewAmendment.errorMessage)
                }
                else {
                    setErrorText(null);
                }
            }
    
            setAnyErrors(anyBadAnswers);
        }
        catch (error) {
            console.log(error);
            setErrorText(error);
            setAnyErrors(true);
        }
    }
    
    //Create the date picker question element
    const disabledDays = [];
    let datePicker = null;
    if (selectedAmendment == "addOneOff") {
        let selectedDate = null;
        if (answers != null && answers.datePicker != null) {
            selectedDate = new Date(answers.datePicker);
        }
        datePicker = <Flex backgroundColor={"#eeeeee"} borderRadius={"10px"}><DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={(answer) => {
                handleChange("datePicker", answer);
            }}
            fromDate={nextDate}
            disabled={disabledDays}
            styles={{
                //caption: { color: 'blue' },
                //head: { color: 'red' },
                //button: { color: 'red' },
                //button: {color: 'black', backgroundColor: 'red' },  
            }}
        /></Flex>
    }

    //Create the availability/time picker component
    let dayAvailabilityPicker = null;
    const questionErrorProps = {...standardTextProps};
    questionErrorProps.fontSize = "14px";
    questionErrorProps.position = "absolute";
    questionErrorProps.color = "#610000";
    questionErrorProps.maxWidth = "400px";
    let givenSessionLength = null;
    if (answers != null) {
        givenSessionLength = answers.sessionLength;
    }
    if (selectedAmendment == "addOneOff" && (answers.alreadyConfirmed == "No" || userType != "admin")) {
        let defaultDayAvailability = [];
        if (answers != null && answers.dayAvailability != null) {
            defaultDayAvailability = answers.dayAvailability;
        }
        dayAvailabilityPicker = <Flex marginTop={"5vh"}>
            <DayAvailabilityTemplate 
                id={"dayAvailability"} 
                key={"dayAvailability" + givenSessionLength + updateDayAvailability}
                label={"Availability"} 
                handleChange={handleChange}
                required={true}
                defaultValue={defaultDayAvailability}
                questionLabelProps={standardTextProps}
                questionErrorProps={questionErrorProps}
                answersSuccessfullySubmitted={formSubmitted}
                minSessionLength={givenSessionLength}
            />
        </Flex>
    }

    let lessonTimePicker = null;
    if ((selectedAmendment == "addOneOff" || selectedAmendment == "addScheduled") && answers.alreadyConfirmed == "Yes" && userType == "admin") {
        lessonTimePicker = <Flex marginTop={"5vh"} alignItems={"center"}>
            <TimeRangeTemplate 
                id={"lessonTime"} 
                key={"lessonTime" + givenSessionLength + updateDayAvailability}
                label={"Lesson time"} 
                handleChange={handleChange}
                required={true}
                questionLabelProps={standardTextProps}
                questionErrorProps={questionErrorProps}
                answersSuccessfullySubmitted={formSubmitted}
            />
        </Flex>
    }

    let availabilityPicker = null;
    if (selectedAmendment == "addScheduled" || selectedAmendment == "changeDayTime") {
        availabilityPicker = <Flex marginTop={""}>
            <WeekAvailabilityTemplate 
                id={"weekAvailability"} 
                key={"weekAvailability" + givenSessionLength}
                label={"Availability for lesson:"}
                handleChange={handleChange}
                required={true}
                questionLabelProps={standardTextProps}
                questionErrorProps={questionErrorProps}
                answersSuccessfullySubmitted={formSubmitted}
                minSessionLength={givenSessionLength || null}
                defaultValue={defaultAnswers.weekAvailability}
            />
        </Flex>
    }

    let amendmentTitle = null;
    if (amendmentText != null) {
        amendmentTitle = <Text {...standardTextProps}>{amendmentText}</Text>
    }
    //Create the amendment type question component
    let amendmentPicker = null;
    const amendmentPickerTextProps = {...standardTextProps}
    amendmentPickerTextProps.fontSize = "16px";
    let amendmentOptions = [];
    let foundChangeDayTime = false;
    let foundChangeType = false;
    let foundChangeLocation = false;
    let foundSetLastDate = false;
    let foundCancelAll = false
    const allAmendments = [...existingAmendments, ...newAmendments];
    for (const amendment of allAmendments) {
        if (amendment.type == "changeDayTime" && amendment.confirmed == false && amendment.rejectedBy == null) {
            foundChangeDayTime = true;
        }
        else if (amendment.type == "changeType" && amendment.confirmed == false && amendment.rejectedBy == null) {
            foundChangeType = true;
        }
        else if (amendment.type == "changeLocation" && amendment.confirmed == false && amendment.rejectedBy == null) {
            foundChangeLocation = true;
        }
        else if (amendment.type == "setLastDate" && amendment.confirmed == false && amendment.rejectedBy == null) {
            foundSetLastDate = true;
        }
        else if (amendment.type == "cancelAll") {
            foundCancelAll = true;
        }
    }
    //Depending on the user type, there will be different options available
    if (userType == "student" || userType == "parentStudent" || userType == "parent") {
        amendmentOptions = [
            "Add an extra one-off lesson",
            "Add an extra weekly scheduled lesson",
        ];
        if (lessonDates == null || lessonDates.length > 0) {
            amendmentOptions.push("Cancel single lesson(s)");
        }
        if (foundChangeDayTime == false) {
            amendmentOptions.push("Change lesson day/time");
        }
        if (foundChangeType == false) {
            amendmentOptions.push("Change lesson type");
        }
        if (lessonProps.lessonType == "In-Person" && foundChangeLocation == false) {
            amendmentOptions.push("Change lesson location");
        }
        if (foundSetLastDate == false) {
            amendmentOptions.push("Set last lesson date");
        }
        if (foundCancelAll == false) {
            amendmentOptions.push("Cancel all lessons");
        }
    }
    else if (userType == "tutor") {
        if (lessonDates != null && lessonDates.length > 0) {
            amendmentOptions.push("Cancel single lesson(s)");
        }
        amendmentOptions.push("Change lesson day/time");
        if (foundCancelAll == false) {
            amendmentOptions.push("Cancel all lessons");
        }
    }
    else if (userType == "admin") {
        amendmentOptions = ["Add an extra one-off lesson", "Add an extra weekly scheduled lesson"];
        if (lessonDates != null && lessonDates.length > 0) {
            amendmentOptions.push("Cancel single lesson(s)");
        }
        if (foundChangeDayTime == false) {
            amendmentOptions.push("Change lesson day/time");
        }
        if (foundChangeType == false) {
            amendmentOptions.push("Change lesson type");
        }
        if (lessonProps.lessonType == "In-Person" && foundChangeLocation == false) {
            amendmentOptions.push("Change lesson location");
        }
        if (foundSetLastDate == false) {
            amendmentOptions.push("Set last lesson date");
        }
        if (foundCancelAll == false) {
            amendmentOptions.push("Cancel all lessons");
        }
    }
    if (addNewAmendment) {
        amendmentPicker = <DropDownQuestionTemplate
            handleChange={handleChange}
            //key={"amendmentPicker" + selectedAmendment}
            id={"amendmentPicker"}
            label={"Choose an amendment"}
            defaultValue={lessonProps.newAmendmentType}
            options={amendmentOptions}
            questionLabelProps={amendmentPickerTextProps}
            questionErrorProps={questionErrorProps}
        />
    }

    //Create the session length question component
    let sessionLengthPicker = null;
    if ((selectedAmendment == "addOneOff" || selectedAmendment == "addScheduled") && (answers.alreadyConfirmed == "No" || userType != "admin")) {
        sessionLengthPicker = <DropDownQuestionTemplate
            handleChange={handleChange}
            id={"sessionLength"}
            label={"Choose a session length"}
            options={["45 Minutes", "1 Hour", "1 Hour 30 Minutes", "2 Hours"]}
            defaultValue = {lessonProps.sessionLength || "1 Hour"}
            questionLabelProps={amendmentPickerTextProps}
            questionErrorProps={questionErrorProps}
        />
    }

    let lessonTypePicker = null;
    const existingLessonType = lessonProps.lessonType;
    const lessonTypeOptions = [];
    if (selectedAmendment == "changeType") {
        if (existingLessonType == "Online") {
            lessonTypeOptions.push("In-Person");
        }
        else {
            lessonTypeOptions.push("Online");
        }
    }
    else {
        lessonTypeOptions.push("In-Person", "Online");
    }

    if (selectedAmendment == "addScheduled" || selectedAmendment == "changeType" || selectedAmendment == "addOneOff") {
        lessonTypePicker = <RadioButtonsTemplate
            id={"lessonType"}
            label={"Lesson Type:"}
            options={lessonTypeOptions}
            handleChange={handleChange}
            required={true}
            defaultValue={defaultAnswers.lessonType}
            questionLabelProps={amendmentPickerTextProps}
            questionErrorProps={questionErrorProps}
            key={"lessonType" + selectedAmendment}
        />
    }

    let addressPicker = null;
    if ((selectedAmendment == "changeType" && lessonProps.lessonType == "Online") || (selectedAmendment == "addOneOff" && answers.lessonType == "In-Person") || (selectedAmendment == "addScheduled" && answers.lessonType == "In-Person") || selectedAmendment == "changeLocation") {
        addressPicker = <ExistingOrNewAddressTemplate
            id={"address"}
            label={"Address:"}
            handleChange={handleChange}
            required={true}
            options={[...userAddressList]}
            defaultValue={defaultAnswers.address}
            validations={[{type:"ValidAddress"}]}
            questionLabelProps={amendmentPickerTextProps}
            questionErrorProps={questionErrorProps}
            key={"address" + selectedAmendment + answers.lessonType + defaultAnswers.lessonType + defaultAnswers.address}
        />
    }

    let alreadyConfirmedPicker = null;
    if ((selectedAmendment == "addOneOff" || selectedAmendment == "addScheduled") && userType == "admin") {
        alreadyConfirmedPicker = <RadioButtonsTemplate
            id={"alreadyConfirmed"}
            label={"Already Confirmed:"}
            handleChange={handleChange}
            required={true}
            options={["Yes", "No"]}
            defaultValue={"No"}
            validations={[{type:"ValidYesNo"}]}
            questionLabelProps={amendmentPickerTextProps}
            questionErrorProps={questionErrorProps}
            key={"alreadyConfirmed"}
        />
    }

    let confirmCancelPicker = null;
    if (selectedAmendment == "cancelAll") {
        confirmCancelPicker = <RadioButtonsTemplate
            id={"confirmCancel"}
            handleChange={handleChange}
            required={true}
            options={["Yes, I'm sure", "NO! Go back!"]}
            defaultValue={defaultAnswers.confirmCancel}
            questionLabelProps={amendmentPickerTextProps}
            questionErrorProps={questionErrorProps}
        />
    }

    let moreDetailsQuestion = null;
    if (selectedAmendment != null) {
        moreDetailsQuestion = <TextFieldTemplate 
            id={"moreDetails"}
            label={"Notes/reason (this will be visible to everyone involved):"}
            handleChange={handleChange}
            required={false}
            extraProps={{
                textType: "paragraph"
            }}
            questionLabelProps={amendmentPickerTextProps}
            questionErrorProps={questionErrorProps}
            validations={[]}
        />
    }

    //Called when a new amendment is added
    const confirmNewAmendment = async function() {
        const addedNewAmendments = [...newAmendments];
        let newAmendmentObject = null;
        if (selectedAmendment == "addOneOff") {
            newAmendmentObject = {
                type: "addOneOff",
                date: new Date(answers.datePicker).toDateString(),
                startTime: null,
                lessonType: answers.lessonType,
            };
            if (answers.lessonType != "Online") {
                newAmendmentObject.address = answers.address;
            }
            if (userType == "admin" && answers.alreadyConfirmed == "Yes") {
                newAmendmentObject.startTime = answers.lessonTime.startTime;
                newAmendmentObject.endTime = answers.lessonTime.endTime;
                newAmendmentObject.confirmed = true;
            }
            else {
                newAmendmentObject.confirmed = false;
                newAmendmentObject.dayAvailability = answers.dayAvailability;
                newAmendmentObject.sessionLength = answers.sessionLength;
            }
        }
        else if (selectedAmendment == "addScheduled") {
            newAmendmentObject = {
                type: "addScheduled",
                weekAvailability: answers.weekAvailability,
                sessionLength: answers.sessionLength,
                lessonType: answers.lessonType,
                address: answers.address,
                confirmed: false,
            };
        }
        else if (selectedAmendment == "cancelSingle") {
            const selectedLessonsArray = Object.values(answers.selectedLessons);
            const newLessonDates = [...lessonDates];
            for (const lesson of selectedLessonsArray) {
                const newObject = {
                    type: "cancelSingle",
                    date: lesson.date,
                    startTime: lesson.startTime,
                    endTime: lesson.endTime,
                    cancelType: lesson.type,
                    confirmed: true,
                };
                if (answers.moreDetails != null && answers.moreDetails != "") {
                    newObject.notes = {
                        [userID]: [answers.moreDetails]
                    };
                }
                addedNewAmendments.push(newObject);

                for (let lessonDateIndex = 0; lessonDateIndex < newLessonDates.length; lessonDateIndex = lessonDateIndex + 1) {
                    const lessonDateDetails = newLessonDates[lessonDateIndex];
                    if (lesson.date == lessonDateDetails.date && lesson.startTime == lessonDateDetails.startTime && lesson.endTime == lessonDateDetails.endTime) {
                        newLessonDates.splice(lessonDateIndex, 1);
                        lessonDateIndex = lessonDateIndex - 1;
                    }
                }
                setLessonDates(newLessonDates);
            }
        }
        else if (selectedAmendment == "setLastDate") {
            newAmendmentObject = {
                type: "setLastDate",
                date: answers.selectedLesson.date,
                startTime: answers.selectedLesson.startTime,
                endTime: answers.selectedLesson.endTime,
                confirmed: true,
            };
        }
        else if (selectedAmendment == "changeType") {
            newAmendmentObject = {
                type: "changeType",
                lessonType: answers.lessonType,
                confirmed: false,
            };
            if (answers.lessonType == "Online") {
                newAmendmentObject.confirmed = true;
            }
            else {
                newAmendmentObject.address = answers.address;
            }
        }
        else if (selectedAmendment == "changeLocation") {
            newAmendmentObject = {
                type: "changeLocation",
                address: answers.address,
                confirmed: false,
            };
        }
        else if (selectedAmendment == "changeDayTime") {
            newAmendmentObject = {
                type: "changeDayTime",
                weekAvailability: answers.weekAvailability,
                confirmed: false,
            };
        }
        else if (selectedAmendment == "cancelAll") {
            newAmendmentObject = {
                type: "cancelAll",
                confirmed: true,
            };
        }

        if (newAmendmentObject != null) {
            if (answers.moreDetails != null && answers.moreDetails != "") {
                newAmendmentObject.notes = {
                    [userID]: [answers.moreDetails]
                };
            }
            addedNewAmendments.push(newAmendmentObject);
        }
        const validAmendments = validateField(addedNewAmendments, [{type:"ValidLessonAmendments", checkCompatibility: true, lessonProps: lessonProps}]);
        if (validAmendments.hasError == false) {
            setNewAmendments(addedNewAmendments);
            setSelectedAmendment(null);
            setAmendmentText(null);
            setAnswers(defaultAnswers);
            setAddNewAmendment(false);
            setErrorText(null);
        }
        else {
            setErrorText(validAmendments.errorMessage);
        }
    };

    let confirmNewAmendmentButton = null;
    if (selectedAmendment != null) {
        confirmNewAmendmentButton = <Button
            children="Add Amendment"
            type="button"
            variation="primary"
            isDisabled={formSubmitted || anyErrors}
            fontSize={"2.2vh"}
            maxWidth={"25vh"}
            onClick={() => {
                confirmNewAmendment();
            }}
        />
    }

    //Called when the cross next to a new amendment is pressed
    const removeNewAmendment = function (amendmentIndex) {
        if (!formSubmitted) {
            const updatedNewAmendments = [...newAmendments];
            const removedAmendment = updatedNewAmendments[amendmentIndex];
            updatedNewAmendments.splice(amendmentIndex, 1);
            setNewAmendments(updatedNewAmendments);

            checkAnswersForErrors(answers, updatedNewAmendments);

            if (removedAmendment.type == "cancelSingle") {
                const newLessonDates = [...originalLessonDates];
                for (const amendment of updatedNewAmendments) {
                    if (amendment.type == "cancelSingle") {
                        for (let lessonDateIndex = 0; lessonDateIndex < newLessonDates.length; lessonDateIndex = lessonDateIndex + 1) {
                            const lessonDateDetails = newLessonDates[lessonDateIndex];
                            if (amendment.date == lessonDateDetails.date && amendment.startTime == lessonDateDetails.startTime && amendment.endTime == lessonDateDetails.endTime) {
                                newLessonDates.splice(lessonDateIndex, 1);
                                lessonDateIndex = lessonDateIndex - 1;
                            }
                        }
                    }
                }
                setLessonDates(newLessonDates);
            }
        }
    };

    //Called when the form is submitted, generates the various submit stages after a random amount of time (taken from form template)
    const preSubmitPopUpStages = async function() {
        setPreSubmitPopUp({
            show: true,
            text: "Verifying answers..."
        });
        const randomWait1 = Math.floor(Math.random() * 200) + 200;
        await sleep(randomWait1);
        setPreSubmitPopUp({
            show: true,
            text: "Submitting answers..."
        });
        const randomWait2 = Math.floor(Math.random() * 400) + 300;
        await sleep(randomWait2);
        setPreSubmitPopUp({
            show: true,
            text: "Waiting for server..."
        });
    }

    //Submit any new amendments to the database
    const submitNewAmendments = async function () {
        try {
            console.log(newAmendments);
            const validAmendments = validateField(newAmendments, [{type:"ValidLessonAmendments", checkCompatibility: true, lessonProps: lessonProps}]);
            if (validAmendments.hasError == true) {
                throw validAmendments.errorMessage;
            }

            preSubmitPopUpStages();
            setFormSubmitted(true);
            setNumNewAmendments(newAmendments.length);
            setAnswersSubmitted(currentAnswerIteration);
            const newLessonAmendments = [];
            const existingLessonAmendments = [...existingAmendments];
            let totalNewAmendments = 0;

            for (const newAmendment of newAmendments) {
                // if (newAmendment.type == "cancelSingle" && newAmendment.cancelType == "extra") {
                //     for (let amendmentIndex = 0; amendmentIndex < existingLessonAmendments.length; amendmentIndex = amendmentIndex+ 1) {
                //         const existingAmendment = existingLessonAmendments[amendmentIndex];
                //         if (existingAmendment.type == "addOneOff" && existingAmendment.confirmed && existingAmendment.date == newAmendment.date && existingAmendment.startTime == newAmendment.startTime) {
                //             existingLessonAmendments.splice(amendmentIndex, 1);
                //             amendmentIndex = amendmentIndex - 1;
                //         }
                //     }
                //     totalNewAmendments = totalNewAmendments + 1;
                // }
                //else {
                newAmendment.requestedBy = {
                    userID: props.userID,
                    userType: userType,
                    date: new Date()
                };
                newLessonAmendments.push(newAmendment);
                totalNewAmendments = totalNewAmendments + 1;
                //}
            }
            if (totalNewAmendments > 0) {
                //newLessonAmendments.push(...existingLessonAmendments);
                const lessonModelUpdate = {
                    id: lessonProps.id,
                    amendments: {
                        add: newLessonAmendments
                    }
                };
                const newLessonModel = await APIFunctions.updateLesson(lessonModelUpdate, userType);
                console.log("Updated lesson amendments:");
                console.log(newLessonModel);
            }
            setNewAmendments([]);
            setFormSubmitted(false);
            setAnswers(defaultAnswers);
            setSubmitPopUp(true);
            setCurrentAnswerIteration(currentAnswerIteration + 1);
            setPreSubmitPopUp({show: false, text: ""});
            setFormSubmitted(false);
            setLessonDates(null);
        }
        catch (error) {
            console.log(error);
            let errorMessage = "Error requesting new lesson change: ";
            if (numNewAmendments > 1) {
                errorMessage = "Error requesting new lesson changes: ";
            }
            if (typeof(error) == "string") {
                errorMessage = errorMessage + error;
            }
            const errorAnswers = {...answers};
            errorAnswers.selectedAmendment = selectedAmendment;
            props.APIFunctions.logFormError(error, errorAnswers);
            console.log(errorMessage + ": " + error);
            setFormSubmissionErrorPopUp({show:true, message: errorMessage});
            setPreSubmitPopUp({show: false, text: ""});
            setCurrentAnswerIteration(currentAnswerIteration + 1);
            setAnswers(defaultAnswers);
            //setNewAmendments([]);
            setFormSubmitted(false);
        }
    };

    let submitButtonText = null;
    let submitButton = null;
    if (newAmendments.length == 1) {
        submitButtonText = "Submit new amendment"
    }
    else if (newAmendments.length > 1) {
        submitButtonText = "Submit new amendments"
    }
    if (newAmendments.length > 0) {
        submitButton = <Button
            children={submitButtonText}
            type="button"
            variation="primary"
            isDisabled={formSubmitted}
            fontSize={"2.0vh"}
            maxWidth={"30vh"}
            onClick={() => {
                submitNewAmendments();
            }}
        />
    }

    //Create the UI to show the user their new pending amendments that they've just made 
    let newAmendmentsTitle = null;
    let newAmendmentsUIs = null;
    if (newAmendments.length > 0) {
        newAmendmentsTitle = <Text {...standardTextProps}>New changes:</Text>
        newAmendmentsUIs = [];
        for (let amendmentIndex = 0; amendmentIndex < newAmendments.length; amendmentIndex = amendmentIndex + 1) {
            const amendment = newAmendments[amendmentIndex];
            const amendmentUI = <AmendmentTemplate 
                key={"NewAmendmentIndex:" + amendmentIndex} 
                amendment={amendment} 
                amendmentIndex={amendmentIndex} 
                removeAmendment={removeNewAmendment} 
                standardTextProps={standardTextProps}
                lessonProps={lessonProps}
            />
            newAmendmentsUIs.push(amendmentUI)
        }
    }
    
    let existingAmendmentsTitle = null;
    let existingAmendmentsUIs = [];
    let pendingAmendmentsTitle = null;
    let pendingAmendmentsUIs = [];
    let rejectedAmendmentsTitle = null;
    let rejectedAmendmentsUIs = [];
   
    
    if (existingAmendments != null && existingAmendments.length > 0) {
        for (let amendmentIndex = 0; amendmentIndex < existingAmendments.length; amendmentIndex = amendmentIndex + 1) {
            const existingAmendment = existingAmendments[amendmentIndex];
            const amendmentUI = <AmendmentTemplate
                key={"ExistingAmendmentIndex:" + amendmentIndex} 
                amendment={existingAmendment} 
                standardTextProps={standardTextProps}
                lessonProps={lessonProps}
            />
            if (existingAmendment.confirmed == true) {
                existingAmendmentsUIs.push(amendmentUI);
                existingAmendmentsTitle = <Text {...standardTextProps}>Confirmed changes:</Text>;
            }
            else if (existingAmendment.confirmed == false && existingAmendment.rejectedBy != null) {
                rejectedAmendmentsTitle = <Text {...standardTextProps}>Rejected changes:</Text>;
                rejectedAmendmentsUIs.push(amendmentUI);
            }
            else {
                pendingAmendmentsTitle = <Text {...standardTextProps}>Pending changes:</Text>;
                pendingAmendmentsUIs.push(amendmentUI);
            }
        }
    }

    let errorTextComponent = null;
    if (errorText != null && errorText != "") {
        errorTextComponent = <Text 
            {...standardTextProps} 
            fontSize={"15px"} 
            width={"80vw"} 
            position={"absolute"} 
            top={"-50px"}
            color={"#ff0000"}
        >
            {errorText}
        </Text>
    }

    let orientation = "row";
    let questionGap = "5vw";
    let questionAlign = "flex-start";
    if (props.width < 800) {
        orientation = "column";
        questionGap = "0px"
        questionAlign = "center";
    }
    
    let submitPopUpText = "Successfully requested new change. This may need to be approved by another party before it can take effect";
    if (numNewAmendments > 1) {
        submitPopUpText = "Successfully requested new changes. This may need to be approved by another party before it can take effect";
    }

    return (
        <Flex direction={"column"} gap={"3vh"} alignItems={"center"}> 
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
                {titleText}
                <Flex gap={"4vw"}>
                    {backButton}
                </Flex>
            </Flex>
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>             
                {lessonFlex}
                {addExtraAmendmentButton}
                {amendmentPicker}
                {amendmentTitle}
                {confirmCancelPicker}
                {alreadyConfirmedPicker}
                {selectors.lessonSelector}
                {sessionLengthPicker}
                {lessonTypePicker}
                {addressPicker}
                {availabilityPicker}
                <Flex direction={orientation} gap={questionGap} alignItems={questionAlign}>
                    {datePicker}
                    {dayAvailabilityPicker}
                    {lessonTimePicker}
                </Flex>
                {moreDetailsQuestion}
                <Flex direction={"column"} alignItems={"center"} position={"relative"}>
                    {errorTextComponent}
                    {confirmNewAmendmentButton}
                </Flex>
            </Flex>
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
                <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
                    {newAmendmentsTitle}
                    {newAmendmentsUIs}
                </Flex>
                {submitButton}
            </Flex>
            <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
                {pendingAmendmentsTitle}
                {pendingAmendmentsUIs}
            </Flex>
            <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
                {existingAmendmentsTitle}
                {existingAmendmentsUIs}
            </Flex>
            <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
                {rejectedAmendmentsTitle}
                {rejectedAmendmentsUIs}
            </Flex>
            {(preSubmitPopUp.show && answersSubmitted == currentAnswerIteration) && <PopUpTemplate key={"preSubmit" + preSubmitPopUp.text} text={preSubmitPopUp.text} setPopUpVisibility={setPreSubmitPopUp} showXButton={false} />}
            {submitPopUp && <PopUpTemplate text={submitPopUpText} setPopUpVisibility={setSubmitPopUp} okButtonFunction={()=>{}} showXButton={false} />}
            {formSubmissionErrorPopUp.show && <PopUpTemplate text={formSubmissionErrorPopUp.message} setPopUpVisibility={setFormSubmissionErrorPopUp} okButtonFunction={()=>{}} showXButton={false} reloadOnDismiss={true} />}
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default AmendLesson;