import { Flex, Text } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { calculateLessonCost, calculateLessonWage, getDayName, getSessionEndTime } from '../../App';
import { FormTemplate } from '../../custom-ui-components';

const RespondToAmendment = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    //Get the current user to pass to subsequent functions

    const [acceptReject, setAcceptReject] = useState(null);
    const [redirect, setRedirect] = useState(null);

    const location = useLocation();
    const amendment = location.state;
    let userPath = null;
    if (amendment == null) {
        const pathName = window.location.pathname;
        userPath = pathName.split("/")[1];
        return <Navigate to={"/" + userPath + "/RespondToAmendments"} />
    }

    const lessonProps = amendment.lesson;
    const userType = amendment.userType;
    const amendmentType = amendment.type;

    if (lessonProps == null || userType == null || amendmentType == null) {
        let path = "/";
        if (userPath != null) {
            path = "/" + userPath + "/RespondToAmendments";
        }
        return <Navigate to={path} />
    }

    const titleText = <Text {...titleTextProps}>Respond to Lesson Amendment Request</Text>

    const lessonDetails = [];
    lessonDetails.push(
        <Text {...titleTextProps} key={"LessonDetails"} >Current Lesson Details:</Text>
    );
    if (userType != "tutor") {
        lessonDetails.push(
            <Text {...standardTextProps} key={"tutor"} >Tutor: {lessonProps.tutorFirstNames} {lessonProps.tutorLastName}</Text>
        );
    }
    if (userType != "student") {
        lessonDetails.push(
            <Text {...standardTextProps} key={"student"} >Student: {lessonProps.studentNames}</Text>
        );
    }
    lessonDetails.push(
        <Text {...standardTextProps} key={"subject"} >Subject: {lessonProps.subject}</Text>
    );
    lessonDetails.push(
        <Text {...standardTextProps} key={"date/time"} >{lessonProps.lessonDay}s: {lessonProps.startTime}-{lessonProps.endTime}</Text>
    );
    lessonDetails.push(
        <Text {...standardTextProps} key={"lessonType"} >Lesson type: {lessonProps.lessonType}</Text>
    );
    if (lessonProps.lessonType == "In-Person") {
        lessonDetails.push(
            <Text {...standardTextProps} key={"address"} >{lessonProps.address.address1stLine},</Text>
        );
        lessonDetails.push(
            <Text {...standardTextProps} key={"postcode"} >{lessonProps.address.postcode}</Text>
        );
    }
    const currentDate = new Date(new Date().toDateString());
    const lessonStartDate = new Date(lessonProps.startDate);
    if (currentDate <= lessonStartDate) {
        lessonDetails.push(
            <Text {...standardTextProps} key={"startDate"}>Start date: {lessonProps.startDate}</Text>
        );
    }
    let endDateString = "N/A";
    if (lessonProps.endDate != null) {
        endDateString = lessonProps.endDate;
    }
    lessonDetails.push(
        <Text {...standardTextProps} key={"endDate"}>End date: {endDateString}</Text>
    );
    lessonDetails.push(
        <Text {...standardTextProps} key={"tutorLevel"}>Tutor level: {lessonProps.tutorLevel}</Text>
    );

    if (userType != "tutor") {
        lessonDetails.push(
            <Text {...standardTextProps} key={"tutorCost"}>Lesson cost: {calculateLessonCost(lessonProps.tutorLevel, lessonProps.startTime, lessonProps.endTime, lessonProps.lessonType, true)}</Text>
        );
    }
    if (userType != "parent" && userType != "student") {
        lessonDetails.push(
            <Text {...standardTextProps} key={"tutorCost"}>Lesson wage: {calculateLessonWage(lessonProps.tutorLevel, lessonProps.startTime, lessonProps.endTime, lessonProps.lessonType, true)}</Text>
        );
    }

    const lessonDetailsFlex = <Flex direction={"column"} gap={"0px"}>
        {lessonDetails}
    </Flex>

    const generateAvailabilityStringFromArray = function (availabilityArray) {
        try {
            let availabilityString = "";
            const availabilityWindows = availabilityArray.sort((a, b) => {
                const aStartTime = a.startTime;
                const aStartHour = parseInt(aStartTime.split(":")[0]);
                const aStartMinute = parseInt(aStartTime.split(":")[1]);
                const aStartTimeInt = (aStartHour * 60) + aStartMinute;
                const bStartTime = b.startTime;
                const bStartHour = parseInt(bStartTime.split(":")[0]);
                const bStartMinute = parseInt(bStartTime.split(":")[1]);
                const bStartTimeInt = (bStartHour * 60) + bStartMinute;
                return aStartTimeInt - bStartTimeInt;
            });
            for (let availabilityWindowIndex = 0; availabilityWindowIndex < availabilityWindows.length; availabilityWindowIndex = availabilityWindowIndex + 1) {
                const availabilityWindow = availabilityWindows[availabilityWindowIndex];
                const availabilityWindowText = availabilityWindow.startTime + "-" + availabilityWindow.endTime;
                availabilityString = availabilityString + availabilityWindowText;
                if (availabilityWindowIndex < availabilityWindows.length - 1) {
                    availabilityString = availabilityString + ", ";
                }
            }
            return availabilityString;
        }
        catch (error) {
            return "Error generating availability string";
        }
    }

    let dayAvailability = amendment.dayAvailability;
    let dayAvailabilityWindowsText = null;
    if (dayAvailability != null) {
        dayAvailabilityWindowsText = generateAvailabilityStringFromArray(dayAvailability);
    }

    let weekAvailability = amendment.weekAvailability;
    let weekAvailabilityUIs = null;
    const availableDays = [];
    if (weekAvailability != null) {
        const availabilityStrings = [];
        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        for (const day of days) {
            const dayAvailability = weekAvailability[day];
            if (Array.isArray(dayAvailability) && dayAvailability.length > 0) {
                const dayAvailabilityString = generateAvailabilityStringFromArray(dayAvailability);
                availabilityStrings.push({
                    day: day,
                    string: dayAvailabilityString
                });
                availableDays.push(day);
            }
        }

        weekAvailabilityUIs = [];
        for (const dayObject of availabilityStrings)  {
            const dayUI = <Text {...standardTextProps} key={"addScheduledAvailability" + dayObject.day} >{dayObject.day}: {dayObject.string}</Text>
            weekAvailabilityUIs.push(dayUI);
        }
    }

    const amendmentText = [];
    amendmentText.push(
        <Text {...titleTextProps} key={"AmendmentDetails"} marginTop={"20px"}>New Amendment Request:</Text>
    );
    let backgroundColour = "#34eb9e";
    if (amendment.date != null) {
        const currentDate = new Date();
        const amendmentDate = new Date(amendment.date);
        if (amendment.startTime != null) {
            const startTimeHour = parseInt(amendment.startTime.split(":")[0], 10);
            const startTimeMinute = parseInt(amendment.startTime.split(":")[1], 10)
            amendmentDate.setHours(startTimeHour);
            amendmentDate.setMinutes(startTimeMinute);
        }
        let endDate = new Date();
        endDate.setUTCFullYear(endDate.getUTCFullYear() + 1);
        if (lessonProps.endDate != null) {
            endDate = new Date(lessonProps.endDate);
        }
        if ((amendmentDate < currentDate || amendmentDate > endDate) && amendmentType != "setLastDate") {
            backgroundColour = "#e8e8e8";
            standardTextProps.color = "#a8a8a8";
        }
    }
    try {
        if (amendmentType == "addOneOff") {
            if (amendment.date == null) {
                throw "Invalid date";
            }
            const date = new Date(amendment.date);

            let timeText = null;
            if (amendment.confirmed == true) {
                const startTime = amendment.startTime;
                const sessionLength = amendment.sessionLength;
                const endTime = getSessionEndTime(startTime, sessionLength);
                timeText = startTime + "-" + endTime;
                amendmentText.push(
                    <Text {...standardTextProps} key={"addOneOffMain"} >Add one-off lesson: {date.toDateString()}, {timeText}</Text>
                );
            }
            else {
                amendmentText.push(
                    <Text {...standardTextProps} key={"addOneOffMain"} >Add one-off lesson: {date.toDateString()}, {amendment.sessionLength}</Text>,
                    <Text {...standardTextProps} key={"addOneOffAvailability"}>Availability: {dayAvailabilityWindowsText}</Text>
                );
            }
        }
        else if (amendmentType == "addScheduled") {
            let addressText = null;
            if (amendment.lessonType != "Online") {
                addressText = <Text {...standardTextProps} key={"addScheduledAddress"} >Address: {amendment.address.address1stLine}, {amendment.address.postcode}</Text>;
            }
            amendmentText.push(
                <Text {...standardTextProps} key={"addScheduledMain"} >New scheduled lesson: {amendment.sessionLength}, {amendment.lessonType}</Text>,
                addressText,
                //<Text {...standardTextProps} key={"addScheduledAvailability"} >Availability:</Text>,
                weekAvailabilityUIs
            );
        }
        else if (amendmentType == "cancelSingle") {
            amendmentText.push(
                <Text {...standardTextProps} key={"cancelSingleMain"} >No lesson: {amendment.date}, {amendment.startTime} - {amendment.endTime}</Text>
            );
        }
        else if (amendmentType == "cancelAll") {
            amendmentText.push(
                <Text {...standardTextProps} key={"cancelAllMain"} >Cancel all future lessons</Text>
            );
        }
        else if (amendmentType == "setLastDate") {
            amendmentText.push(
                <Text {...standardTextProps} key={"setLastDateMain"} >Final lesson: {amendment.date}</Text>
            );
        }
        else if (amendmentType == "changeType") {
            let addressString = null;
            if (amendment.lessonType == "In-Person") {
                addressString = "- " + amendment.address.address1stLine + ", " + amendment.address.postcode;
            }
            amendmentText.push(
                <Text {...standardTextProps} key={"changeTypeMain"} >New lesson type: {amendment.lessonType} {addressString}</Text>
            );
        }
        else if (amendmentType == "changeLocation") {
            amendmentText.push(
                <Text {...standardTextProps} key={"changeLocationMain"} >New lesson location: {amendment.address.address1stLine}, {amendment.address.postcode}</Text>
            );
        }
        else if (amendmentType == "changeDayTime") {
            amendmentText.push(
                <Text {...standardTextProps} key={"changeDayTimeMain"} >Change lesson day/time:</Text>,
                weekAvailabilityUIs
            );
        }
        else {
            amendmentText.push(
                <Text {...standardTextProps} key={"invalidAmendment"} >Invalid amendment type</Text>
            );
        }

        if (amendment.notes != null) {
            const noteTexts = [];
            const amendmentNotesArray = Object.entries(amendment.notes);
            for (const userNotes of amendmentNotesArray) {
                const user = userNotes[0];
                const notes = userNotes[1];
                if (user != props.userID) {
                    for (const note of notes) {
                        if (note != null && note != "") {
                            noteTexts.push(<Text {...standardTextProps} key={"note:" + note}>{note}</Text>)
                        }
                    }
                }
            }
            if (noteTexts.length > 0) {
                let suffix = "";
                if (noteTexts.length > 1) {
                    suffix = "s";
                }
                amendmentText.push(
                    <Text {...standardTextProps} key={"noteTitle"} marginTop={"1vh"}>Note{suffix}:</Text>,
                    ...noteTexts
                );
            }
        }
    }
    catch (error) {
        console.log("Error generating amendment UI: " + error);
        amendmentText.push(
            <Text {...standardTextProps}>Invalid amendment</Text>
        );
    }

    const amendmentDetailsFlex = <Flex direction={"column"} gap={"0px"}>
        {amendmentText}
    </Flex>
    
    const submit = async function (answers) {
        try {
            let newAmendment = {...amendment};
            console.log(newAmendment);
            console.log(answers);
            if (answers.acceptReject == "Accept") {
                if (amendmentType == "changeDayTime") {
                    const newLesson = answers.newLessons.lessons[0];
                    delete newLesson.startDate;
                    newAmendment.startTime = newLesson.startTime;
                    newAmendment.endTime = newLesson.endTime;
                    newAmendment.lessonDay = newLesson.day;
                }
                if (amendmentType == "addOneOff") {
                    const newLesson = answers.newLessons.lessons[0];
                    newAmendment.startTime = newLesson.startTime;
                    newAmendment.endTime = newLesson.endTime;
                }
                newAmendment.confirmedBy = {
                    userID: props.user.username,
                    userType: userType,
                    date: new Date()
                };
                newAmendment.confirmed = true;
            }
            else if (answers.acceptReject == "Reject") {
                newAmendment.rejectedBy = {
                    userID: props.user.username,
                    userType: userType,
                    reason: answers.reason
                };
                newAmendment.confirmed = false;
            }

            delete newAmendment.lesson;
            delete newAmendment.userType;

            const newAmendments = {
                update: [newAmendment]
            };

            const lessonUpdateProps = {
                id: lessonProps.id,
                amendments: newAmendments
            }

            const newLessonProps = await APIFunctions.updateLesson(lessonUpdateProps, userType);
            console.log(newLessonProps);
        }
        catch (error) {
            console.log(error);
            throw {
                message: "Error responding to amendment request",
                error: error
            };
        }
    };

    const handleQuestionChange = function (questionID, newAnswer, hasError) {
        if (questionID == "acceptReject") {
            if (!hasError) {
                setAcceptReject(newAnswer);
            }
            else {
                setAcceptReject(null);
            }
        }
    };

    const exit = function () {
        setRedirect(<Navigate to={"/" + userType + "/RespondToAmendments"} />);
    };

    let respondQuestions = [];
    respondQuestions.push({
        id: "acceptReject", 
        label: "Accept/reject amendment request:",
        type: "radio", 
        required: true, 
        defaultValue: null,
        options: ["Accept", "Reject"],   
    });

    
    if (amendmentType == "changeDayTime" || amendmentType == "addOneOff") {
        if (amendmentType == "addOneOff") {
            const amendmentDate = new Date(amendment.date);
            const amendmentDayNum = amendmentDate.getDay();
            const dayName = getDayName(amendmentDayNum);
            amendment.weekAvailability = {
                [dayName]: amendment.dayAvailability
            };
        }
        respondQuestions.push({
            key: "lessonDayTime" + acceptReject,
            id: "newLessons", 
            label: "Choose lesson day/time",
            type: "createlessontime", 
            required: true,
            extraProps: {
                sessionLength: lessonProps.sessionLength,
                numSessions: 1,
                availability: amendment.weekAvailability,
                enforceNumSessions: true,
                showDate: false,
                defaultButtonOrLesson: "lesson"
            },
            onlyShow: {id: "acceptReject", answers: ["Accept"]}
        });
    }

    respondQuestions.push({
        key: "reason",
        id: "reason", 
        label: "Reason for rejecting",
        type: "text", 
        required: true,
        extraProps: {
            textType: "paragraph"
        },
        onlyShow: {id: "acceptReject", answers: ["Reject"]}
    });


    const respondForm = <FormTemplate
        {...props} 
        questions={respondQuestions}
        submitAnswers={submit}
        returnAnswersEarly={{
            handleChange: handleQuestionChange,
            questions: ["acceptReject", "changeDayTime"]
        }}
        okButtonFunction={exit}
    />

    return (
        <Flex direction={"column"}> 
            {redirect}
            {titleText}
            {lessonDetailsFlex}
            {amendmentDetailsFlex}
            {respondForm}
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default RespondToAmendment;