import './App.css';
import { Authenticator, Button, Flex, Text, useTheme, withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter, Routes, Route, withRouter, useLocation, Navigate, useNavigate } from "react-router-dom";
import {useEffect, useState, React} from 'react';

import { Amplify } from 'aws-amplify';
import { generateClient, get, put, post } from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
// import { API, Auth, graphqlOperation } from 'aws-amplify'; 

import { v4 as uuidv4 } from 'uuid';

//import WebSocket from "isomorphic-ws";

import LogoImage from "./Images/NDTLogoWhite50DPI.png";
import awsmobile from "./aws-exports.js";
import * as queries from "./graphql/queries.ts"
import * as mutations from "./graphql/mutations.ts"
import * as subscriptions from "./graphql/subscriptions.ts"


//Import Home page and layout
import Layout from "./pages/home/Layout.jsx";
import Home from "./pages/home/Home.jsx";
import UserNeedsAttributes from "./pages/home/UserNeedsAttributes.jsx";
import RegisterAsStudent from './pages/home/RegisterAsStudent.jsx';
import RegisterAsTutor from './pages/home/RegisterAsTutor.jsx';
import LayoutNoNavBar from './pages/LayoutNoNavbar.jsx';
import EmailNotVerified from './pages/EmailNotVerified.jsx';

//Import Student pages and layout
import StudentLayout from "./pages/student/StudentLayout.jsx";
import StudentHome from "./pages/student/StudentHome.jsx";
import StudentMyLessons from "./pages/student/MyLessons.jsx";
import StudentLessonRequests from './pages/student/StudentLessonRequests.jsx';
import NewLessonRequest from './pages/student/NewLessonRequest.jsx';

//Import Tutor pages and layout 
import TutorLayout from "./pages/tutor/TutorLayout.jsx";
import TutorHome from "./pages/tutor/TutorHome.jsx";
import TutorMyLessons from "./pages/tutor/MyLessons.jsx";
import TutorMyStudents from "./pages/tutor/MyStudents.jsx";
import LessonRequests from './pages/admin/LessonRequests.jsx';
import TutorNeedsAttributes from './pages/tutor/TutorNeedsAttributes.jsx';

//Import Admin pages and layout
import AdminLayout from './pages/admin/AdminLayout.jsx';
import AdminHome from "./pages/admin/AdminHome.jsx";
import AdminLessons from './pages/admin/AdminLessons.jsx';
import CreateNewLesson from './pages/admin/CreateNewLesson.jsx';
import EditLesson from './pages/admin/EditLesson.jsx';
import DeletedLessons from './pages/admin/DeletedLessons.jsx';
import LessonRequest from './pages/admin/LessonRequest.jsx';
import GiveAccessToTutor from './pages/admin/GiveAccessToTutor.jsx';

//Import SignOut Page
import SignOut from './pages/SignOut.jsx';
import ProfilePage from './pages/user/ProfilePage.jsx';
import UserLayout from './pages/user/UserLayout.jsx';
import { validateField } from './custom-ui-components/form-components/FormValidation.js';
import ViewLessonRequest from './pages/student/ViewLessonRequest.jsx';
import EditLessonRequest from './pages/student/EditLessonRequest.jsx';
import AdminViewLesson from './pages/admin/ViewLesson.jsx';
import ParentHome from './pages/parent/ParentHome.jsx';
import RegisterAsParent from './pages/home/RegisterAsParent.jsx';
import ParentLayout from './pages/parent/ParentLayout.jsx';
import MyStudent from './pages/parent/MyStudent.jsx';
import AddNewStudent from './pages/parent/AddNewStudent.jsx';
import ParentNewLessonRequest from './pages/parent/ParentNewLessonRequest.jsx';
import ParentLessons from './pages/parent/ParentLessons.jsx';
import ParentLessonRequests from './pages/parent/ParentLessonRequests.jsx';
import ViewUsers from './pages/admin/ViewUsers.jsx';
import TutorLessonRequests from './pages/tutor/TutorLessonRequests.jsx';
import TutorLessonRequest from './pages/tutor/TutorLessonRequest.jsx';
import { PageNotFound } from './pages/PageNotFound.jsx';
import { authComponents, AuthenticatorWithEmail, authFormFields } from './pages/Authentication.jsx';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TutorLessonRequestAccept from './pages/tutor/TutorLessonRequestAccept.jsx';
import TutorLessonRequestDecline from './pages/tutor/TutorLessonRequestDecline.jsx';
import TutorChangeAvailability from './pages/tutor/TutorChangeAvailability.jsx';
import TutorChangeSubjects from './pages/tutor/TutorChangeSubjects.jsx';
import UserSettingsPage from './pages/user/UserSettingsPage.jsx';
import ViewLesson from './pages/student/ViewLesson.jsx';
import AmendLesson from './pages/student/AmendLesson.jsx';
import LessonCredits from './pages/home/LessonCredits.jsx';
import ThankYouPurchase from './pages/home/ThankYouPurchase.jsx';
import ViewUser from './pages/admin/ViewUser.jsx';
import ViewTransactions from './pages/home/ViewTransactions.jsx';
import ViewTransaction from './pages/home/ViewTransaction.jsx';
import TeachingResourcesSubject from './pages/tutor/TeachingResourcesSubject.jsx';
import TeachingResourcesYearGroup from './pages/tutor/TeachingResourcesYearGroup.jsx';
import TeachingResources from './pages/tutor/TeachingResources.jsx';
import TeachingResourcesAdmin from './pages/admin/TeachingResourcesAdmin.jsx';
import CreateTeachingResource from './pages/admin/CreateTeachingResource.jsx';
import FooterTemplate from './custom-ui-components/FooterTemplate.jsx';
import ViewPricing from './pages/admin/ViewPricing.jsx';
import TutorHelpList from './pages/tutor/TutorHelpList.jsx';
import HelpPlanAndDeliver from './pages/tutor/HelpPlanAndDeliver.jsx';
import RespondToAmendment from './pages/student/RespondToAmendment.jsx';
import RespondToAmendments from './pages/student/RespondToAmendments.jsx';
import TutorChangePayment from './pages/tutor/TutorChangePayment.jsx';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorLoadingPage } from './pages/ErrorLoadingPage.jsx';
import { SiteMaintenance } from './pages/SiteMaintenance.jsx';
import VerifyID from './pages/home/VerifyID.jsx';
import SignUpThankYou from './pages/home/SignUpThankYou.jsx';
import Chats from './pages/home/Chats.jsx';
import PayTutors from './pages/admin/PayTutors.jsx';
import AdminChats from './pages/admin/AdminChats.jsx';
import MassEmail from './pages/admin/MassEmail.jsx';
import AgreeTerms from './pages/home/AgreeTerms.jsx';
import ParentStartGuide from './pages/parent/ParentStartGuide.jsx';
import StudentStartGuide from './pages/student/StudentStartGuide.jsx';
import TutorStartGuide from './pages/tutor/TutorStartGuide.jsx';
import ViewInAppCredit from './pages/admin/ViewInAppCredit.jsx';
import ViewPreviousLessons from './pages/admin/ViewPreviousLessons.jsx';
import ForceAppUpdate from './pages/admin/ForceAppUpdate.jsx';
import { LoadingSpinnerPage } from './pages/LoadingPage.jsx';
import Tests from './pages/admin/Tests.jsx';
import ReportIssue from './pages/home/ReportIssue.jsx';
import AppIssues from './pages/admin/AppIssues.jsx';
import HelicopterDeliveries from './pages/home/HelicopterDeliveries.jsx';
import { OptionsTemplate } from './custom-ui-components/index.js';
import UserRegistration from './pages/home/UserRegistration.jsx';
import { compareObjects, examBoards, isJSON, DayAvailability, TimeRange, TimeDetails, TimeDuration } from 'nextdoortutor';
import AuthPage from './pages/Auth.jsx';
console.log(examBoards);
const test = {
	startTime: "10:00",
	endTime: "11:35",
};
const testTimeRange = new TimeRange(test);


console.log(testTimeRange);


// const Buffer = require('buffer/').Buffer
const apiClient = generateClient();

const StyledLink = styled(Link)`
	color: #1b12ff;
	&:hover {
		color:#587af5;
	}
	&:active {
		color:#ff192d;
	}
`;

const ScrollToTop = function () {
	const {pathname} = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
};//

const ReactErrorBoundary = function (props) {
	const [error, setError] = useState(null);
	const [errorCleared, setErrorCleared] = useState(false);
	const [redirect, setRedirect] = useState(null);

	// Reset error state on after redirect
	const location = useLocation();
	useEffect(() => {
		setErrorCleared(null);
	}, [location]);

	const recordError = async function(error, errorInfo) {
		const recordErrorAPI = "errorReporting";
		const notifyErrorAPIPath = "/notifyError";

		let errorString = error;
		if (typeof(error) != "string") {
			if (error.stack != null) {
				errorString = error.stack.toString();
			}
		}

		const errorDetails = {
			accessJWT: props.user.signInUserSession.accessToken.jwtToken,
			error: errorString,
			errorInfo: JSON.stringify(errorInfo),
			currentPage: window.location.pathname,
			hostname: window.location.hostname,
			time: new Date().toString(),
			userAgent: navigator.userAgent,
			userAgentData: JSON.stringify(navigator.userAgentData)
		};
		setError(errorDetails);

		const putParameters = {
			body: errorDetails
		};

		if (window.location.hostname != "localhost") {
			const errorModel = await callAPIGateway(recordErrorAPI, notifyErrorAPIPath, putParameters);
			setError(errorModel);
		}
	}

	const reportBug = function () {
		console.log("Navigating to report bug page");
		setErrorCleared(true);
		setRedirect(<Navigate to={"/ReportIssue"} state={{defaultFormType: "bug", error: error}} />);
	};

	if (errorCleared) {
		return <>
			{redirect}
			{props.children}
		</>
	}
	else {
		return (
			<ErrorBoundary
				FallbackComponent={ErrorLoadingPage}
				onError={(error, errorInfo) => {
					console.log("Error caught!");  
					console.error(error);  
					console.error(errorInfo);
					recordError(error, errorInfo);
				}}
				onReset={reportBug}
			>
				{props.children}
			</ErrorBoundary>
		);
	}
};

const App = withAuthenticator(function(props) {
	const passingProps = {...props};

	const [clientUpdates, setClientUpdates] = useState({});

	// User Data:
	// Single user model for signed in user
	const [user, setUser] = useState(props.user);
	const [userModel, setUserModel] = useState(null);
	passingProps.user = user;
	passingProps.userModel = userModel;
	if (user != null) {
		passingProps.userID = user.username;
	}
	// Dictionary of all users for Admin
	const [userDictionary, setUserDictionary] = useState(null);
	passingProps.userDictionary = userDictionary;

	const [userAccessJWT, setUserAccessJWT] = useState(null);
	const [jwtTimings, setJwtTimings] = useState({});

	// Student Data:
	// Single student model for Student
	const [studentModel, setStudentModel] = useState(null);
	passingProps.studentModel = studentModel;
	// Array of student models for Admin
	const [students, setStudents] = useState(null);
	passingProps.students = students;
	// Array of student models for Parent
	const [parentsStudents, setParentsStudents] = useState(null);
	passingProps.parentsStudents = parentsStudents;
	// Dictionary of student models for Admin and Parent
	const [studentDictionary, setStudentDictionary] = useState(null);
	passingProps.studentDictionary = studentDictionary;


	// Parent Data:
	// Single parent model for Parent
	const [parentModel, setParentModel] = useState(null);
	passingProps.parentModel = parentModel;
	// Array of parent models for Admin
	const [parents, setParents] = useState(null);
	passingProps.parents = parents;
	// Dictionary of parent models for Admin
	const [parentDictionary, setParentDictionary] = useState(null);
	passingProps.parentDictionary = parentDictionary;


	// Tutor Data:
	// Single tutor model for Tutor
	const [tutorModel, setTutorModel] = useState(null);
	passingProps.tutorModel = tutorModel;
	// Array of tutor models for Admin
	const [tutors, setTutors] = useState(null);
	passingProps.tutors = tutors;
	// Dictionary of tutor models for Admin
	const [tutorDictionary, setTutorDictionary] = useState(null);
	passingProps.tutorDictionary = tutorDictionary;


	//Lesson Data:
	// Array of lesson models for Student
	const [studentLessons, setStudentLessons] = useState(null);
	passingProps.studentLessons = studentLessons;
	// Array of lesson models for Parent
	const [parentLessons, setParentLessons] = useState(null);
	passingProps.parentLessons = parentLessons;
	// Array of lesson models for Tutor
	const [tutorLessons, setTutorLessons] = useState(null);
	passingProps.tutorLessons = tutorLessons;
	// Array of lesson models for Admin
	const [adminLessons, setAdminLessons] = useState(null);
	passingProps.adminLessons = adminLessons;
	// Array of deleted lesson models for Admin
	const [adminDeletedLessons, setAdminDeletedLessons] = useState(null);
	passingProps.adminDeletedLessons = adminDeletedLessons;
	// Dictionary of lesson models for Parent and Admin (key: studentID)
	const [studentLessonDictionary, setStudentLessonDictionary] = useState(null);
	passingProps.studentLessonDictionary = studentLessonDictionary;
	// Dictionary of lesson models for all users (key: id)
	const [lessonDictionary, setLessonDictionary] = useState(null);
	passingProps.lessonDictionary = lessonDictionary;
	const [adminLessonDictionary, setAdminLessonDictionary] = useState(null);
	passingProps.adminLessonDictionary = adminLessonDictionary;
	

	// Lesson Request Data:
	// Array of lesson request models for Student
	const [studentLessonRequests, setStudentLessonRequests] = useState(null);
	passingProps.studentLessonRequests = studentLessonRequests;
	// Array of lesson request models for Parent
	const [parentLessonRequests, setParentLessonRequests] = useState(null);
	passingProps.parentLessonRequests = parentLessonRequests;
	// Array of lesson request models for Admin
	const [adminLessonRequests, setAdminLessonRequests] = useState(null);
	passingProps.adminLessonRequests = adminLessonRequests;
	// Dictionary of lesson request models for all users (key: id)
	const [lessonRequestDictionary, setLessonRequestDictionary] = useState(null);
	passingProps.lessonRequestDictionary = lessonRequestDictionary;
	// Dictionary of lesson request models for admins (key: id)
	const [adminLessonRequestDictionary, setAdminLessonRequestDictionary] = useState(null);
	passingProps.adminLessonRequestDictionary = adminLessonRequestDictionary;
	// Dictionary of lesson request models for Parent and Admin (key: studentID)
	const [studentLessonRequestDictionary, setStudentLessonRequestDictionary] = useState(null);
	passingProps.studentLessonRequestDictionary = studentLessonRequestDictionary;
	

	// Tutor Request Data:
	// Array of tutor request models for Tutor
	const [tutorTutorRequests, setTutorTutorRequests] = useState(null);
	passingProps.tutorTutorRequests = tutorTutorRequests;
	// Dictionary of tutpr request models for all users (key: id)
	const [tutorRequestDictionary, setTutorRequestDictionary] = useState(null);
	passingProps.tutorRequestDictionary = tutorRequestDictionary;
	// Array of tutor request models for Admin
	const [adminTutorRequests, setAdminTutorRequests] = useState(null);
	passingProps.adminTutorRequests = adminTutorRequests;


	// Transaction Data:
	// Array of transactions for signed in user
	const [userTransactions, setUserTransactions] = useState(null);
	passingProps.userTransactions = userTransactions;
	// Array of transactions for Admin
	const [adminTransactions, setAdminTransactions] = useState(null);
	passingProps.adminTransactions = adminTransactions;
	// Dictionary of transactions for Admin
	const [transactionDictionary, setTransactionDictionary] = useState(null);
	passingProps.transactionDictionary = transactionDictionary;


	// Chat Data:
	// Dictionary of chats for signed in user
	const [chats, setChats] = useState(null);
	passingProps.chats = chats;
	// Dictionary of chats for Admin
	const [adminChats, setAdminChats] = useState(null);
	passingProps.adminChats = adminChats;
	
	const [pageType, setPageType] = useState(null);
	passingProps.pageType = pageType;

	const [environment, setEnvironment] = useState(null);
	passingProps.environment = environment;

	const [chatsToSend, setChatsToSend] = useState({});
	passingProps.chatsToSend = chatsToSend;
	passingProps.setChatsToSend = setChatsToSend;

	const [userConnections, setUserConnections] = useState({});
	passingProps.userConnections = userConnections;
	
	const [initialAdminRedirect, setInitialAdminRedirect] = useState(false);
	const [initialLoadDone, setInitialLoadDone] = useState(false);
	const [teachingResources, setTeachingResources] = useState({});
	const [fromLocation, setFromLocation] = useState(null);
	const [graphqlSubscriptions, setGraphqlSubscriptions] = useState(null);
	const [webSocketOpen, setWebSocketOpen] = useState(false);
	const [webSocketAlreadyOpened, setWebSocketAlreadyOpened] = useState(false);
	const [webSocket, setWebSocket] = useState(null);
	const [webSocketStartTime, setWebSocketStartTime] = useState(null);
	
	const [usersTyping, setUsersTyping] = useState({});
	const [usersLastOnline, setUsersLastOnline] = useState(null);
	const [numUnreadMessages, setNumUnreadMessages] = useState(0);
	const [airwallexBalance, setAirwallexBalance] = useState(null);
	const [appRedirect, setAppRedirect] = useState(null);
	const [messageRedirect, setMessageRedirect] = useState(null);
	const [activeUser, setActiveUser] = useState(true);
	const [delayedLastMouseClick, setDelayedLastMouseClick] = useState(null);
	const [heartbeat, setHeartbeat] = useState(0);
	const [pageVisibility, setPageVisibility] = useState(props.pageVisibility);
	
	const [location, setLocation] = useState(null);

	const [adminUsersInfo, setAdminUsersInfo] = useState({});
	const [websocketBuffer, setWebsocketBuffer] = useState([]);

	const [APIFunctions, setAPIFunctions] = useState(null);

	const [forceLoad, setForceLoad] = useState({});

	const [userGroups, setUserGroups] = useState(null);
	const blankGroups = {
		groups: null,
		userIsTutor: null,
		userIsStudent: null,
		userIsParent: null,
		userIsAdmin: null,
		userIsDeveloper: null,
		inAllUsersGroup: null
	}
	passingProps.userGroups = userGroups;
	if (userGroups != null) {
		passingProps.groups = userGroups.groups;
		passingProps.userIsTutor = userGroups.userIsTutor;
		passingProps.userIsStudent = userGroups.userIsStudent;
		passingProps.userIsParent = userGroups.userIsParent;
		passingProps.userIsAdmin = userGroups.userIsAdmin;
		passingProps.userIsDeveloper = userGroups.userIsDeveloper;
		passingProps.inAllUsersGroup = userGroups.inAllUsersGroup;
	}

	const [backStack, setBackStack] = useState([]);

	const callGraphQLAPI = async function (callType = "query", apiName, parameters = {}) {
		try {
			if (apiName == null) {
				throw "Given apiName is null";
			}
			if (typeof(apiName) !== "string") {
				throw "Given apiName is not a string";
			}
			if (callType.toLowerCase() != "query" && callType.toLowerCase() != "mutation") {
				throw "Invalid callType: " + callType;
			}
			if (apiName == null) {
				throw "Given apiName is null";
			}
			if (typeof(apiName) !== "string") {
				throw "Given apiName is not a string";
			}
			let apiCall = null;
			if (callType.toLowerCase() == "query") {
				apiCall = queries[apiName];
				if (apiCall == null && mutations[apiName] != null) {
					throw "No query found for given apiName: '" + apiName + "', but a mutation was found. Use callType 'mutation' instead";
				}
				else if (apiCall == null) {
					throw "No query found for given apiName: '" + apiName + "'";
				}
			}
			if (callType.toLowerCase() == "mutation") {
				apiCall = mutations[apiName];
				if (apiCall == null && queries[apiName] != null) {
					throw "No mutation found for given apiName: '" + apiName + "', but a query was found. Use callType 'query' instead";
				}
				else if (apiCall == null) {
					throw "No mutation found for given apiName: '" + apiName + "'";
				}
			}
	
	
			if (parameters == null) {
				parameters = {};
			}
			if (!isJSON(parameters)) {
				throw "Given parameters is not a JSON object";
			}
	
			for (const [key, value] of Object.entries(parameters)) {
				if (value == null) {
					delete parameters[key];
				}
				// if (isJSON(value)) {
				// 	parameters[key] = JSON.stringify(value);
				// }
			}
			
			let response = await apiClient.graphql({query: apiCall, variables: parameters});
			try {
				response = response.data;
				response = response[apiName];
				response = JSON.parse(response);
			} catch {}
			if (isJSON(response) && response.statusCode != null) {
				let body = response.body;
				const statusCode = response.statusCode
				try {
					body = JSON.parse(body);
				} catch {}
				if (statusCode == 200) {
					return body;
				}
				else {
					throwError(null, body, statusCode);
				}
			}
	
			return response;
		}
		catch (error) {
			console.log("GraphQL API error");
			let errorMessage = "Internal server error";
			const errors = error.errors;
			const fieldErrors = {};
			for (const error of errors) {
				const errorType = error.errorType;
				if (errorType == "questionErrors") {
					throwError("Error calling graphQL API", error.errorInfo);
				}
				if (errorType == null || errorType == "Unauthorized") {
					throwError(errorMessage, null);
				}
				const objectErrorMessage = error.message;
				const errorPath = error.path || [];
				let errorObject = objectErrorMessage;
				for (let objectIndex = errorPath.length - 1; objectIndex > 0; objectIndex = objectIndex - 1) {
					const object = errorPath[objectIndex];
					errorObject = {
						[object]: errorObject
					};
				}
				fieldErrors[errorPath[0]] = errorObject;
			}
			
			if (Object.keys(fieldErrors).length == 1) {
				errorMessage = Object.values(fieldErrors)[0];
			}
			throwError(errorMessage);	
		}
	};
	
	const queryGraphQL = async function (queryName, parameters = {}) {
		return await callGraphQLAPI("query", queryName, parameters);
	};
	
	const mutateGraphQL = async function (mutationName, parameters = {}) {
		const graphqlResponse = await callGraphQLAPI("mutation", mutationName, parameters);
		if (isJSON(graphqlResponse) && graphqlResponse.updateID != null) {
			updateClient(graphqlResponse);
		}
		return graphqlResponse;
	};

	//Subscribe to GraphQL subscriptions
	useEffect(() => {
		if (user == null || user.username == null || userGroups == null || userGroups.groups == null || graphqlSubscriptions != null) {
			return;
		}

		//Currently subscriptions aren't being used
		const newSubscriptions = {};

		newSubscriptions.userUpdateSubscription = apiClient.graphql({query: subscriptions.onUserUpdate, variables: {
			userID: user.username
		}}).subscribe({
			next: (subscriptionUpdate) => {
				const clientUpdate = subscriptionUpdate.data.onUserUpdate;
				updateClient(clientUpdate);
			}
		});

		// newSubscriptions.userSubscription = apiClient.graphql({query: subscriptions.onUserMessageReceived, variables: {userID: user.username}}).subscribe({
		// 	next: (notificationData) => {
		// 		const notificationMessage = notificationData.value.data.onNotificationReceived;
		// 		console.log('New Notification:', notificationMessage);
		// 		// Handle the notification (e.g., display it to the user)
		// 	}
		// });

		//console.log(newSubscriptions.userSubscription);
		// for (const group of userGroups.groups) {
		// 	newSubscriptions[group + "Subscription"] = apiClient.graphql({query: subscriptions.onGroupMessageReceived, variables: {group: group}}).subscribe({
		// 		next: (notificationData) => {
		// 			const notificationMessage = notificationData.value.data.onNotificationReceived;
		// 			console.log('New Group Notification:', notificationMessage);
		// 			// Handle the notification (e.g., display it to the user)
		// 		}
		// 	});
		// }
		// newSubscriptions["tutorSubscription"] = apiClient.graphql({query: subscriptions.onGroupMessageReceived, variables: {group: "Tutors"}}).subscribe({
		// 	next: (notificationData) => {
		// 		const notificationMessage = notificationData.value.data.onNotificationReceived;
		// 		console.log('New Group Notification:', notificationMessage);
		// 		// Handle the notification (e.g., display it to the user)
		// 	}
		// });
		// if (userGroups.userIsAdmin) {
		// 	newSubscriptions.adminSubscription = apiClient.graphql({query: subscriptions.onAdminRegionMessageReceived, variables: {region: "global"}}).subscribe({
		// 		next: (notificationData) => {
		// 			const notificationMessage = notificationData.value.data.onNotificationReceived;
		// 			console.log('New Admin Notification:', notificationMessage);
		// 			// Handle the notification (e.g., display it to the user)
		// 		}
		// 	});
		// }
		setGraphqlSubscriptions(newSubscriptions);
	}, [user, userGroups, graphqlSubscriptions]);
	
	//Add listener for auth events
	useEffect(() => {
		Hub.listen('auth', ({ payload }) => {
			switch (payload.event) {
			case 'signedIn':
				console.log('user have been signedIn successfully.');
				break;
			case 'signedOut':
				console.log('user have been signedOut successfully.');
				getNewUserSession();
				break;
			case 'tokenRefresh':
				console.log('auth tokens have been refreshed.');
				break;
			case 'tokenRefresh_failure':
				console.log('failure while refreshing auth tokens.');
				break;
			case 'signInWithRedirect':
				console.log('signInWithRedirect API has successfully been resolved.');
				break;
			case 'signInWithRedirect_failure':
				console.log('failure while trying to resolve signInWithRedirect API.');
				break;
			case 'customOAuthState':
				console.log('custom state returned from CognitoHosted UI');
				break;
			}
		});
	}, []);

	useEffect(() => {
		if (awsmobile == null) {
			return;
		}
		const apiArray = awsmobile.aws_cloud_logic_custom;
		if (apiArray == null || !Array.isArray(apiArray) || apiArray.length == 0) {
			return;
		}
		const api1 = apiArray[0];
		const api1Endpoint = api1.endpoint;
		const envStart = api1Endpoint.lastIndexOf("/");
		const env = api1Endpoint.slice(envStart + 1);
		setEnvironment(env);
	}, [awsmobile]);

	let lastMouseClick = props.lastMouseClick;
	useEffect(() => {
		const queryParamsString = document.location.search;
		const queryParams = new URLSearchParams(queryParamsString);
		const newFromLocation = queryParams.get("from");
		setFromLocation(newFromLocation);
		lastMouseClick = new Date().valueOf();
	}, []);
	
	const currentPageVisibility = props.pageVisibility;

	//Heartbeat
	useEffect(() => {
		const updateHeartBeat = async function() {
			//console.log("Heartbeat");
			const sleep = async function () {
				//return new Promise(resolve => setTimeout(resolve, 1000));
				return new Promise(resolve => setTimeout(resolve, 550000));
			}
			await sleep();
			const heartbeatObject = (JSON.stringify({
				action: "heartbeat"
			}));
			webSocket.send(heartbeatObject);
			setHeartbeat(heartbeat + 1);
		}
		if (webSocket != null && webSocketOpen) {
			updateHeartBeat();
		}
	}, [heartbeat, webSocket, webSocketOpen]);

	//Set the new delayed mouse click
	useEffect(() => {
		const updateLastMouseClickTick = async function() {
			const returnLater = async function (input) {
				const sleep = async function () {
					return new Promise(resolve => setTimeout(resolve, 120000));
				}
				await sleep();
				return input;
			}
			const oldLastMouseClick = await returnLater(lastMouseClick);
			setDelayedLastMouseClick(oldLastMouseClick)
		}

		updateLastMouseClickTick();
	}, [lastMouseClick]);

	//Check whether the mouse has been clicked within the last 120 seconds
	useEffect(() => {
		const callUpdateUserActive = async function (newActive) {
			await updateUserActive(newActive);
		};

		if (lastMouseClick != null) {
			if (activeUser == true && lastMouseClick == delayedLastMouseClick) {
				setActiveUser(false);
				callUpdateUserActive(false);
			}
			else if (activeUser == false && lastMouseClick != delayedLastMouseClick){
				setActiveUser(true);
				callUpdateUserActive(true);
			}
		}
	}, [lastMouseClick, delayedLastMouseClick, activeUser, userAccessJWT]);

	const updateUserPageVisibility = async function (newPageVisibility) {
		const userStatusObject = {
			action: "updateUserStatus",
			pageVisibility: newPageVisibility,
			time: new Date().toISOString(),
			sessionStart: webSocketStartTime
		};
		//await callWebsocketAPI(userStatusObject);
	}

	const updateUserActive = async function (newUserActive) {
		if (webSocket != null && webSocket.readyState == 1) {
			const userStatusObject = {
				action: "updateUserStatus",
				active: newUserActive,
				time: new Date().toISOString(),
				sessionStart: webSocketStartTime
			};
			//await callWebsocketAPI(userStatusObject);
		}
	}

	useEffect(() => {
		const updatePage = async function () {
			await updateUserPageVisibility(currentPageVisibility);
		}
		if (currentPageVisibility != pageVisibility) {
			setPageVisibility(currentPageVisibility);
			updatePage(currentPageVisibility);
		}
	}, [currentPageVisibility, pageVisibility, userAccessJWT]);

	//Websocket buffer
	useEffect(() => {
		if (webSocket == null) {
			return;
		}
		if (webSocket.readyState != WebSocket.OPEN) {
			return;
		}
		if (websocketBuffer.length > 0) {
			const message = websocketBuffer[0];
			webSocket.send(message);
			const newBuffer = websocketBuffer.slice(1);
			setWebsocketBuffer(newBuffer);
		}
	}, [websocketBuffer, webSocket, webSocketOpen]);
	
	//Maintain up to date current pathname
	const GetLocation = function () {
		const newLocation = useLocation();
		useEffect(() => {
			setLocation(newLocation);
			let pageType = "home";
			try {
				pageType = newLocation.pathname.split("/")[1].toLowerCase();
				if (pageType == "") {
					pageType = "home";
				}
			} catch {}
			setPageType(pageType);
		}, [newLocation]);
	}
	
	useEffect(() => {
		if (location == null) {
			return;
		}

		if (appRedirect == null) {
			const newLocation = {...location};
			delete newLocation.key;
			if (backStack.length > 1 && compareObjects(backStack[backStack.length - 2], newLocation)) {
				const newBackStack = backStack.slice(0, backStack.length - 1);
				setBackStack(newBackStack);
			}
			else if (backStack.length == 0 || !compareObjects(backStack[backStack.length - 1], newLocation)) {
				setBackStack([...backStack, newLocation]);
			}
		}
		else {
			const compareLocation = {...location};
			delete compareLocation.key;
			if (compareObjects(backStack[backStack.length - 1], compareLocation)) {
				setAppRedirect(null);
			}
		}
	}, [location, backStack, appRedirect]);
	

	useEffect(() => {
		if (location == null) {
			return;
		}
		const sendActivity = async function () {
			const pageName = location.pathname;
			const state = location.state;
			const activityDetails = {
				activity: pageName,
				state: state
			};
			await sendUserActivityAPI(activityDetails);
		}

		sendActivity();
	}, [location != null && location.pathname]);

	// const goBack = function () {
	// 	if (backStack.length > 1) {
	// 		const previousPage = backStack[backStack.length - 2]
	// 		let to = previousPage.pathname;
	// 		if (previousPage.search != null && previousPage.search != "") {
	// 			to = to + "?" + previousPage.search;
	// 		}
	// 		console.log("Previous page: " + to);
	// 		setAppRedirect(<Navigate to={to}/>);
	// 		const newBackStack = backStack.slice(0, backStack.length - 1);
	// 		setBackStack(newBackStack);
	// 	}
	// }

	 //Terms and condtions updates
	const AcceptNewTerms = function () {
		const termsUpdates = [
			// {
			//   date: "Sun Jan 07 2024",
			//   difference: "Updated cancellation policy"
			// }
		];
		const userAttributes = user.attributes;
		let agreeTermsDate = userAttributes["custom:agreeTermsDate"];
		if (agreeTermsDate == null) {
			agreeTermsDate = new Date("01 September 2023");
		}
		else {
			agreeTermsDate = new Date(agreeTermsDate);
		}

		const newUpdates = [];
		for (const update of termsUpdates) {
			if (new Date() > new Date(update.date)) {
				newUpdates.push(update);
			}
		}
		if (newUpdates.length > 0 && location.pathname.toLowerCase() != "/agreeterms" ) {
			return <Navigate to={"/AgreeTerms"} state={{newUpdates: newUpdates}}/>;
		}
	}
	const pathname = location != null && location.pathname;

	const width = props.width;
	const height = props.height;

	//Create websocket
	useEffect(() => {
		if (userAccessJWT == null) {
			return;
		}

		let shouldOpenNewSocket = false;
		if (!webSocketOpen) {
			if (webSocket == null) {
				shouldOpenNewSocket = true;
			}
			else if (webSocket.readyState == 3) {
				shouldOpenNewSocket = true;
			}
		}

		if (shouldOpenNewSocket) {
			let websocketURL = "wss://ax37lfznl4.execute-api.eu-west-2.amazonaws.com/production/";
			if (environment == "testing") {
				websocketURL = "wss://n24dh4vuza.execute-api.eu-west-2.amazonaws.com/testing/";
			}
			const newWebSocket = new WebSocket(websocketURL);
			const newWebSocketStartTime = new Date().toISOString();
			setWebSocketStartTime(newWebSocketStartTime);
			newWebSocket.onopen = async function open() {
				setWebSocketOpen(true);
				const connectParams = {
					action: "connectUserID",
					userInfo: {
						userAgent: navigator.userAgent,
						pageVisibility: pageVisibility,
						active: activeUser
					},
					time: newWebSocketStartTime
				};
				const connectDatabaseCommand = await callWebsocketAPI(connectParams, true);
				this.send(connectDatabaseCommand);
				console.log("Connected to web socket: " + new Date().toLocaleTimeString());
				
				if (webSocketAlreadyOpened) {
					getUserChats();
				}
				setWebSocketAlreadyOpened(true);
			};
		
			newWebSocket.onclose = function close() {
				this.send(JSON.stringify({
					action: "testClose",
					accessJWT: userAccessJWT,
					userInfo: {
						userAgent: navigator.userAgent,
						pageVisibility: pageVisibility,
						active: activeUser
					}
				}));
				setWebSocketOpen(false);
				console.log("Disconnected: " + new Date().toLocaleTimeString());
			};

			setWebSocket(newWebSocket);
		}
	}, [userAccessJWT, webSocketOpen, webSocket, pageVisibility, activeUser, webSocketAlreadyOpened]);
	
	// Receive web socket commands
	useEffect(() => {
		if (!webSocketOpen) {
			return;
		}
		const newWebSocket = webSocket;
		newWebSocket.onmessage = function incoming(messageEvent) {
			//console.log(messageEvent);
			let messageData = messageEvent.data;
			try {
				messageData = JSON.parse(messageData);
			} catch {}
			const command = messageData.command;
			const data = messageData.data;
			if (command == null || typeof(command) != "string") {
				return;
			}
			if (command == "newChat") {
				updateIncomingChat(data, "received");
			}
			else if (command == "newChatSent") {
				updateIncomingChat(data, "sent");
			}
			else if (command == "newChatRead") {
				updateIncomingChat(data, "read");
			}
			else if (command == "newChatReadSent") {
				updateIncomingChat(data, "readSent");
			}
			else if (command == "updateLastOnline") {
				updateUsersLastOnline(data);
			}
			else if (command == "newIsTyping") {
				updateTypingUsers(data);
			}
			else if (command == "updateAdminUsersInfo") {
				updateAdminUsersInfo(data);
			}
			else if (command.toLowerCase() == "refreshpage" || command.toLowerCase() == "refresh" || command.toLowerCase() == "forcerefresh" || command.toLowerCase() == "reload" || command.toLowerCase() == "forcereload") {
				forcePageRefresh(data);
			}
			else if (command.toLowerCase() == "forcenavigate" || command.toLowerCase() == "forceredirect" || command.toLowerCase() == "redirect") {
				forceNavigate(data);
			}
			else if (command.toLowerCase() == "forceclose" || command.toLowerCase() == "close") {
				forceClose(data);
			}
	
			// const signature = data.signature;
			// console.log(signature);
			// const publicKey = data.publicKey;
	
			// const verify = new JSEncrypt();
			// verify.setPublicKey(publicKey);
			// const verified = verify.verify(data, signature, "SHA256");
	
			// console.log(verified);
	
			// setTimeout(function timeout() {
			//   webSocket.send(Date.now());
			// }, 50);
		};
		setWebSocket(newWebSocket);
	}, [chats, usersLastOnline, usersTyping, webSocketOpen, appRedirect, messageRedirect, adminUsersInfo, userAccessJWT]);

	const updateIncomingChat = function (newChat, type) {
		let newChats = {};
		if (chats != null) {
			newChats = {...chats};
		}
		let otherUserID = null;
		if (newChat.recipientID == user.username) {
			newChats[newChat.senderID] = newChat.messages;
			otherUserID = newChat.senderID;
			setChats(newChats);
		}
		else if (newChat.senderID == user.username) {
			newChats[newChat.recipientID] = newChat.messages;
			otherUserID = newChat.recipientID;
			setChats(newChats);
		}
		if (type == "received") {
			const notificationTitle = "Next Door Tutor Message";
			const notificationBody = "Message from " + otherUserID;
			const notification = new Notification(notificationTitle, {
				body: notificationBody,
				icon: LogoImage
			});

			notification.onclick = function() {
				window.parent.parent.focus();
				if (window.location.pathname.toLowerCase() != "/messages") {
					setAppRedirect(<Navigate to={"/Messages?recipient=" + otherUserID} />);
				}
				else {
					//setAppRedirect(<Navigate to={"/Messages?recipient=" + otherUserID} />);
					setMessageRedirect(otherUserID);
				}
			};
		}
		if (type != "chatReadSent") {
			let newNumUnreadMessages = 0;
			const newChatArray = Object.values(newChats);
			for (const chat of newChatArray) {
				for (const message of chat) {
					if (message.read == null && message.sender != user.username) {
						newNumUnreadMessages = newNumUnreadMessages + 1;
					}
				}
			}
			setNumUnreadMessages(newNumUnreadMessages);
		}
	};

	const updateUsersLastOnline = function (newOnlineObject) {
		let newUsersLastOnline = {...usersLastOnline};

		const userID = newOnlineObject.userID;
		const newLastOnline = newOnlineObject.lastOnline;

		newUsersLastOnline[userID] = newLastOnline;

		setUsersLastOnline(newUsersLastOnline);
	};

	const updateTypingUsers = function (typingObject) {
		const userID = typingObject.senderID;
		const isTyping = typingObject.isTyping;

		const newUsersTyping = {...usersTyping};
		const newUserTyping = isTyping;
		newUsersTyping[userID] = newUserTyping;

		setUsersTyping(newUsersTyping);
	};

	const updateAdminUsersInfo = function (data) {
		if (data == null) {
			console.log("No data from websocket");
			return;
		}
		const userID = data.userID;
		setAdminUsersInfo((prev) => {
			const newAdminUsersInfo = {...prev};
			delete data.userID;
			newAdminUsersInfo[userID] = data;
			if (data.appendActivity == true && prev[userID] != null && prev[userID].activity != null) {
				newAdminUsersInfo[userID].activity = [...prev[userID].activity, ...data.activity];
			}
			return newAdminUsersInfo;
		});
	};

	const forcePageRefresh = function () {
		window.location.reload();
	};

	const forceNavigate = function (data) {
		const pathname = data.pathname;
		if (pathname.startsWith("http")) {
			console.log("Navigating to external site");
			window.location.replace(pathname);
			return;
		}
		const state = data.state;
		setAppRedirect(<Navigate to={pathname} state={state}/>);
	};

	const forceClose = function (pathname, state = null) {
		window.close();
	};

	const getUserChats = async function (initialLoad = false, extraLogging = false) {
		try {
			const startTime = new Date().valueOf();
			const retrievedChatsObject = await getUserChatsAPI();
			const retrievedChats = retrievedChatsObject.chats;
			setChats(retrievedChats);
			setUsersLastOnline(retrievedChatsObject.usersLastOnline);

			let newNumUnreadMessages = 0;
			const newChatArray = Object.values(retrievedChats);
			for (const chat of newChatArray) {
				for (const message of chat) {
					if (message.read == null && message.sender != user.username) {
						newNumUnreadMessages = newNumUnreadMessages + 1;
					}
				}
			}
			setNumUnreadMessages(newNumUnreadMessages);

			const endTime = new Date().valueOf();
			const duration = endTime - startTime;
			if (extraLogging) {
				console.log("getUserChats load time: " + duration + " ms");
			}
			return retrievedChats;
		}
		catch (error) {
			setChats("error");
			setUsersLastOnline("error");
		}
	};

	const getUserGroups = function (newUser) {
		let givenUser = user;
		if (newUser != null) {
			givenUser = newUser;
		}

		const newUserGroups = givenUser["cognito:groups"];
		if (newUserGroups == null) {
			setUserGroups({
				groups: []
			});
		}
		setUserGroups({
			groups: newUserGroups,
			userIsTutor: searchGroups(newUserGroups, "Tutors"),
			userIsStudent: searchGroups(newUserGroups, "Students"),
			userIsParent: searchGroups(newUserGroups, "Parents"),
			userIsAdmin: searchGroups(newUserGroups, "Admins"),
			userIsDeveloper: searchGroups(newUserGroups, "Developers"),
			inAllUsersGroup: searchGroups(newUserGroups, "AllUsers")
		});
	};

	useEffect (() => {
		getNewUserSession();
	}, []);

	useEffect (() => {
		if (user == null) {
			return;
		}
		getUserGroups(user);
		const newJWT = user.accessJWT;
		if (newJWT == null) {
			return;
		}
		setUserAccessJWT(newJWT);
		if (jwtTimings[newJWT] == null) {
			console.log("User updated");
			const newJWTTimings = {...jwtTimings};
			newJWTTimings[newJWT] = new Date().toISOString();
			setJwtTimings(newJWTTimings);
		
		}
	}, [user, jwtTimings]);

	useEffect (() => {
		if (forceLoad == null) {
			return;
		}
		if (forceLoad.parent && userGroups.userIsParent != null && userGroups.userIsParent) {
			loadParent();
			setForceLoad({...forceLoad, parent: false});
		}
		if (forceLoad.student && userGroups.userIsStudent != null && userGroups.userIsStudent) {
			loadStudent();
			setForceLoad({...forceLoad, student: false});
		}
		if (forceLoad.tutor && userGroups.userIsTutor != null && userGroups.userIsTutor) {
			loadTutor();
			setForceLoad({...forceLoad, tutor: false});
		}
		if (forceLoad.admin && userGroups.userIsAdmin != null && userGroups.userIsAdmin) {
			loadAdmin();
			setForceLoad({...forceLoad, admin: false});
		}

	}, [forceLoad, userGroups]);

	const adminGetUsers = async function (extraLogging = false) {
		if (userGroups.userIsAdmin) {
			try {
				setUserDictionary("loading");
				const retrievedUsers = await adminGetUsersAPI();
				let newAllUsers = {};
				for (const user of retrievedUsers) {
					newAllUsers[user.Username] = user;
				}
				setUserDictionary(newAllUsers);
				return retrievedUsers;
			}
			catch (error) {
				console.log("Error getting users: " + error);
				setUserDictionary("error");
			}
		}
	};

	const modelArrayToDictionary = function (modelArray, dictionaryKey = "id", isArrayOfKeys = false, isArrayOfValues = false) {
		try {
			if (modelArray == null) {
				return {};
			}
			if (modelArray != null && !Array.isArray(modelArray)) {
				throw "Model array is not an array";
			}
			if (typeof(dictionaryKey) != "string") {
				throw "Dictionary key is not a string";
			}

			const newModelDictionary = {};
			for (const model of modelArray) {
				if (!isJSON(model) || model[dictionaryKey] == null) {
					continue;
				}
				if (dictionaryKey == "modelTypeID") {
					const modelDetails = (model.modelTypeID || "Other").split("#");
					const modelType = modelDetails[0];
					model.modelType = modelType;
					if (modelDetails.length > 1) {
						const modelID = modelDetails[1];
						model.modelID = modelID;
					}
					dictionaryKey = "modelID";
				}

				if (isArrayOfKeys) {
					const keys = model[dictionaryKey];
					for (const key of keys) {
						if (isArrayOfValues) {
							newModelDictionary[key] = newModelDictionary[key] || [];
							newModelDictionary[key].push(model);
						}
						else {
							newModelDictionary[key] = model;
						}
					}
				}
				else {
					const key = model[dictionaryKey];
					if (isArrayOfValues) {
						newModelDictionary[key] = newModelDictionary[key] || [];
						newModelDictionary[key].push(model);
					}
					else {
						newModelDictionary[key] = model;
					}
				}
			}
			return newModelDictionary;
		}
		catch (error) {
			throw "Error creating dictionary from model array: " + error;
		}
	};

	const loadAdmin = async function (onlyLoad = null, initialLoad = false, extraLogging = false) {
		try {
			if (!userGroups.userIsAdmin) {
				return;
			}
			const startTime = new Date().valueOf();

			const apiParameters = {
				body: {
					onlyLoad: onlyLoad,
					getEmails: true
				}
			};
			const adminData = await callAPIGateway("adminFunctions", "/loadAdmin", apiParameters, "put");
			if (adminData.students != null) {
				setStudents(adminData.students);
				setStudentDictionary({...{...studentDictionary}, ...modelArrayToDictionary(adminData.students, "id")});
			}
			if (adminData.parents != null) {
				setParents(adminData.parents);
				setParentDictionary({...{...parentDictionary}, ...modelArrayToDictionary(adminData.parents, "id")});
			}
			if (adminData.tutors != null) {
				setTutors(adminData.tutors);
				setTutorDictionary({...{...tutorDictionary}, ...modelArrayToDictionary(adminData.tutors, "id")});
			}
			if (adminData.lessons != null) {
				setAdminLessons(adminData.lessons);
				setAdminDeletedLessons(adminData.deletedLessons);
				setAdminLessonDictionary({...{...adminLessonDictionary},  ...modelArrayToDictionary(adminData.lessons, "id")});
				setStudentLessonDictionary({...{...studentLessonDictionary}, ...modelArrayToDictionary(adminData.lessons, "studentIDs", true, true)});
			}
			if (adminData.lessonRequests != null) {
				setAdminLessonRequests(adminData.lessonRequests);
				setAdminLessonRequestDictionary({...{...adminLessonRequestDictionary}, ...modelArrayToDictionary(adminData.lessonRequests, "id")});
				setStudentLessonRequestDictionary({...{...studentLessonRequestDictionary}, ...modelArrayToDictionary(adminData.lessonRequests, "studentIDs", true, true)});
			}
			if (adminData.transactions != null) {
				setAdminTransactions(adminData.transactions);
				setTransactionDictionary({...{...transactionDictionary}, ...modelArrayToDictionary(adminData.transactions, "id", false, true)});
			}

			const endTime = new Date().valueOf();
			const duration = endTime - startTime;
			if (extraLogging) {
				console.log("Load Admin time: " + duration + " ms");
			}
		}
		catch (error) {
			console.log("Error loading admin: " + error);
		}
	};

	const updateUser = function (newUserModel) {
		for (const [key, value] of Object.entries(newUserModel)) {
			if (key.endsWith("List") && Array.isArray(value)) {
				const dictionaryKey = key.slice(0, -4) + "Dictionary";
				const newDictionary = modelArrayToDictionary(value, "modelTypeID");
				if (Object.keys(newDictionary).length > 0) {
					newUserModel[dictionaryKey] = newDictionary;
				}
			}
		}
		if (newUserModel.user != null) {
			newUserModel = {...newUserModel, ...newUserModel.user};
		}
		setUserModel(prev => {
			return {...prev, ...newUserModel};
		});
	}

	const updateClient = function (update, extraLogging = true) {
		setClientUpdates(prev => {
			const updateID = update.updateID;
			// If update already been processed by this client
			if (prev[updateID] != null) {
				extraLogging && console.log("Client already processed updated: " + updateID);
				return prev;
			}

			const updateType = update.updateType;
			let updateData = update.data;
			try {
				updateData = JSON.parse(updateData);
			} catch {}

			if (updateType == "LoadUserModel") {
				updateUser(updateData);
			}

			const newClientUpdates = {...prev};
			newClientUpdates[updateID] = {
				updateTime: update.updateTime,
				clientProcessedTime: new Date().toISOString()
			};

			console.log(update.refreshCognitoUser);
			if (update.refreshCognitoUser == true) {
				extraLogging && console.log("Refreshing cognito user");
				getNewUserSession();
			}

			extraLogging && console.log("Client updated: " + updateID);
			return newClientUpdates;
		});
	};

	const loadUser = async function (onlyLoad = null, initialLoad = false, extraLogging = false) {
		try {
			const startTime = new Date().valueOf();
			//onlyLoad = "tutorQualification";
			const newUserModel = await queryGraphQL("loadUser", {onlyLoad: onlyLoad});
			updateUser(newUserModel);

			const endTime = new Date().valueOf();
			const duration = endTime - startTime;
			if (extraLogging) {
				console.log("Load user time: " + duration + " ms");
			}
		}
		catch (error) {
			console.log("Error loading user: " + getErrorString(error));
		}
	};

	const loadParent = async function (onlyLoad = null, initialLoad = false, extraLogging = false) {
		try {
			if (!userGroups.userIsParent) {
				return;
			}
			const startTime = new Date().valueOf();
			
			const apiParameters = {
				body: {
					onlyLoad: onlyLoad
				}
			};

			const parentData = await callAPIGateway("parentDataModels", "/loadParent", apiParameters, "put");

			if (parentData.parentModel != null) {
				setParentModel(parentData.parentModel);
			}
			if (parentData.studentDictionary != null) {
				setParentsStudents(Object.values(parentData.studentDictionary));
				setStudentDictionary({...{...studentDictionary}, ...parentData.studentDictionary});
			}
			if (parentData.lessons != null) {
				setParentLessons(parentData.lessons);
				setStudentLessonDictionary({...{...studentLessonDictionary}, ...modelArrayToDictionary(parentData.lessons, "studentIDs", true, true)});
				setLessonDictionary({...{...lessonDictionary}, ...modelArrayToDictionary(parentData.lessons, "id")});
			}
			if (parentData.lessonRequests != null) {
				setParentLessonRequests(parentData.lessonRequests);
				setLessonRequestDictionary({...{...lessonRequestDictionary}, ...modelArrayToDictionary(parentData.lessonRequests, "id")});
				setStudentLessonRequestDictionary({...{...studentLessonRequestDictionary}, ...modelArrayToDictionary(parentData.lessonRequests, "studentIDs", true, true)});
			}
			const endTime = new Date().valueOf();
			const duration = endTime - startTime;
			if (extraLogging) {
				console.log("loadParent time: " + duration + " ms");
			}
		}
		catch (error) {
			console.log("Error loading parent: " + error);
		}
	};

	const loadStudent = async function (onlyLoad = null, initialLoad = false, extraLogging = false) {
		try {
			if (!userGroups.userIsStudent) {
				return;
			}
			const startTime = new Date().valueOf();
			
			const apiParameters = {
				body: {
					onlyLoad: onlyLoad
				}
			};

			const studentData = await callAPIGateway("studentDataModels", "/loadStudent", apiParameters, "put");

			if (studentData.studentModel != null) {
				setStudentModel(studentData.studentModel);
			}
			if (studentData.lessons != null) {
				setStudentLessons(studentData.lessons);
				setLessonDictionary({...{...lessonDictionary},  ...modelArrayToDictionary(studentData.lessons, "id")});
			}
			if (studentData.lessonRequests != null) {
				setStudentLessonRequests(studentData.lessonRequests);
				setLessonRequestDictionary({...{...lessonRequestDictionary}, ...modelArrayToDictionary(studentData.lessonRequests, "id")});
			}
			const endTime = new Date().valueOf();
			const duration = endTime - startTime;
			if (extraLogging) {
				console.log("loadStudent time: " + duration + " ms");
			}
		}
		catch (error) {
			console.log("Error loading student: " + error);
		}
	};

	const loadTutor = async function (onlyLoad = null, initialLoad = false, extraLogging = false) {
		try {
			if (!userGroups.userIsTutor) {
				return;
			}
			const startTime = new Date().valueOf();
			
			const apiParameters = {
				body: {
					onlyLoad: onlyLoad
				}
			};

			// const tutorData = await callAPIGateway("tutorDataModels", "/loadTutor", apiParameters, "put");

			// if (tutorData.tutorModel != null) {
			// 	setTutorModel(tutorData.tutorModel);
			// }
			// if (tutorData.lessons != null) {
			// 	setTutorLessons(tutorData.lessons);
			// 	setLessonDictionary({...{...lessonDictionary},  ...modelArrayToDictionary(tutorData.lessons, "id")});
			// }
			// if (tutorData.tutorRequests != null) {
			// 	setTutorTutorRequests(tutorData.tutorRequests);
			// 	setTutorRequestDictionary({...{...tutorRequestDictionary}, ...modelArrayToDictionary(tutorData.tutorRequests, "id")});
			// }
			const endTime = new Date().valueOf();
			const duration = endTime - startTime;
			if (extraLogging) {
				console.log("loadTutor time: " + duration + " ms");
			}
		}
		catch (error) {
			console.log("Error loading tutor: " + error);
		}
	};

	const getUserTransactions = async function (initialLoad = false, extraLogging = false) {
		if (!userGroups.userIsTutor && !userGroups.userIsStudent && !userGroups.userIsStudent) {
			return;
		}
		if (initialLoad) {
			setUserTransactions(null);
		}
		else {
			setUserTransactions("loading");
		}
		try {
			const startTime = new Date().valueOf();
			const retrievedTransactions = await getUserTransactionsAPI();
			setUserTransactions(retrievedTransactions);
			const endTime = new Date().valueOf();
			const duration = endTime - startTime;
			if (extraLogging) {
				console.log("getUserTransactions load time: " + duration + " ms");
			}
		}
		catch (error) {
			console.log("Error getting user transactions: " + error);
		}
	};

	const getNewUserSession = async function (extraLogging = false) {
		try {
			console.log("Getting new current user");
			const startTime = new Date().valueOf();
			const newUserSession = await Auth.fetchAuthSession({ forceRefresh: true });
			const newUserProps = newUserSession.tokens.accessToken.payload;
			const accessJWT = newUserSession.tokens.accessToken.toString();
			newUserProps.accessJWT = accessJWT;
			newUserProps.attributes = newUserSession.tokens.idToken.payload;
			setUser(newUserProps);
			setUserAccessJWT(accessJWT);
			const endTime = new Date().valueOf();
			const duration = endTime - startTime;
			if (extraLogging) {
				console.log("getNewCurrentUser load time: " + duration + " ms");
			}
		}
		catch (error) {
			console.log("Error getting new current user: " + error);
		}
	};

	const getTeachingResources = async function (subject, yearGroup) {
		if (userGroups.userIsTutor || userGroups.userIsAdmin || userGroups.userIsDeveloper) {
			const newTeachingResources = {...teachingResources};
			const newTeachingResourcesSubject = newTeachingResources[subject] || {};
			try {
				if (typeof(subject) != "string" || subject == null) {
					throw "Invalid subject";
				}

				if (yearGroup != "KS1" && yearGroup != "KS2" && yearGroup != "KS3" && yearGroup != "KS4" && yearGroup != "KS5" && yearGroup != "University") {
					throw "Invalid year group";
				}

				const specificTeachingResources = await getTeachingResourcesAPI(subject, yearGroup);

				newTeachingResourcesSubject[yearGroup] = specificTeachingResources;
				newTeachingResources[subject] = newTeachingResourcesSubject;
				setTeachingResources(newTeachingResources);
				return specificTeachingResources;
			}
			catch (error) {
				console.log("Error getting teaching resources: " + error);
				newTeachingResourcesSubject[yearGroup] = "error";
				newTeachingResources[subject] = newTeachingResourcesSubject;
				setTeachingResources(newTeachingResources);
				return "error";
			}
		}
	};

	const callWebsocketAPI = async function (data = {}, returnCommand = false) {
		try {
			if (data.action == null) {
				throw "No action given";
			}
			const accessToken = (await Auth.fetchAuthSession()).tokens.accessToken.toString();
			data.accessJWT = accessToken;
			const command = JSON.stringify(data);

			if (returnCommand) {
				return command;
			}

			if (webSocket == null || webSocket.readyState != WebSocket.OPEN) {
				const newWebSocketBuffer = [...websocketBuffer];
				newWebSocketBuffer.push(command);
				setWebsocketBuffer(newWebSocketBuffer);
			}
			else {
				webSocket.send(command);
			}
		}
		catch (error) {
			throw "Error calling websocket API: " + error
		}
	}
	
	const updateTutorModelAPI = async function (newAttributes) {
		const parameters = {
			attributes: newAttributes
		};
		
		try {
			const updatedModelTypes = await mutateGraphQL("updateTutorModel", parameters);
			let onlyLoad = null;
			if (Array.isArray(updatedModelTypes) && updatedModelTypes.length == 1) {
				onlyLoad = updatedModelTypes[0];
			}
			else if (typeof(updatedModelTypes) == "string") {
				onlyLoad = updatedModelTypes;
			}
			loadUser(onlyLoad);
		}
		catch (error) {
			throwError("Error updating tutor model", error);
		}
	};

	const updateUserAPI = async function (newAttributes) {
		const parameters = {
			attributes: newAttributes
		};
		
		try {
			await mutateGraphQL("updateUser", parameters);
			loadUser("user");
		}
		catch (error) {
			throwError("Error updating user", error);
		}
	};
	
	const registerTutor = async function (newModelAttributes) {
		try {
			const parameters = {
				registrationType: "tutor",
				attributes: newModelAttributes
			};
	
			const tutorRegistrationReply = await mutateGraphQL("registration", parameters);
			//const tutorRegistrationReply = await callAPIGateway("tutorDataModels", "/createTutorModel", parameters, "post");
			await getNewUserSession();
			loadUser();
			loadAdmin(["tutors"]);
			return tutorRegistrationReply;
		}
		catch (error) {
			throwError("Error registering as tutor", error);
		}
	};
	
	const updateStudentModelAPI = async function (newAttributes, studentID = null) {
		const parameters = {
			attributes: newAttributes,
			studentID: studentID
		};
	
		try {
			await mutateGraphQL("updateStudentModel", parameters);
			if (studentID == null) {
				loadUser("user");
			}
			else {
				loadUser("student#" + studentID);
			}
		}
		catch (error) {
			throwError("Error updating student model", error);
		}
	};
	
	const registerStudent = async function (newModelAttributes) {
		try {
			const parameters = {
				registrationType: "student",
				attributes: newModelAttributes
			};
	
			const studentRegistrationReply = await mutateGraphQL("registration", parameters);
			await getNewUserSession();
			loadUser();
			return studentRegistrationReply;
		}
		catch (error) {
			throwError("Error registering as student", error);
		}
	};
	
	const updateParentModelAPI = async function (newDataModel, userType = "parent", parentID = null) {
		const parentDataModelAPI = "parentDataModels";
		const updateParentDataPath = "/updateParentModel";
	
		const putParameters = {
			body: {
				attributes: newDataModel,
				userType: userType,
				parentID: parentID
			}
		};
	
		try {
			const parentDataModelReply = await callAPIGateway(parentDataModelAPI, updateParentDataPath, putParameters, "put");
			if (userType == "parent") {
				loadParent(["parentModel"]);
			}
			else if (userType == "admin") {
				loadAdmin(["parents"]);
			}
			return parentDataModelReply;
		}
		catch (error) {
			throw "Error updating parent data model, API Error:\n" + error;
		}
	};

	const registrationAPI = async function (registrationType, newModelAttributes) {
		try {
			const parameters = {
				registrationType: registrationType,
				attributes: newModelAttributes
			};
			await mutateGraphQL("registration", parameters);
		}
		catch (error) {
			throwError("Error registering user", error);
		}
	};

	// const registerParent = async function (newModelAttributes) {
	// 	try {
	// 		console.log(newModelAttributes);
	// 		const parameters = {
	// 			registrationType: "parent",
	// 			attributes: newModelAttributes
	// 		};
	
	// 		const parentRegistrationReply = await mutateGraphQL("registration", parameters);
	// 		await getNewUserSession();
	// 		loadUser();
	// 		return parentRegistrationReply;
	// 	}
	// 	catch (error) {
	// 		throwError("Error registering as tutor", error);
	// 	}
	// };

	const parentAddStudentAPI = async function (studentAttributes) {
		try {
			const parameters = {
				attributes: studentAttributes
			};
			const newParentsStudentReply = await mutateGraphQL("parentAddStudent", parameters);
			return newParentsStudentReply;
		}
		catch (error) {
			throwError("Error adding student for parent", error);
		}
	}

	const getUserTransactionsAPI = async function () {
		const userModelsAPI = "userModels";
		const getUserTransactionsPath = "/getUserTransactions";
		try {
			const parentLessonsReply = await callAPIGateway(userModelsAPI, getUserTransactionsPath, null, "put");
			return parentLessonsReply;
		}
		catch (error) {
			throw "Error getting user transactions, API Error:\n" + error;
		}
	};

	const updateParentsStudentModelAPI = async function (studentAttributes) {
		const studentDataModelAPI = "studentDataModels";
		const updateStudentPath = "/updateStudentModel";
		try {
			if (studentAttributes.studentID == null) {
				throw "No student ID given"
			}
			const putParameters = {
				body: {
					userType: "parent",
					attributes: studentAttributes
				}
			};
			const newStudentModel = await callAPIGateway(studentDataModelAPI, updateStudentPath, putParameters, "put");
			loadParent(["students"]);
			return newStudentModel;
		}
		catch (error) {
			throw "Error updating parent's student model, API Error:\n" + error;
		}
	};

	const createTutorRequestAPI = async function (attributes) {
		console.log("Creating tutor request API");
		const tutorDataModelsAPI = "tutorDataModels";
		const createTutorRequestPath = "/createTutorRequest";
	
		try {
			if (userGroups.userIsAdmin) {
				const postParameters = {
					body: {
						attributes: attributes
					} 
				};

				const tutorRequestsReply = await callAPIGateway(tutorDataModelsAPI, createTutorRequestPath, postParameters, "post");
				loadTutor(["tutorRequests"]);
				loadAdmin(["lessonRequests"]);
				loadParent(["lessonRequests"]);
				loadStudent(["lessonRequests"]);
				return tutorRequestsReply;
			}
			else {
				throw "User is not an admin";
			}
		}
		catch (error) {
			if (typeof(error) == "string") {
				throw "Error creating tutor request: " + error;
			} 
			else {
				throw error;
			}
		}
	};

	const respondToTutorRequestAPI = async function (tutorRequestID, response, details) {
		const tutorDataModelAPI = "tutorDataModels";
		const respondToTutorRequestPath = "/respondToTutorRequest";
		try {
			const putParameters = {
				body: {
					tutorRequestID: tutorRequestID,
					response: response,
					lessons: details,
					declineReason: details
				}
			};

			const replySuccess = await callAPIGateway(tutorDataModelAPI, respondToTutorRequestPath, putParameters, "put");
			//Refresh tutor requests
			loadAdmin(["lessonRequests", "lessons"]);
			loadParent(["lessonRequests", "lessons"]);
			loadStudent(["lessons", "lessonRequests"]);
			loadTutor(["lessons", "tutorRequests"]);
			return replySuccess;
		}
		catch (error) {
			console.log(error.response.data);
			throw "Error responding to tutor request, API Error:\n" + error;
		}
	};
	
	const createLessonAPI = async function (newLessonAttributes) {
		//console.log("Creating student model")
		const lessonDataModelAPI = "lessonDataModels";
		const createLessonPath = "/createLessonModel";
		try {
			const postParameters = {
				body: {
					attributes: newLessonAttributes
				}
			};
	
			const createLessonReply = await callAPIGateway(lessonDataModelAPI, createLessonPath, postParameters, "post");
			loadAdmin(["lessons", "lessonRequests"]);
			loadStudent(["lessons", "lessonRequests"]);
			loadTutor(["lessons", "tutorRequests"]);
			loadParent(["lessons", "lessonRequests"]);
			return createLessonReply;
		}
		catch (error) {
			console.log(error.response.data);
			throw error;
		}
	};

	const updateLessonAPI = async function (newLessonAttributes, userType) {
		const lessonDataModelAPI = "lessonDataModels";
		const updateLessonPath = "/updateLesson";
		try {
			if (!userGroups.userIsDeveloper) {
				if (newLessonAttributes.deleteLessonPermanently != null) {
					throw "User does not have the permissions to permanently delete";
				}
			}

			if (userType == null || typeof(userType) != "string") {
				userType = "admin";
			}

			const putParameters = {
				body: {
					attributes: JSON.stringify(newLessonAttributes),
					userType: userType
				}
			};
			
			const updateLessonReply = await callAPIGateway(lessonDataModelAPI, updateLessonPath, putParameters, "put");
			loadAdmin(["lessons", "lessonRequests"]);
			loadStudent(["lessons", "lessonRequests"]);
			loadTutor(["lessons", "tutorRequests"]);
			loadParent(["lessons", "lessonRequests"]);

			if (newLessonAttributes.amendments != null && newLessonAttributes.amendments.add != null) {
				let foundNewLessonRequest = false;
				for (const amendment of newLessonAttributes.amendments.add) {
					if (amendment.type == "addScheduled") {
						foundNewLessonRequest = true;
					}
				}
				if (foundNewLessonRequest == true) {
					loadTutor(["tutorRequests"]);
				}
			}
			return updateLessonReply;
		}
		catch (error) {
			if (error.response != null && error.response.data != null) {
				throw error.response.data;
			}
			throw error;
		}
	};

	const createLessonRequestAPI = async function (newLessonRequestAttributes, userType) {
		console.log("Creating lesson request")
		const lessonRequestModelAPI = "lessonRequests";
		const createLessonRequestPath = "/createLessonRequest";
		try {
			if (userType != null && userType != "student" && userType != "parent") {
				userType = null;
			}

			if (userType == null) {
				userType = JSON.stringify(userType);
			}
			
			const postParameters = {
				body: {
					attributes: newLessonRequestAttributes,
					usertype: userType
				}
			};
	
			const createLessonRequestReply = await callAPIGateway(lessonRequestModelAPI, createLessonRequestPath, postParameters, "post");
			loadAdmin(["lessonRequests"]);
			loadStudent(["lessonRequests"]);
			loadParent(["lessonRequests"]);
			return createLessonRequestReply;
		}
		catch (error) {
			console.log("Error creating lesson request with API");
			if (error.response != null) {
				throw error.response.data;
			}
			throw error;
		}
	};

	const updateLessonRequestAPI = async function (newLessonAttributes, userType) {
		const lessonRequestsDataModelAPI = "lessonRequests";
		const updateLessonRequestPath = "/updateLessonRequest";
		try {

			if (!userGroups.userIsDeveloper) {
				if (newLessonAttributes.deleteLessonPermanently != null) {
					throw "User does not have the permissions to permanently delete";
				}
			}

			if (userType != "admin" && userType != "student" && userType != "parent") {
				throw "Invalid user type";
			}

			const putParameters = {
				body: {
					attributes: newLessonAttributes,
					userType: userType
				}
			};
			
			const updateLessonRequestReply = await callAPIGateway(lessonRequestsDataModelAPI, updateLessonRequestPath, putParameters, "put");
			loadAdmin(["lessonRequests"]);
			loadStudent(["lessonRequests"]);
			loadParent(["lessonRequests"]);
			return updateLessonRequestReply;
		}
		catch (error) {
			throw error;
		}
	};
	
	const updateUserAttributesAPI = async function (newAttributes) {
		console.log("Updating User Attributes")
		try {
			let attributesObject = newAttributes;
			if (Array.isArray(newAttributes)) {
				attributesObject = {};
				for (const newAttribute of newAttributes) {
					if (!isJSON(newAttribute)) {
						throw "Invalid attribute given";
					}
					if (typeof(newAttribute.Name) != "string") {
						throw "Invalid attribute name given";
					}
					attributesObject[newAttribute.Name] = newAttribute.Value;
				}
			}
	
			const parameters = {
				attributes: attributesObject
			};

			console.log(attributesObject);
			
			const validatedAttributes = await mutateGraphQL("updateUserAttributes", parameters);
			console.log(validatedAttributes);
			// if (validatedAttributes == null || validatedAttributes.length == 0) {
			// 	throw "No valid attributes given to update"
			// }
			
			// const updatedAttributes = []

			// const updateAttribute = async function (newAttribute) {
			// 	const updateUserAttributeReply = await Auth.updateUserAttributes({
			// 		AccessToken: (await Auth.fetchAuthSession()).tokens.accessToken.toString(),
			// 		attributes: newAttributes
			// 	});
			// 	console.log(updateUserAttributeReply);
			// 	if (updateUserAttributeReply === "SUCCESS") {
			// 		updatedAttributes.push(Object.keys(newAttribute)[0]);
			// 	}
			// 	else {
			// 		throw "Error updating attribute: " + newAttribute.Name;
			// 	}
			// }

			// const attributeUpdatePromises = [];
			// for (const newAttribute of validatedAttributes) {
			// 	attributeUpdatePromises.push(updateAttribute(newAttribute));
			// }

			// await Promise.all(attributeUpdatePromises);

			await getNewUserSession();
			return validatedAttributes;
		}
		catch (error) {
			throwError("Error updating user attributes", error);
		}
	};

	const adminGetUsersAPI = async function () {
		const userModelsAPI = "userModels";
		const adminGetUsersPath = "/adminGetUsers ";
	
		try {
			if (userGroups.userIsAdmin) {
				const users = await callAPIGateway(userModelsAPI, adminGetUsersPath, null, "put");
				return users;
			}
		}
		catch (error) {
			throw "Error getting users, API Error" + error;
		}
	};

	const findTutorsForLessonAPI = async function (request, completeSearch) {
		const matchingAlgorithmAPI = "matchingAlgorithm";
		const findTutorsPath = "/findTutors";

		if (!userGroups.userIsAdmin) {
			throw "User is not admin";
		}

		const putParameters = {
			body: {
				request: request,
				completesearch: completeSearch,
			}
		};
	
		try {
			const possibleTutors = await callAPIGateway(matchingAlgorithmAPI, findTutorsPath, putParameters, "put");
			return possibleTutors;
		}
		catch (error) {
			console.log(error);
			console.log(error.response.data);
			throw error;
		}
	};

	const requestRefundAPI = async function (transaction, refundReason) {
		const transactionsAPI = "transactions";
		const requestRefundPath = "/requestRefund";
		try {
			const putParameters = {
				body: {
					transaction: transaction,
					refundReason: refundReason
				}
			};
			
			const requestRefundReply = await callAPIGateway(transactionsAPI, requestRefundPath, putParameters, "put");
			getUserTransactions();
			loadAdmin(["transactions"]);
			return requestRefundReply;
		}
		catch (error) {
			console.log(error.response.data);
			throw error;
		}
	};

	const cancelRefundRequestAPI = async function (transaction) {
		const transactionsAPI = "transactions";
		const cancelRefundRequestPath = "/cancelRefundRequest";
		try {
			const putParameters = {
				body: {
					transaction: transaction,
				}
			};
			
			const cancelRefundRequestReply = await callAPIGateway(transactionsAPI, cancelRefundRequestPath, putParameters, "put");
			getUserTransactions();
			loadAdmin(["transactions"]);
			return cancelRefundRequestReply;
		}
		catch (error) {
			console.log(error.response.data);
			throw error;
		}
	};

	const processRefundAPI = async function (transaction, refundAmount, forceRefund) {
		const transactionsAPI = "transactions";
		const processRefundPath = "/processRefund";
		try {
			if (!userGroups.userIsAdmin) {
				throw "User does not have admin privileges"
			}
			const putParameters = {
				body: {
					transaction: transaction,
					refundAmount: refundAmount,
					forceRefund: forceRefund
				}
			};
			console.log(refundAmount);

			const processRefundReply = await callAPIGateway(transactionsAPI, processRefundPath, putParameters, "put");
			getUserTransactions();
			loadAdmin(["transactions"]);
			return processRefundReply;
		}
		catch (error) {
			console.log(error.response.data);
			throw error;
		}
	};

	const getStripeAccountLinkAPI = async function () {
		const transactionsAPI = "transactions";
		const getStripeAccountLinkPath = "/getStripeAccountLink";
	
		try {
			if (userGroups.userIsAdmin) {
				const accountLink = await callAPIGateway(transactionsAPI, getStripeAccountLinkPath, null, "put")
				return accountLink;
			}
		}
		catch (error) {
			throw "Error getting Stripe account link, API Error" + error;
		}
	};

	const getTeachingResourcesAPI = async function (subject, yearGroup) {
		const teachingResourcesAPI = "teachingResources";
		const getTeachingResourcesPath = "/getTeachingResources";
		try {
			if (userGroups.userIsTutor || userGroups.userIsAdmin || userGroups.userIsDeveloper) {
				if (typeof(subject) != "string") {
					throw "Invalid subject";
				}

				if (yearGroup != "KS1" && yearGroup != "KS2" && yearGroup != "KS3" && yearGroup != "KS4" && yearGroup != "KS5" && yearGroup != "University") {
					throw "Invalid year group";
				}

				const putParameters = {
					body: {
						subject: subject,
						yeargroup: yearGroup
					} 
				}; 
				const teachingResources = await callAPIGateway(teachingResourcesAPI, getTeachingResourcesPath, putParameters, "put");
				return teachingResources;
			}
		}
		catch (error) {
			throw "Error getting teaching resources, API Error: " + error;
		}
	};

	const createTeachingResourceAPI = async function (attributes) {
		const teachingResourcesAPI = "teachingResources";
		const createTeachingResourcePath = "/createTeachingResource";
		try {
			if (userGroups.userIsTutor || userGroups.userIsAdmin || userGroups.userIsDeveloper) {

				const putParameters = {
					body: {
						attributes: attributes
					} 
				}; 
				const teachingResources = await callAPIGateway(teachingResourcesAPI, createTeachingResourcePath, putParameters, "post");
				return teachingResources;
			}
		}
		catch (error) {
			throw "Error creating teaching resource, API Error: " + error;
		}
	};

	const getStripeVerificationSessionAPI = async function () {
		const stripeAPI = "stripe";
		const getVerificationSessionPath = "/getStripeVerificationSession";
		try {
			if (userGroups.userIsTutor || userGroups.userIsParent || userGroups.userIsStudent) {
				const verificationSession = await callAPIGateway(stripeAPI, getVerificationSessionPath, null, "put");
				return verificationSession;
			}
		}
		catch (error) {
			throw "Error getting Stripe verification session, API Error: " + error;
		}
	};

	const createStripeInvoiceAPI = async function () {
		try {
			if (!userGroups.userIsParent && !userGroups.userIsStudent) {
				return;
			}
			const invoice = await callAPIGateway("stripe", "/createStripeInvoice", null, "post");
			return invoice;
		}
		catch (error) {
			throw "Error creating new Stripe invoice: " + error;
		}
	};

	const getUserChatsAPI = async function () {
		const chatsAPI = "chats";
		const getUserChatsPath = "/getUserChats";
		try {
			const chatsObject = await callAPIGateway(chatsAPI, getUserChatsPath, null, "put");
			return chatsObject;
		}
		catch (error) {
			throw "Error getting user chats, API Error: " + error;
		}
	};

	const adminGetChatsAPI = async function () {
		const chatsAPI = "chats";
		const adminGetChatsPath = "/adminGetChats";
		try {
			const chatsObject = await callAPIGateway(chatsAPI, adminGetChatsPath, null, "put");
			setAdminChats(chatsObject);
			return chatsObject;
		}
		catch (error) {
			throw "Error getting admin chats, API Error: " + error;
		}
	};

	const logFormErrorAPI = async function (error, answers) {
		try {
			console.log("Logging form error");
			const parameters = {
				currentPage: window.location.pathname,
				error: getErrorString(error),
				answers: answers,
				time: new Date().toString(),
				userAgent: navigator.userAgent
			}; 
			//await mutateGraphQL("logFormError", parameters);
		}
		catch (error) {
			throwError("Error logging form error", error);
		}
	};

	const getAirwallexBalanceAPI = async function () {
		try {
			const airwallexAPI = "airwallex";
			const getAirwallexBalancePath = "/getAirwallexBalance";
			const newBalance = await callAPIGateway(airwallexAPI, getAirwallexBalancePath, null, "put")
			setAirwallexBalance(newBalance);
		}
		catch (error) {
			throw "Error getting airwallex balance: " + error;
		}
	};

	const payTutorsAPI = async function (tutorPayments) {
		try {
			if (userGroups.userIsAdmin) {
				const airwallexAPI = "airwallex";
				const payTutorsPath = "/payTutors";
				const putParameters = {
					body: {
						payouts: tutorPayments
					} 
				}; 
				const paidTutors = await callAPIGateway(airwallexAPI, payTutorsPath, putParameters, "put");
				loadAdmin(["tutors"]);
				return paidTutors;
			}
		}
		catch (error) {
			if (error.response != null && error.response.data != null) {
				throw "Error paying tutors: " + error.response.data;
			}
			throw error;
		}
	};

	const adminMassEmailAPI = async function (users, lessonStatus, personalise, subject, body, greeting, sendPreview) {
		try {
			if (userGroups.userIsAdmin) {
				const adminFunctionsAPI = "adminFunctions";
				const adminMassEmailPath = "/adminMassEmail";
				const putParameters = {
					body: {
						users: users,
						lessonStatus: lessonStatus,
						personalise: personalise,
						subject: subject,
						body: body,
						greeting: greeting,
						sendPreview: sendPreview
					} 
				}; 
				const emailReturn = await callAPIGateway(adminFunctionsAPI, adminMassEmailPath, putParameters, "put");
				return emailReturn;
			}
		}
		catch (error) {
			if (error.response != null && error.response.data != null) {
				throw "Error sending mass email: " + error.response.data;
			}
			throw error;
		}
	};

	const getUserConnectionsAPI = async function (userIDs, passedUserConnections = null) {
		try {
			//console.log(jwtTimings);
			const userJWTTime = jwtTimings[userAccessJWT];
			//console.log(userJWTTime);
			for (const [jwt, time] of Object.entries(jwtTimings)) {
				if (new Date(time) > new Date(userJWTTime)) {
					//console.log("User JWT is not the most recent");
					break;
				}
			}
			if (!userGroups.userIsAdmin) {
				return;
			}
			const adminAPI = "adminFunctions";
			const getUserConnectionsPath = "/getWebsocketConnections";

			if (userIDs != "all" && !Array.isArray(userIDs)) {
				userIDs = [userIDs];
			}
			const putParameters = {
				body: {
					userIDs: userIDs
				} 
			}; 
			const newConnections = await callAPIGateway(adminAPI, getUserConnectionsPath, putParameters, "put");
			let newUserConnections = {...userConnections};
			if (passedUserConnections != null && typeof(passedUserConnections) == "object") {
				newUserConnections = {...passedUserConnections};
			}
			if (userIDs == "all") {
				newUserConnections = {};
			}
			for (const connection of newConnections) {
				newUserConnections[connection.userID] = {...newUserConnections[connection.userID]};
				newUserConnections[connection.userID][connection.connectionID] = connection;
			}
			setUserConnections(newUserConnections);
			return newUserConnections;
		}
		catch (error) {
			if (error.response != null && error.response.data != null) {
				throw "Error getting user websocket connections: " + error.response.data;
			}
			throw error;
		}
	};

	const forceAppUpdateAPI = async function (userIDs, connectionIDs, command, updatedUserIDs = null) {
		try {
			if (!userGroups.userIsAdmin) {
				return;
			}
			const adminAPI = "adminFunctions";
			const forceUpdatePath = "/forceAppUpdate";

			if (!Array.isArray(userIDs) && userIDs != "all" && userIDs != null) {
				userIDs = [userIDs];
			}
			if (!Array.isArray(connectionIDs) && connectionIDs != null) {
				connectionIDs = [connectionIDs];
			}
			if (typeof(command) == "string") {
				command = {command: command};
			}
			const putParameters = {
				body: {
					userIDs: userIDs,
					connectionIDs: connectionIDs,
					...command
				} 
			}; 
			const connectionsUpdated = await callAPIGateway(adminAPI, forceUpdatePath, putParameters, "put");
			const newUserConnections = {...userConnections};
			const reloadUsers = [];

			if (userIDs != null) {
				if (Array.isArray(userIDs)) {
					reloadUsers.push(...userIDs);
				}
				else {
					reloadUsers.push(userIDs);
				}
			}
			if (updatedUserIDs != null) {
				if (Array.isArray(updatedUserIDs)) {
					reloadUsers.push(...updatedUserIDs);
				}
				else {
					reloadUsers.push(updatedUserIDs);
				}
			}
			
			for (const userID of reloadUsers) {
				delete newUserConnections[userID];
			}
			setUserConnections(newUserConnections);
			await sleep(500);
			if (reloadUsers.length > 0) {
				getUserConnectionsAPI(reloadUsers, newUserConnections);
			}
			return connectionsUpdated;
		}
		catch (error) {
			if (error.response != null && error.response.data != null) {
				throw "Error forcing user app update: " + error.response.data;
			}
			throw error;
		}
	};

	const verifyNewEmailAPI = async function (verificationCode, newEmail) {
		try {
			if (userGroups.userIsAdmin) {
				const updateUserAttributesAPI = "updateUserAttributes";
				const verifyNewEmailPath = "/verifyNewEmail";
				const putParameters = {
					body: {
						newEmail: newEmail,
						verificationCode: verificationCode
					} 
				}; 
				await callAPIGateway(updateUserAttributesAPI, verifyNewEmailPath, putParameters, "put");
				await getNewUserSession();
			}
		}
		catch (error) {
			if (error.response != null && error.response.data != null) {
				throw "Error verifying new email: " + error.response.data;
			}
			throw error;
		}
	};

	const sendMessageAPI = async function (message, recipientID, messageID) {
		try {
			const sendMessageCommand = {
				action: "sendMessage",
				message: message,
				recipientID: recipientID,
				messageID: messageID
			};
			await callWebsocketAPI(sendMessageCommand);
		}
		catch (error) {
			throw "Error sending message to web socket: " + error;
		}
	};

	const readMessageAPI = async function (recipientID) {
		try {
			const readMessageCommand = {
				action: "readMessage",
				recipientID: recipientID,
			};
			await callWebsocketAPI(readMessageCommand);
		}
		catch (error) {
			throw "Error sending read message command to web socket: " + error;
		}
	};

	const sendTypingAPI = async function (recipientID, isTyping) {
		try {
			const sendTypingCommand = {
				action: "sendTyping",
				recipientID: recipientID,
				isTyping: isTyping
			};
			await callWebsocketAPI(sendTypingCommand);
		}
		catch (error) {
			throw "Error sending typing command to web socket: " + error;
		}
	};

	const sendUserActivityAPI = async function (activityDetails) {
		try {
			const activity = activityDetails.activity;
			const state = activityDetails.state;
			const sendActivityCommand = {
				action: "userActivity",
				activity: activity,
				sessionStart: webSocketStartTime
			};
			//await callWebsocketAPI(sendActivityCommand);
		}
		catch (error) {
			throw "Error sending user activity command to web socket: " + error;
		}
	};

	const adminGetUserInfoAPI = async function (searchParams) {
		try {
			const adminGetUserInfoCommand = {
				action: "adminGetUserInfo",
				userID: searchParams.userID,
				activityStartKey: searchParams.activityStartKey,
				activitySearchLimit: searchParams.activitySearchLimit,
				searchActivity: searchParams.searchActivity
			};
			console.log(adminGetUserInfoCommand);
			await callWebsocketAPI(adminGetUserInfoCommand);
		}
		catch (error) {
			throw "Error sending adminGetUserInfo command to web socket: " + error;
		}
	};

	const reportIssueAPI = async function (issue, functionName = "report") {
		try {
			const postParameters = {
				body: {
					issue: issue,
					function: functionName
				} 
			}; 
			const response = await callAPIGateway("errorReporting", "/reportIssue", postParameters, "post");
			return response;
		}
		catch (error) {
			throw "Error reporting issue: " + error;
		}
	};

	const adminGetIssuesAPI = async function (params) {
		try {
			const postParameters = {
				body: {
					params: params
				} 
			}; 
			const response = await callAPIGateway("errorReporting", "/adminGetIssues", postParameters, "put");
			return response;
		}
		catch (error) {
			throw "Error getting issues for admin: " + error;
		}
	};


	//NOT USED
	const getEmbeddedMapAPI = async function (mapType, origin, destination) {
		try {
			const matchingAlgorithmAPI = "matchingAlgorithm";
			const getMapPath = "/getMap";

			if (mapType == null) {
				mapType = "place";
			}
			if (origin == null) {
				origin = "";
			}
			if (destination == null) {
				destination = "";
			}

			const getParameters = {
				headers: {
					type: mapType,
					//origin: origin,
					destination: destination
				},
				body: {
					accessJWT: userAccessJWT
				}
			};
			
			console.log(getParameters);

			const embeddedMap = await apiClient.get(matchingAlgorithmAPI, getMapPath, getParameters);
			return embeddedMap;
		}
		catch (error) {
			console.log(error);
			console.log(error.response.data);
			throw error;
		}
	};

	//console.log(userAccessJWT);
	//console.log(user);
	//console.log(props);

	const getUserSettings = function (userSettings) {
		try {
			userSettings = JSON.parse(userSettings);
		} catch {}

		if (userSettings == null) {
			userSettings = {};
		}
		if (userSettings.distanceUnit == null) {
			userSettings.distanceUnit = "imperial";
		}

		return userSettings;
	};
	

	//const userAttributes = user.attributes || {};
	const userAttributes = {};
	const userSettings = getUserSettings(userAttributes["custom:settings"]);

	let creditUserModel = null;
	if (userGroups != null && userGroups.userIsParent) {
		creditUserModel = parentModel;
	}
	else if (userGroups != null &&  userGroups.userIsStudent) {
		creditUserModel = studentModel;
	}
	
	let tutorCredit = null;
	if (userGroups != null && userGroups.userIsTutor && tutorModel != null) {
		tutorCredit = tutorModel.credit || 0;
	}

	let currentLessonCredit = null;
	let lessonCreditString = null;
	if (creditUserModel != null) {
		currentLessonCredit = parseFloat(creditUserModel.lessonCredits, 10);
		if (tutorCredit != null) {
			currentLessonCredit = currentLessonCredit + parseFloat(tutorCredit, 10);
		}
		if (currentLessonCredit == null) {
			currentLessonCredit = 0
		}
	}
	else if (tutorCredit != null) {
		currentLessonCredit = tutorCredit;
	}
	if (currentLessonCredit != null) {
		const creditWhole = currentLessonCredit.toString().split(".")[0];
		let creditDecimal = currentLessonCredit.toString().split(".")[1];
		if (creditDecimal == null) {
			creditDecimal = "00";
		}
		else if (creditDecimal.length == 0) {
			creditDecimal = "00";
		}
		else if (creditDecimal.length == 1) {
			creditDecimal = creditDecimal + "0";
		}
		else if (creditDecimal.length > 2) {
			creditDecimal = creditDecimal.substring(0, 2);
		}
		if (currentLessonCredit >= 0) {
			lessonCreditString = "£" + creditWhole + "." + creditDecimal;
		}
		else {
			lessonCreditString = "-£" + creditWhole.split("-").join("") + "." + creditDecimal;
		}
	}

	//Get the user
	
	passingProps.settings = userSettings;
	
	passingProps.styledLink = StyledLink;
	passingProps.currentLessonCredit = currentLessonCredit;
	passingProps.lessonCreditString = lessonCreditString;
	passingProps.fromLocation = fromLocation;
	passingProps.numUnreadMessages = numUnreadMessages;
	passingProps.usersLastOnline = usersLastOnline;
	passingProps.messageRedirect = messageRedirect;
	passingProps.usersTyping = usersTyping;
	passingProps.setMessageRedirect = setMessageRedirect;
	passingProps.lastMouseClick = lastMouseClick;
	passingProps.backStack = backStack;
	passingProps.setBackStack = setBackStack;

	//Set APIPreReady
	useEffect(() => {
		if (userAccessJWT == null || webSocket == null || userGroups == null) {
			setAPIFunctions(null);
			return;
		}
		
		const newAPIFunctions = {};
		
		newAPIFunctions.findTutorsForLesson = findTutorsForLessonAPI;
		newAPIFunctions.getEmbeddedMap = getEmbeddedMapAPI;
		newAPIFunctions.getUserTransactions = getUserTransactions;

		newAPIFunctions.createTeachingResource = createTeachingResourceAPI;
		newAPIFunctions.getTeachingResources = getTeachingResources;

		newAPIFunctions.getStripeAccountLink = getStripeAccountLinkAPI;
		newAPIFunctions.getStripeVerificationSession = getStripeVerificationSessionAPI;
		newAPIFunctions.getAirwallexBalance = getAirwallexBalanceAPI;
		newAPIFunctions.createStripeInvoice = createStripeInvoiceAPI;
		
		newAPIFunctions.adminMassEmail = adminMassEmailAPI;

		newAPIFunctions.sendMessage = sendMessageAPI;
		newAPIFunctions.readMessage = readMessageAPI;
		newAPIFunctions.sendTyping = sendTypingAPI;
		newAPIFunctions.adminGetChats = adminGetChatsAPI;
		newAPIFunctions.sendUserActivity = sendUserActivityAPI;
		newAPIFunctions.adminGetUserInfo = adminGetUserInfoAPI;
		
		newAPIFunctions.logFormError = logFormErrorAPI;

		newAPIFunctions.getNewCurrentUser = getNewUserSession;
		newAPIFunctions.verifyNewEmail = verifyNewEmailAPI;

		newAPIFunctions.updateUser = updateUserAPI

		newAPIFunctions.createTutorModel = registerTutor;
		newAPIFunctions.updateTutorModel = updateTutorModelAPI;

		newAPIFunctions.createStudentModel = registerStudent;
		newAPIFunctions.updateStudentModel = updateStudentModelAPI;

		newAPIFunctions.registerUser = registrationAPI;
		newAPIFunctions.updateParentModel = updateParentModelAPI;
		newAPIFunctions.parentAddStudent = parentAddStudentAPI;
		newAPIFunctions.updateParentsStudent = updateParentsStudentModelAPI;

		newAPIFunctions.createLesson = createLessonAPI;
		newAPIFunctions.updateLesson = updateLessonAPI;
		
		newAPIFunctions.createLessonRequest = createLessonRequestAPI;
		newAPIFunctions.updateLessonRequest = updateLessonRequestAPI;

		newAPIFunctions.createTutorRequest = createTutorRequestAPI;
		newAPIFunctions.respondToTutorRequest = respondToTutorRequestAPI;

		newAPIFunctions.requestRefund = requestRefundAPI;
		newAPIFunctions.cancelRefundRequest = cancelRefundRequestAPI;
		newAPIFunctions.processRefund = processRefundAPI;

		newAPIFunctions.payTutors = payTutorsAPI;
		
		newAPIFunctions.adminGetUsers = adminGetUsers;

		newAPIFunctions.getUserConnections = getUserConnectionsAPI;
		newAPIFunctions.forceAppUpdate = forceAppUpdateAPI;

		newAPIFunctions.updateUserAttributes = updateUserAttributesAPI;

		newAPIFunctions.reportIssue = reportIssueAPI;
		newAPIFunctions.adminGetIssues = adminGetIssuesAPI;

		newAPIFunctions.loadAdmin = loadAdmin;

		setAPIFunctions(newAPIFunctions);
	}, [
		userAccessJWT, jwtTimings, webSocket, userGroups,
		tutorDictionary, studentDictionary, parentDictionary, userDictionary,
		lessonDictionary, studentLessonDictionary, lessonRequestDictionary, studentLessonRequestDictionary, tutorRequestDictionary, transactionDictionary,
		userConnections
	]);

	const refreshUser = async function(s = 3600) {
		const ms = s * 1000
		while (true) {
			await sleep(ms);
			getNewUserSession();
		}
	};

	//Get user attributes
	useEffect(() => {
		if (APIFunctions == null || userAccessJWT == null || userGroups == null) {
			return;
		}
		
		const setAttributes = async function (extraLogging = false) {
			const startTime = new Date().valueOf();
			const promises = []; 
			// try { promises.push(loadAdmin(null, true, extraLogging)) } catch (error) {
			// 	console.log("Error loading admin: " + error);
			// }
			try { promises.push(loadUser(null, true, extraLogging)) } catch (error) {
				console.log("Error loading user: " + error);
			}
			// try { promises.push(loadParent(null, true, extraLogging)) } catch (error) {
			// 	console.log("Error loading parent: " + error);
			// }
			// try { promises.push(loadStudent(null, true, extraLogging)) } catch (error) {
			// 	console.log("Error loading student: " + error);
			// }
			// try { promises.push(loadTutor(null, true, extraLogging)) } catch (error) {
			// 	console.log("Error loading student: " + error);
			// }
			// try { promises.push(getUserTransactions(true, extraLogging)) } catch (error) {
			// 	console.log("Error getting user transactions: " + error);
			// }
			// try { promises.push(getUserChats(true, extraLogging)) } catch (error) {
			// 	console.log("Error getting user chats: " + error);
			// }
			
			await Promise.all(promises);
			const endTime = new Date().valueOf();
			const loadTime = endTime - startTime;
			console.log("Load complete in " + loadTime + " ms");
			//Refresh the user every hour incase it's needed
			refreshUser(3600);
		}
		
		if (userGroups.inAllUsersGroup != null && initialLoadDone == false) {
			//console.log("Setting attributes");
			setAttributes(false);
			setInitialLoadDone(true);
			//setAttributes(userIsDeveloper);
		}
	}, [userAccessJWT, initialLoadDone, userGroups, APIFunctions]);

	const standardTextProps = {};
	//standardTextProps.color = "#08007d";
	standardTextProps.color = "#08007d";
	standardTextProps.fontWeight = "medium";
	standardTextProps.fontSize = "25px";
	standardTextProps.maxWidth= "90vw"
	//standardTextProps.maxWidth = "400px";
	if (width < 550) {
		standardTextProps.fontSize = "18px";
	}
	standardTextProps.textAlign = "center";

	passingProps.userTransactions = userTransactions;
	passingProps.standardTextProps = standardTextProps;
	passingProps.getNewCurrentUser = getNewUserSession;
	passingProps.APIFunctions = APIFunctions;
	passingProps.origin = window.location.origin;

	passingProps.appRedirect = appRedirect;
	passingProps.setAppRedirect = setAppRedirect;

	const page =  props.page
	//console.log(page);
	passingProps.page = page;
	passingProps.pathname = pathname;
	passingProps.chats = chats;
	passingProps.webSocket = webSocket;

	if (page.split("/")[1] != "" && initialAdminRedirect != true) {
		setInitialAdminRedirect(true);
	}

	const titleTextProps = {};
	titleTextProps.color = "#08007d";
	titleTextProps.fontWeight = "bold";
	titleTextProps.fontSize = "30px";
	titleTextProps.textAlign = "center";
	passingProps.titleTextProps = titleTextProps;

	passingProps.sideMarginSize = "5vw";

	if (userGroups != null && userGroups.userIsAdmin) {
		passingProps.adminUsersInfo = adminUsersInfo;
	}

	if (userGroups != null && userGroups.userIsAdmin) {
		passingProps.allUsers = userDictionary;
		passingProps.teachingResources = teachingResources;
		passingProps.setAirwallexBalance = setAirwallexBalance;
		passingProps.airwallexBalance = airwallexBalance;
		passingProps.setAdminAllChats = setAdminChats;
		passingProps.adminAllChats = adminChats;
	}

	//Uncomment below to turn on site maintenance
	// if (user == null || user.attributes == null || user.attributes.email != "techsupport@nextdoortutor.co.uk") {
	// 	return <SiteMaintenance />
	// }

	if (APIFunctions == null) {
		return <LoadingSpinnerPage {...passingProps} />
	}

	const buyCreditUsers = [
		"2734ef2a-20e4-47e2-9fbf-eb40b12412fe",
		"9e428431-8f9e-4642-9f56-db1d0923fb92",
		"0c7fe414-4c80-41f4-a26c-46e983ef715b"
	]
	for (const buyCreditUser of buyCreditUsers) {
		if (user.username == buyCreditUser) {
			if (parentModel != null || studentModel != null) {
				let totalCredit = 0;
				if (parentModel != null) {
					totalCredit = totalCredit + parentModel.lessonCredits;
				}
				if (studentModel != null) {
					totalCredit = totalCredit + studentModel.lessonCredits;
				}
				if (totalCredit >= 0) {
					continue;
				}
			}
			const signOutButton = <Button 
				{...props.standardTextProps}
				maxWidth={"200px"} 
				onClick={() => {
					Auth.signOut();
				}}
			>
				Sign Out
			</Button>;

			return <Flex marginTop={"30px"} marginBottom={"30px"} direction={"column"} alignItems={"center"} gap={"50px"}>
				<LessonCredits {...passingProps} />
				{signOutButton}
			</Flex>
		}
	}

	return (
		<BrowserRouter>
			<ReactErrorBoundary {...props}>
				<ScrollToTop />
				<AcceptNewTerms />
				<GetLocation />
				{appRedirect}
				<Routes>
					<Route path="/" element={<Layout {...passingProps} />}>
						<Route index element={<Home {...passingProps} initialAdminRedirect={initialAdminRedirect} setInitialAdminRedirect={setInitialAdminRedirect}/>} />
						<Route path="StudentRegister" element={<UserRegistration {...passingProps} registrationType={"student"} />} />
						<Route path="ParentRegister" element={<UserRegistration {...passingProps} registrationType={"parent"} />} />
						<Route path="TutorRegister" element={<UserRegistration {...passingProps} registrationType={"tutor"} />} />
						<Route path="LessonCredits" element={<LessonCredits {...passingProps} />} />
						<Route path="LessonCredits/ThankYou" element={<ThankYouPurchase {...passingProps} />} />
						<Route path="VerifyID" element={<VerifyID {...passingProps}/>} />
						<Route path="Messages" element={<Chats {...passingProps}/>} />
						<Route path="AgreeTerms" element={<AgreeTerms {...passingProps}/>} />
						<Route path="TutorGuide" element={<TutorStartGuide {...passingProps}/>} />
						<Route path="ParentGuide" element={<ParentStartGuide {...passingProps}/>} />
						<Route path="StudentGuide" element={<StudentStartGuide {...passingProps}/>} />
						<Route path="ReportIssue" element={<ReportIssue {...passingProps}/>} />
						<Route path="HelicopterDeliveries" element={<HelicopterDeliveries {...passingProps}/>} />
						<Route path="Auth" element={<AuthPage {...passingProps}/>} />
					</Route>
					
					<Route path="/Transactions" element={<Layout {...passingProps} />}>
						<Route index element={<ViewTransactions {...passingProps} userType={"customer"} />} />
						<Route path="ViewTransaction" element={<ViewTransaction {...passingProps} userType={"customer"} />} />
					</Route>

					<Route path="/AddDetails" element={<LayoutNoNavBar {...passingProps} />}>
						<Route index element={<UserNeedsAttributes {...passingProps} />} />
					</Route>
					
					<Route path="/Student" element={<StudentLayout {...passingProps} userType={"student"}/>}>
						<Route index element={<StudentHome {...passingProps} userType={"student"}/>} />
						<Route path="MyLessons" element={<StudentMyLessons {...passingProps} userType={"student"}/>} />
						<Route path="NewLessonRequest" element={<NewLessonRequest {...passingProps} userType={"student"}/>} />
						<Route path="LessonRequests" element={<StudentLessonRequests {...passingProps} userType={"student"}/>} />
						<Route path="MyLessonRequest" element={<ViewLessonRequest {...passingProps} userType={"student"}/>} />
						<Route path="EditLessonRequest" element={<EditLessonRequest {...passingProps} userType={"student"}/>} />
						<Route path="ViewLesson" element={<ViewLesson {...passingProps} userType={"student"} />} />
						<Route path="AmendLesson" element={<AmendLesson {...passingProps} userType={"student"}/>} />
						<Route path="AmendLessons" element={<StudentMyLessons {...passingProps} amendLessons={true}/>} />
						<Route path="RespondToAmendments" element={<RespondToAmendments {...passingProps} lessons={passingProps.studentLessons} userType={"student"}/>} />
						<Route path="RespondToAmendment" element={<RespondToAmendment {...passingProps} userType={"student"}/>} />
						<Route path="SignUpThankYou" element={<SignUpThankYou {...passingProps} userType={"student"}/>} />
						<Route path="StartGuide" element={<StudentStartGuide {...passingProps} userType={"student"}/>} />
					</Route>

					<Route path="/Parent" element={<ParentLayout {...passingProps} userType={"parent"}/>}>
						<Route index element={<ParentHome {...passingProps} userType={"parent"}/>} />
						<Route path="MyStudent" element={<MyStudent {...passingProps} userType={"parent"} />} />
						<Route path="MyLessons" element={<ParentLessons {...passingProps} userType={"parent"} />} />
						<Route path="LessonRequests" element={<ParentLessonRequests {...passingProps} userType={"parent"} />} />
						<Route path="AddNewStudent" element={<AddNewStudent {...passingProps} userType={"parent"} />} />
						<Route path="NewLessonRequest" element={<ParentNewLessonRequest {...passingProps} userType={"parent"} />} />
						<Route path="MyLessonRequest" element={<ViewLessonRequest {...passingProps} userType={"parent"} />} />
						<Route path="ViewLesson" element={<ViewLesson {...passingProps}userType={"parent"}  />} />
						<Route path="AmendLessons" element={<ParentLessons {...passingProps} amendLessons={true} userType={"parent"} />} />
						<Route path="AmendLesson" element={<AmendLesson {...passingProps} userType={"parent"}/>} />
						<Route path="RespondToAmendments" element={<RespondToAmendments {...passingProps} lessons={passingProps.parentLessons} userType={"parent"}/>} />
						<Route path="RespondToAmendment" element={<RespondToAmendment {...passingProps} userType={"parent"}/>} />
						<Route path="SignUpThankYou" element={<SignUpThankYou {...passingProps}userType={"parent"} />} />
						<Route path="StartGuide" element={<ParentStartGuide {...passingProps}userType={"parent"} />} />
					</Route>

					<Route path="/Tutor" element={<TutorLayout {...passingProps} userType={"tutor"} />}>
						<Route index element={<TutorHome {...passingProps} userType={"tutor"} />} />
						<Route path="MyLessons" element={<TutorMyLessons {...passingProps} userType={"tutor"} />} />
						<Route path="MyStudents" element={<TutorMyStudents {...passingProps}userType={"tutor"}  />} />
						<Route path="LessonRequests" element={<TutorLessonRequests {...passingProps} userType={"tutor"} />} />
						<Route path="LessonRequest" element={<TutorLessonRequest {...passingProps} userType={"tutor"} />} />
						<Route path="ChangeAvailability" element={<TutorChangeAvailability {...passingProps} userType={"tutor"} />} />
						<Route path="ChangeSubjects" element={<TutorChangeSubjects {...passingProps} userType={"tutor"} />} />
						<Route path="ChangeBankDetails" element={<TutorChangePayment {...passingProps} userType={"tutor"} />} />
						<Route path="ViewLesson" element={<ViewLesson {...passingProps} userType={"tutor"} />} />
						<Route path="AmendLesson" element={<AmendLesson {...passingProps} userType={"tutor"} />} />
						<Route path="TeachingResourcesSubject" element={<TeachingResourcesSubject {...passingProps} userType={"tutor"} />} />
						<Route path="TeachingResourcesYearGroup" element={<TeachingResourcesYearGroup {...passingProps} userType={"tutor"} />} />
						<Route path="TeachingResources" element={<TeachingResources {...passingProps} userType={"tutor"} />} />
						<Route path="Help" element={<TutorHelpList {...passingProps} userType={"tutor"} />} />
						<Route path="Help/PlanAndDeliver" element={<HelpPlanAndDeliver {...passingProps} userType={"tutor"} />} />
						<Route path="RespondToAmendments" element={<RespondToAmendments {...passingProps} lessons={passingProps.tutorLessons} userType={"tutor"}/>} />
						<Route path="RespondToAmendment" element={<RespondToAmendment {...passingProps} userType={"tutor"}/>} />
						<Route path="SignUpThankYou" element={<SignUpThankYou {...passingProps} userType={"tutor"} />} />
						<Route path="StartGuide" element={<TutorStartGuide {...passingProps} userType={"tutor"} />} />
					</Route>
					<Route path="/AddTutorDetails" element={<LayoutNoNavBar {...passingProps} userType={"tutor"} />}>
						<Route index element={<TutorNeedsAttributes {...passingProps} userType={"tutor"} />} />
					</Route>

					<Route path="/Admin" element={<AdminLayout {...passingProps} userType={"admin"} />}>
						<Route index element={<AdminHome {...passingProps} userType={"admin"} />} />
						<Route path="Lessons" element={<AdminLessons {...passingProps} userType={"admin"} /> }/>
						<Route path="ViewLesson" element={<AdminViewLesson {...passingProps} userType={"admin"} />}/>
						<Route path="DeletedLessons" element={<DeletedLessons {...passingProps} userType={"admin"} /> }/>
						<Route path="LessonRequests" element={<LessonRequests {...passingProps} userType={"admin"} />}/>
						<Route path="LessonRequest" element={<LessonRequest {...passingProps} userType={"admin"} />}/>
						<Route path="CreateNewLesson" element={<CreateNewLesson {...passingProps} userType={"admin"} />} />
						<Route path="EditLesson" element={<EditLesson {...passingProps} userType={"admin"} />} />
						<Route path="GiveAccessToTutor" element={<GiveAccessToTutor {...passingProps} userType={"admin"} />} />
						<Route path="ViewUsers" element={<ViewUsers {...passingProps} userType={"admin"} />} />
						<Route path="ViewUser" element={<ViewUser {...passingProps} userType={"admin"} />} />
						<Route path="Transactions" element={<ViewTransactions {...passingProps} userType={"admin"} />} />
						<Route path="ViewTransaction" element={<ViewTransaction {...passingProps} userType={"admin"} />} />
						<Route path="TeachingResourcesAdmin" element={<TeachingResourcesAdmin {...passingProps} />} />
						<Route path="TeachingResourcesSubject" element={<TeachingResourcesSubject {...passingProps} userType={"admin"} />} />
						<Route path="TeachingResourcesYearGroup" element={<TeachingResourcesYearGroup {...passingProps} userType={"admin"} />} />
						<Route path="TeachingResources" element={<TeachingResources {...passingProps} userType={"admin"} />} />
						<Route path="CreateTeachingResource" element={<CreateTeachingResource {...passingProps} userType={"admin"} />} />
						<Route path="ViewPricing" element={<ViewPricing {...passingProps} userType={"admin"} />} />
						<Route path="AmendLesson" element={<AmendLesson {...passingProps} userType={"admin"} />} />
						<Route path="PayTutors" element={<PayTutors {...passingProps} userType={"admin"} />} />
						<Route path="Messages" element={<AdminChats {...passingProps} userType={"admin"} />} />
						<Route path="MassEmail" element={<MassEmail {...passingProps} userType={"admin"} />} />
						<Route path="ViewCredit" element={<ViewInAppCredit {...passingProps} userType={"admin"} />} />
						<Route path="ViewPreviousLessons" element={<ViewPreviousLessons {...passingProps} userType={"admin"} />} />
						<Route path="ForceAppUpdate" element={<ForceAppUpdate {...passingProps} userType={"admin"} />} />
						<Route path="Tests" element={<Tests {...passingProps} userType={"admin"} />} />
						<Route path="AppIssues" element={<AppIssues {...passingProps} userType={"admin"} />} />
					</Route>

					<Route path="/User" element={<UserLayout {...passingProps} />}>
						<Route index element={<ProfilePage {...passingProps} />} />
						<Route path="Settings" element={<UserSettingsPage {...passingProps} />} />
					</Route>

					<Route path="/SignOut" element={<LayoutNoNavBar {...passingProps} />}>
						<Route index element={<SignOut {...passingProps} />} />
					</Route>

					<Route path="*" element={<Layout {...passingProps} />}>
						<Route path='*' element={<PageNotFound {...passingProps} />} />
					</Route>
				</Routes>
			</ReactErrorBoundary>
		</BrowserRouter>
	);
}, {
	components: authComponents(),
	socialProviders: [
		"google"
	],
	//theme: useTheme()
});

const callAPIGateway = async function (apiName, path, parameters = {}, callType) {
	try {
		if (apiName == null) {
			throw "Given apiName is null";
		}
		if (typeof(apiName) !== "string") {
			throw "Given apiName is not a string";
		}
		if (path == null) {
			throw "Given path is null";
		}
		if (typeof(path) != "string") {
			throw "Given path is not a string";
		}
		path = path.split(" ").join("");
		if (path[0] != "/") {
			path = "/" + path;
		}
		if (parameters == null) {
			parameters = {};
		}
		if (typeof(parameters) != "object") {
			throw "Given parameters is not an object";
		}

		if (parameters.body == null) {
			parameters.body = {...parameters};
		}

		const accessToken = (await Auth.fetchAuthSession()).tokens.accessToken.toString();
		parameters.body = {...parameters.body, accessJWT: accessToken};
		// for (const [key, value] of Object.entries(parameters.body)) {
		// 	if (value == null) {
		// 		delete parameters.body[key];
		// 	}
		// }

		console.log("Calling APIGateway:");

		let restOperation = null;
		if (callType == "get") {
			delete parameters.body;
			restOperation = get({
				apiName: apiName,
				path: path,
				options: parameters
			})
		}
		else if (callType == "put") {
			restOperation = put({
				apiName: apiName,
				path: path,
				options: parameters
			})
		}
		else if (callType == "post") {
			console.log("Post:");
			restOperation = post({
				apiName: apiName,
				path: path,
				options: parameters
			})
		}
		// else if (callType == "delete") {
		// 	restOperation = delete({
		// 		apiName: apiName,
		// 		path: path,
		// 		options: parameters
		// 	})
		// }
		else {
			throw "Invalid call type";
		}
		const { body } = await restOperation.response;
		const response = await body.json();
		return response;
	}
	catch (error) {
		console.log("Error calling APIGateway: ");
		console.log(error);
		throw error;
	}
};

export const signOut = function () {
	try {
		Auth.signOut();
	}
	catch (error) {
		console.log("Error signing out: " + error);
	}
};

// export const goBack = function (backStack) {
// 	const navigate = useNavigate();
// 	if (backStack.length > 1) {
// 		const previousPage = backStack[backStack.length - 2]
// 		console.log("Previous page: " + previousPage.pathname);
// 		navigate({})
// 		//setAppRedirect(<Navigate to={previousPage.pathname} state={previousPage.state}/>);
// 		const newBackStack = backStack.slice(0, backStack.length - 1);
// 		setBackStack(newBackStack);
// 	}
// }

const searchGroups = function(groups, searchGroupName) {
	//Simple function to check whether the current user is part of the tutor user group
	try {
		if (groups != null || groups == []) {
				for (let groupIndex = 0; groupIndex < groups.length; groupIndex = groupIndex + 1) {
					const currentGroup = groups[groupIndex];
					if (currentGroup === searchGroupName) {
						return true;
					}
				}
		}
		return false;
	} 
	catch (error) {
		console.log("Error searching user groups: " + error);
		return false;
	}
};

export const getNeededTutorAttributes = function(dataModel) {
	if (dataModel === "NOT_FOUND" || dataModel == null) {
		return ("NOT_FOUND");
	}

		//Convert data model to array of attributes
		const attributesArray = Object.entries(dataModel);
		
		//Create empty array of the attributes that are needed
		let neededAttributes = [];
		
		let needsFirstNames = true;
		let needsLastName = true;
		let needsPhoneNumber = true;
		//let needsSubjects = true;
		let needsAvailability = true;
		let needsNumSessionsWanted = true;
		let needsOtherJobs = true;
		let needsPreviousExperience = true;
		//Iterate through all attributes of the data model and return an array with the attribute names of all null attributes
		for (let attributeIndex = 0; attributeIndex < attributesArray.length; attributeIndex = attributeIndex + 1) {
			const currentAttribute = attributesArray[attributeIndex];
			const currentAttributeName = currentAttribute[0];
			const currentAttributeValue = currentAttribute[1];
			if (currentAttributeName == "availability") {
				const validation = validateField(currentAttributeValue, [{"type":"Required", "questionType":"weekavailability"}]);
				if (validation.hasError == false) {
					needsAvailability = false;
				}
			}
			//else if (currentAttributeName == "subjectsGCSE") {
			//   const validation = await validateField(currentAttributeValue, [{"type":"Required", "questionType":"subjects"}]);
			//   if (validation.hasError == false) {
			//     needsSubjects = false;
			//   }
			// }
			// else if (currentAttributeName == "subjectsALevel") {
			//   const validation = await validateField(currentAttributeValue, [{"type":"Required", "questionType":"subjects"}]);
			//   if (validation.hasError == false) {
			//     needsSubjects = false;
			//   }
			// }
			else if (currentAttributeName == "firstNames" && currentAttributeValue != null && currentAttributeValue != "") {
				needsFirstNames = false;
			}
			else if (currentAttributeName == "lastName" && currentAttributeValue != null && currentAttributeValue != "") {
				needsLastName = false;
			}
			else if (currentAttributeName == "phoneNumber" && currentAttributeValue != null && currentAttributeValue != "") {
				needsPhoneNumber = false;
			}
			else if (currentAttributeName == "numSessionsWanted" && currentAttributeValue != null && currentAttributeValue != "") {
				needsNumSessionsWanted = false;
			}
			else if (currentAttributeName == "otherJobs" && currentAttributeValue != null && currentAttributeValue != "") {
				needsOtherJobs = false;
			}
			else if (currentAttributeName == "previousExperience" && currentAttributeValue != null && currentAttributeValue != "") {
				needsPreviousExperience = false;
			}
		}

		if (needsFirstNames === true) {
			neededAttributes.push("firstNames");
		}
		if (needsLastName === true) {
			neededAttributes.push("lastName");
		}
		if (needsPhoneNumber === true) {
			neededAttributes.push("phoneNumber");
		}
		if (needsAvailability === true) {
			neededAttributes.push("availability");
		}
		if (needsNumSessionsWanted === true) {
			neededAttributes.push("numSessionsWanted");
		}
		if (needsOtherJobs === true) {
			neededAttributes.push("otherJobs");
		}
		if (needsPreviousExperience === true) {
			neededAttributes.push("previousExperience");
		}
		return neededAttributes;
};

const reload = function() {
	console.log("Manually reloading page");
	window.location.reload(false);
};

export const generateUUID = function() {
	//Generates a random UUID
	return uuidv4();
};

export const sleep = async function(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
};

export const calculateLessonCost = function (tutorLevel, startTime, endTime, lessonType, returnString = false, lessonDuration = null) {
	try {
		if (lessonDuration == null) {
			const startHour = parseInt(startTime.split(":")[0], 10);
			const startMinute = parseInt(startTime.split(":")[1], 10);
			const startTimeInt = (startHour * 60) + startMinute;
			const endHour = parseInt(endTime.split(":")[0], 10);
			const endMinute = parseInt(endTime.split(":")[1], 10);
			const endTimeInt = (endHour * 60) + endMinute;
			lessonDuration = endTimeInt - startTimeInt;
		}
		if (typeof(lessonDuration) == "string") {
			lessonDuration = getSessionLengthMins(lessonDuration);
		}

		let cost = 0;
		let costString = null;
		if (tutorLevel == "A-Level") {
			if (lessonDuration == 45) {
				cost = 15;
			}
			else if (lessonDuration == 60) {
				cost = 17.5;
			}
			else if (lessonDuration == 90) {
				cost = 26.25;
			}
			else if (lessonDuration == 120) {
				cost = 35;
			}
			else {
				cost = "error";
			}
		}
		else if (tutorLevel == "University") {
			if (lessonType == "In-Person") {
				if (lessonDuration == 45) {
					cost = 20;
				}
				else if (lessonDuration == 60) {
					cost = 25;
				}
				else if (lessonDuration == 90) {
					cost = 37.5;
				}
				else if (lessonDuration == 120) {
					cost = 50;
				}
				else {
					cost = "error";
				}
			}
			else if (lessonType == "Online") {
				if (lessonDuration == 45) {
					cost = 18.5;
				}
				else if (lessonDuration == 60) {
					cost = 22.5;
				}
				else if (lessonDuration == 90) {
					cost = 33.75;
				}
				else if (lessonDuration == 120) {
					cost = 45;
				}
				else {
					cost = "error";
				}
			}
			else if (lessonType == null) {
				throw "No lesson type given";
			}
			else {
				throw "Invalid lesson type given";
			}
		}
		else if (tutorLevel == null) {
			cost = "error";
			throw "No tutor level given";
		}
		else {
			cost = "error";
			throw "Invalid tutor level given";
		}

		if (returnString == true) {
			costString = priceValuetoString(cost);
			return costString;
		}
		else {
			return cost;
		}
	}
	catch (error) {
		console.log("Error calculating lesson cost");
		return "error";
	}
};

export const calculateLessonWage = function (tutorLevel, startTime, endTime, lessonType, returnString = false, lessonDuration = null) {
	try {
		if (lessonDuration == null) {
			const startHour = parseInt(startTime.split(":")[0], 10);
			const startMinute = parseInt(startTime.split(":")[1], 10);
			const startTimeInt = (startHour * 60) + startMinute;
			const endHour = parseInt(endTime.split(":")[0], 10);
			const endMinute = parseInt(endTime.split(":")[1], 10);
			const endTimeInt = (endHour * 60) + endMinute;
			lessonDuration = endTimeInt - startTimeInt;
		}
		
		let wage = 0;
		let wageString = null;
		if (tutorLevel == "A-Level" || tutorLevel == "Currently an A-Level student" || tutorLevel == "Currently in a gap year after A-Levels") {
			if (lessonDuration == 45) {
				wage = 9.38;
			}
			else if (lessonDuration == 60) {
				wage = 12.5;
			}
			else if (lessonDuration == 90) {
				wage = 18.75;
			}
			else if (lessonDuration == 120) {
				wage = 25;
			}
			else {
				wage = "error";
			}
		}
		else if (tutorLevel == "University" || tutorLevel == "Currently a university student") {
			if (lessonType == "In-Person") {
				if (lessonDuration == 45) {
					wage = 15;
				}
				else if (lessonDuration == 60) {
					wage = 20;
				}
				else if (lessonDuration == 90) {
					wage = 30;
				}
				else if (lessonDuration == 120) {
					wage = 40;
				}
				else {
					wage = "error";
				}
			}
			else if (lessonType == "Online") {
				if (lessonDuration == 45) {
					wage = 13.13;
				}
				else if (lessonDuration == 60) {
					wage = 17.5;
				}
				else if (lessonDuration == 90) {
					wage = 26.25;
				}
				else if (lessonDuration == 120) {
					wage = 35;
				}
				else {
					wage = "error";
				}
			}
			else if (lessonType == null) {
				throw "No lesson type given";
			}
			else {
				throw "Invalid lesson type given";
			}
		}
		else if (tutorLevel == null) {
			wage = "error";
			throw "No tutor level given";
		}
		else {
			wage = "error";
			throw "Invalid tutor level given";
		}

		if (returnString == true) {
			wageString = priceValuetoString(wage);
			return wageString;
		}
		else {
			return wage;
		}
	}
	catch (error) {
		console.log("Error calculating lesson wage");
		console.log(error);
		return "error";
	}
};

export const priceValuetoString = function (priceValue) {
	try {
		try {
			priceValue = parseFloat(priceValue);
		} catch {}
		if (typeof(priceValue) != "number") {
			throw "Invalid price value";
		}

		let priceString = null;

		const pricePound = priceValue.toString().split(".")[0];
		let pricePenny = priceValue.toString().split(".")[1];

		if (pricePenny == null) {
			pricePenny = "00";
		}
		else if (pricePenny.length == 0) {
			pricePenny = "00";
		}
		else if (pricePenny.length == 1) {
			pricePenny = pricePenny + "0";
		}
		else if (pricePenny.length > 2) {
			pricePenny = pricePenny.substring(0, 2);
		}
		
		if (priceValue < 0) {
			priceString = "-£" + Math.abs(pricePound) + "." + pricePenny;
		}
		else {
			priceString = "£" + pricePound + "." + pricePenny;
		}

		return priceString;
	}
	catch (error) {
		console.log("Error converting price value to string")
		throw error;
	}
};

export const getStripePrice = function (priceID) {
	try {
		if (typeof(priceID) != "string") {
			throw "Invalid type for priceID, given priceID type: " + typeof(priceID);
		}

		let price = null;

		if (priceID == "price_1N5wGrDLqgKSpJ1sID06zJGD" || priceID == "price_1NiHfgDLqgKSpJ1s2ZDkMEJi") {
			price = 5;
		}
		else if (priceID == "price_1N5wH2DLqgKSpJ1srA0NsAPE" || priceID == "price_1NiHfgDLqgKSpJ1sShD60pTi") {
			price = 7.5;
		}
		else if (priceID == "price_1N5wH9DLqgKSpJ1sutLyrchY" || priceID == "price_1NiHfgDLqgKSpJ1sNdCcOrg6") {
			price = 10;
		}
		else if (priceID == "price_1N5wHrDLqgKSpJ1sNGPumHzM" || priceID == "price_1NiHfgDLqgKSpJ1sOMRvFPzN") {
			price = 15;
		}
		else if (priceID == "price_1N5wHzDLqgKSpJ1skrdsUyfy" || priceID == "price_1NiHfgDLqgKSpJ1scS9oiJxd") {
			price = 20;
		}
		else if (priceID == "price_1N5wI4DLqgKSpJ1sM69gVqKy" || priceID == "price_1NiHfgDLqgKSpJ1sqIxrGQZR") {
			price = 30;
		}
		else if (priceID == "price_1N5wRpDLqgKSpJ1sKDNnktIM" || priceID == "price_1NiHfgDLqgKSpJ1sT6pgxahR") {
			price = 40;
		}
		else if (priceID == "price_1N5wIBDLqgKSpJ1sWvSXCBtl" || priceID == "price_1NiHfgDLqgKSpJ1sCtDVWzXu") {
			price = 50;
		}
		else if (priceID == "price_1N5wILDLqgKSpJ1suRkm4p24" || priceID == "price_1NiHfgDLqgKSpJ1spT64lEzU") {
			price = 75;
		}
		else if (priceID == "price_1N5wIQDLqgKSpJ1sUFCY6SOO" || priceID == "price_1NiHfgDLqgKSpJ1smbHeH8RJ") {
			price = 100;
		}
		else if (priceID == "price_1N5wIkDLqgKSpJ1s8ZjoKmwy" || priceID == "price_1NiHfgDLqgKSpJ1saI3SCyP2") {
			price = 150;
		}
		else if (priceID == "price_1N5wJLDLqgKSpJ1s9JjWbNnB" || priceID == "price_1NiHfgDLqgKSpJ1sNmWupfqE") {
			price = 200;
		}

		if (price == null) {
			throw "Invalid priceID";
		}
		return price;
	}
	catch (error) {
		throw "Error getting stripe price from priceID '" + priceID + "', given error: " + error;
	}
};

export const getStripePriceID = function (price, environment = "dev") {
	try {
		if (typeof(price) != "number") {
			throw "Invalid type for price, given price type: " + typeof(price);
		}

		const priceIDs = {
			0.01: {
				dev: "price_1P4UW2DLqgKSpJ1sGMWMqad8",
				testing: "price_1P4UZhDLqgKSpJ1sz2qsjWBx"
			},
			0.02: {
				dev: "price_1P4UXDDLqgKSpJ1sciRCMM2L",
				testing: "price_1P4UZoDLqgKSpJ1sVAE4zRmM"
			},
			0.05: {
				dev: "price_1P4UXQDLqgKSpJ1sp9xcrMcF",
				testing: "price_1P4UZtDLqgKSpJ1sjq63b6dv"
			},
			0.1: {
				dev: "price_1P4UXfDLqgKSpJ1s7AXU7SwG",
				testing: "price_1P4UaCDLqgKSpJ1sFXj16rXQ"
			},
			0.2: {
				dev: "price_1P4UYEDLqgKSpJ1seTKNNbfx",
				testing: "price_1P4UaGDLqgKSpJ1sibx6aqid"
			},
			0.5: {
				dev: "price_1P4UYPDLqgKSpJ1smmGkwXIW",
				testing: "price_1P4UaUDLqgKSpJ1sjbspGg7V"
			},
			1: {
				dev: "price_1P4UYjDLqgKSpJ1sEixbqOYE",
				testing: "price_1P4UaZDLqgKSpJ1sn12rcnUM"
			},
			2: {
				dev: "price_1P4UYtDLqgKSpJ1sYhBLmqgZ",
				testing: "price_1P4UalDLqgKSpJ1sEDkHvHgn"
			},
			5: {
				dev: "price_1NiHfgDLqgKSpJ1s2ZDkMEJi",
				testing: "price_1N5wGrDLqgKSpJ1sID06zJGD"
			},
			10: {
				dev: "price_1NiHfgDLqgKSpJ1sNdCcOrg6",
				testing: "price_1N5wH9DLqgKSpJ1sutLyrchY"
			},
			20: {
				dev: "price_1NiHfgDLqgKSpJ1scS9oiJxd",
				testing: "price_1N5wHzDLqgKSpJ1skrdsUyfy"
			},
			50: {
				dev: "price_1NiHfgDLqgKSpJ1sCtDVWzXu",
				testing: "price_1N5wIBDLqgKSpJ1sWvSXCBtl"
			},
			100: {
				dev: "price_1NiHfgDLqgKSpJ1smbHeH8RJ",
				testing: "price_1N5wIQDLqgKSpJ1sUFCY6SOO"
			},
			200: {
				dev: "price_1NiHfgDLqgKSpJ1sNmWupfqE",
				testing: "price_1N5wJLDLqgKSpJ1s9JjWbNnB"
			},
			500: {
				dev: "price_1P4UZ5DLqgKSpJ1sO9AFVw7t",
				testing: "price_1P4UasDLqgKSpJ1sE95XhcQT"
			},
			1000: {
				dev: "price_1P4UZJDLqgKSpJ1sBAkO046I",
				testing: "price_1P4UayDLqgKSpJ1sbwANVRh2"
			}
		}

		if (priceIDs[price] == null) {
			throw "Invalid price";
		}
		const envPrices = priceIDs[price];
		if (envPrices[environment] == null) {
			throw "Invalid environment";
		}
		const priceID = envPrices[environment];
		return priceID;
	}
	catch (error) {
		throw "Error getting stripe priceID from price '" + price + "', given error: " + error;
	}
};

export const getSessionLengthMins = function (sessionLengthString) {
	try {
		sessionLengthString = sessionLengthString.split("s").join("");
		sessionLengthString = sessionLengthString.split(" ").join("");
		sessionLengthString = sessionLengthString.split("-").join("");
		let splitString = sessionLengthString.split("Hour").join(":");
		splitString = splitString.split("Minute").join("");
		splitString = splitString.split(":");

		let numHours = null;
		let numMinutes = null;
		if (splitString.length == 2) {
			const hours = splitString[0];
			if (hours == null || hours == "") {
				numHours = 0;
			}
			else {
				numHours = parseInt(hours, 10);
			}
			const minutes = splitString[1];
			if (minutes == null || minutes == "") {
				numMinutes = 0;
			}
			else {
				numMinutes = parseInt(minutes, 10);
			}
		}
		else if (splitString.length == 1) {
			const minutes = splitString[0];
			if (minutes == null || minutes == "") {
				numMinutes = 0;
			}
			else {
				numMinutes = parseInt(minutes, 10);
			}
		}
		else {
			throw "Invalid string format";
		}

		const totalMinutes = (numHours * 60) + numMinutes;
		return totalMinutes;
	}
	catch (error) {
		throw "Error getting session length in minutes: " + error;
	}
};

export const getSessionLengthString = function (sessionLengthMins) {
	try {
		if (typeof(sessionLengthMins) != "number") {
			throw "Invalid session length mins type";
		}
		if (sessionLengthMins < 0) {
			throw "Invalid session length";
		}

		let sessionLengthString = "";

		const numHours = Math.floor(sessionLengthMins / 60);
		const numMinutes =  sessionLengthMins % 60;

		if (numHours > 0) {
			sessionLengthString = numHours + " Hour";
			if (numHours > 1) {
				sessionLengthString = sessionLengthString + "s";
			}
		}
		if (numHours > 0 && numMinutes > 0) {
			sessionLengthString = sessionLengthString + " ";
		}
		if (numMinutes > 0) {
			sessionLengthString = numHours + " Minute";
			if (numMinutes > 1) {
				sessionLengthString = sessionLengthString + "s";
			}
		}
		return sessionLengthString;
	}
	catch (error) {
		throw "Error getting session length string: " + error;
	}
};

export const getSessionLength = function (startTime, endTime, returnString = true) {
	try {
		if (typeof(startTime) != "string") {
			throw "Invalid start time type";
		}
		if (typeof(endTime) != "string") {
			throw "Invalid end time type";
		}

		const startTimeHour = parseInt(startTime.split(":")[0], 10);
		const startTimeMinute = parseInt(startTime.split(":")[1], 10);
		const startTimeInt = (startTimeHour * 60) + startTimeMinute;
		const endTimeHour = parseInt(endTime.split(":")[0], 10);
		const endTimeMinute = parseInt(endTime.split(":")[1], 10);
		const endTimeInt = (endTimeHour * 60) + endTimeMinute;

		if (endTimeInt < startTimeInt) {
			throw "End time before start time";
		}
		const sessionLengthMins = endTimeInt - startTimeInt;
		if (returnString) {
			const sessionLength = getSessionLengthString(sessionLengthMins);
			return sessionLength;
		}
		else {
			return sessionLengthMins;
		}

	}
	catch (error) {
		throw "Error getting session length: " + error;
	}
};

export const getSessionEndTime = function (startTime, sessionLength) {
	//Takes the start time as a string ("10:30") and the session length in minutes and returns the end time string
	try {
		if (startTime == null) {
			throw "No start time given";
		}
		if (sessionLength == null) {
			throw "No session length given";
		}

		if (typeof(sessionLength) == "string") {
			try {
				sessionLength = getSessionLengthMins(sessionLength);
			}
			catch (error) {
				throw "Invalid session length string: " + error;
			}
		}
		if (typeof(sessionLength) != "number") {
			throw "Invalid session length type";
		}

		const startTimeHours = parseInt(startTime.split(":")[0], 10);
		const startTimeMinutes = parseInt(startTime.split(":")[1], 10);
		const startTimeInt = (startTimeHours * 60) + startTimeMinutes;

		const minutesInDay = 1440;
		const endTimeInt = (startTimeInt + sessionLength) % minutesInDay;
		const endTimeHour = Math.floor(endTimeInt / 60);
		const endTimeMinute = endTimeInt % 60;

		let endTimeHourString = "" + endTimeHour;
		if (endTimeHourString.length == 1) {
			endTimeHourString = "0" + endTimeHourString;
		}
		let endTimeMinuteString = "" + endTimeMinute;
		if (endTimeMinuteString.length == 1) {
			endTimeMinuteString = "0" + endTimeMinuteString;
		}

		const endTimeString = endTimeHourString + ":" + endTimeMinuteString;
		
		return endTimeString;
	}
	catch (error) {
		throw "Error getting session end time: " + error;
	}
};

export const timeIntToString = function (timeInt) {
	try {
		if (timeInt == null) {
			throw "No time int given";
		}
		if (typeof(timeInt) == "string") {
			timeInt = parseInt(timeInt, 10);
		}
		if (typeof(timeInt) != "number") {
			throw "Invalid time int type";
		}

		const hours = Math.floor(timeInt / 60);
		const minutes = timeInt % 60;
		let hoursString = "" + hours;
		if (hoursString.length == 1) {
			hoursString = "0" + hoursString;
		}
		let minutesString = "" + minutes;
		if (minutesString.length == 1) {
			minutesString = "0" + minutesString;
		}
		const timeString = hoursString + ":" + minutesString;
		return timeString;
	}
	catch (error) {
		throw "Error converting time int to string: " + error;
	}
}

export const getDatesForLesson = function (lessonProps, getAllDates = false, numDates = 1000, userType) {
	const getDayIndex = function (day) {
		try {
			if (day == "Monday") {
				return 1;
			}
			if (day == "Tuesday") {
				return 2;
			}
			if (day == "Wednesday") {
				return 3;
			}
			if (day == "Thursday") {
				return 4;
			}
			if (day == "Friday") {
				return 5;
			}
			if (day == "Saturday") {
				return 6;
			}
			if (day == "Sunday") {
				return 0;
			}
			throw "Invalid day"
		}
		catch (error) {
			throw "Error getting day index: " + error;
		}
	};
	try {
		//Check lesson amendments
		const lessonAmendments = lessonProps.amendments || [];
		const cancelledLessons = {};
		const extraLessons = {};
		const otherLessonAmendments = {};
		for (const amendment of lessonAmendments) {
			const amendmentType = amendment.type;
			const amendmentConfirmed = amendment.confirmed;
			if (amendmentConfirmed) {
				if (amendmentType == "addOneOff") {
					const extraDate = amendment.date;
					const startTime = amendment.startTime;
					const sessionLength = amendment.sessionLength;
					let endTime = amendment.endTime;
					if (endTime == null) {
						endTime = getSessionEndTime(startTime, sessionLength);
						amendment.endTime = endTime;
					}
					if (amendment.lessonType == null) {
						amendment.lessonType = lessonProps.lessonType;
					}
					extraLessons[extraDate] = extraLessons[extraDate] || []
					extraLessons[extraDate].push(amendment);
				}
				else if (amendmentType == "cancelSingle") {
					const amendmentDate = amendment.date;
					cancelledLessons[amendmentDate] = cancelledLessons[amendmentDate] || [];
					cancelledLessons[amendmentDate].push(amendment);
				}
				else {
					const amendmentDate = amendment.date;
					if (amendmentDate != null) {
						const newLessonAmendments = otherLessonAmendments[amendmentDate] || [];
						newLessonAmendments.push(amendment);
						otherLessonAmendments[amendmentDate] = newLessonAmendments;
					}
				}
			}
		}

		let startDate = new Date(lessonProps.startDate);
		if (startDate < new Date() && userType != "admin") {
			startDate = new Date();
		}
		let endDate = new Date(startDate);

		const currentMonth = endDate.getMonth();
		endDate.setMonth(currentMonth + 12);
		if (lessonProps.endDate != null && !getAllDates) {
			try {
				endDate = new Date(lessonProps.endDate)
			} catch {}
		}

		const lessonDay = lessonProps.lessonDay;
		const dayIndex = getDayIndex(lessonDay);

		let foundDates = [];
		let dateIteration = new Date(startDate);
		while (dateIteration <= endDate && dateIteration >= startDate && foundDates.length < numDates) {
			const newDateIteration = new Date(dateIteration);
			const dateIterationDay = newDateIteration.getDay();
			const newDateString = newDateIteration.toDateString();
			const otherAmendments = otherLessonAmendments[newDateString];
			let lessonType = lessonProps.lessonType;
			let startTime = lessonProps.startTime;
			let endTime = lessonProps.endTime;
			if (otherAmendments != null) {
				for (const amendment of otherAmendments) {
					if (amendment.type == "changeOnline") {
						lessonType = "Online";
					}
					else if (amendment.type == "changeInPerson") {
						lessonType = "In-Person";
					}
					else if (amendment.type == "changeTime") {
						startTime = amendment.startTime;
						endTime = amendment.endTime;
					}
				}
			}
			const sessionLength = getSessionLength(startTime, endTime);
			if (extraLessons[newDateString] != null && extraLessons[newDateString].length > 0) {
				for (const extraLessonProps of extraLessons[newDateString]) {
					let addExtraLesson = true;
					if (cancelledLessons[newDateString] != null && cancelledLessons[newDateString].length > 0) {
						for (const cancelAmendment of cancelledLessons[newDateString]) {
							if (cancelAmendment.confirmed == true && cancelAmendment.cancelType == "extra" && cancelAmendment.startTime == extraLessonProps.startTime && cancelAmendment.endTime == extraLessonProps.endTime) {
								addExtraLesson = false;
								break;
							}
						}
					}
					
					if (addExtraLesson) {
						foundDates.push({
							type: "extra",
							date: newDateString,
							startTime: extraLessonProps.startTime,
							endTime: extraLessonProps.endTime,
							sessionLength: extraLessonProps.sessionLength,
							lessonType: extraLessonProps.lessonType
						});
					}
				}
			}
			else if (dayIndex == dateIterationDay) {
				let addLesson = true;
				if (cancelledLessons[newDateString] != null && cancelledLessons[newDateString].length > 0) {
					for (const cancelAmendment of cancelledLessons[newDateString]) {
						if (cancelAmendment.confirmed == true && cancelAmendment.cancelType == "scheduled") {
							addLesson = false;
							break;
						}
					}
				}
				if (addLesson) {
					foundDates.push({
						type: "scheduled",
						date: newDateString,
						startTime: startTime,
						endTime: endTime,
						lessonType: lessonType,
						sessionLength: sessionLength
					});
				}
			}
			dateIteration.setDate(dateIteration.getDate() + 1);
		}
		foundDates = foundDates.sort((a, b) => {
			const aDate = new Date(a.date);
			const aStartTime = a.startTime;
			const aStartHour = parseInt(aStartTime.split(":")[0], 10);
			const aStartMinute = parseInt(aStartTime.split(":")[0], 10);
			aDate.setHours(aStartHour);
			aDate.setMinutes(aStartMinute);

			const bDate = new Date(b.date);
			const bStartTime = b.startTime;
			const bStartHour = parseInt(bStartTime.split(":")[0], 10);
			const bStartMinute = parseInt(bStartTime.split(":")[0], 10);
			bDate.setHours(bStartHour);
			bDate.setMinutes(bStartMinute);

			return aDate.valueOf() - bDate.valueOf();
		});

		return foundDates;
	}
 	catch (error) {
		throw "Error getting dates for lesson: " + error;        
	}
};

export const isValidPrice = function (testNumber, allowNegative = true) {
	let value = null;
	if (testNumber == null) {
		return "No value given";
	}
	if (typeof(testNumber) != "number" && typeof(testNumber) != "string") {
		return "Invalid type";
	}
	if (typeof(testNumber) == "number" && testNumber != null) {
		value = testNumber;
	}
	else if (typeof(testNumber) == "string") {
		if (testNumber == "") {
			return "Empty string";
		}
		let decimalIndex = null;
		for (let digitIndex = 0; digitIndex < testNumber.length; digitIndex = digitIndex + 1) {
			const ASCIIValue = testNumber.charCodeAt(digitIndex);
			if (ASCIIValue < 48 || ASCIIValue > 57) {
				if (ASCIIValue == 46) {
					if (decimalIndex != null) {
						return "Multiple decimals found";
					}
					if (testNumber.length > digitIndex + 3) {
						return "More than 2 digits after decimal";
					}
					decimalIndex = digitIndex;
				}
				else {
					return "Invalid character: " + testNumber[digitIndex];
				}
			}
			value = parseFloat(testNumber);
		}
	}
	if (!allowNegative && value < 0) {
		return "Negative value";
	}
	return true;
}

export const getMatchingWeekAvailabilityFromList = function (availabilityList) {
	try {
		let matchingAvailability = {
			Monday: [{startTime: "00:00", endTime: "23:59"}],
			Tuesday: [{startTime: "00:00", endTime: "23:59"}],
			Wednesday: [{startTime: "00:00", endTime: "23:59"}],
			Thursday: [{startTime: "00:00", endTime: "23:59"}],
			Friday: [{startTime: "00:00", endTime: "23:59"}],
			Saturday: [{startTime: "00:00", endTime: "23:59"}],
			Sunday: [{startTime: "00:00", endTime: "23:59"}],
		};
		let allNull = true;
		for (let index = 0; index < availabilityList.length; index = index + 1) {
			const weekAvailability = availabilityList[index];
			if (weekAvailability != null) {
				allNull = false;
				matchingAvailability = getMatchingWeekAvailability(weekAvailability, matchingAvailability);
			}
		}

		if (allNull) {
			return null;
		}
		
		return matchingAvailability;
	}
	catch (error) {
		throw "Error getting matching availability from list: " + error;
	}
}

export const getMatchingWeekAvailability = function (availability1, availability2) {
	try {
		const matchingAvailability = {
			Monday: [],
			Tuesday: [],
			Wednesday: [],
			Thursday: [],
			Friday: [],
			Saturday: [],
			Sunday: []
		};

		const days = Object.keys(matchingAvailability);
		for (const day of days) {
			const availability1DayAvailability = availability1[day];
			const availability2DayAvailability = availability2[day];

			const matchingDayAvailability = getMatchingAvailabilityWindows(availability1DayAvailability, availability2DayAvailability);

			matchingAvailability[day] = matchingDayAvailability;
		}

		return matchingAvailability;
	} 
	catch (error) {
		throw "Error getting matching week availability: " + error;
	} 
};

export const compareWeekAvailability = function (requestedAvailability, requestedNumSessions, requestedSessionLength, tutorAvailability) {
	try {
		let lessonLengthMins = null;
		if (requestedSessionLength == "30 Minutes") {
			lessonLengthMins = 30;
		}
		else if (requestedSessionLength == "45 Minutes") {
			lessonLengthMins = 45;
		}
		else if (requestedSessionLength == "1 Hour") {
			lessonLengthMins = 60;
		}
		else if (requestedSessionLength == "1 Hour 30 Minutes") {
			lessonLengthMins = 90;
		}
		else if (requestedSessionLength == "2 Hours") {
			lessonLengthMins = 120;
		}
		
		const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
		const matchingWeekAvailability = {};
		
		
		let daysWithMatches = 0;
		
		for (const day of days) {
			matchingWeekAvailability[day] = [];
			const requestedDayAvailability = requestedAvailability[day];
			if (requestedDayAvailability == undefined) {
				continue;
			}
			
			const tutorDayAvailability = tutorAvailability[day];
			if (tutorDayAvailability == undefined) {
				continue;
			}

			const matchingDayAvailability = compareDayAvailability(requestedDayAvailability, lessonLengthMins, tutorDayAvailability);
			matchingWeekAvailability[day] = matchingDayAvailability;
			
			if (matchingDayAvailability.length > 0) {
				daysWithMatches = daysWithMatches + 1;
			}
		}
		
		const returnObject = {};
		returnObject.matchingAvailability = matchingWeekAvailability;
		
		if (daysWithMatches >= requestedNumSessions) {
			returnObject.valid = true;
			return returnObject;
		}
		else {
			returnObject.valid = false;
			return returnObject;
		}
	}
	catch (error) {
		throw "Error comparing weekly availability: " + error;
	}
};

export const compareDayAvailability = function (requestedDayAvailability, lessonLengthMins, tutorDayAvailability) {
	try {
		if (typeof(lessonLengthMins) != "number" || lessonLengthMins < 30) {
			throw "Invalid lesson length";
		}
		
		const matchingDayAvailability = [];

		const matchingAvailabilityWindows = getMatchingAvailabilityWindows(requestedDayAvailability, tutorDayAvailability);
		for (const matchingWindow of matchingAvailabilityWindows) {
			const windowStartTimeInt = matchingWindow.startTimeInt;
			const windowEndTimeInt = matchingWindow.endTimeInt;
			
			const windowLength = windowEndTimeInt - windowStartTimeInt;
			
			if (windowLength < lessonLengthMins) {
				//Window is not a sufficient length for lesson
				continue;
			}
			
			matchingDayAvailability.push(matchingWindow);
		}
		
		return matchingDayAvailability;
	}
	catch (error) {
		throw "Error comparing day availability: " + error;
	}
};

export const getDayName = function (dayNum) {
	try {
		let currentDay = null;
		if (dayNum == 0) {
			currentDay = "Sunday";
		}
		else if (dayNum == 1) {
			currentDay = "Monday";
		}
		else if (dayNum == 2) {
			currentDay = "Tuesday";
		}
		else if (dayNum == 3) {
			currentDay = "Wednesday";
		}
		else if (dayNum == 4) {
			currentDay = "Thursday";
		}
		else if (dayNum == 5) {
			currentDay = "Friday";
		}
		else if (dayNum == 6) {
			currentDay = "Saturday";
		}
		else {
			throw "Invalid day num";
		}
		
		return currentDay;
	}
	catch (error) {
		throw "Error getting day name: " + error;
	}
};

export const shortDateString = function (date) {
	try {
		try {
			date = new Date(date);
		}
		catch (error) {
			throw "Invalid date";
		}
		let day = date.getDate();
		if (day < 10) {
			day = "0" + day;
		}
		let month = date.getMonth() + 1;
		if (month < 10) {
			month = "0" + month;
		}
		let year = date.getFullYear();

		const shortDate = day + "/" + month + "/" + year;
		return shortDate;
	}
	catch (error) {
		throw "Error getting short date string: " + error;
	}
};

export const getMatchingAvailabilityWindows = function (dayAvailability1, dayAvailability2) {
	try {
		const matchingAvailability = [];
		
		for (let availabilityWindow1 of dayAvailability1) {
			availabilityWindow1 = getAvailabilityWindowInts(availabilityWindow1);
			const window1StartTime = availabilityWindow1.startTimeInt;
			const window1EndTime = availabilityWindow1.endTimeInt;
			
			for (let availabilityWindow2 of dayAvailability2) {
				availabilityWindow2 = getAvailabilityWindowInts(availabilityWindow2);
				const window2StartTime = availabilityWindow2.startTimeInt;
				const window2EndTime = availabilityWindow2.endTimeInt;
				
				if (window1StartTime > window2EndTime || window1EndTime < window2StartTime) {
					continue;
				}
				if (window1StartTime < window2StartTime && window1EndTime < window2StartTime) {
					continue;
				}
				if (window2StartTime < window1StartTime && window2EndTime < window1StartTime) {
					continue;
				}

				//At this point, we know availability windows 1 and 2 align for some amount of time
				const matchingWindow = {};
				if (window1StartTime > window2StartTime) {
					matchingWindow.startTime = availabilityWindow1.startTime;
					matchingWindow.startTimeInt = window1StartTime;
				}
				else {
					matchingWindow.startTime = availabilityWindow2.startTime;
					matchingWindow.startTimeInt = window2StartTime;
				}
				
				if (window1EndTime < window2EndTime) {
					matchingWindow.endTime = availabilityWindow1.endTime;
					matchingWindow.endTimeInt = window1EndTime;
				}
				else {
					matchingWindow.endTime = availabilityWindow2.endTime;
					matchingWindow.endTimeInt = window2EndTime;
				}
				
				matchingAvailability.push(matchingWindow);
			}
		}
		return matchingAvailability;
	}
	catch (error) {
		throw "Error getting matching availability windows: " + error;
	}
};

export const getAvailabilityWindowInts = function (availabilityWindow) {
	const windowStartTime = availabilityWindow.startTime;
	const windowEndTime = availabilityWindow.endTime;
	
	const startHour = parseInt(windowStartTime.split(":")[0], 10);
	const startMinute = parseInt(windowStartTime.split(":")[1], 10);
	const startTimeInt = (startHour * 60) + startMinute;
	const endHour = parseInt(windowEndTime.split(":")[0], 10);
	const endMinute = parseInt(windowEndTime.split(":")[1], 10);
	const endTimeInt = (endHour * 60) + endMinute;
	
	if (startHour < 0 || startHour > 23 || startMinute < 0 || startMinute > 59) {
		throw "Invalid start time";
	}
	if (endHour < 0 || endHour > 23 || endMinute < 0 || endMinute > 59) {
		throw "Invalid end time";
	}
	
	const returnWindow = {
		startTime: windowStartTime,
		endTime: windowEndTime,
		startTimeInt: startTimeInt,
		endTimeInt: endTimeInt
	};
	
	return returnWindow;
};

export const getStudentNames = function (studentIDs, studentModels) {
	try {
		//Sanitise inputs
		if (studentIDs == null) {
			throw "No student IDs given";
		}
		if (typeof(studentIDs) != "object") {
			throw "studentIDs is not an object";
		}
		if (studentModels == null) {
			throw "No student models given";
		}
		if (typeof(studentModels) != "object") {
			throw "studentModels is not an object";
		}

		let studentNames = "";
		for (let studentIndex = 0; studentIndex < studentIDs.length; studentIndex = studentIndex + 1) {
			const studentID = studentIDs[studentIndex];
			const studentModel = studentModels[studentID];
			if (studentModel == null) {
				throw "No student model found";
			}
			studentNames = studentNames + studentModel.firstNames + " " + studentModel.lastName;
			if (studentIndex < studentIDs.length - 2) {
				studentNames = studentNames + ", ";
			}
			else if (studentIndex < studentIDs.length - 1) {
				studentNames = studentNames + " and ";
			}
		}

		return studentNames;
	}
	catch (error) {
		throw "Error getting student names: " + error;
	}
};

export const getStudentYearGroups = function (studentIDs, studentModels) {
	try {
		//Sanitise inputs
		if (studentIDs == null) {
			throw "No student IDs given";
		}
		if (typeof(studentIDs) != "object") {
			throw "studentIDs is not an object";
		}
		if (studentModels == null) {
			throw "No student models given";
		}
		if (typeof(studentModels) != "object") {
			throw "studentModels is not an object";
		}

		let studentYearGroups = [];
		for (const studentID of studentIDs) {
			const studentModel = studentModels[studentID];
			if (studentModel == null) {
				throw "No student model found";
			}
			const studentYearGroup = studentModel.yearGroup;
			studentYearGroups.push(studentYearGroup); 
		}

		studentYearGroups = studentYearGroups.sort((a, b) => {
			if (a == "Reception" && b == "Reception") {
				return 0;
			}
			else if (a == "Reception") {
				return -1;
			}
			else if (b == "Reception") {
				return 1;
			}

			const aYearGroup = parseInt(a.split("Year ")[1], 10);
			const bYearGroup = parseInt(b.split("Year ")[1], 10);
			return aYearGroup - bYearGroup;
		});

		let yearGroupString = "";
		for (let studentIndex = 0; studentIndex < studentYearGroups.length; studentIndex = studentIndex + 1) {
			const studentYearGroup = studentYearGroups[studentIndex];
			yearGroupString = yearGroupString + studentYearGroup;
			if (studentIndex < studentYearGroups.length - 2) {
				yearGroupString = yearGroupString + ", ";
			}
			else if (studentIndex < studentYearGroups.length - 1) {
				yearGroupString = yearGroupString + " and ";
			}
		}

		return yearGroupString;
	}
	catch (error) {
		throw "Error getting student names: " + error;
	}
};

export const getTutorName = function (tutorID, tutorModels) {
	try {
		//Sanitise inputs
		if (tutorID == null) {
			throw "No tutor ID given";
		}
		if (typeof(tutorID) != "string") {
			throw "tutorID is not a string";
		}
		if (tutorModels == null) {
			throw "No tutor models given";
		}
		if (typeof(tutorModels) != "object") {
			throw "tutorModels is not an object";
		}

		const tutorModel = tutorModels[tutorID];
		if (tutorModel == null) {
			throw "No tutor model found";
		}
		const tutorFirstNames = tutorModel.firstNames;
		const tutorLastName = tutorModel.lastName;
		if (tutorFirstNames == null || tutorLastName == null) {
			throw "Tutor model missing names";
		}
		const tutorFullName = tutorFirstNames + " " + tutorLastName;

		return tutorFullName;
	}
	catch (error) {
		throw "Error getting tutor name: " + error;
	}
};

export const modelsStringBuilder = function (models, template, modelSeparator = ", ", lastModelSeparator = " and ", extraLogging = false) {
	try {
		//Sanitise inputs
		if (models == null) {
			throw "No models given";
		}
		if (!Array.isArray(models)) {
			throw "Models is not an array";
		}
		if (models.length == 0) {
			throw "No models given";
		}
		if (template == null) {
			throw "No template given";
		}
		if (!Array.isArray(template)) {
			throw "Template is not an array";
		}
		if (template.length == 0) {
			throw "No template given";
		}
		if (modelSeparator == null) {
			throw "No model separator given";
		}
		if (typeof(modelSeparator) != "string") {
			throw "Model separator is not a string";
		}
		if (lastModelSeparator == null) {
			throw "No last model separator given";
		}
		if (typeof(lastModelSeparator) != "string") {
			throw "Last model separator is not a string";
		}
		extraLogging && console.log("Building string from models and template");
		extraLogging && console.log("Models: " + JSON.stringify(models));
		extraLogging && console.log("Template: " + JSON.stringify(template));
		extraLogging && console.log("Model separator: '" + modelSeparator + "'");
		extraLogging && console.log("Last model separator: '" + lastModelSeparator + "'");
			
		let returnString = "";
		for (let modelIndex = 0; modelIndex < models.length; modelIndex = modelIndex + 1) {
			extraLogging && console.log("Model index: " + modelIndex);
			const model = models[modelIndex];
			extraLogging && console.log("Model: " + JSON.stringify(model));
			if (model == null) {
				throw "Model is null";
			}
			if (typeof(model) != "object") {
				throw "Model is not an object";
			}
			let modelString = "";
			for (const templateSection of template) {
				extraLogging && console.log("Template section: " + JSON.stringify(templateSection));
				if (templateSection == null) {
					throw "Template section is null";
				}
				if (typeof(templateSection) != "object") {
					throw "Template section is not an object";
				}
				const sectionConditions = templateSection.conditions;
				if (sectionConditions != null) {
					extraLogging && console.log("Conditions for template section: " + JSON.stringify(sectionConditions));
					let conditionsMet = true;
					if (!Array.isArray(sectionConditions)) {
						throw "Section conditions is not an array";
					}
					for (const condition of sectionConditions) {
						extraLogging && console.log("Testing condition: " + JSON.stringify(condition));
						if (condition == null) {
							throw "Condition is null";
						}
						if (typeof(condition) != "object") {
							throw "Condition is not an object";
						}
						const conditionParameter = condition.parameter;
						extraLogging && console.log("Condition parameter: " + JSON.stringify(conditionParameter));
						if (conditionParameter == null) {
							throw "No condition parameter given";
						}
						if (typeof(conditionParameter) != "string") {
							throw "Condition parameter is not a string";
						}
						const conditionType = condition.type;
						extraLogging && console.log("Condition type: " + JSON.stringify(conditionType));
						if (conditionType == null) {
							throw "No condition type given";
						}
						if (typeof(conditionType) != "string") {
								throw "Condition type is not a string";
						}
						if (conditionParameter == "key") {
							extraLogging && console.log("Condition parameter is 'key': " + JSON.stringify(conditionType));
							const conditionKey = condition.key;
							extraLogging && console.log("Condition key: '" + conditionKey + "'");
							if (conditionKey == null) {
								throw "No condition key given";
							}
							if (typeof(conditionKey) != "string") {
								throw "Condition key is not a string";
							}
							if (conditionType == "equals" || conditionType == "notEquals") {
								extraLogging && console.log("Condition type is 'equals' or 'notEquals'");
								const conditionValue = condition.value;
								extraLogging && console.log("Condition value:" + JSON.stringify(conditionValue));
								if (conditionValue == null) {
									throw "No condition value given";
								}
								if (typeof(conditionValue) != "string") {
									throw "Condition value is not a string";
								}
								if (conditionType == "equals" && !compareObjects(model[conditionKey], conditionValue)) {
										extraLogging && console.log("Condition type is 'equals' and " + model[conditionKey] + " does not equal " + conditionValue);
										conditionsMet = false;
										continue;
								}
								if (conditionType == "notEquals" && compareObjects(model[conditionKey], conditionValue)) {
									extraLogging && console.log("Condition type is 'notEquals' and " + model[conditionKey] + " does equal " + conditionValue);
									conditionsMet = false;
									continue;
								}
							}
							else if (conditionType == "exists") {
								extraLogging && console.log("Condition type is 'exists'");
								if (!model.hasOwnProperty(conditionKey)) {
									extraLogging && console.log("Condition type is 'exists' and key '" + conditionKey + "' doesn't exist");
									conditionsMet = false;
									continue;
								}
							}
							else if (conditionType == "notExists") {
								extraLogging && console.log("Condition type is 'notExists'");
								if (model.hasOwnProperty(conditionKey)) {
									extraLogging && console.log("Condition type is 'notExists' and key '" + conditionKey + "' does exist");
									conditionsMet = false;
									continue;
								}
							}
							else {
								throw "Invalid key condition type: " + conditionType;
							}
						}
						else if (conditionParameter == "modelIndex") {
							extraLogging && console.log("Condition parameter is 'modelIndex'");
							if (conditionType == "equals" || conditionType == "notEquals" || conditionType == "greaterThan" || conditionType == "lessThan" || conditionType == "greaterThanOrEquals" || conditionType == "lessThanOrEquals") {
								const conditionValue = condition.value;
								extraLogging && console.log("Condition value: " + conditionValue);
								if (conditionValue == null) {
									throw "No condition value given";
								}
								if (typeof(conditionValue) != "number") {
									throw "Condition value is not a number";
								}
								if (conditionType == "equals" && modelIndex != conditionValue) {
									extraLogging && console.log("Condition type is 'equals' and index '" + modelIndex + "' does not equal '" + conditionValue + "'");
									conditionsMet = false;
									continue;
								}
								if (conditionType == "notEquals" && modelIndex == conditionValue) {
									extraLogging && console.log("Condition type is 'notEquals' and index '" + modelIndex + "' does equal '" + conditionValue + "'");
									conditionsMet = false;
									continue;
								}
								if (conditionType == "greaterThan" && modelIndex <= conditionValue) {
									extraLogging && console.log("Condition type is 'greaterThan' and index '" + modelIndex + "' is not greater than '" + conditionValue + "'");
									conditionsMet = false;
									continue;
								}
								if (conditionType == "lessThan" && modelIndex >= conditionValue) {
									extraLogging && console.log("Condition type is 'lessThan' and index '" + modelIndex + "' is not less than '" + conditionValue + "'");
									conditionsMet = false;
									continue;
								}
								if (conditionType == "greaterThanOrEquals" && modelIndex < conditionValue) {
									extraLogging && console.log("Condition type is 'greaterThanOrEquals' and index '" + modelIndex + "' is not greater than or equal to '" + conditionValue + "'");
									conditionsMet = false;
									continue;
								}
								if (conditionType == "lessThanOrEquals" && modelIndex > conditionValue) {
									extraLogging && console.log("Condition type is 'greaterThanOrEquals' and index '" + modelIndex + "' is not less than or equal to '" + conditionValue + "'");
									conditionsMet = false;
									continue;
								}
							}
						}
						else {
							throw "Invalid condition parameter: " + conditionParameter;
						}
						extraLogging && console.log("Condition met");
					}
					extraLogging && console.log("All conditions met: " + conditionsMet);
					if (conditionsMet == false) {
						continue;
					}
				}
				const sectionType = templateSection.type;
				extraLogging && console.log("Section type: " + sectionType);
				if (sectionType == null) {
					throw "No section type given";
				}
				if (typeof(sectionType) != "string") {
					throw "Section type is not a string";
				}
				if (sectionType == "key") {
					extraLogging && console.log("Section type is 'key'");
					const sectionKey = templateSection.key;
					extraLogging && console.log("Section key: " + sectionKey);
					if (sectionKey == null) {
						throw "No section key given";
					}
					if (typeof(sectionKey) != "string") {
						throw "Section key is not a string";
					}
					if (!model.hasOwnProperty(sectionKey)) {
						throw "Model does not have key: " + sectionKey;
					}
					extraLogging && console.log("Model value for key '" + sectionKey + "': " + JSON.stringify(model[sectionKey]));
					modelString = modelString + model[sectionKey];
				}
				if (sectionType == "string") {
					extraLogging && console.log("Section type is 'string'");
					const sectionString = templateSection.string;
					extraLogging && console.log("Section string: " + sectionString);
					if (sectionString == null) {
						throw "No section string given";
					}
					if (typeof(sectionString) != "string") {
						throw "Section string is not a string";
					}
					modelString = modelString + sectionString;
				}
				if (sectionType == "modelIndex") {
					extraLogging && console.log("Section type is 'modelIndex'");
					let modelNumber = modelIndex;
					const indexModifiers = templateSection.indexModifiers;
					extraLogging && console.log("Index modifiers: " + JSON.stringify(indexModifiers));
					if (indexModifiers != null) {
						if (!Array.isArray(indexModifiers)) {
							throw "indexModifiers is not an array";
						}
						for (const indexModifier of indexModifiers) {
							extraLogging && console.log("Index modifier: " + JSON.stringify(indexModifier));
							if (indexModifier == null) {
								throw "indexModifier is null";
							}
							if (typeof(indexModifier) != "object") {
								throw "indexModifier is not an object";
							}
							const modifierType = indexModifier.type;
							extraLogging && console.log("Modifier type: " + modifierType);
							if (modifierType == null) {
								throw "No modifier type given";
							}
							if (typeof(modifierType) != "string") {
								throw "Modifier type is not a string";
							}
							const modifierValue = indexModifier.value;
							extraLogging && console.log("Modifier value: " + modifierValue);
							if (modifierValue == null) {
								throw "No modifier value given";
							}
							if (typeof(modifierValue) != "number") {
								throw "Modifier value is not a number";
							}
							if (modifierType == "add") {
								extraLogging && console.log("Modifier type is 'add'" );
								modelNumber = modelNumber + modifierValue;
							}
							else if (modifierType == "subtract") {
								extraLogging && console.log("Modifier type is 'subtract'" );
								modelNumber = modelNumber - modifierValue;
							}
							else if (modifierType == "multiply") {
								extraLogging && console.log("Modifier type is 'multiply'" );
								modelNumber = modelNumber * modifierValue;
							}
							else if (modifierType == "divide") {
								extraLogging && console.log("Modifier type is 'divide'" );
								modelNumber = modelNumber / modifierValue;
							}
							else if (modifierType == "power") {
								extraLogging && console.log("Modifier type is 'power'" );
								modelNumber = modelNumber ** modifierValue;
							}
							extraLogging && console.log("New model number: " + modelNumber);
						}
					}
					extraLogging && console.log("Final model number: " + modelNumber);
					modelString = modelString + modelNumber;
				}
				extraLogging && console.log("Model string: " + modelString);
			}
			extraLogging && console.log("Final model string: " + modelString);
			returnString = returnString + modelString;
			if (modelIndex < models.length - 2) {
				returnString = returnString + modelSeparator;
			}
			else if (modelIndex < models.length - 1) {
				returnString = returnString + lastModelSeparator;
			}
			extraLogging && console.log("Return string: " + returnString);
		}
		extraLogging && console.log("Final return string: " + returnString);
		return returnString;
	}
	catch (error) {
		throw "Error building string from models and template: " + error;
	}
};

export const getErrorString = function (error) {
    if (typeof(error) != "string" && typeof(error) != "number") {
        try {
            if (error.message != null && typeof(error.message) == "string") {
                if (error.name != null && typeof(error.name) == "string") {
                    error = error.name + ": " + error.message;
                }
                else {
                    error = error.message;
                }
            }
            else {
                throw "No error message";
            }
        }
        catch {
            try {
                error = Object.getOwnPropertyNames(error)
            }
            catch {
				if (error == null) {
					error = "";
				}
				else {
                	error = JSON.stringify(error);
				}
            }
        }
    }

    return error;
};

export const getErrorStatusCode = function (error) {
    if (typeof(error) == "string" || typeof(error) == "number") {
		return null;
	}
	try {
		const statusCode = error.statusCode || error.statuscode;
		if (statusCode == null) {
			return null;
		}
		return statusCode;
	}
	catch {
		return null;
	}
};

export const throwError = function (message, errorStack, statusCode = null) {
    if (isJSON(errorStack) && errorStack.questionErrors != null) {
        throw {questionErrors: errorStack.questionErrors};
    }

    const errorStackString = getErrorString(errorStack);
    let errorString = message || "";
    if (typeof(errorString) == "string" && errorString.length > 2 && errorString.slice(-2) != ": " && errorStackString.length > 0) {
        errorString = errorString + ": ";
    }
    errorString = errorString + errorStackString;

    if (typeof(errorStack) == "object") {
		try {
			if (errorStack.statusCode != null || errorStack.statuscode != null) {
        		statusCode = errorStack.statusCode || errorStack.statuscode;
			}
		} catch {}
    }
	let errorObject = errorString;
    if (statusCode != null) {
        errorObject = {
            statusCode: statusCode, 
            message: errorString
        };
    }

    throw errorObject;
};

export const posterLocations = [
	"Grape Tree",
	"Sainsbury's",
	"Tesco",
	"Kekolo Coffee",
	"Chandos Deli", 
	"Shaw Trust", 
	"G Hatto Barber Shop", 
	"Dorothy House Hospice Care",
	"Lulu Caffe",
	"Boston Tea Party"
];

export const tutorLeafletLocations = [
	"University of Bath Welcome Pack",
	"University of Bristol Freshers Fair" 
];

export const studentLeafletLocations = [
	"Delivered through my letterbox",
	"School",
	"Scouts",
	"Golden Star (Bristol)",
	"Peking Chef (Oldfield)",
	"Peking Chinese (Bath)",
	"Cheong Sing (Bath)",
	"Handed to me in public"
];

export const parentLeafletLocations = [...studentLeafletLocations];

export const whereFromOptions = [
	"Online", 
	"Word of mouth", 
	"Our posters", 
	"Our leaflets",
	"Other"
];

export const blankAvailability = function () {
	return {
		Monday: [],
		Tuesday: [],
		Wednesday: [],
		Thursday: [],
		Friday: [],
		Saturday: [],
		Sunday: []
	};
};


//export default AuthenticatorWithEmail(App);
export default function AppWithAuth () {
	const getWindowSize = function() {
		const {innerWidth, innerHeight} = window;
		return {innerWidth, innerHeight};
	}

	const [windowSize, setWindowSize] = useState(getWindowSize());
	const [lastMouseClick, setLastMouseClick] = useState(null);
	const [page, setPage] = useState("");
	const [pageVisibility, setPageVisibility] = useState("visible");

	useEffect(() => {
		setLastMouseClick(new Date().valueOf());
		const handleWindowResize = function() {
			setWindowSize(getWindowSize());
		}

		const incrementMouseClicks = function () {
			setLastMouseClick(new Date().valueOf());
		};

		const manageVisibilityChange = function () {
			setPageVisibility(document.visibilityState);
		};

		window.addEventListener("resize", handleWindowResize);
		window.addEventListener("mousedown", incrementMouseClicks);
		window.addEventListener("touchstart", incrementMouseClicks);
		document.addEventListener("visibilitychange", manageVisibilityChange);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
			window.removeEventListener("mousedown", incrementMouseClicks);
			window.removeEventListener("touchstart", incrementMouseClicks);
			document.removeEventListener("visibilitychange", manageVisibilityChange);
		};
	}, []);

	let width = 1920;
	let height = 1080;
	if (windowSize != null) {
			width = windowSize.innerWidth;
			height = windowSize.innerHeight;
	}
	const passingProps = {};
	passingProps.width = width;
	passingProps.height = height;
	passingProps.lastMouseClick = lastMouseClick;
	passingProps.page = window.location.pathname;
	passingProps.pageVisibility = pageVisibility;

	const titleTextProps = {};
	titleTextProps.color = "#08007d";
	titleTextProps.fontWeight = "bold";
	titleTextProps.fontSize = "30px";
	titleTextProps.textAlign = "center";
	passingProps.titleTextProps = titleTextProps;

	const auth = authComponents();
	return (
		<Flex direction={"column"} className="Page" minHeight={height} justifyContent={"center"} alignItems={"center"} position={"relative"}>
			<Flex>
				<App {...passingProps} />
			</Flex>
			{/* <Flex position={"absolute"} top={"10vh"}>
				<Text {...titleTextProps}>Welcome to the Next Door Tutor app!</Text>
			</Flex> */}
		</Flex>
	);
}


//export default App;