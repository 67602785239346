import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { TextField, Text, Flex, Button, Icon } from "@aws-amplify/ui-react";
import { useState, useRef, useEffect } from 'react';
import { validateField } from "./FormValidation";
import { DayAvailabilityTemplate } from "./DayAvailabilityTemplate";
import { blankAvailability, getSessionLengthMins } from "../../App";
import {MdHelp} from 'react-icons/md';
import { compareObjects } from "nextdoortutor";

export const WeekAvailabilityTemplate = function(props) {
  // try {
    const givenDefaultValue = props.defaultValue;
    const questionID = props.id;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;
    const minSessionLength = props.minSessionLength;

    const [validations, setValidations] = useState([]);
    const [defaultValue, setDefaultValue] = useState({...blankAvailability()});

    useEffect(() => {
      const newValidations = props.validations || [];

      for (let validationIndex = 0; validationIndex < newValidations.length; validationIndex = validationIndex + 1) {
        const validation = newValidations[validationIndex];
        if (validation.type == "ValidWeekAvailability") {
          newValidations.splice(validationIndex, 1);
          validationIndex = validationIndex - 1;
        }
        else if (validation.type == "Required") {
          newValidations.splice(validationIndex, 1);
          validationIndex = validationIndex - 1;
        }
      }
      newValidations.push({type: "ValidWeekAvailability", minSessionLength: minSessionLength});
  
      if (required) {
        newValidations.push({type: "Required", questionType: "weekavailability"});
      }

      setValidations(newValidations);
    }, [props.validations]);
    

    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    
    useEffect(() => {
      if (givenDefaultValue != null) {
        let newDefaultAvailability = {...blankAvailability()};
        if (minSessionLength != null) {
          let sessionLengthMins = minSessionLength;
          if (typeof(sessionLengthMins) == "string") {
            sessionLengthMins = getSessionLengthMins(minSessionLength);
          }
          for (const day of weekdays) {
            const defaultDayAvailability = givenDefaultValue[day] || [];
            for (const availabilityWindow of defaultDayAvailability) {
              const startTime = availabilityWindow.startTime;
              const endTime = availabilityWindow.endTime;
              
              const startTimeHours = parseInt(startTime.split(":")[0], 10);
              const startTimeMinutes = parseInt(startTime.split(":")[1], 10);
              const startTimeInt = (startTimeHours * 60) + startTimeMinutes;
              
              const endTimeHours = parseInt(endTime.split(":")[0], 10);
              const endTimeMinutes = parseInt(endTime.split(":")[1], 10);
              const endTimeInt = (endTimeHours * 60) + endTimeMinutes;
              
              const durationMinutes = endTimeInt - startTimeInt;
              
              if (durationMinutes >= sessionLengthMins) {
                newDefaultAvailability[day].push(availabilityWindow);
              }
            }
          }
        }
        else {
          newDefaultAvailability = {...givenDefaultValue};
        }
        const validateDefaultValue = validateField(newDefaultAvailability, [{type:"ValidWeekAvailability"}]);
        if (validateDefaultValue.hasError == false && !compareObjects(newDefaultAvailability, defaultValue)) {
          setDefaultValue(newDefaultAvailability);
        }
      }
    }, [givenDefaultValue]);

    const [dayAnswers, setDayAnswers] = useState(defaultValue);
    const [dayErrors, setDayErrors] = useState({});
    const [errors, setErrors] = useState({hasError:false, questionError:""})
    const [dayUIs, setDayUIs] = useState([])

    useEffect(() => {
      if (defaultValue != null) {
        const validationResult = validateField(defaultValue, validations);
        const questionHasError = validationResult.hasError;
        setErrors({questionHasError:questionHasError, questionError:validationResult.errorMessage});
        props.handleChange(questionID, defaultValue, questionHasError);
        if (!compareObjects(defaultValue, dayAnswers)) {
          setDayAnswers(defaultValue);
        }
      }
    }, [defaultValue]);
    

    const handleChangeWeek = function(childQuestionID, input, childHasError) {
      const newDayAnswer = input;
      const newAnswers = dayAnswers;
      newAnswers[childQuestionID] = newDayAnswer;
      setDayAnswers(newAnswers);

      const newDayError = childHasError;
      const newDayErrors = dayErrors;
      newDayErrors[childQuestionID] = newDayError;
      setDayErrors(newDayErrors);
      let anyDayErrors = false;
      const allDayErrors = Object.values(dayErrors);
      for (const dayHasError of allDayErrors) {
        anyDayErrors = anyDayErrors || dayHasError;
      }

      const validationResult = validateField(newAnswers, validations);
      const questionHasError = validationResult.hasError;
      setErrors({questionHasError:questionHasError, questionError:validationResult.errorMessage});
      if (!questionHasError && validationResult.warning != null) {
        setErrors({questionHasError:questionHasError, questionError:validationResult.warning});
      }
      

      props.handleChange(questionID, newAnswers, questionHasError);
    }

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {props.label}
    </Text>

    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>

    const errorText = <Text {...errorTextProps}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    // useEffect(() => {
      
    //   setDayUIs(componentArray);
    // }, [defaultValue, dayAnswers]);

    const componentArray = [];
      for (const day of weekdays) {
        const childProps = {...props};
        childProps.label = day;
        childProps.key = day + JSON.stringify(defaultValue[day]);
        childProps.labelSize = "18px";
        childProps.id = day;
        childProps.handleChange = handleChangeWeek;
        childProps.required = false;
        childProps.validations =[{"type":"ValidDayAvailability"}];
        childProps.answersSuccessfullySubmitted = answersSuccessfullySubmitted;
        childProps.description = null;
        childProps.defaultValue = defaultValue[day];
        childProps.weekAvailability = dayAnswers;
        childProps.day = day;
  
        const dayAvailabilityComponent = <DayAvailabilityTemplate {...childProps} key={childProps.key} />
        componentArray.push(dayAvailabilityComponent);
      }

    

    const timeFormatWarning = <Text {...labelTextProps} textAlign={"center"} fontSize={"15px"}> (Note 24hr time format)</Text>
    
    const [showHelp, setShowHelp] = useState({
      show: false,
      pointerType: null,
      clicked: false,
      hovering: false,
      mouseOverWindow: false,
      crossClicked: false,
      crossStop: false,
      mouseOverHelp: false
    });

    useEffect(() => {
      if (showHelp.crossClicked && !showHelp.mouseOverHelp) {
        const newShowHelp = {...showHelp};
        newShowHelp.crossClicked = false;
        setShowHelp(newShowHelp);
      }
    }, [showHelp]);

    const xButton = <Flex
      key={"XButton"}
      direction={"row"}
      style={{
        cursor:"pointer",
        background:"#ff0202",
        zIndex:4
      }}
      width="30px"
      height="30px"
      textAlign="center"
      borderRadius="50%"
      fontSize="18px"
      justifyContent={"center"}
      onClick={() => {
        const newShowHelp = {...showHelp};
        newShowHelp.show = false;
        newShowHelp.clicked = false;
        newShowHelp.pointerType = null;
        newShowHelp.crossClicked = true;
        newShowHelp.mouseOverWindow = false;
        setShowHelp(newShowHelp)
      }}
      position={"absolute"}
      top={"-10px"}
      right={"-10px"}
    >X</Flex>
    
    let iframeSize = 300;
    const width = window.innerWidth;
    if (width < 500) {
      iframeSize = Math.round(width * 0.8);
    }

    const instructionsGif = <Flex
      alignItems={"center"} 
      justifyContent={"center"} 
      position={"fixed"}
      top={"30vh"}
      alignSelf={"center"}
      width={iframeSize + 15 + "px"} 
      height={iframeSize + 15 + "px"}
      style={{zIndex:2}}
      onPointerEnter={() => {
        const newShowHelp = {...showHelp};
        newShowHelp.mouseOverHelp = false;
        newShowHelp.mouseOverWindow = true;
        newShowHelp.hovering = false;
        setShowHelp(newShowHelp);
      }}
      onPointerLeave={() => {
        const newShowHelp = {...showHelp};
        newShowHelp.mouseOverWindow = false;
        if (showHelp.clicked == false && showHelp.hovering == false) {
          newShowHelp.show = false;
        }
        setShowHelp(newShowHelp);
      }}
    >
      {(showHelp.pointerType != "mouse" && !showHelp.hovering) && <Flex 
        backgroundColor={"#000000"}
        position={"fixed"}
        top={"0px"}
        left={"0px"}
        width={"100vw"}
        height={"100vh"}
        style={{
          opacity: 0.7
        }}
        onClick={() => {
          const newShowHelp = {...showHelp};
          newShowHelp.show = false;
          newShowHelp.clicked = false;
          newShowHelp.pointerType = null;
          setShowHelp(newShowHelp)
        }}
      />}
      <Flex 
        width={iframeSize + 15 + "px"} 
        height={iframeSize + 15 + "px"} 
        backgroundColor={"#92e887"} 
        alignItems={"center"} 
        justifyContent={"center"}
        borderRadius={"10px"}
        position={"fixed"}
        top={"30vh"}
        alignSelf={"center"}
        border={"solid"}
        style={{zIndex:2}}
      />
      <iframe 
        src="https://giphy.com/embed/t3X42tlFmpvrVNDDpi"
        //src="https://tenor.com/view/snow-day-gif-6132267026660552137"
        key={"gif" + showHelp}
        width={iframeSize + "px"} 
        height={iframeSize + "px"} 
        style={{borderRadius: "10px", pointerEvents:"none", zIndex:2, borderStyle:"solid", borderWidth:"1px", borderColor:"#000000"}}
        frameBorder={0}
      />
      {xButton}
    </Flex>

  let helpButtonColour = "#ffd152";
  //let helpButtonColour = "#ffffff";
  if (showHelp.clicked && showHelp.mouseOverHelp && !showHelp.hovering) {
    helpButtonColour = "#c42727";
  }
  else if (showHelp.clicked) {
    helpButtonColour = "#eb6f42";
  }
  else if (showHelp.hovering || showHelp.mouseOverWindow) {
    helpButtonColour = "#ebaa42 ";
    //helpButtonColour = "#ff7f00";
  }
  
  const helpButton = <Icon
    key={"helpButton"}
    width={"30px"}
    height={"30px"}
    as={MdHelp}
    color={helpButtonColour}
    borderRadius={"7vh"}
    backgroundColor={"#4287f5"}
    style={{
      cursor: "pointer",
      zIndex:1,
      transition: "color 0.2s ease"
    }}
    position={"absolute"} 
    right={"-50px"}
    onClick={(event) => {
      const eventPointerType = event.nativeEvent.pointerType;
      const newShowHelp = {...showHelp};
      newShowHelp.clicked = true;
      if (showHelp.show == true) {
        if (showHelp.hovering == false) {
          newShowHelp.clicked = false;
          newShowHelp.show = false;
          newShowHelp.pointerType = null;
        }
        else {
          newShowHelp.hovering = false;
          newShowHelp.pointerType = eventPointerType;
        }
      }
      else {
        newShowHelp.pointerType = eventPointerType;
        newShowHelp.show = true;
      }
      setShowHelp(newShowHelp);
    }}
    onPointerEnter={(event) => {
      const eventPointerType = event.pointerType;
      const newShowHelp = {...showHelp};
      if (showHelp.crossClicked == true) {
        newShowHelp.crossStop = true;
      }
      newShowHelp.mouseOverHelp = true;
      if (eventPointerType == "mouse" && newShowHelp.crossStop == false) {
        if (showHelp.clicked == false) {
          newShowHelp.hovering = true;
          newShowHelp.show = true;
          newShowHelp.pointerType = eventPointerType;
        }
      }
      setShowHelp(newShowHelp);
    }}
    onPointerLeave={(event) => {
      const eventPointerType = event.pointerType;
      if (eventPointerType == "mouse") {
        const newShowHelp = {...showHelp};
        newShowHelp.mouseOverHelp = false;
        newShowHelp.hovering = false;
        newShowHelp.crossClicked = false;
        newShowHelp.crossStop = false
        newShowHelp.mouseOverWindow = false;
        if (showHelp.clicked == false) {
          newShowHelp.show = false;
        }
        setShowHelp(newShowHelp);
      }
    }}
  />

    //Return week availability field component to calling function (Most likely a Form Template)
    return (
      <div className="WeekAvailability">
        <Flex direction={"column"}  gap={"3vh"}>
          <Flex direction={"column"} justifyContent={"left"} gap={"0.3vh"}>
            {(showHelp.show) && instructionsGif}
            <Flex alignItems={"center"} position={"relative"} width={"fit-content"} alignSelf={"center"}>
              {renderedRequiredAsterisk}
              {labelText}
              {helpButton}
            </Flex>
            {descriptionText}
            {/* {timeFormatWarning} */}
          </Flex>
          <Flex direction={"column"} alignItems={"center"} gap={"5vh"}>
            {componentArray}
          </Flex>
          <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
            {errorText}
          </Flex>
        </Flex>
      </div>
    );
  // }
  // catch (error) {
  //   throw "WeekAvailabilityTemplate Error: " + error;
  // }
}

//export default WeekAvailabilityTemplate;