/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const loadUser = /* GraphQL */ `query LoadUser($onlyLoad: String) {
  loadUser(onlyLoad: $onlyLoad) {
    user {
      areaCode
      id
      modelTypeID
      createdAt
      updatedAt
      version
      gender
      phoneNumber
      credit
      signUpWhereFrom
      referralCode
      isParent
      isStudent
      isTutor
      firstNames
      lastName
      staticKey
      yearGroup
      currentWeeklyHours
      weeklyHoursWanted
      otherJobs
      previousExperience
      __typename
    }
    tutorQualificationList {
      areaCode
      id
      modelTypeID
      createdAt
      updatedAt
      version
      educationID
      qualificationType
      subject
      result
      examBoard
      year
      __typename
    }
    tutorEducationList {
      id
      modelTypeID
      createdAt
      updatedAt
      version
      type
      institution
      currentYear
      graduationYear
      __typename
    }
    studentList {
      areaCode
      id
      modelTypeID
      createdAt
      updatedAt
      version
      yearGroup
      firstNames
      lastName
      staticKey
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.LoadUserQueryVariables, APITypes.LoadUserQuery>;
export const getTutorRequest = /* GraphQL */ `query GetTutorRequest($id: ID!) {
  getTutorRequest(id: $id) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTutorRequestQueryVariables,
  APITypes.GetTutorRequestQuery
>;
export const listTutorRequests = /* GraphQL */ `query ListTutorRequests(
  $filter: ModelTutorRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listTutorRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tutorID
      lessonRequestID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTutorRequestsQueryVariables,
  APITypes.ListTutorRequestsQuery
>;
export const getLessonRequest = /* GraphQL */ `query GetLessonRequest($id: ID!) {
  getLessonRequest(id: $id) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLessonRequestQueryVariables,
  APITypes.GetLessonRequestQuery
>;
export const listLessonRequests = /* GraphQL */ `query ListLessonRequests(
  $filter: ModelLessonRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listLessonRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      studentID
      parentID
      subject
      sessionLength
      numSessions
      address
      availability
      lessonType
      schoolSet
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLessonRequestsQueryVariables,
  APITypes.ListLessonRequestsQuery
>;
export const getLesson = /* GraphQL */ `query GetLesson($id: ID!) {
  getLesson(id: $id) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLessonQueryVariables, APITypes.GetLessonQuery>;
export const listLessons = /* GraphQL */ `query ListLessons(
  $filter: ModelLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  listLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tutorID
      studentID
      parentID
      tutorName
      studentName
      subject
      day
      startTime
      endTime
      lessonType
      lessonLocation
      firstLessonDate
      lessonDates
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLessonsQueryVariables,
  APITypes.ListLessonsQuery
>;
export const getIssues = /* GraphQL */ `query GetIssues($id: ID!) {
  getIssues(id: $id) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetIssuesQueryVariables, APITypes.GetIssuesQuery>;
export const listIssues = /* GraphQL */ `query ListIssues(
  $id: ID
  $filter: ModelIssuesFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIssues(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      title
      details
      submittedBy
      due
      priority
      progress
      assignedTo
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIssuesQueryVariables,
  APITypes.ListIssuesQuery
>;
export const tutorRequestsByTutorID = /* GraphQL */ `query TutorRequestsByTutorID(
  $tutorID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTutorRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  tutorRequestsByTutorID(
    tutorID: $tutorID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      lessonRequestID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TutorRequestsByTutorIDQueryVariables,
  APITypes.TutorRequestsByTutorIDQuery
>;
export const tutorRequestsByLessonRequestID = /* GraphQL */ `query TutorRequestsByLessonRequestID(
  $lessonRequestID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTutorRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  tutorRequestsByLessonRequestID(
    lessonRequestID: $lessonRequestID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      lessonRequestID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TutorRequestsByLessonRequestIDQueryVariables,
  APITypes.TutorRequestsByLessonRequestIDQuery
>;
export const lessonRequestsByStudentID = /* GraphQL */ `query LessonRequestsByStudentID(
  $studentID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonRequestsByStudentID(
    studentID: $studentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studentID
      parentID
      subject
      sessionLength
      numSessions
      address
      availability
      lessonType
      schoolSet
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonRequestsByStudentIDQueryVariables,
  APITypes.LessonRequestsByStudentIDQuery
>;
export const lessonRequestsByParentID = /* GraphQL */ `query LessonRequestsByParentID(
  $parentID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonRequestsByParentID(
    parentID: $parentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studentID
      parentID
      subject
      sessionLength
      numSessions
      address
      availability
      lessonType
      schoolSet
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonRequestsByParentIDQueryVariables,
  APITypes.LessonRequestsByParentIDQuery
>;
export const lessonsByTutorID = /* GraphQL */ `query LessonsByTutorID(
  $tutorID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonsByTutorID(
    tutorID: $tutorID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentID
      parentID
      tutorName
      studentName
      subject
      day
      startTime
      endTime
      lessonType
      lessonLocation
      firstLessonDate
      lessonDates
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonsByTutorIDQueryVariables,
  APITypes.LessonsByTutorIDQuery
>;
export const lessonsByStudentID = /* GraphQL */ `query LessonsByStudentID(
  $studentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonsByStudentID(
    studentID: $studentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentID
      parentID
      tutorName
      studentName
      subject
      day
      startTime
      endTime
      lessonType
      lessonLocation
      firstLessonDate
      lessonDates
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonsByStudentIDQueryVariables,
  APITypes.LessonsByStudentIDQuery
>;
export const lessonsByParentID = /* GraphQL */ `query LessonsByParentID(
  $parentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonsByParentID(
    parentID: $parentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentID
      parentID
      tutorName
      studentName
      subject
      day
      startTime
      endTime
      lessonType
      lessonLocation
      firstLessonDate
      lessonDates
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonsByParentIDQueryVariables,
  APITypes.LessonsByParentIDQuery
>;
export const issuesByAssignedToAndDueAndPriority = /* GraphQL */ `query IssuesByAssignedToAndDueAndPriority(
  $assignedTo: String!
  $duePriority: ModelIssuesAssignedToDueCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIssuesFilterInput
  $limit: Int
  $nextToken: String
) {
  issuesByAssignedToAndDueAndPriority(
    assignedTo: $assignedTo
    duePriority: $duePriority
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      details
      submittedBy
      due
      priority
      progress
      assignedTo
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IssuesByAssignedToAndDueAndPriorityQueryVariables,
  APITypes.IssuesByAssignedToAndDueAndPriorityQuery
>;
export const issuesByAssignedToAndPriorityAndDue = /* GraphQL */ `query IssuesByAssignedToAndPriorityAndDue(
  $assignedTo: String!
  $priorityDue: ModelIssuesAssignedToPriorityCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIssuesFilterInput
  $limit: Int
  $nextToken: String
) {
  issuesByAssignedToAndPriorityAndDue(
    assignedTo: $assignedTo
    priorityDue: $priorityDue
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      details
      submittedBy
      due
      priority
      progress
      assignedTo
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IssuesByAssignedToAndPriorityAndDueQueryVariables,
  APITypes.IssuesByAssignedToAndPriorityAndDueQuery
>;
