import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Button, Flex, Grid, Text } from "@aws-amplify/ui-react";
import { AppNavButton, FormTemplate } from "../../custom-ui-components";
import BackButton from "../../custom-ui-components/BackButton";
import { subjects } from "nextdoortutor";


const CreateTeachingResource = function(props) {
  const height = props.height;
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;
  const APIFunctions = props.APIFunctions;
  const [redirect, setRedirect] = useState(null);

  const titleText = <Text {...titleTextProps}>Add New Teaching Resource</Text>

  const backButton = <BackButton {...props} defaultBack={"/Admin/TeachingResourcesAdmin"} />

  const formQuestions = [];
  formQuestions.push({
    id: "title", 
    label: "Title:",
    type: "text",
    required: true, 
    defaultValue: null
  });

  formQuestions.push({
    id: "subject", 
    label: "Subject:",
    type: "dropdown",
    options: [...subjects],
    required: true, 
    validations: [{type: "ValidSubject"}]
  });

  formQuestions.push({
    id: "topic", 
    label: "Topic within subject:",
    type: "text",
    required: false, 
  });

  formQuestions.push({
    id: "yearGroup", 
    label: "Year Group:",
    type: "dropdown",
    options: ["Key Stage 1", "Key Stage 2", "Key Stage 3", "Key Stage 4", "Key Stage 5", "University"],
    required: true
  });

  formQuestions.push({
    id: "link", 
    label: "Link:",
    type: "text",
    required: true, 
    defaultValue: null,
    validations: [{type: "URL"}]
  });

  formQuestions.push({
    id: "notes", 
    label: "Notes:",
    type: "text",
    required: false, 
    defaultValue: null,
    extraProps: {
      textType: "paragraph"
    }
  });


  const submitAnswers = async function (answers) {
    try {
      console.log(answers);
      await APIFunctions.createTeachingResource(answers);
    }
    catch (error) {
      console.log("Error submitting form to create new teaching resource: " + error);
      throw {
        message: "Error creating new teaching resource",
        error: error
      }
    }
  };

  const exitForm = function () {
    setRedirect(<Navigate to={"/Admin/TeachingResourcesAdmin"} />);
  };

  const resourceForm = <FormTemplate {...props} submitAnswers={submitAnswers} questions={formQuestions} okButtonFunction={exitForm} />

  return (
      <Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
        {titleText}
        {backButton}
        {resourceForm}
        {redirect}
      </Flex>
  );
}

export default CreateTeachingResource;