import {React, useCallback, useEffect, useState } from 'react';
import { Flex, Text } from '@aws-amplify/ui-react';
import { FormTemplate } from '../../custom-ui-components';
import { Navigate } from 'react-router-dom';
import { posterLocations, studentLeafletLocations, parentLeafletLocations, tutorLeafletLocations, throwError, whereFromOptions } from '../../App';
import { isJSON } from 'nextdoortutor';

const UserRegistration = function(props) {
    const registrationType = (props.registrationType || "").toLowerCase();

    const [questionAnswers, setQuestionAnswers] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [title, setTitle] = useState(null);
    const [form, setForm] = useState(null);
    const [tutorEducationAnswers, setTutorEducationAnswers] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    // Return if the registration type is not given or the user is already registered
    useEffect(() => {
        if (registrationType == "parent" || registrationType == "student") {
            if (!submitted && (props.userIsParent || props.userIsStudent)) {
                setRedirect(<Navigate to={"/"}/>);
            }
        }
        else if (registrationType == "tutor") {
            if (!submitted && props.userIsTutor) {
                setRedirect(<Navigate to={"/"}/>);
            }
        }
        else {
            setRedirect(<Navigate to={"/"}/>);
        }
    }, [registrationType, props.userIsParent, props.userIsStudent, props.userIsTutor, submitted]);

    const submitAnswers = useCallback(async function(returnedAnswers) {
        setQuestionAnswers(returnedAnswers);
        try {
            const userAttributes = {...returnedAnswers};
            const termsOfUseAgreement = userAttributes.termsOfUseAgreement || {};
            const agreements = {
                age: false,
                terms: false,
                exclusivity: false
            };
            for (const [agreement, agreed] of Object.entries(termsOfUseAgreement)) {
                if (agreement == "I confirm I am aged 13 or older") {
                    agreements.age = agreed;
                }
                else if (agreement == "I have read and accept the Next Door Tutor Terms of Service and Privacy Policy") {
                    agreements.terms = agreed;
                }
                else if (agreement == "I agree to only schedule sessions and/or complete financial transactions with my " + (registrationType == "tutor" && "tutees" || "tutors") + " via Next Door Tutor Ltd") {
                    agreements.exclusivity = agreed;
                }
            }
            for (const agreed of Object.values(agreements)) {
                if (agreed !== true) {
                    throwError("You must agree to the terms of use to register");
                }
            }
            delete userAttributes.termsOfUseAgreement;
            userAttributes.termsOfUse = true;

            if (registrationType == "tutor") {
                const tutorQualifications = userAttributes.qualifications.add;
                userAttributes.qualifications = tutorQualifications;
                const tutorEducation = userAttributes.education.add;
                userAttributes.education = tutorEducation;
                console.log(tutorEducation);

                userAttributes.address = {
                    postcode: userAttributes.postcode
                };
                delete userAttributes.postcode;

                if (userAttributes.gender == "Other" && typeof(userAttributes.pronouns) == "string" && userAttributes.pronouns != "") {
                    userAttributes.gender = userAttributes.pronouns;
                }
            }

            setSubmitted(true);
            await props.APIFunctions.registerUser(registrationType, userAttributes);
            console.log("Successfully registered user as a " + registrationType);
        }
        catch (error) {
            setSubmitted(false);
            throwError(null, error);
        }
    }, [props.APIFunctions, registrationType]);

    const exitForm = useCallback(function () {
        let fromLocation = props.fromLocation;
        if (fromLocation == null) {
            const queryParams = new URLSearchParams(window.location.search);
            fromLocation = queryParams.get("from");
        }

        const capitalisedRegistrationType = registrationType.charAt(0).toUpperCase() + registrationType.slice(1);
        const startGuidePath = "/" + capitalisedRegistrationType + "/StartGuide";

        if (fromLocation != null) {
            const state = {
                from: fromLocation,
                userType: registrationType
            };
            setRedirect(<Navigate to={startGuidePath + "?from=" + fromLocation} state={state} />);
        }
        else {
            setRedirect(<Navigate to={startGuidePath} />);
        }
    }, [props.fromLocation, registrationType]);

    // Create the title
    useEffect(() => {
        setTitle(<Text {...props.titleTextProps}  >
            Register to be a {registrationType}
        </Text>);
    }, [registrationType, props.titleTextProps]);

    // Create the form
    useEffect(() => {
        const handleFormChange = function (questionID, newAnswer, hasError) {
            if (questionID == "education" && hasError == false) {
                setTutorEducationAnswers(newAnswer);
            }
        };

        const startQuestions = [
            // First name
            {
                id: "firstNames", 
                label: "First Name(s):", 
                type: "text", 
                required: true, 
                validations: [{type: "Name"}], 
                defaultValue: null, 
                placeholder: "Enter name"
            },
            // Last name
            {
                id: "lastName", 
                label: "Surname:", 
                type: "text", 
                required: true, 
                validations: [{type: "Name"}], 
                defaultValue: null, 
                placeholder: "Enter name"
            },
            // Phone number
            {
                id: "phoneNumber", 
                label: "Contact Number:", 
                type: "text", 
                required: true, 
                validations: [{type: "UKMobPhone"}], 
                defaultValue: null, 
                placeholder: "Enter phone number"
            }
        ];
        
        let leafletLocations = [];
        if (registrationType == "parent") {
            leafletLocations = parentLeafletLocations;
        }
        else if (registrationType == "student") {
            leafletLocations = studentLeafletLocations;
        }
        else if (registrationType == "tutor") {
            leafletLocations = tutorLeafletLocations;
        }

        const endQuestions = [
            // Sign up where from
            {
                id: "signUpWhereFrom", 
                label: "Where did you hear about us?", 
                type: "dropdown", 
                required: true, 
                defaultValue: null, 
                options: whereFromOptions
            },
            // Poster details
            {
                id: "whereFromPoster", 
                label: "Where was the poster located?", 
                type: "autocomplete", 
                required: true, 
                defaultValue: null, 
                options: posterLocations,
                onlyShow: {id: "signUpWhereFrom", answers: ["Our posters"]}
            },
            // Leaflet details
            {
                id: "whereFromLeaflet", 
                label: "Where was the leaflet from?", 
                type: "autocomplete", 
                required: true, 
                defaultValue: null, 
                options: leafletLocations,
                onlyShow: {id: "signUpWhereFrom", answers: ["Our leaflets"]}
            },
            // Other details
            {
                id: "whereFromOther", 
                label: "More details on how you heard about us:",
                type: "text", 
                required: false, 
                defaultValue: null, 
                onlyShow: {id: "signUpWhereFrom", answers: ["Other"]}
            },
            // Referral code
            {
                id: "referralCode",
                label: "Were you referred by an existing user?",
                description: "Ask them for their referral code and enter it here",
                type: "text",
                required: false,
                validations: [],
                defaultValue: null,
            },
            // Terms of use agreement
            {
                id: "termsOfUseAgreement",
                label: "Terms of use",
                type: "checkbox", 
                required: true, 
                defaultValue: null, 
                options: ["I confirm I am aged 13 or older",
                    "I have read and accept the Next Door Tutor Terms of Service and Privacy Policy",
                    "I agree to only schedule sessions and/or complete financial transactions with my " + (registrationType == "tutor" && "tutees" || "tutors") + " via Next Door Tutor Ltd"
                ],
                optionLinks: {
                    1: [
                        {
                            startCharIndex: 43,
                            endCharIndex: 58,
                            link: "https://nextdoortutor.co.uk/TermsOfService"
                        },
                        {
                            startCharIndex: 64,
                            endCharIndex: 77,
                            link: "https://nextdoortutor.co.uk/PrivacyPolicy"
                        }
                    ]
                },
                validations: [{type: "AllChecked"}], 
            }
        ];

        const parentQuestions = [];
        const studentQuestions = [
            // Year group
            {
                id: "yearGroup", 
                label: "Year Group:", 
                type: "dropdown", 
                required: true, 
                options: ["Year 13", "Year 12", "Year 11", "Year 10", "Year 9", "Year 8"],
                validations: [{type: "Dropdown"}, {type: "ValidYearGroup"}], 
                defaultValue: null
            }
        ];
        const tutorQuestions = [
            // Gender
            {
                id: "gender", 
                label: "Gender:",
                type: "radio",
                options: ["Male", "Female", "Non-Binary", "Other", "Prefer not to say"],
                required: true, 
                defaultValue: null, 
            },
            // Pronouns
            {
                id: "pronouns", 
                label: "Pronouns:",
                type: "text",
                required: false,
                onlyShow: {id: "gender", answers: ["Other"]},
                defaultValue: null, 
            },
            // Education
            {
                id: "education", 
                label: "Your education:",
                description: "(You can add or remove any in the future)",
                type: "tutoreducation", 
                required: false, 
                defaultValue: null
            },
            // Qualifications
            {
                id: "qualifications", 
                label: "Subject(s) you wish to teach:",
                description: "(You can add or remove any in the future)",
                type: "tutorqualifications", 
                required: false, 
                gradeAt: "A-Level",
                defaultValue: null,
                tutorEducation: (isJSON(tutorEducationAnswers) && tutorEducationAnswers.add != null && tutorEducationAnswers.add),
                educationQuestionID: "education"
            },
            // Postcode
            {
                id: "postcode", 
                label: "Postcode:", 
                type: "text", 
                required: true, 
                validations: [{type: "ValidPostcode"}], 
                defaultValue: null, 
                placeholder: "Enter postcode",
                description: "This is the postcode for the address you will commute from. It will be used in calculating the distance to potential lessons."
            },
            // Weekly hours wanted
            {
                id: "weeklyHoursWanted", 
                label: "How many hours would you ideally like to be tutoring per week?",
                description: "(You can change this later)",
                type: "text",
                required: true, 
                validations: [{type: "Integer", allowString: true, greaterThan: -1, lessThan: 100}], 
                defaultValue: null,
                extraProps: {returnNumber: true} 
            },
            // Weekly availability
            {
                id: "availability", 
                label: "Weekly availability:",
                description: "(You will be able to update this at any point)",
                type: "weekavailability", 
                required: true, 
                validations: [{type: "ValidWeekAvailability"}], 
                defaultValue: null, 
            },
            // Previous experience
            {
                id: "previousExperience", 
                label: "Do you have any previous tutoring experience?", 
                type: "radiothendetails", 
                options: ["Yes", "No"],
                required: true, 
                validations: [], 
                defaultValue: null, 
                placeholder: "Tell us about your experience"
            },
            // Other jobs
            {
                id: "otherJobs", 
                label: "Do you have any other part time job(s)?",
                type: "radiothendetails", 
                required: true, 
                validations: [], 
                defaultValue: null, 
            }
        ]

        const questions = [...startQuestions];
        if (registrationType == "parent") {
            questions.push(...parentQuestions);
        }
        else if (registrationType == "student") {
            questions.push(...studentQuestions);
        }
        else if (registrationType == "tutor") {
            questions.push(...tutorQuestions);
        }
        questions.push(...endQuestions);

        const registrationForm = <FormTemplate 
            {...props}
            submitAnswers={submitAnswers}
            existingAnswers={questionAnswers}
            questions={questions}
            okButtonFunction={exitForm}
            returnAnswersEarly={{
                handleChange: handleFormChange,
                questions: ["education"]
            }}
        />
        setForm(registrationForm);
    }, [registrationType, tutorEducationAnswers, submitAnswers, questionAnswers, exitForm]);

    return (
        <Flex 
            direction={"column"}
            alignItems={"center"}
        >   
            {title}
            {form}
            {redirect}
        </Flex> 
    );
}

export default UserRegistration;