import {React, useEffect, useState } from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { Button, Flex, Icon, ScrollView, Text } from '@aws-amplify/ui-react';
import LessonRequestTemplate from '../../custom-ui-components/LessonRequestTemplate';
import {MdCached, MdSouth, MdNorth} from 'react-icons/md';
import { DropDownQuestionTemplate } from '../../custom-ui-components';
import CheckBoxesTemplate from '../../custom-ui-components/form-components/CheckBoxesTemplate';
import AutoCompleteTemplate from '../../custom-ui-components/form-components/AutoCompleteTemplate';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { subjects } from 'nextdoortutor';

const LessonRequests = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const width = props.width;
    const lessonRequests = props.adminLessonRequests;
    const studentDictionary = props.studentDictionary
    const APIFunctions = props.APIFunctions;

    const noFilters = {Day:false, Time:false, Subject:false, Tutor:false, Student:false}
    const [studentNames, setStudentNames] = useState(null);
    const [lessonFilters, setLessonFilters] = useState(noFilters);
    const [sortBy, setSortBy] = useState("Sort by request date");
    const [showFilters, setShowFilters] = useState(false);
    const [shownStudent, setShownStudent] = useState(null);
    const [shownSubject, setShownSubject] = useState("");
    const [numRefreshes, setNumRefreshes] = useState(0);
    const [sortOrder, setSortOrder] = useState("oldestFirst");
    const [showOldRequests, setShowOldRequests] = useState({"Show New Requests": true, "Show Pending Requests": false, "Show Completed Requests": false});
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [deletingLessonRequest, setDeletingLessonRequest] = useState(false);
    const [deletedLessonRequest, setDeletedLessonRequest] = useState(false);
    const [currentLessonRequest, setCurrentLessonRequest] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);

    useEffect(() => {
        if (lessonRequests == "loading" || studentDictionary == "loading") {
            return;
        }
        else if (lessonRequests == "error" || studentDictionary == "error") {
            return;
        }
        else if (lessonRequests != null && studentDictionary != null) {
            const usedStudents = {};
            for (const lessonRequest of lessonRequests) {
                let studentIDs = lessonRequest.studentIDs;
                if (studentIDs == null) {
                    studentIDs = [lessonRequest.studentID];
                }
                for (const studentID of studentIDs) {
                    const studentModel = studentDictionary[studentID];
                    if (studentModel == null) {
                        continue;
                    }
                    usedStudents[studentID] = studentModel;
                }
            }
            const usedStudentsArray = Object.values(usedStudents);

            const newStudentNames = [];
            for (const student of usedStudentsArray) {
                newStudentNames.push({id:student.id, label: (student.firstNames + " " + student.lastName)});
            }
            setStudentNames(newStudentNames);
        }
    }, [props.studentDictionary, lessonRequests]);

    if (studentDictionary == null || studentDictionary == "loading") {
        return <LoadingSpinnerPage {...props} />
    }
    else if (studentDictionary == "error" || lessonRequests == "error") {
        return <ErrorLoadingPage {...props}/>
    }
  
    const askDeleteLessonRequest = function (currentLessonRequestProps, clickEvent) {
        if (deletePopUp == true) {
            setDeletePopUp(false);
        }
        else {
            setCurrentLessonRequest(currentLessonRequestProps);
            const controlClick = clickEvent.ctrlKey;
            if (controlClick != true) {
                setDeletePopUp(true);
            }
            else {
                deleteLesson();
            }
        }
    }

    const toggleSortDirection = function () {
        if (sortOrder === "oldestFirst") {
            setSortOrder("newestFirst");
        }
        else {
            setSortOrder("oldestFirst");
        }
    }

    const iconSize = "40";
    let iconMD = MdNorth;
    if (sortOrder === "oldestFirst") {
        iconMD = MdSouth;
    }
    let orderIcon = <Button 
        style={{cursor:"pointer"}} 
        onClick={toggleSortDirection}
        width={iconSize + "px"}
        height={iconSize + "px"}
        justifyContent={"center"}
        alignItems={"center"}
        
    >
        <Icon
            key={"orderChangeButton"}
            width={"30px"}
            height={"30px"}
            as={iconMD}
        />
    </Button>
    
    let lessonRequestsUI = null;
    if (lessonRequests != null && lessonRequests != "loading") {
        if (sortBy == "Sort by request date") {
            if (sortOrder == "oldestFirst") {
                lessonRequests.sort((a, b) => (new Date(a["createdAt"]).valueOf()) - (new Date(b["createdAt"]).valueOf()));
            }
            else if (sortOrder == "newestFirst") {
                lessonRequests.sort((a, b) => (new Date(b["createdAt"]).valueOf()) - (new Date(a["createdAt"]).valueOf()));
            }
        }
        if (sortBy == "Sort by start date") {
            if (sortOrder == "oldestFirst") {
                lessonRequests.sort((a, b) => (new Date(a["requestedStartDate"]).valueOf()) - (new Date(b["requestedStartDate"]).valueOf()));
            }
            else if (sortOrder == "newestFirst") {
                lessonRequests.sort((a, b) => (new Date(b["requestedStartDate"]).valueOf()) - (new Date(a["requestedStartDate"]).valueOf()));
            }
        }
        if (sortBy == "Sort by num sessions") {
            if (sortOrder == "oldestFirst") {
                lessonRequests.sort((a, b) => (a["numSessions"] - b["numSessions"]));
            }
            else if (sortOrder == "newestFirst") {
                lessonRequests.sort((a, b) => (b["numSessions"] - a["numSessions"]));
            }
        }

        lessonRequestsUI = [];
        for (const request of lessonRequests) {
            const requestUI = <LessonRequestTemplate key={request.id} userType={"admin"} requestID={request.id} {...props} deleteLessonRequest={askDeleteLessonRequest}/>

            let showLessonRequest = true;
            if (!(shownSubject == request.subject || shownSubject == "" || lessonFilters.Subject == false)) {
                showLessonRequest = false;
            }
            else if (shownStudent != null && lessonFilters.Student != false) {
                let studentIDs = null;
                if (request.studentID != null) {
                    studentIDs = [request.studentID];
                }
                else {
                    studentIDs = request.studentIDs;
                }

                let foundStudent = false;
                for (const studentID of studentIDs) {
                    if (shownStudent.id == studentID) {
                        foundStudent = true;
                        break;
                    }
                }
                
                if (foundStudent == false) {
                    showLessonRequest = false;
                }
            }
            if (request.status == 1 && !showOldRequests["Show Pending Requests"]) {
                showLessonRequest = false;
            }
            else if (request.status == 3 && !showOldRequests["Show Completed Requests"]) {
                showLessonRequest = false;
            }
            else if ((request.status == 0 || request.status == 2) && !showOldRequests["Show New Requests"]) {
                showLessonRequest = false;
            }
            else if (request.status == 10) {
                showLessonRequest = false;
            }
            if (showLessonRequest == true) {
                lessonRequestsUI.push(requestUI);
            }
        }
    }
    else {
        lessonRequestsUI = <LoadingSpinner />
    }

    const titleText = <Text {...titleTextProps}>All lesson requests</Text>

    let showFiltersText = "Show Filters";
        if (showFilters == true) {
            showFiltersText = "Hide Filters"
        }
    const showFiltersButton = <Button
        key={"ShowFiltersButton"}
        onClick={() => {
            const previousLessonFilters = {...lessonFilters};
            if (showFilters == true) {
                setShowFilters(false);
            }
            else {
                setShowFilters(true);
            }
        }}
    >
        {showFiltersText}
    </Button>

    // let refreshButtonDisabled = false;
    // if (numRefreshes >= 10) {
    //     refreshButtonDisabled = true
    // }
    // const refreshButton = <Button
    //     key={"RefreshButton"}
    //     onClick={() => {
    //         //Prevent API spam by limiting the user to 10 refreshes (Resets when the page is reloaded)
    //         if (!refreshButtonDisabled) {
    //             props.APIFunctions.refreshLessons();
    //             props.APIFunctions.refreshUsers();
    //             setNumRefreshes(numRefreshes + 1);
    //         }
    //     }}
    //     isDisabled={refreshButtonDisabled}
    // >
    //     <Icon
    //         key={"refreshIcon"}
    //         width={"25px"}
    //         height={"25px"}
    //         as={MdCached}
    //     />
    // </Button>

    let filterDirection = "row";
    if (width < 900) {
        filterDirection = "column"
    }
    const getFilterFlex = function () {
        const mainFilterTextProps = {...standardTextProps}
        const filterTextProps = {...mainFilterTextProps}
        mainFilterTextProps.fontSize = "20px"
        filterTextProps.fontSize = "18px";
        
        const checkboxTextProps = {...standardTextProps}
        checkboxTextProps.fontSize = "16px"

        const filterMenu = <CheckBoxesTemplate
            questionLabelProps = {mainFilterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "FilterMenu"
            label = "Filter by:"
            options = {["Subject", "Student"]}
            defaultValue = {lessonFilters}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newFilters = {...selectedAnswers}
                setLessonFilters(newFilters);
            }}
            direction = {filterDirection}
        />

        const showOldRequestsMenu = <CheckBoxesTemplate
            questionLabelProps = {mainFilterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "showOldRequestsMenu"
            label = {null}
            options = {["Show New Requests", "Show Pending Requests", "Show Completed Requests"]}
            defaultValue = {showOldRequests}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newShowOldRequests = {...selectedAnswers}
                setShowOldRequests(newShowOldRequests);
            }}
            direction = {filterDirection}
        />

        const subjectFilter = <DropDownQuestionTemplate
            questionLabelProps = {filterTextProps}
            id = "SubjectFilter"
            label = "Subject:"
            options = {[...subjects]}
            defaultValue = {shownSubject}
            placeHolder = "Choose a subject"
            handleChange = {(questionID, selectedAnswer, errors) => {
                setShownSubject(selectedAnswer);
            }}
            direction = {filterDirection}
        />

        const studentFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "StudentFilter"
            label = "Student:"
            options = {studentNames}
            defaultValue = {shownStudent}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer === undefined) {
                    selectedAnswer = false
                }
                setShownStudent(selectedAnswer);
            }}
            direction = {filterDirection}
        /> 

        let marginFix = "0vh";
        if (filterDirection == "column") {
            marginFix = "-4vh";
        }
        const filterFlex = <Flex alignItems={"center"} gap={"1vh"} direction={"column"} >
            {filterMenu}
            <Flex marginTop={marginFix}>
                {showOldRequestsMenu}
            </Flex>
            {lessonFilters.Subject && subjectFilter}
            {lessonFilters.Student && studentFilter}
            
        </Flex>

        return filterFlex;
    }

    const filterFlex = getFilterFlex();

    const sortByDropDown = <DropDownQuestionTemplate
        //questionLabelProps = {filterTextProps}
        id = "sortBy"
        //label = "Subject:"
        options = {["Sort by request date", "Sort by start date", "Sort by num sessions"]}
        defaultValue = {sortBy}
        placeHolder = {null}
        handleChange = {(questionID, selectedAnswer, errors) => {
            if (selectedAnswer != null) {
                setSortBy(selectedAnswer);
            }
        }}
        direction = {filterDirection}
    />

    const deleteLesson = async function () {
        try {
            const currentLessonRequestProps = currentLessonRequest;
            setDeletingLessonRequest(true);
            const newLessonRequest = {};
            newLessonRequest.id = currentLessonRequestProps.id;
            newLessonRequest.deleted = true;
            const newLessonRequestModel = await APIFunctions.updateLessonRequest(newLessonRequest, "admin");
            console.log(newLessonRequestModel);
            setDeletingLessonRequest(false);
            setDeletedLessonRequest(true);
        }
        catch (error) {
            setDeletePopUp(false);
            setDeletingLessonRequest(false);
            setDeletedLessonRequest(false);
            try {
                if (error.response.data == "Error updating lesson request data model, given error: User does not have permission to edit this lesson request") {
                    setErrorPopUp("You don't have permission to edit this lesson request");
                }
                else {
                    setErrorPopUp(error.response.data);
                }
            }
            catch {
                if (typeof(error) == "string") {
                    setErrorPopUp(error);
                }
                else {
                    setErrorPopUp("Error deleting lesson request");
                }
            }
        }
    }

    const numLessonRequestsText = <Text {...standardTextProps} fontSize={"18px"}>Lesson requests found: {lessonRequestsUI.length}</Text>

    const deleteText = "Are you sure you want to delete this lesson request?";
    const deletePopUpComponent = <PopUpTemplate text={deleteText} setPopUpVisibility={setDeletePopUp} yesButtonFunction={deleteLesson} noButtonFunction={function(){}} showXButton={true} />
    const deletingRequestText = "Deleting lesson request...";
    const deletingLessonRequestPopUp = <PopUpTemplate text={deletingRequestText} setPopUpVisibility={setDeletingLessonRequest} showXButton={false} />
    const deletedLessonRequestText = "Lesson request deleted";
    const deletedLessonRequestPopUp = <PopUpTemplate text={deletedLessonRequestText} okButtonFunction={function(){}} setPopUpVisibility={setDeletedLessonRequest} showXButton={false} />

    const errorPopUpComponent = <PopUpTemplate text={errorPopUp} okButtonFunction={function(){}} setPopUpVisibility={setErrorPopUp} showXButton={true} />

    return (
        <div className='AdminLessonRequestsPage'>
            <Flex direction={"column"} alignItems={"center"} gap={"4vh"}>
                {titleText}                
                <Flex direction={"column"} alignItems={"center"} gap={"1vh"}>
                    <Flex gap={"1vw"}>
                        {showFiltersButton}
                        <Flex gap={"-10px"}>
                            {sortByDropDown}
                            {orderIcon}
                        </Flex>
                    </Flex>
                    {showFilters && filterFlex}
                </Flex>
                {numLessonRequestsText}
                {lessonRequestsUI}
                {/* Uncomment for scroll view (Didn't work as well on mobile) */}
                {/* <Flex
                    style={{
                        borderTopStyle: "dotted",
                        borderBottomStyle: "dotted",
                        borderLeftStyle: "dotted",
                        borderRightStyle: "dotted",
                        borderColor: "#2e66ff",
                    }}
                >
                    <ScrollView 
                        maxHeight={"80vh"}
                        
                        width={"95vw"} 
                        margin={"10px"}
                    >
                        <Flex direction={"column"} alignItems={"center"} gap={"3vh"}>
                            {lessonRequestsUI}
                        </Flex>
                    </ScrollView>
                </Flex> */}
                {deletePopUp && deletePopUpComponent}
                {deletingLessonRequest && deletingLessonRequestPopUp}
                {deletedLessonRequest && deletedLessonRequestPopUp}
                {(errorPopUp != false) && errorPopUpComponent}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default LessonRequests;