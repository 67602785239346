import { React } from 'react';
import { Navigate } from 'react-router-dom';

const AuthPage = function(props) {
    const queryParams = new URLSearchParams(window.location.search);
    let page = queryParams.get("page") || "/";
    queryParams.delete("page");
    if (queryParams.toString().length > 0) {
        page = page + "?" + queryParams.toString();
    }
    console.log("Redirecting user after sign in");
    return (
        <Navigate to={page} /> 
    );
}

export default AuthPage;