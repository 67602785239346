import * as React from "react";
import { TextField, Text, Flex, Button, Badge, Grid, Icon, SelectField } from "@aws-amplify/ui-react";
import { validateField } from "./FormValidation";
import { useState, useEffect } from 'react';
import OptionsTemplate from "./OptionsTemplate";
import TimeRangeTemplate from "./TimeRangeTemplate";
import RadioButtonsTemplate from "./RadioButtonsTemplate";


const CreateLessonTimeTemplate = function(props) {
  try {
    const labelTextProps = {...props.questionLabelProps};
    const errorTextProps = {...props.questionErrorProps};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    let defaultValue = props.defaultValue;
    const questionValidations = props.validations;
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;
    const extraProps = props.extraProps || {};
    const availability = extraProps.availability;
    const matchingAvailability = extraProps.matchingAvailability || extraProps.availability;
    const numSessions = extraProps.numSessions;
    const enforceNumSessions = extraProps.enforceNumSessions;
    const startPointDate = new Date(extraProps.startPointDate).toDateString() || new Date().toDateString();
    const defaultButtonOrLesson = extraProps.defaultButtonOrLesson || "button";

    let sessionLength = extraProps.sessionLength || 60;
    if (sessionLength == "30 Minutes") {
      sessionLength = 30;
    }
    else if (sessionLength == "45 Minutes") {
      sessionLength = 45;
    }
    else if (sessionLength == "1 Hour") {
      sessionLength = 60;
    }
    else if (sessionLength == "1 Hour 30 Minutes") {
      sessionLength = 90;
    }
    else if (sessionLength == "2 Hours") {
      sessionLength = 120;
    }

    const [timeRanges, setTimeRanges] = useState([]);
    const [matchingTimeRanges, setMatchingTimeRanges] = useState([]);

    let dayOptions = []
    if (availability != null) {
      const newDayOptions = [];
      const availabilityArray = Object.entries(availability);
      const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
      for (const dayAvailability of availabilityArray) {
          const day = dayAvailability[0];
          let dayIndex = null;
          for (let validDayIndex = 0; validDayIndex < dayOrder.length; validDayIndex = validDayIndex + 1) {
            const validDay = dayOrder[validDayIndex];
            if (validDay == day) {
              dayIndex = validDayIndex;
            }
          }
          const availabilityWindow = dayAvailability[1];
          if (availabilityWindow.length > 0) {
            newDayOptions[dayIndex] = day;
          }
      }
      for (let optionIndex = 0; optionIndex < newDayOptions.length; optionIndex = optionIndex + 1) {
        const option = newDayOptions[optionIndex];
        if (option == null || option == undefined) {
          newDayOptions.splice(optionIndex, 1);
          optionIndex = optionIndex - 1;
        }
      }
      dayOptions = [...newDayOptions];
    }

    const dropDownOptions = OptionsTemplate(dayOptions, "dropdown");

    const lessonAnswerValidations = [{"type":"ValidLessonTimeDay"}, {"type": "ValidStartDate"}];

    let lessonValidationExists = false;
    for (let validation of questionValidations) {
      if (validation.type === "ValidLessonTimeDays") {
        lessonValidationExists = true;
      }
    }
    if (lessonValidationExists === false) {
      questionValidations.push ({"type":"ValidLessonTimeDays"});
    }


    //Reset the default value incase it needs to be 
    if (defaultValue == null) {
      defaultValue = [];
    }
    //Define a blank time range for resetting to
    const blankLesson = {
      day: dayOptions[0],
      startTime: null,
      endTime: null,
      startDate: null
    }

    //Set state variables used to store the current state and any errors
    const [lessonAnswers, setLessonAnswers] = useState(defaultValue);
    const [currentLessonAnswer, setCurrentLessonAnswer] = useState(blankLesson);
    const [errors, setErrors] = useState({questionHasError:false, questionError:"", currentLessonError:true});
    const [buttonOrLesson, setButtonOrLesson] = useState(defaultButtonOrLesson);

    const updateTimeOptions = function (day) {
      if (availability != null) {
          if (day == null || day == "") {
            setTimeRanges([]);
            return [];
          }
          else {
            const dayAvailability = availability[day];
            const dayAvailabilityOrdered = [];
            for (const timeRange of dayAvailability) {
              const startTime = timeRange.startTime;
              const startHour = parseInt(startTime.split(":")[0]);
              const startMinute = parseInt(startTime.split(":")[1]);
              const startTimeInt = (startHour * 60) + startMinute;
              const endTime = timeRange.endTime;
              const timeRangeObject = {
                startTime: startTime,
                endTime: endTime,
                startTimeInt: startTimeInt
              };
              let added = false;
              for (let existingIndex = 0; existingIndex < dayAvailabilityOrdered.length; existingIndex = existingIndex + 1) {
                if (added == false) {
                  const existingTimeRange = dayAvailabilityOrdered[existingIndex];
                  const existingStartTimeInt = existingTimeRange.startTimeInt;
                  if (startTimeInt < existingStartTimeInt && added == false) {
                    dayAvailabilityOrdered.splice(existingIndex, 0, timeRangeObject);
                    added = true;
                  }
                }
              }
              if (added == false) {
                dayAvailabilityOrdered.push(timeRangeObject);
              }
            }
            setTimeRanges([...dayAvailabilityOrdered]);
            return dayAvailabilityOrdered;
          }
      }
    }

    const updateMatchingTimeOptions = function (day) {
      if (matchingAvailability != null) {
          if (day == null || day == "") {
            setMatchingTimeRanges([]);
            return [];
          }
          else {
            const dayAvailability = matchingAvailability[day];
            const dayAvailabilityOrdered = [];
            for (const timeRange of dayAvailability) {
              const startTime = timeRange.startTime;
              const startHour = parseInt(startTime.split(":")[0]);
              const startMinute = parseInt(startTime.split(":")[1]);
              const startTimeInt = (startHour * 60) + startMinute;
              const endTime = timeRange.endTime;
              const timeRangeObject = {
                startTime: startTime,
                endTime: endTime,
                startTimeInt: startTimeInt
              };
              let added = false;
              for (let existingIndex = 0; existingIndex < dayAvailabilityOrdered.length; existingIndex = existingIndex + 1) {
                if (added == false) {
                  const existingTimeRange = dayAvailabilityOrdered[existingIndex];
                  const existingStartTimeInt = existingTimeRange.startTimeInt;
                  if (startTimeInt < existingStartTimeInt && added == false) {
                    dayAvailabilityOrdered.splice(existingIndex, 0, timeRangeObject);
                    added = true;
                  }
                }
              }
              if (added == false) {
                dayAvailabilityOrdered.push(timeRangeObject);
              }
            }
            setMatchingTimeRanges([...dayAvailabilityOrdered]);
            return dayAvailabilityOrdered;
          }
      }
    }

    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
      const validationObject = {
        lessons: [...lessonAnswers],
        validAvailability: extraProps.availability,
        sessionLength: sessionLength,
        enforceNumSessions: enforceNumSessions,
        numSessions: numSessions,
        startPointDate: startPointDate
      };
      const validationResult = validateField(validationObject, questionValidations);
      const questionHasError = validationResult.hasError;

      props.handleChange(questionID, validationObject, questionHasError);
      updateTimeOptions(blankLesson.day);
      updateMatchingTimeOptions(blankLesson.day);
    }, []);


    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }

    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>
    
    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>

    const errorText = <Text {...errorTextProps} textAlign={"center"} width={"400px"}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Define the add new item button
    let newItemButtonText = "Add lesson";
    let backgroundColor = null;
    if (lessonAnswers.length > 0) {
      newItemButtonText = "Add another weekly lesson";
    }
    if (numSessions != null) {
      if (lessonAnswers.length < numSessions) {
        backgroundColor = "#82f768";
      }
    }
    let addNewItemButton = <Button
      //When clicked, all this does is change the mode to the adding new time range item mode
      disabled={answersSuccessfullySubmitted}
      backgroundColor={backgroundColor}
      onClick={() => {
        setButtonOrLesson("lesson");
      }}
    >
      {newItemButtonText}
    </Button>
    if (numSessions != null && enforceNumSessions == true) {
      if (lessonAnswers.length >= numSessions) {
        addNewItemButton = null;
      }
    }

    //Define the cancel item button
    const cancelButton = <Button
      //When clicked, it set the mode back to button (where the button is needed to be pressed to add a new time range)
      //It also resets the currently inputted time range and errors
      isDisabled={answersSuccessfullySubmitted}
      onClick={() => {
        setButtonOrLesson("button");
        setCurrentLessonAnswer(blankLesson);

        const validationObject = {
          lessons: [...lessonAnswers],
          validAvailability: availability,
          sessionLength: sessionLength,
          enforceNumSessions: enforceNumSessions,
          numSessions: numSessions,
          startPointDate: startPointDate
        };
        const validationResult = validateField(validationObject, questionValidations);

        const existingErrors = errors;
        existingErrors.currentLessonError = true;
        //existingErrors.questionError = "";
        existingErrors.questionError = validationResult.errorMessage;
        setErrors(existingErrors);
      }}
    >
      Cancel
    </Button>

    //This function is called when the cross on an existing subject answer is pressed
    const removeLesson = function (lesson) {
      //Define a temperary new array for all the exising subject answers
      const newLessons = [];
      //Add all the existing answers to the temperary array
      newLessons.push(...lessonAnswers);

      //Find time range answer to remove and remove it
      for (let index = 0; index < newLessons.length; index = index + 1) {
        const existingLesson = newLessons[index];
        if (existingLesson == lesson) {
          newLessons.splice(index, 1);
          break
        }
      }
      //Update the main time range answers array with the updated array (with the item removed)
      setLessonAnswers(newLessons);

      const validationObject = {
        lessons: [...newLessons],
        validAvailability: availability,
        sessionLength: sessionLength,
        enforceNumSessions: enforceNumSessions,
        numSessions: numSessions,
        startPointDate: startPointDate
      };
      const validationResult = validateField(validationObject, questionValidations);
      const questionHasError = validationResult.hasError;
      setErrors({questionHasError:questionHasError, questionError:validationResult.errorMessage, currentLessonError:errors.currentLessonError})
      props.handleChange(questionID, validationObject, questionHasError);
    } 

    //Create badges for currently made times

    //Now create the actual array of badges with the correct properties so that to appear in the correct location
    //The row and column they're placed in depends on their index in the array
    const badgeArray = [];
    for (let badgeIndex = 0; badgeIndex < lessonAnswers.length; badgeIndex = badgeIndex + 1) {
      const lessonAnswer = lessonAnswers[badgeIndex];
      const answerString = lessonAnswer.day + ": " + lessonAnswer.startTime + " - " + lessonAnswer.endTime;
      const startDateObject = new Date(lessonAnswer.startDate)
      let startDateString = "First lesson: " + startDateObject.getDate() + "/" + (startDateObject.getMonth() + 1) + "/" + startDateObject.getFullYear();
      if (extraProps.showDate == false) {
        startDateString = null;
      }
      
      //Create the badge object
      const answerBadge = <Badge
        style={{
          alignItems: "center",
          marginRight: 10,
          marginTop: 10,
          backgroundColor:"#0e418f",
          color:"#e6edfa"
        }}
        key={answerString}
        size="small"
        paddingLeft={"1vw"}
        paddingRight={"1vw"}
        columnStart={(badgeIndex % 2) + 1}
        rowStart={Math.floor(badgeIndex / 2) + 1}
      >
        <Flex direction={"column"} gap={"3px"}>
          <Text color={"#e6edfa"} >{answerString}</Text>
          <Text color={"#e6edfa"}>{startDateString}</Text>
        </Flex>
        <Icon
          //This is a cross icon that when clicked removes the time range answer
          style={{
            cursor: "pointer",
            paddingLeft: 3,
            width: 20,
            height: 20,
          }}
          viewBox={{ width: 20, height: 20 }}
          paths={[
            {
              d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
              stroke: "white",
            },
          ]}
          ariaLabel="button"
          onClick={() => {
            //When clicked, call the function to remove the item
            if (answersSuccessfullySubmitted=== false) {
              removeLesson(lessonAnswer);
            }
          }}
        />
      </Badge>
      //Add the created badge to the badge array
      badgeArray.push(answerBadge)
    }

    let addButtonColour = "";
    let addButtonDisabled = true;
    if (errors.currentLessonError !== true && answersSuccessfullySubmitted === false) {
      addButtonColour = "#82f768";
      addButtonDisabled = false;
    }

    //Define the add button that submits a new answer to the subject answers
    const addButton = <Button
      backgroundColor={addButtonColour}
      key={"AddButton"}
      isDisabled={addButtonDisabled}
      onClick={() => {
        //When clicked, run the necessary validations first
        //Rerun the basic validation
        const lessonValidationObject = {
          lesson: currentLessonAnswer,
          validTimeRanges: timeRanges,
          sessionLength: sessionLength,
          enforceNumSessions: enforceNumSessions,
          numSessions: numSessions,
          startPointDate: startPointDate
        };
        const validationResultForAnswer = validateField(lessonValidationObject, lessonAnswerValidations);
        if (validationResultForAnswer.hasError != true) {
          //Create a temperary array of answers that can be used by the validation function to ensure they are valid, even with the new item
          const newLessonAnswers = [];
          newLessonAnswers.push(...lessonAnswers);
          newLessonAnswers.push(currentLessonAnswer);
          //Validate that none of the subject answers (including the new one) are for the same subject
          const validationObject = {
            lessons: [...newLessonAnswers],
            validAvailability: availability,
            sessionLength: sessionLength,
            startPointDate: startPointDate
          };
          const validationResultForAnswers = validateField(validationObject, questionValidations);

          if (validationResultForAnswers.hasError != true) {
            //If the validation was successful, update the actual subject answers with the new answer added
            setLessonAnswers(newLessonAnswers);
            //Reset the state back to 'button' for a new time range to potentially be added
            setButtonOrLesson("button");
            //Reset the current subject answer to be blank for inputting a new subject
            setCurrentLessonAnswer(blankLesson);
            updateTimeOptions(blankLesson.day);
            updateMatchingTimeOptions(blankLesson.day);
            //Reset all errors
            const validationObject2 = {
              lessons: [...newLessonAnswers],
              validAvailability: availability,
              sessionLength: sessionLength,
              enforceNumSessions: enforceNumSessions,
              numSessions: numSessions,
              startPointDate: startPointDate
            };
            const validationResultForAnswers2 = validateField(validationObject2, questionValidations);
            setErrors({questionHasError:validationResultForAnswers2.hasError, questionError:validationResultForAnswers2.errorMessage, currentLessonError:true});
            props.handleChange(questionID, validationObject, validationResultForAnswers2.hasError);
          }
          else {
            //If the validation was unsuccessful, flag an error to tell the user
            const existingErrors = errors;
            existingErrors.currentLessonError = true;
            existingErrors.questionError = validationResultForAnswers.errorMessage;
            setErrors(existingErrors);
          }
        }
        else {
          //If the validation was unsuccessful, flag an error to tell the user
          const existingErrors = errors;
          existingErrors.currentLessonError = true;
          existingErrors.questionError = validationResultForAnswer.errorMessage;
          setErrors(existingErrors);
        }
      }}
    >
      Add
    </Button>
    
    const dayDropDown = <SelectField 
      label={"Day:"}
      value={currentLessonAnswer["day"]}
      placeholder={"-Select a day-"}
      disabled={answersSuccessfullySubmitted}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentValue = e.target.value;
        let newAnswer = currentLessonAnswer;
        newAnswer["day"] = currentValue

        newAnswer["startDate"] = null;

        setCurrentLessonAnswer(newAnswer);
        const newTimeRanges = updateTimeOptions(currentValue);
        const newMatchingTimeRanges = updateMatchingTimeOptions(currentValue);
        setForceUpdate(forceUpdate + 1);
        const lessonValidationObject = {
          lesson: newAnswer,
          validTimeRanges: newTimeRanges,
          sessionLength: sessionLength,
          enforceNumSessions: enforceNumSessions,
          numSessions: numSessions,
          startPointDate: startPointDate
        };
        const validationResult = validateField(lessonValidationObject, lessonAnswerValidations);

        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;

        const newLessonAnswers = [...lessonAnswers];
        newLessonAnswers.push(currentLessonAnswer);
        const validationObject = {
          lessons: newLessonAnswers,
          validAvailability: availability,
          sessionLength: sessionLength,
          enforceNumSessions: enforceNumSessions,
          numSessions: numSessions,
          startPointDate: startPointDate
        };
        const validationResultForAnswers = validateField(validationObject, questionValidations);
        if (questionHasError === true) {
          const existingErrors = errors;
          existingErrors.currentLessonError = questionHasError;
          existingErrors.questionError = questionErrorMessage;
          setErrors(existingErrors);
        }
        else {
          const existingErrors = errors;
          existingErrors.questionError = validationResultForAnswers.errorMessage;
          existingErrors.currentLessonError = validationResultForAnswers.hasError;
          setErrors(existingErrors);
        }
      }}

    > 
      {dropDownOptions}
    </SelectField>

    const getDayValue = function (dayName) {
      try {
        if (typeof(dayName) == "number") {
            if (dayName >= 0 && dayName <= 6) {
                return dayName;
            }
            else {
                throw "Invalid day name (given out of number)";
            }
        }
        else if (typeof(dayName) == "string") {
            const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            let dayNum = null;
            for (let stringLength = 2; stringLength <= 9; stringLength = stringLength + 1) {
              for (let dayIndex = 0; dayIndex < dayNames.length; dayIndex = dayIndex + 1) {
                const day = dayNames[dayIndex];
                if (stringLength <= day.length) {
                  const daySubstring = day.substring(0, stringLength);
                  if (dayName == daySubstring) {
                    dayNum = (dayIndex + 1) % 7;
                    return dayNum;
                  }
                }
              } 
            }
            if (dayNum == null) {
              throw "Invalid day name: " + dayName;
            }
        }
        else {
          throw "Invalid type given for dayName: " + typeof(dayName);
        }
      }  
      catch (error) {
        throw "Error getting day value: " + error;
      }
    };

    const handleStartDateChange = function (questionID, answer, hasError) {
      let newDateAnswer = null;
      if (answer != null) {
        newDateAnswer = new Date(answer);
      }
      let newAnswer = currentLessonAnswer;
      newAnswer["startDate"] = newDateAnswer;

      setCurrentLessonAnswer(newAnswer);
      setForceUpdate(forceUpdate + 1);
      const lessonValidationObject = {
        lesson: newAnswer,
        validTimeRanges: timeRanges,
        sessionLength: sessionLength,
        enforceNumSessions: enforceNumSessions,
        numSessions: numSessions,
        startPointDate: startPointDate
      };
      const validationResult = validateField(lessonValidationObject, lessonAnswerValidations);
      const questionHasError = validationResult.hasError;
      const questionErrorMessage = validationResult.errorMessage;
      const newLessonAnswers = [];
      newLessonAnswers.push(...lessonAnswers);
      newLessonAnswers.push(currentLessonAnswer);
      const validationObject = {
        lessons: [...newLessonAnswers],
        validAvailability: availability,
        sessionLength: sessionLength,
        startPointDate: startPointDate
      };
      const validationResultForAnswers = validateField(validationObject, questionValidations);
      if (questionHasError === true) {
        const existingErrors = errors;
        existingErrors.currentLessonError = questionHasError;
        existingErrors.questionError = questionErrorMessage;
        setErrors(existingErrors);
      }
      else {
        const existingErrors = errors;
        existingErrors.questionError = validationResultForAnswers.errorMessage;
        existingErrors.currentLessonError = validationResultForAnswers.hasError;
        setErrors(existingErrors);
      }
    }

    let startDateRadio = null;
    if (currentLessonAnswer.day != null && currentLessonAnswer.day != "" && extraProps.showDate != false) {
      const currentDayValue = getDayValue(currentLessonAnswer.day);
      const startPointDateObject = new Date(startPointDate);
      const startPointDay = startPointDateObject.getDay();
      let daysAfterSelectedDay = ((currentDayValue - startPointDay) + 7) % 7;
      if (daysAfterSelectedDay == 0) {
        daysAfterSelectedDay = daysAfterSelectedDay + 7
      }
      
      const firstDateOption = new Date(startPointDateObject);
      firstDateOption.setDate(startPointDateObject.getDate() + daysAfterSelectedDay);
      const firstDateOptionString = firstDateOption.toDateString();
      const secondDateOption = new Date(startPointDateObject);
      secondDateOption.setDate(startPointDateObject.getDate() + daysAfterSelectedDay + 7);
      const secondDateOptionString = secondDateOption.toDateString();
      const startDateOptions = [firstDateOptionString, secondDateOptionString];
    
      startDateRadio = <RadioButtonsTemplate
        key={"startDate" + currentLessonAnswer["day"]}
        label={"Choose starting date for first lesson"}
        required={true}
        id={"startDate" + currentLessonAnswer.day}
        options={startDateOptions}
        handleChange={handleStartDateChange}
        defaultValue={firstDateOptionString}
      />
    }

    const handleTimeChange = function (questionID, input, questionHasNewError) {
      const startTime = input.startTime;
      const endTime = input.endTime;

      let newAnswer = currentLessonAnswer;
      newAnswer["startTime"] = startTime;
      newAnswer["endTime"] = endTime;

      setCurrentLessonAnswer(newAnswer);
      setForceUpdate(forceUpdate + 1);
      const lessonValidationObject = {
        lesson: newAnswer,
        validTimeRanges: timeRanges,
        sessionLength: sessionLength,
        enforceNumSessions: enforceNumSessions,
        numSessions: numSessions,
        startPointDate: startPointDate
      };
      const validationResult = validateField(lessonValidationObject, lessonAnswerValidations);

      const questionHasError = validationResult.hasError;
      const questionErrorMessage = validationResult.errorMessage;
      const newLessonAnswers = [];
      newLessonAnswers.push(...lessonAnswers);
      newLessonAnswers.push(currentLessonAnswer);
      const validationObject = {
        lessons: [...newLessonAnswers],
        validAvailability: availability,
        sessionLength: sessionLength,
        startPointDate: startPointDate
      };
      const validationResultForAnswers = validateField(validationObject, questionValidations);
      if (questionHasError === true) {
        const existingErrors = errors;
        existingErrors.currentLessonError = questionHasError;
        existingErrors.questionError = questionErrorMessage;
        setErrors(existingErrors);
      }
      else {
        const existingErrors = errors;
        existingErrors.questionError = validationResultForAnswers.errorMessage;
        existingErrors.currentLessonError = validationResultForAnswers.hasError;
        setErrors(existingErrors);
      }
    }

    const timeRangeInput = <TimeRangeTemplate 
      id={"LessonTimeRange"}
      required={true}
      handleChange={handleTimeChange}
      questionErrorProps = {errorTextProps}
    /> 
    
    const availabilityTexts = [];
    for (const timeRange of matchingTimeRanges) {
      availabilityTexts.push(
        <Text 
          {...labelTextProps} 
          fontSize={"16px"} 
          key={timeRange.startTime + timeRange.endTime}
        >
          {timeRange.startTime} - {timeRange.endTime}
        </Text>
      );
    }
    if (matchingTimeRanges.length == 0) {
      availabilityTexts.push(
        <Text 
          {...labelTextProps} 
          fontSize={"16px"}
          textAlign={"center"}
          key={"NoMatchingAvailability"}
          maxWidth={"230px"}
          alignItems={"center"}
        >
          No specified matching availability for this day
        </Text>
      );
    }
    
    let availabilityTypeName = "Matching";
    if (extraProps.matchingAvailability == null) {
      availabilityTypeName = "Given";
    }
    let availabilityFlex = <Flex direction={"column"} gap={"1vh"} maxWidth={"230px"} alignItems={"center"}> 
      <Text {...labelTextProps} fontSize={"16px"} key={"AvailabilityTextTitle"} textAlign={"center"}>
        {availabilityTypeName} availability for {currentLessonAnswer.day}:
      </Text>
      <Flex direction={"column"} gap={"0vh"}>
        {availabilityTexts}
      </Flex>
    </Flex>
    if (currentLessonAnswer.day == null || currentLessonAnswer.day == "") {
      availabilityFlex = null;
    }
    if (matchingTimeRanges.length == 0) {
      availabilityFlex = availabilityTexts;
    }

    //Depending on the current state, return the correct components
    let formItem = null
    if (buttonOrLesson == "button") {
      //If the current state is 'button', return the add new item button
      formItem = addNewItemButton;
    }
    else if (buttonOrLesson == "lesson") {
      //If the current state is 'time' (meaning a new time range is being inputted), return the time fields as well as errors and relevant buttons
      formItem = <Flex>
        <Flex direction={"column"} gap={"2vh"}>
            <Flex direction={"column"} gap={"3vh"}>
              <Flex direction={"column"} gap={"1vh"}>
                {dayDropDown}
                {startDateRadio}
              </Flex>
              <Flex direction={"column"} gap={"0px"}>
                {availabilityFlex}   
                {timeRangeInput}
              </Flex>
            </Flex>
          <Flex justifyContent={"right"}>
            {cancelButton}
            {addButton}
          </Flex>
        </Flex>
        
      </Flex>
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      lessonAnswers,
      <Flex direction="column" gap={"0vh"}>
        <Flex direction="column" marginBottom={"1vh"}>
          <Flex alignItems={"center"}>
            {renderedRequiredAsterisk}
            {labelText}
          </Flex>
          {descriptionText}
        </Flex>
        <Flex direction="column" alignItems={"center"} gap={"2vh"}>
          {formItem}
          <Flex direction="column" alignItems={"center"} position={"relative"}>
            {errorText}
          </Flex>
          <Flex direction={"column"} alignItems={"center"} gap={"0vh"}>
            {badgeArray}
          </Flex>  
        </Flex>
      </Flex>
    );
  }
  catch (error) {
    throw "CreateLessonTimeTemplate Error: " + error;
  }
}

export default CreateLessonTimeTemplate;