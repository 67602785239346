/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const parentAddStudent = /* GraphQL */ `mutation ParentAddStudent($attributes: StudentInput!) {
  parentAddStudent(attributes: $attributes) {
    userID
    updateID
    updateType
    refreshCognitoUser
    updateTime
    data
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ParentAddStudentMutationVariables,
  APITypes.ParentAddStudentMutation
>;
export const registration = /* GraphQL */ `mutation Registration(
  $registrationType: String!
  $attributes: RegistrationInput!
) {
  registration(registrationType: $registrationType, attributes: $attributes) {
    userID
    updateID
    updateType
    refreshCognitoUser
    updateTime
    data
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RegistrationMutationVariables,
  APITypes.RegistrationMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser($attributes: UpdateUserInput!) {
  updateUser(attributes: $attributes) {
    userID
    updateID
    updateType
    refreshCognitoUser
    updateTime
    data
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const logFormError = /* GraphQL */ `mutation LogFormError(
  $currentPage: String!
  $error: String!
  $answers: AWSJSON!
  $time: String!
  $userAgent: String
) {
  logFormError(
    currentPage: $currentPage
    error: $error
    answers: $answers
    time: $time
    userAgent: $userAgent
  )
}
` as GeneratedMutation<
  APITypes.LogFormErrorMutationVariables,
  APITypes.LogFormErrorMutation
>;
export const updateUserAttributes = /* GraphQL */ `mutation UpdateUserAttributes($attributes: AWSJSON!) {
  updateUserAttributes(attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.UpdateUserAttributesMutationVariables,
  APITypes.UpdateUserAttributesMutation
>;
export const updateStudentModel = /* GraphQL */ `mutation UpdateStudentModel($studentID: String, $attributes: AWSJSON!) {
  updateStudentModel(studentID: $studentID, attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.UpdateStudentModelMutationVariables,
  APITypes.UpdateStudentModelMutation
>;
export const updateTutorModel = /* GraphQL */ `mutation UpdateTutorModel($attributes: AWSJSON!) {
  updateTutorModel(attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.UpdateTutorModelMutationVariables,
  APITypes.UpdateTutorModelMutation
>;
export const createTutorRequest = /* GraphQL */ `mutation CreateTutorRequest(
  $input: CreateTutorRequestInput!
  $condition: ModelTutorRequestConditionInput
) {
  createTutorRequest(input: $input, condition: $condition) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTutorRequestMutationVariables,
  APITypes.CreateTutorRequestMutation
>;
export const updateTutorRequest = /* GraphQL */ `mutation UpdateTutorRequest(
  $input: UpdateTutorRequestInput!
  $condition: ModelTutorRequestConditionInput
) {
  updateTutorRequest(input: $input, condition: $condition) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTutorRequestMutationVariables,
  APITypes.UpdateTutorRequestMutation
>;
export const deleteTutorRequest = /* GraphQL */ `mutation DeleteTutorRequest(
  $input: DeleteTutorRequestInput!
  $condition: ModelTutorRequestConditionInput
) {
  deleteTutorRequest(input: $input, condition: $condition) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTutorRequestMutationVariables,
  APITypes.DeleteTutorRequestMutation
>;
export const createLessonRequest = /* GraphQL */ `mutation CreateLessonRequest(
  $input: CreateLessonRequestInput!
  $condition: ModelLessonRequestConditionInput
) {
  createLessonRequest(input: $input, condition: $condition) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLessonRequestMutationVariables,
  APITypes.CreateLessonRequestMutation
>;
export const updateLessonRequest = /* GraphQL */ `mutation UpdateLessonRequest(
  $input: UpdateLessonRequestInput!
  $condition: ModelLessonRequestConditionInput
) {
  updateLessonRequest(input: $input, condition: $condition) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLessonRequestMutationVariables,
  APITypes.UpdateLessonRequestMutation
>;
export const deleteLessonRequest = /* GraphQL */ `mutation DeleteLessonRequest(
  $input: DeleteLessonRequestInput!
  $condition: ModelLessonRequestConditionInput
) {
  deleteLessonRequest(input: $input, condition: $condition) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLessonRequestMutationVariables,
  APITypes.DeleteLessonRequestMutation
>;
export const createLesson = /* GraphQL */ `mutation CreateLesson(
  $input: CreateLessonInput!
  $condition: ModelLessonConditionInput
) {
  createLesson(input: $input, condition: $condition) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLessonMutationVariables,
  APITypes.CreateLessonMutation
>;
export const updateLesson = /* GraphQL */ `mutation UpdateLesson(
  $input: UpdateLessonInput!
  $condition: ModelLessonConditionInput
) {
  updateLesson(input: $input, condition: $condition) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLessonMutationVariables,
  APITypes.UpdateLessonMutation
>;
export const deleteLesson = /* GraphQL */ `mutation DeleteLesson(
  $input: DeleteLessonInput!
  $condition: ModelLessonConditionInput
) {
  deleteLesson(input: $input, condition: $condition) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLessonMutationVariables,
  APITypes.DeleteLessonMutation
>;
export const createIssues = /* GraphQL */ `mutation CreateIssues(
  $input: CreateIssuesInput!
  $condition: ModelIssuesConditionInput
) {
  createIssues(input: $input, condition: $condition) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIssuesMutationVariables,
  APITypes.CreateIssuesMutation
>;
export const updateIssues = /* GraphQL */ `mutation UpdateIssues(
  $input: UpdateIssuesInput!
  $condition: ModelIssuesConditionInput
) {
  updateIssues(input: $input, condition: $condition) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIssuesMutationVariables,
  APITypes.UpdateIssuesMutation
>;
export const deleteIssues = /* GraphQL */ `mutation DeleteIssues(
  $input: DeleteIssuesInput!
  $condition: ModelIssuesConditionInput
) {
  deleteIssues(input: $input, condition: $condition) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIssuesMutationVariables,
  APITypes.DeleteIssuesMutation
>;
