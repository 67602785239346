import { Button, Flex, Text } from '@aws-amplify/ui-react';
import {useLocation, Navigate} from "react-router-dom";
import {React, useState, useEffect } from 'react';
import { FormTemplate } from '../../custom-ui-components';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { compareObjects, subjects } from 'nextdoortutor';

const EditLessonRequest = function(props) {
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [deletingLessonRequest, setDeletingLessonRequest] = useState(false);
    const [deletedLessonRequest, setDeletedLessonRequest] = useState(false);
    const [defaultAvailabilityPopUp1, setDefaultAvailabilityPopUp1] = useState(false);
    const [defaultAvailabilityPopUp2, setDefaultAvailabilityPopUp2] = useState(null);
    const [defaultAvailabilityPopUp3, setDefaultAvailabilityPopUp3] = useState(null);

    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userAttributes = user.attributes;
    const userAddressString = userAttributes["custom:addressList"] || "[]";
    const userAddressList = JSON.parse(userAddressString);
    const studentModel = props.studentModel;

    const location = useLocation();
    const extraProps = location.state;
    if (extraProps == null) {
        return <Navigate to={"/Student/LessonRequests"} />
    }
    const lessonRequestProps = extraProps.lessonRequestProps;

    if (studentModel == null) {
        return <LoadingSpinnerPage {...props} />
    }
    if (studentModel == "error") {
        return <ErrorLoadingPage {...props}/>
    }
    

    const defaultAvailability = studentModel.defaultAvailability;

    const editLessonRequestTitle = <Text {...titleTextProps} >
        DOESNT WORK!!!!!!! Edit lesson request
    </Text>

    const deleteLessonRequestButton = <Button 
        width={"fit-content"}
        onClick={ () => {
            setDeletePopUp(true);
        }}
    >
        Delete Lesson Request
    </Button>

    const submitAnswers = async function(returnedAnswers) {
        try {
            setQuestionAnswers(returnedAnswers);
            const submitionAnswers = {...returnedAnswers};
            const comparisonLessonRequest = {...lessonRequestProps};
            delete comparisonLessonRequest.createdAt;
            delete comparisonLessonRequest.__typename;
            delete comparisonLessonRequest._deleted;
            delete comparisonLessonRequest._lastChangedAt;
            delete comparisonLessonRequest.updatedAt;
            delete comparisonLessonRequest._version;
            delete comparisonLessonRequest.studentModel;

            if (returnedAnswers.lessonType != "Online") {
                if (returnedAnswers.address.label == "New Address") {
                    let addressAlreadyFound = false;
                    for (const existingAddress of userAddressList) {
                        const existingPostcode = existingAddress.postcode;
                        const newPostcode = submitionAnswers.address.postcode;
                        if (newPostcode == existingPostcode) {
                            addressAlreadyFound = true;
                            console.log("Found matching existing address");
                        }
                    }
                    if (addressAlreadyFound == false) {
                        delete returnedAnswers.address.label;
                        const newAddress = {address1stLine: returnedAnswers.address.address1stLine, postcode: returnedAnswers.address.postcode};
                        const newAddressList = [...userAddressList];
                        newAddressList.push(newAddress);
                        const newAddressListAttribute = {Name:"addressList", Value:newAddressList};

                        const updatedAttributes = await APIFunctions.updateUserAttributes(newAddressListAttribute);
                        let updatedAddressList = updatedAttributes[0]["custom:addressList"];
                        try {
                            updatedAddressList = JSON.parse(updatedAddressList);
                        } catch {}
                        for (const userAddress of updatedAddressList) {
                            const userAddress1stLine = userAddress.address1stLine;
                            const userPostcode = userAddress.postcode;
                            if (userAddress1stLine == newAddress.address1stLine && userPostcode == newAddress.postcode) {
                                const returnedGeocode = userAddress.geocode;
                                returnedAnswers.address.geocode = returnedGeocode;
                                break;
                            }
                        }
                    }
                }
                else {
                    delete returnedAnswers.address.label;
                }
            }

            const newLessonRequest = {};
            newLessonRequest.id = lessonRequestProps.id;
            newLessonRequest.studentID = lessonRequestProps.studentID;
            newLessonRequest.subject = returnedAnswers.subject;
            newLessonRequest.lessonType = returnedAnswers.lessonType;
            newLessonRequest.sessionLength = returnedAnswers.sessionLength;
            newLessonRequest.numSessions = returnedAnswers.numSessions;
            newLessonRequest.schoolSet = returnedAnswers.schoolSet || null;
            newLessonRequest.availability = returnedAnswers.availability;
            
            if (returnedAnswers.lessonType != "Online") {
                newLessonRequest.address = {
                    address1stLine: returnedAnswers.address.address1stLine,
                    postcode: returnedAnswers.address.postcode,
                    geocode: returnedAnswers.address.geocode
                }
            }
            else {
                newLessonRequest.address = null;
            }

            if (!compareObjects(comparisonLessonRequest, newLessonRequest)) {
                const newLessonRequestModel = await APIFunctions.updateLessonRequest(newLessonRequest, "student");
                console.log(newLessonRequestModel);
            }
            else {
                throw "No change to lesson request"
            }
        }
        catch (error) {
            console.log(error);
            if (error == "No change to lesson request") {
                throw error;
            }
            throw {
                message: "Error updating lesson request",
                error: error
            };
        }
    }

    const exitForm = function () {
        console.log("Exiting form");
        setRedirect(<Navigate to={"/Student/LessonRequests"} />);
    }

    const askDefaultAvailability = async function() {
        console.log("Checking default availability");
        if (!compareObjects(defaultAvailability, questionAnswers.availability )) {
            setDefaultAvailabilityPopUp1(true);
        }
        else {
            exitForm();
        }
    }

    const editLessonRequestForm = function () {
        const questions = [];
    
        questions.push({
            id: "subject", 
            label: "Subject:", 
            type: "dropdown",
            options: [...subjects],
            required: true, 
            validations: [{type: "ValidSubject"}], 
            defaultValue: lessonRequestProps.subject, 
        });

        questions.push({
            id: "sessionLength", 
            label: "Session Length:", 
            type: "dropdown",
            options: ["2 Hours", "1 Hour", "1 Hour 30 Minutes", "45 Minutes", "30 Minutes"],
            required: true, 
            defaultValue: lessonRequestProps.sessionLength, 
        });

        questions.push({
            id: "numSessions", 
            label: "Number of lessons per week:", 
            type: "dropdown",
            options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            required: true, 
            defaultValue: lessonRequestProps.numSessions,
        });

        questions.push({
            id: "studentSubjectSet", 
            label: "Current school set for subject:",
            description: "What level is the student currently at",
            type: "text",
            required: false,
            defaultValue: lessonRequestProps.schoolSet, 
        });
        
        questions.push({
            id: "lessonType", 
            label: "Lesson Type:",
            type: "radio",
            options: ["In-Person", "Online", "Either"],
            required: true, 
            defaultValue: lessonRequestProps.lessonType,
        });

        const addressOptions = [...userAddressList];
        let addressDefault = null;
        try {
            const defaultAddress1stLine = lessonRequestProps.address.address1stLine;
            const defaultPostcode = lessonRequestProps.address.postcode;
            const defaultGeocode = lessonRequestProps.address.geocode;
            addressDefault = {label: defaultPostcode, address1stLine: defaultAddress1stLine, postcode: defaultPostcode, geocode: defaultGeocode};
        } catch {}
        questions.push({
            id: "address", 
            label: "Lesson address:", 
            type: "existingornewaddress", 
            required: true, 
            defaultValue: addressDefault, 
            options: addressOptions || [],
            onlyShow: {id:"lessonType", answers:["In-Person", "Either"]}
        });
        
        questions.push({
            id: "availability", 
            label: "Availability for lesson:", 
            type: "weekavailability", 
            required: true,
            defaultValue: lessonRequestProps.availability
        });
    
        const lessonRequestFormTemplate = <FormTemplate {...props} submitAnswers={submitAnswers} existingAnswers={questionAnswers} questions={questions} okButtonFunction={askDefaultAvailability} />
        return lessonRequestFormTemplate;
    }

    const deleteLessonRequest = async function () {
        console.log("Deleting");
        setDeletingLessonRequest(true);
        const newLessonRequest = {};
        newLessonRequest.id = lessonRequestProps.id;
        newLessonRequest.deleted = true;
        console.log(newLessonRequest);
        const newLessonRequestModel = await APIFunctions.updateLessonRequest(newLessonRequest, "student");
        console.log(newLessonRequestModel);
        setDeletingLessonRequest(false);
        setDeletedLessonRequest(true);
    }

    
    const deleteText = "Are you sure you want to delete this lesson?";
    const deletePopUpComponent = <PopUpTemplate text={deleteText} setPopUpVisibility={setDeletePopUp} yesButtonFunction={deleteLessonRequest} noButtonFunction={function(){}} showXButton={true} />
    const deletingText = "Deleting lesson...";
    const deletingLessonPopUp = <PopUpTemplate text={deletingText} setPopUpVisibility={setDeletingLessonRequest} showXButton={false} />
    const deletedLessonText = "Lesson deleted";
    const deletedLessonPopUp = <PopUpTemplate text={deletedLessonText} okButtonFunction={exitForm} setPopUpVisibility={setDeletedLessonRequest} showXButton={false} />

    const defaultAvailabilityPopUpText = "Would you like to make this your default availability?";
    const yesButtonFunction = async function () {
        console.log("Updating model");
        setDefaultAvailabilityPopUp2("Updating default availability...");
        const newModel = await APIFunctions.updateStudentModel({"defaultAvailability": questionAnswers.availability});
        console.log(newModel);
        setDefaultAvailabilityPopUp2(null)
        setDefaultAvailabilityPopUp3("Default availability updated");
    }
    const noButtonFunction = function () {
        console.log("no");
        exitForm()
    }

    const defaultAvailabilityPopUpObject1 = <PopUpTemplate 
        yesButtonFunction={yesButtonFunction} 
        noButtonFunction={noButtonFunction} 
        text={defaultAvailabilityPopUpText} 
        setPopUpVisibility={setDefaultAvailabilityPopUp1} 
        showXButton={false} 
    />

    const defaultAvailabilityPopUpObject2 = <PopUpTemplate 
        text={defaultAvailabilityPopUp2} 
        setPopUpVisibility={setDefaultAvailabilityPopUp2} 
        showXButton={false} 
    />

    const defaultAvailabilityPopUpObject3 = <PopUpTemplate 
        okButtonFunction={exitForm} 
        text={defaultAvailabilityPopUp3} 
        setPopUpVisibility={setDefaultAvailabilityPopUp3} 
        showXButton={false} 
    />
    
    return (
        <div className='AdminEditLessonPage'>
            <Flex direction={"column"} alignItems={"center"} gap={"4vh"}>
                {redirect}
                {editLessonRequestTitle}
                {deleteLessonRequestButton}
                {editLessonRequestForm()}
                {deletePopUp && deletePopUpComponent}
                {deletingLessonRequest && deletingLessonPopUp}
                {deletedLessonRequest && deletedLessonPopUp}
                {defaultAvailabilityPopUp1 && defaultAvailabilityPopUpObject1}
                {(defaultAvailabilityPopUp2 != null) && defaultAvailabilityPopUpObject2}
                {(defaultAvailabilityPopUp3 != null) && defaultAvailabilityPopUpObject3}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default EditLessonRequest;