import { Flex, Text } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { AppNavButton, FormTemplate } from '../../custom-ui-components';
import { Navigate, useLocation } from 'react-router-dom';
import { throwError } from '../../App';
import { LoadingSpinnerPage } from '../LoadingPage';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { compareObjects } from 'nextdoortutor';

const TutorChangeSubjects = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const userModel = props.userModel;
    const tutorQualifications = userModel.tutorQualificationList;
    const APIFunctions = props.APIFunctions;

    const location = useLocation();
    const state = location.state;

    const [redirect, setRedirect] = useState(null);

    if (userModel == null) {
        return <LoadingSpinnerPage />
    }
    if (userModel == "error") {
        return <ErrorLoadingPage />
    }   

    const titleText = <Text {...titleTextProps}>Add/Remove Teaching Subjects</Text> 

    const questions = [];
    questions.push({
        id: "qualifications", 
        label: "Subjects you wish to teach:",
        type: "tutorqualifications",
        required: false, 
        defaultValue: tutorQualifications,
        tutorEducation: userModel.tutorEducationList,
        APIFunctions: APIFunctions,
    });
    
    const exitForm = function () {
        let redirectLink = "/Tutor";
        if (state != null && state.back != null) {
            redirectLink = state.back;
        }
        setRedirect(<Navigate to={redirectLink} />);
    };

    const submitAnswers = async function(returnedAnswers) {
        const newQualifications = returnedAnswers.qualifications;
        const anyChange = !compareObjects(newQualifications.answers, userModel.tutorQualification || []);
        if (anyChange == false) {
            throwError("No change to subjects", null, 204);
        }
        await APIFunctions.updateTutorModel({
            updateQualifications: newQualifications
        });
    };

    const subjectsForm = <FormTemplate 
        {...props} 
        submitAnswers={submitAnswers} 
        questions={questions} 
        okButtonFunction={"reset"} 
        submitPopUpText={"Successfully updated subjects"} 
    />

    const backButton = <AppNavButton text={"Back"} onClickFunction={exitForm} width={props.width} />

    return (
        <div className='TutorChangeSubjectsPage'>
            <Flex direction={"column"} alignItems={"center"}>
                {redirect}
                {titleText}
                <Flex>
                    {backButton}
                </Flex>
                {subjectsForm}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorChangeSubjects;