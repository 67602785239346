import React from "react";
import { Link, Navigate } from "react-router-dom";
import { Button, Flex, Grid, Text } from "@aws-amplify/ui-react";
import { AppNavButton } from "../../custom-ui-components";
import { subjects } from "nextdoortutor";


const TeachingResourcesSubject = function(props) {
  const height = props.height;
  const titleTextProps = props.titleTextProps;
  const standardTextProps = props.standardTextProps;
  const teachingResources = props.teachingResources;
  const userIsTutor = props.userIsTutor;
  const userIsAdmin = props.userIsAdmin;
  const userType = props.userType;

  if (userType == "tutor" && !userIsTutor) {
    return <Navigate to="/" /> 
  }
  else if (userType == "admin" && !userIsAdmin) {
    return <Navigate to="/" /> 
  }
  else if (userType != "tutor" && userType != "admin") {
    return <Navigate to="/" /> 
  }


  const titleText = <Text {...titleTextProps}>Teaching Resources for Tutors</Text>

  const descriptionText = <Text {...standardTextProps}>Choose subject:</Text>

  const subjectButtons = [];
  let link = "/Tutor/TeachingResourcesYearGroup"
  if (userType == "admin") {
    link = "/Admin/TeachingResourcesYearGroup"
  }
  for (const subject of subjects) {
    const subjectButton = <Link to={link} style={{textDecoration: 'none'}} key={"Subject:" + subject} state={{subject: subject}}><AppNavButton text={subject}/></Link>
    subjectButtons.push(subjectButton);
  }

  let backButtonlink = "/Tutor"
  if (userType == "admin") {
    backButtonlink = "/Admin"
  }
  const backButton = <Link to={backButtonlink} style={{textDecoration: 'none'}} key={"Back button"}><AppNavButton text={"Back"}/></Link>

  return (
      <Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
        <Flex direction={"column"} gap={"1vh"} alignItems={"center"}>
          {titleText}
          {backButton}
        </Flex>
        {descriptionText}
        <Flex direction={"row"} alignItems={"center"} justifyContent={"center"} wrap={"wrap"} width={"80vw"} rowGap={"5vh"} columnGap={"5vw"}>
          {subjectButtons}
        </Flex>
      </Flex>
  );
}

export default TeachingResourcesSubject;