import { Button, CheckboxField, Divider, Flex, Text } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';
import {Link, Navigate, useLocation} from "react-router-dom";
import {AppNavButton, FormTemplate, GuideStepTemplate, FooterTemplate} from '../../custom-ui-components'
import { useState } from 'react';

const ParentStartGuide = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const bulletFontSize = (standardTextProps.fontSize.split("px")[0] * 0.8) + "px";
    const bulletTextProps = {...standardTextProps, textAlign: "left", fontSize: bulletFontSize};
    //Get the current user to pass to subsequent functions
    const user = props.user;

    const userModel = props.userModel;
    const lessons = props.parentLessons;
    const lessonRequests = props.parentLessonRequests;
    const transactions = props.userTransactions;
    const standardWidth = "90vw";
    const [stepsUI, setStepsUI] = useState(null);
    const [stepsComplete, setStepsComplete] = useState({});

    //Step 1: Sign up
    const [signUpUI, setSignUpUI] = useState(null);
    useEffect(() => {
        const signedUp = true;
        if (stepsComplete.signUp != signedUp) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.signUp = signedUp;
            setStepsComplete(newStepsComplete);
        }

        setSignUpUI(<GuideStepTemplate
            {...props}
            stepComplete={signedUp} 
            stepNumber={1} 
            link={"/"} 
            stepText={"Sign up to Next Door Tutor"} 
            moreDetailsFlex={null} 
            detailsAfterComplete={false}
        />);
    }, [standardTextProps, stepsComplete]);

    //Step 2: Register as a parent
    const [registerUI, setRegisterUI] = useState(null);
    useEffect(() => {
        const registered = userModel != null && userModel.isParent == true;
        if (stepsComplete.registered != registered) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.registered = registered;
            setStepsComplete(newStepsComplete);
        }

        const moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...standardTextProps} alignSelf={"flex-start"} textAlign={"left"}>In order to register as a parent, all you need to do is provide us with your:</Text>
            <Text {...bulletTextProps}>
                <li>First and last name</li>
                <li>UK mobile contact number (this is only used for emergency short notice notifications)</li>
                <li>How you found out about Next Door Tutor</li>
                <li>Consent to our terms of use</li>
            </Text>
            <Flex alignSelf={"center"}>
                <Link to={"/ParentRegister"}><Button >Parent Registration Page</Button></Link>
            </Flex>
        </Flex>

        setRegisterUI(<GuideStepTemplate
            {...props}
            stepComplete={registered} 
            stepNumber={2} 
            link={"/ParentRegister"} 
            stepText={"Register as a parent"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={false}
        />);

    }, [userModel, standardTextProps, stepsComplete]);


    //Step 3: Add a child
    const [addChildUI, setAddChildUI] = useState(null);
    useEffect(() => {
        const childAdded = userModel != null && userModel.studentList != null && userModel.studentList.length > 0;
        if (stepsComplete.childAdded != childAdded) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.childAdded = childAdded;
            setStepsComplete(newStepsComplete);
        }

        const registered = stepsComplete.registered;
        let button = null;
        if (registered) {
            button = <Flex alignSelf={"center"}>
                <Link to={"/Parent/AddNewStudent"}><Button >Add a child</Button></Link>
            </Flex>
        }

        let moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...bulletTextProps}>In order to add a child to your account, all you need to do is provide us with their name and school year group</Text>
            <Text {...bulletTextProps}>You can add as many children as you'd like to your account</Text>
            {button}
        </Flex>;

        setAddChildUI(<GuideStepTemplate
            {...props}
            stepComplete={childAdded} 
            stepNumber={3} 
            link={registered && "/Parent/AddNewStudent" || null} 
            stepText={"Add a child"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={true}
        />);
    }, [userModel, standardTextProps, stepsComplete]);

    //Step 4: Create a lesson request
    const [createRequestUI, setCreateRequestUI] = useState(null);
    useEffect(() => {
        const requestMade = lessonRequests != null && lessonRequests.length > 0;
        if (stepsComplete.requestMade != requestMade) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.requestMade = requestMade;
            setStepsComplete(newStepsComplete);
        }

        const registered = stepsComplete.registered;
        let button = null;
        if (registered) {
            button = <Flex alignSelf={"center"}>
                <Link to={"/Parent/NewLessonRequest"}><Button >Create a lesson request</Button></Link>
            </Flex>
        }

        let moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...standardTextProps}>Creating a lesson request will require you to specify:</Text>
            <Text {...bulletTextProps}>
                <li>Which of your registered children the lesson is for (can be more than 1)</li>
                <li>The subject to be taught in the lesson (please create multiple lesson requests if multiple subjects are needed)</li>
                <li>The length of the lesson (45 mins, 1 hour, 1 hour 30 mins or 2 hours)</li>
                <li>The number of lessons you'd like for that subject per week</li>
                <li>Whether you want the lesson to be in-person or online</li>
                <li>Your address for the tutor to teach at (if in-person lessons are selected)</li>
                <li>The student's weekly availability</li>
                <li>The date for when you'd like the lesson to first take place</li>
                <li>Whether you'd prefer an A Level or University student to be your tutor (prices may differ)</li>
            </Text>
            {button}
        </Flex>;

        setCreateRequestUI(<GuideStepTemplate
            {...props}
            stepComplete={requestMade} 
            stepNumber={4} 
            link={registered && "/Parent/NewLessonRequest" || null} 
            stepText={"Create a lesson request"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={true}
        />);
    }, [lessonRequests, standardTextProps, stepsComplete]);

    //Step 5: Wait for a lesson
    const [waitForLessonUI, setWaitForLessonUI] = useState(null);
    useEffect(() => {
        const lessonMade = lessons != null && lessons.length > 0;
        if (stepsComplete.lessonMade != lessonMade) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.lessonMade = lessonMade;
            setStepsComplete(newStepsComplete);
        }

        const registered = stepsComplete.registered;
        let button = null;
        if (registered) {
            button = <Flex alignSelf={"center"}>
                <Link to={"/Parent/LessonRequests"}><Button >View lesson requests</Button></Link>
            </Flex>
        }

        let moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...bulletTextProps}>We start this process as soon as you've submitted a lesson request</Text>
            <Text {...bulletTextProps}>We'll find the best tutor and send them the teaching opportunity</Text>
            <Text {...bulletTextProps}>Once they've accepted, you'll be notified of the lesson details</Text>
            <Text {...bulletTextProps}>This process can sometimes take a few days if tutors are unresponsive, but often happens the same day!</Text>
            {button}
        </Flex>;

        setWaitForLessonUI(<GuideStepTemplate
            {...props}
            stepComplete={lessonMade} 
            stepNumber={5} 
            link={null} 
            stepText={"Wait for the best tutor to be found for your lesson"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={true}
        />);
    }, [lessons, standardTextProps, stepsComplete]);

    //Step 6: Buy credit
    const [buyCreditUI, setBuyCreditUI] = useState(null);
    useEffect(() => {
        const creditBought = transactions != null && transactions.length > 0;
        if (stepsComplete.creditBought != creditBought) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.creditBought = creditBought;
            setStepsComplete(newStepsComplete);
        }

        const registered = stepsComplete.registered;
        let button = null;
        if (registered) {
            button = <Flex alignSelf={"center"}>
                <Link to={"/LessonCredits"}><Button >Buy lesson credit</Button></Link>
            </Flex>
        }

        let moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...bulletTextProps}>In order to pay for your lessons, credit is bought and stored on your account</Text>
            <Text {...bulletTextProps}>Credit is then deducted from your account after each lesson and paid to the tutor</Text>
            <Text {...bulletTextProps}>Please try to ensure you have enough credit in your account before each lesson (reminders will be emailed beforehand)</Text>
            <Text {...bulletTextProps}>We partner with Stripe for payment processing</Text>
            {button}
        </Flex>;

        setBuyCreditUI(<GuideStepTemplate
            {...props}
            stepComplete={creditBought} 
            stepNumber={6} 
            link={registered && "/LessonCredits" || null} 
            stepText={"Buy lesson credit"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={true}
        />);
    }, [transactions, standardTextProps, stepsComplete]);

    //Step 7: Manage reoccurring lesson
    const [manageLessonUI, setManageLessonUI] = useState(null);
    useEffect(() => {
        let managedLesson = false;
        if (lessons != null && lessons.length > 0) {
            for (const lesson of lessons) {
                if (lesson.amendments != null && lesson.amendments.length > 0) {
                    for (const amendment of lesson.amendments) {
                        const requestedBy = amendment.requestedBy;
                        if (requestedBy != null && requestedBy.userID == user.username) {
                            managedLesson = true;
                        }
                    }
                }
            }
        }

        if (stepsComplete.managedLesson != managedLesson) {
            const newStepsComplete = {...stepsComplete};
            newStepsComplete.managedLesson = managedLesson;
            setStepsComplete(newStepsComplete);
        }

        const registered = stepsComplete.registered;
        let button = null;
        if (registered) {
            button = <Flex alignSelf={"center"}>
                <Link to={"/Parent/AmendLessons"}><Button >Make an amendment to a lesson</Button></Link>
            </Flex>
        }

        let moreDetailsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...bulletTextProps}>Once you have been assigned a lesson, make sure to keep on top of when the next lesson will be</Text>
            <Text {...bulletTextProps}>Without any changes, the lesson will be set to keep happening every week</Text>
            <Text {...bulletTextProps}>You can make the following amendments to a lesson (some need to be approved by the tutor):</Text>
            <Text {...bulletTextProps}>
                <li>Cancel a single lesson</li>
                <li>Request an extra one of session with the tutor</li>
                <li>Request to change the weekly day/time of the lesson</li>
                <li>Change the lesson to online or request to change it to in-person</li>
                <li>Request an extra weekly lesson</li>
                <li>Set the final date for the last lesson</li>
                <li>Cancel all future lessons</li>
            </Text>
            {button}
        </Flex>;

        setManageLessonUI(<GuideStepTemplate
            {...props}
            stepComplete={managedLesson} 
            stepNumber={7} 
            link={registered && "/Parent/AmendLessons" || null} 
            stepText={"Manage your reoccurring lesson(s)"} 
            moreDetailsFlex={moreDetailsFlex} 
            detailsAfterComplete={true}
        />);
    }, [lessons, standardTextProps, stepsComplete]);

    //Progress bar
    const [progressBarUI, setProgressBarUI] = useState(null);
    useEffect(() => {
        const stepsCompleteArray = Object.values(stepsComplete);
        const totalSteps = stepsCompleteArray.length;
        let totalStepsComplete = 0;
        for (const step of stepsCompleteArray) {
            if (step == true) {
                totalStepsComplete = totalStepsComplete + 1;
            }
        }
        const competionPercent = Math.round((totalStepsComplete / totalSteps) * 100) + "%";

        setProgressBarUI(
            <Flex 
                marginTop={"5vh"}
                marginBottom={"5vh"}
                width={"80vw"} 
                height={"35px"} 
                backgroundColor={"#dddddd"}
                alignItems={"center"}
                borderRadius={"1vh"}
                position={"relative"}
                direction={"column"}
                justifyContent={"center"}
                alignSelf={"center"}
            >
                <Flex 
                    width={competionPercent} 
                    height={"100%"}
                    borderRadius={"1vh"}
                    position={"absolute"}
                    left={"0px"}
                    top={"0px"}
                    backgroundColor={"#00ff00"} 
                />
                <Flex 
                    width={"100%"} 
                    height={"100%"}
                    position={"absolute"}
                    left={"0px"}
                    top={"0px"}
                    style={{borderStyle: "solid", borderWidth: "2px", borderColor: "#000000"}}
                    borderRadius={"1vh"}
                />
                <Text {...standardTextProps} fontSize={"15px"} position={"absolute"} alignSelf={"center"}>{competionPercent} Complete</Text>
            </Flex>
        );

    }, [stepsComplete]);

    //Extra info
    const [extraInfoUI, setExtraInfoUI] = useState(null);
    useEffect(() => {
        const registered = stepsComplete.registered;
        let messageText = "in-app messaging service"
        let parentDashboardText = "Parent Dashboard";
        if (registered) {
            messageText = <Link to={"/Messages"}>{messageText}</Link>
            parentDashboardText = <Link to={"/Parent"}>{parentDashboardText}</Link>
        }

        setExtraInfoUI(
        <Flex direction={"column"} alignItems="flex-start" gap="1vh" maxWidth={standardWidth}>
            <Text {...standardTextProps}>Further steps:</Text>
            <ul>
                <li><Text {...bulletTextProps}>Try messaging your tutor(s) using our {messageText}</Text></li>
                <li><Text {...bulletTextProps}>Access everything you need to manage your lessons from the {parentDashboardText}</Text></li>
            </ul>
        </Flex>);
    }, [standardTextProps, stepsComplete]);

    useEffect(() => {
        const stepsFlex = <Flex direction={"column"} alignItems="flex-start" gap="1vh">
            <Divider minWidth={standardWidth}/>
            {signUpUI}
            <Divider minWidth={standardWidth}/>
            {registerUI}
            <Divider minWidth={standardWidth}/>
            {addChildUI}
            <Divider minWidth={standardWidth}/>
            {createRequestUI}
            <Divider minWidth={standardWidth}/>
            {waitForLessonUI}
            <Divider minWidth={standardWidth}/>
            {buyCreditUI}
            <Divider minWidth={standardWidth}/>
            {manageLessonUI}
            <Divider minWidth={standardWidth}/>
            {progressBarUI}
            <Divider minWidth={standardWidth}/>
            {extraInfoUI}
        </Flex>
        setStepsUI(stepsFlex);
    }, [signUpUI, registerUI, addChildUI, createRequestUI, waitForLessonUI, buyCreditUI, manageLessonUI, progressBarUI, extraInfoUI]);

    const titleText = <Text {...titleTextProps}>Guide for Parents</Text>;

    return (  
        <Flex direction={"column"} justifyContent="center" alignItems="center" gap="1vh">
            {titleText}
            {stepsUI}
        </Flex>
    );
}


//Return the correct page back up to the app and index files (with authenticator to force login)
export default ParentStartGuide;