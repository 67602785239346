import {React, useState, useEffect } from 'react';
import { FormTemplate } from '../../custom-ui-components';
import { Link, Navigate } from 'react-router-dom';
import { calculateLessonCost, updateUserAttributesAPI } from '../../App';
import { LoadingSpinnerPage } from '../LoadingPage';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { compareObjects, subjects } from 'nextdoortutor';

const NewLessonRequest = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userAttributes = user.attributes;
    const userAddressString = userAttributes["custom:addressList"] || "[]";
    const userAddressList = JSON.parse(userAddressString);
    const studentModel = props.studentModel;
    

    const titleText = <Text {...titleTextProps}>Make a request for a new lesson</Text>

    const [questionAnswers, setQuestionAnswers] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [defaultAvailabilityPopUp1, setDefaultAvailabilityPopUp1] = useState(false);
    const [defaultAvailabilityPopUp2, setDefaultAvailabilityPopUp2] = useState(null);
    const [defaultAvailabilityPopUp3, setDefaultAvailabilityPopUp3] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [existingLessonRequests, setExistingLessonRequests] = useState(null);
    const [bypassAvailabilitySanityCheck, setBypassAvailabilitySanityCheck] = useState({
        bypass: false,
        previousAvailability: null
    });
    const [pricingEstimate, setPricingEstimate] = useState(null);
    const [showPricingEstimate, setShowPricingEstimate] = useState(true);

    useEffect(() => {
        const minCostAnswers = {...questionAnswers};
        const maxCostAnswers = {...questionAnswers};
        if (minCostAnswers.sessionLength == null || minCostAnswers.sessionLength == "") {
            minCostAnswers.sessionLength = "1 Hour";
        }
        if (maxCostAnswers.sessionLength == null || maxCostAnswers.sessionLength == "") {
            maxCostAnswers.sessionLength = "1 Hour";
        }
        if (minCostAnswers.numSessions == null || minCostAnswers.numSessions == "") {
            minCostAnswers.numSessions = 1;
        }
        if (maxCostAnswers.numSessions == null || maxCostAnswers.numSessions == "") {
            maxCostAnswers.numSessions = 1;
        }
        if (minCostAnswers.lessonType == null || minCostAnswers.lessonType == "Either" || minCostAnswers.lessonType == "") {
            minCostAnswers.lessonType = "Online";
        }
        if (maxCostAnswers.lessonType == null || maxCostAnswers.lessonType == "Either" || maxCostAnswers.lessonType == "") {
            maxCostAnswers.lessonType = "In-Person";
        }

        const aLevelMinCost = calculateLessonCost("A-Level", null, null, minCostAnswers.lessonType, true, minCostAnswers.sessionLength);
        const aLevelMaxCost = calculateLessonCost("A-Level", null, null, maxCostAnswers.lessonType, true, maxCostAnswers.sessionLength);
        const universityMinCost = calculateLessonCost("University", null, null, minCostAnswers.lessonType, true, minCostAnswers.sessionLength);
        const universityMaxCost = calculateLessonCost("University", null, null, maxCostAnswers.lessonType, true, maxCostAnswers.sessionLength);

        let lessonLengthString = minCostAnswers.sessionLength.toLowerCase();
        if (lessonLengthString[lessonLengthString.length - 1] == "s") {
            lessonLengthString = lessonLengthString.substring(0, lessonLengthString.length - 1);
        }

        let lessonTypeText = <Text {...standardTextProps} textAlign={"center"} fontSize={"16px"} alignSelf={"center"}>(Online/In-Person)</Text>;
        let aLevelPriceText = "A-Level tutor: " + aLevelMinCost + "/" + aLevelMaxCost;
        if (aLevelMinCost == aLevelMaxCost) {
            aLevelPriceText = "A-Level tutor: " + aLevelMinCost;
        }
        let universityPriceText = "University tutor: " + universityMinCost + "/" + universityMaxCost;
        if (universityMinCost == universityMaxCost) {
            universityPriceText = "University tutor: " + universityMinCost;
        }
        if (aLevelMinCost == aLevelMaxCost && universityMinCost == universityMaxCost) {
            lessonTypeText = <Text {...standardTextProps} textAlign={"center"} fontSize={"16px"} alignSelf={"center"}>({minCostAnswers.lessonType})</Text>;
        }

        let flexContents = <Flex 
            alignItems={"center"}
            gap={"0px"}
            minHeight={"50px"}
            padding={"10px"}
            height={"100%"}
            onClick={() => {
                setShowPricingEstimate(true);
            }}
            style={{cursor: "pointer"}}
        >   
            <Flex minHeight={"50px"} alignItems={"center"} gap={"0px"}>
                <Icon 
                    as={MdArrowBackIos}
                />
                <Text fontSize={"20px"}>£</Text>
            </Flex>
        </Flex>

        if (showPricingEstimate) {
            flexContents = <Flex
                alignItems={"center"}
            >   
                <Flex
                    onClick={() => {
                        setShowPricingEstimate(false);
                    }}
                    style={{cursor: "pointer"}}
                    height={"100%"}
                    minWidth={"30px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    marginRight={"-10px"}
                >
                    <Icon 
                        as={MdArrowForwardIos}
                    />
                </Flex>
                <Flex direction={"column"} gap={"0px"} padding={"10px"} paddingRight={"2vw"}>
                    <Text {...standardTextProps} textAlign={"left"} fontSize={"18px"} alignSelf={"center"}>Cost per {lessonLengthString} lesson:</Text>
                    {lessonTypeText}
                    <Text {...standardTextProps} textAlign={"left"} fontSize={"16px"}>{aLevelPriceText}</Text>
                    <Text {...standardTextProps} textAlign={"left"} fontSize={"16px"}>{universityPriceText}</Text>
                </Flex>
            </Flex>
        }
        
        setPricingEstimate(<Flex 
            position={"fixed"}  
            backgroundColor={"#a9db6b"}
            paddingRight={"10vw"}
            borderRadius={"5px"}
            right={"-10vw"}
            bottom={"15vh"}
            border={"2px solid black"}
            style={{zIndex: 2}}
        >
            {flexContents}
        </Flex>);
    }, [questionAnswers, showPricingEstimate]);

    const calculateExpectedCost = function (questionID, newAnswer) {
        const newAnswers = {...questionAnswers};
        newAnswers[questionID] = newAnswer;
        setQuestionAnswers(newAnswers);
    };

    if (props.studentLessonRequests != null && existingLessonRequests == null) {
        setExistingLessonRequests(props.studentLessonRequests);
    }

    if (studentModel == null || existingLessonRequests == null) {
        return <LoadingSpinnerPage {...props} />
    }
    if (studentModel == "error" || existingLessonRequests == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    let numOutstandingLessonRequests = 0;
    for (const lessonRequest of existingLessonRequests) {
        if (lessonRequest.status != 3) {
            numOutstandingLessonRequests = numOutstandingLessonRequests + 1;
        }
    }

    if (numOutstandingLessonRequests >= 10 && formSubmitted == false) {
        const tooManyRequestsText1 = <Text {...standardTextProps}>You can only make 10 lesson requests at one time.</Text>
        const tooManyRequestsText2 = <Text {...standardTextProps}>If you would like to make another lesson request, please delete an old one.</Text>
        const tooManyRequestsText3 = <Text {...standardTextProps}>If you believe this is an error, please contact support.</Text>
        const viewRequestsButton = <Link to={"/Student/LessonRequests"} style={{textDecoration: 'none'}}><Button>View existing lesson requests</Button></Link>

        return (
            <div className='NewLessonRequestPage'>
                <Flex direction={"column"} alignItems={"center"} gap={"8vh"}>
                    {titleText}
                    <Flex direction={"column"} alignItems={"center"} >
                        {tooManyRequestsText1}
                        {tooManyRequestsText2}
                        {tooManyRequestsText3}
                    </Flex>
                    {viewRequestsButton}
                </Flex>
                {redirect}
            </div>
        );
    }

    const defaultAvailability = studentModel.defaultAvailability;

    const exitForm = function () {
        setRedirect(<Navigate to={"/Student/LessonRequests"} />);
    }

    const askDefaultAvailability = async function() {
        if (!compareObjects(defaultAvailability, questionAnswers.availability )) {
            setDefaultAvailabilityPopUp1(true);
        }
        else {
            exitForm();
        }
    }

    const submitAnswers = async function(returnedAnswers) {
        try {
            setFormSubmitted(true);
            const submitionAnswers = {...returnedAnswers};
            if (submitionAnswers.address == false) {
                submitionAnswers.address = null;
            }
            setQuestionAnswers(submitionAnswers);
            if (submitionAnswers.lessonType != "Online") {
                if (submitionAnswers.address.label == "New Address" || submitionAnswers.address.geocode == null) {
                    let addressAlreadyFound = false;
                    for (const existingAddress of userAddressList) {
                        const existingPostcode = existingAddress.postcode;
                        const newPostcode = submitionAnswers.address.postcode;
                        if (newPostcode == existingPostcode) {
                            addressAlreadyFound = true;
                            console.log("Found matching existing address");
                        }
                    }
                    if (addressAlreadyFound == false) {
                        delete submitionAnswers.address.label;
                        const newAddress = {address1stLine: submitionAnswers.address.address1stLine, postcode: submitionAnswers.address.postcode};
                        const newAddressList = [...userAddressList];
                        newAddressList.push(newAddress);
                        const newAddressListAttribute = {Name:"addressList", Value:newAddressList};

                        const updatedAttributes = await APIFunctions.updateUserAttributes(newAddressListAttribute);
                        let updatedAddressList = updatedAttributes[0]["custom:addressList"];
                        try {
                            updatedAddressList = JSON.parse(updatedAddressList);
                        } catch {}
                        for (const userAddress of updatedAddressList) {
                            const userAddress1stLine = userAddress.address1stLine;
                            const userPostcode = userAddress.postcode;
                            if (userAddress1stLine == newAddress.address1stLine && userPostcode == newAddress.postcode) {
                                const returnedGeocode = userAddress.geocode;
                                submitionAnswers.address.geocode = returnedGeocode;
                                break;
                            }
                        }
                    }
                }
                else {
                    delete submitionAnswers.address.label;
                }
            }
            submitionAnswers.numSessions = parseInt(submitionAnswers.numSessions, 10);
            submitionAnswers.subject = submitionAnswers.subjectInfo.subject;
            console.log(submitionAnswers);
            submitionAnswers.bypassSanityCheck = bypassAvailabilitySanityCheck;
            const newLessonRequest = await APIFunctions.createLessonRequest(submitionAnswers, "student");
            console.log(newLessonRequest);
        }
        catch (error) {
            if (error.response != null) {
                if (error.response.data != null) {
                    if (error.response.data == "Error invoking geocodeAddress function. Given error: Error geocoding address: \"Invalid address\"") {
                        const questionErrors = [{questionID: "address", errorMessage: "Invalid Address"}];
                        throw {questionErrors: questionErrors};
                    }
                    else {
                        throw {
                            message: "Error requesting a new lesson",
                            error: error
                        };
                    }
                }
                else {
                    throw error;
                }
            }
            else if (error.questionErrors != null) {
                for (const questionError of error.questionErrors) {
                    if (questionError.errorMessage == "Warning: Limited availability given" && error.questionErrors.length == 1) {
                        console.log("Limited availability");
                        setBypassAvailabilitySanityCheck({
                            bypass: true,
                            previousAvailability: {...returnedAnswers.availability}
                        });
                        throw {questionErrors: [{questionID: questionError.questionID, useErrorMessage: questionError.useErrorMessage, errorMessage: "Limited availability given, there is a lower chance of successfully matching with a tutor. Re-submit if you are sure you want to continue with the limited availability."}]}
                    }
                }
                throw error;
            }
            else {
                throw error;
            }
        }
    }

    
    const lessonRequestForm = function () {
        const questions = [];
    
        questions.push({
            id: "subjectInfo", 
            label: "Subject:", 
            type: "studentsubject",
            options: [...subjects],
            required: true, 
            validations: [{type: "ValidStudentSubject"}], 
            defaultValue: null, 
        });

        questions.push({
            id: "sessionLength", 
            label: "Session Length:", 
            type: "dropdown",
            options: ["45 Minutes", "1 Hour", "1 Hour 30 Minutes", "2 Hours"],
            required: true, 
            defaultValue: "1 Hour", 
        });

        questions.push({
            id: "numSessions", 
            label: "Number of lessons per week:", 
            type: "dropdown",
            options: [1, 2, 3, 4, 5, 6, 7],
            required: true, 
            defaultValue: 1, 
        });

        questions.push({
            id: "schoolSet", 
            label: "Current school set for subject:",
            description: "What level is the student currently at",
            options: ["Top Set", "Top/Middle Set", "Middle Set", "Middle/Bottom Set", "Bottom Set", "N/A"],
            type: "dropdown",
            required: false,
            defaultValue: null, 
        });
        
        questions.push({
            id: "lessonType", 
            label: "Lesson Type:",
            type: "radio",
            options: ["In-Person", "Online", "Either"],
            required: true, 
            defaultValue: "In-Person"
        });

        const addressOptions = [...userAddressList];
        questions.push({
            id: "address", 
            label: "Lesson address:", 
            type: "existingornewaddress", 
            required: true, 
            defaultValue: null, 
            options: addressOptions || [],
            onlyShow: {id:"lessonType", answers:["In-Person", "Either"]}
        });
        
        questions.push({
            id: "availability", 
            label: "Availability for lesson:", 
            type: "weekavailability", 
            required: true,
            defaultValue: defaultAvailability
        });

        questions.push({
            id: "requestedStartDate", 
            label: "Lesson start date:",
            description: "If you choose a date, we can't guarentee a lesson on that date, but we won't look for lessons before then", 
            type: "startdate", 
            required: true,
            defaultValue: new Date()
        });

        questions.push({
            id: "tutorLevelValue",
            label: "Tutor level:",
            description: "Would you prefer your tutor to be an A-Level student or university student? University student tutors will have a higher cost per hour than A-Level student tutors.", 
            type: "slider", 
            required: true,
            defaultValue: 4,
            minValue: 1,
            maxValue: 7,
            minValueText: "A-Level",
            maxValueText: "University",
            textValues: [
                //"Only want an A-Level tutor",
                "Would much rather an A-Level tutor",
                "Would rather an A-Level tutor",
                "A-Level tutor would be slightly preferable",
                "I don't mind",
                "University tutor would be slightly preferable",
                "Would rather a university tutor",
                "Would much rather a university tutor",
                //"Only want a university tutor",
            ]
        });

        questions.push({
            id: "showExtraInfo", 
            label: "Is there anything else you want us to consider?", 
            type: "radio",
            options: ["Yes", "No"],
            required: true, 
            defaultValue: "No", 
        });

        questions.push({
            id: "notes", 
            label: "Extra notes:", 
            type: "text", 
            required: false,
            defaultValue: null,
            extraProps: {
                textType: "paragraph"
            },
            validations: [{type: "ValidParagraph"}],
            onlyShow: {id:"showExtraInfo", answers:["Yes"]}
        });
    
        const lessonRequestFormTemplate = <FormTemplate 
            {...props} 
            submitAnswers={submitAnswers} 
            existingAnswers={questionAnswers} 
            questions={questions} 
            okButtonFunction={askDefaultAvailability}
            returnAnswersEarly={{
                questions: ["sessionLength", "numSessions", "lessonType", "tutorLevelValue"],
                handleChange: calculateExpectedCost
            }}
        />
        return lessonRequestFormTemplate;
    }

    const defaultAvailabilityPopUpText = "Would you like to make this your default availability?";
    const yesButtonFunction = async function () {
        console.log("Updating model");
        setDefaultAvailabilityPopUp2("Updating default availability...");
        const newModel = await APIFunctions.updateStudentModel({"defaultAvailability": questionAnswers.availability});
        console.log(newModel);
        setDefaultAvailabilityPopUp2(null)
        setDefaultAvailabilityPopUp3("Default availability updated");
    }
    const noButtonFunction = function () {
        exitForm();
    }

    const defaultAvailabilityPopUpObject1 = <PopUpTemplate 
        yesButtonFunction={yesButtonFunction} 
        noButtonFunction={noButtonFunction} 
        text={defaultAvailabilityPopUpText} 
        setPopUpVisibility={setDefaultAvailabilityPopUp1} 
        showXButton={false} 
    />

    const defaultAvailabilityPopUpObject2 = <PopUpTemplate 
        text={defaultAvailabilityPopUp2} 
        setPopUpVisibility={setDefaultAvailabilityPopUp2} 
        showXButton={false} 
    />

    const defaultAvailabilityPopUpObject3 = <PopUpTemplate 
        okButtonFunction={exitForm} 
        text={defaultAvailabilityPopUp3} 
        setPopUpVisibility={setDefaultAvailabilityPopUp3} 
        showXButton={false} 
    />

    return (
        <div className='NewLessonRequestPage'>
            <Flex direction={"column"} alignItems={"center"} >
                {titleText}
                {lessonRequestForm()}
                {pricingEstimate}
                {defaultAvailabilityPopUp1 && defaultAvailabilityPopUpObject1}
                {(defaultAvailabilityPopUp2 != null) && defaultAvailabilityPopUpObject2}
                {(defaultAvailabilityPopUp3 != null) && defaultAvailabilityPopUpObject3}
            </Flex>
            {redirect}
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default NewLessonRequest;