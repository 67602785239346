/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUserUpdate = /* GraphQL */ `subscription OnUserUpdate($userID: ID!) {
  onUserUpdate(userID: $userID) {
    userID
    updateID
    updateType
    refreshCognitoUser
    updateTime
    data
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUserUpdateSubscriptionVariables,
  APITypes.OnUserUpdateSubscription
>;
export const onCreateTutorRequest = /* GraphQL */ `subscription OnCreateTutorRequest(
  $filter: ModelSubscriptionTutorRequestFilterInput
  $owner: String
) {
  onCreateTutorRequest(filter: $filter, owner: $owner) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTutorRequestSubscriptionVariables,
  APITypes.OnCreateTutorRequestSubscription
>;
export const onUpdateTutorRequest = /* GraphQL */ `subscription OnUpdateTutorRequest(
  $filter: ModelSubscriptionTutorRequestFilterInput
  $owner: String
) {
  onUpdateTutorRequest(filter: $filter, owner: $owner) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTutorRequestSubscriptionVariables,
  APITypes.OnUpdateTutorRequestSubscription
>;
export const onDeleteTutorRequest = /* GraphQL */ `subscription OnDeleteTutorRequest(
  $filter: ModelSubscriptionTutorRequestFilterInput
  $owner: String
) {
  onDeleteTutorRequest(filter: $filter, owner: $owner) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTutorRequestSubscriptionVariables,
  APITypes.OnDeleteTutorRequestSubscription
>;
export const onCreateLessonRequest = /* GraphQL */ `subscription OnCreateLessonRequest(
  $filter: ModelSubscriptionLessonRequestFilterInput
  $owner: String
) {
  onCreateLessonRequest(filter: $filter, owner: $owner) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLessonRequestSubscriptionVariables,
  APITypes.OnCreateLessonRequestSubscription
>;
export const onUpdateLessonRequest = /* GraphQL */ `subscription OnUpdateLessonRequest(
  $filter: ModelSubscriptionLessonRequestFilterInput
  $owner: String
) {
  onUpdateLessonRequest(filter: $filter, owner: $owner) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLessonRequestSubscriptionVariables,
  APITypes.OnUpdateLessonRequestSubscription
>;
export const onDeleteLessonRequest = /* GraphQL */ `subscription OnDeleteLessonRequest(
  $filter: ModelSubscriptionLessonRequestFilterInput
  $owner: String
) {
  onDeleteLessonRequest(filter: $filter, owner: $owner) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLessonRequestSubscriptionVariables,
  APITypes.OnDeleteLessonRequestSubscription
>;
export const onCreateLesson = /* GraphQL */ `subscription OnCreateLesson(
  $filter: ModelSubscriptionLessonFilterInput
  $owner: String
) {
  onCreateLesson(filter: $filter, owner: $owner) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLessonSubscriptionVariables,
  APITypes.OnCreateLessonSubscription
>;
export const onUpdateLesson = /* GraphQL */ `subscription OnUpdateLesson(
  $filter: ModelSubscriptionLessonFilterInput
  $owner: String
) {
  onUpdateLesson(filter: $filter, owner: $owner) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLessonSubscriptionVariables,
  APITypes.OnUpdateLessonSubscription
>;
export const onDeleteLesson = /* GraphQL */ `subscription OnDeleteLesson(
  $filter: ModelSubscriptionLessonFilterInput
  $owner: String
) {
  onDeleteLesson(filter: $filter, owner: $owner) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLessonSubscriptionVariables,
  APITypes.OnDeleteLessonSubscription
>;
export const onCreateIssues = /* GraphQL */ `subscription OnCreateIssues($filter: ModelSubscriptionIssuesFilterInput) {
  onCreateIssues(filter: $filter) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateIssuesSubscriptionVariables,
  APITypes.OnCreateIssuesSubscription
>;
export const onUpdateIssues = /* GraphQL */ `subscription OnUpdateIssues($filter: ModelSubscriptionIssuesFilterInput) {
  onUpdateIssues(filter: $filter) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateIssuesSubscriptionVariables,
  APITypes.OnUpdateIssuesSubscription
>;
export const onDeleteIssues = /* GraphQL */ `subscription OnDeleteIssues($filter: ModelSubscriptionIssuesFilterInput) {
  onDeleteIssues(filter: $filter) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteIssuesSubscriptionVariables,
  APITypes.OnDeleteIssuesSubscription
>;
