import { Button, Flex, Text } from '@aws-amplify/ui-react';
import {useLocation, Navigate} from "react-router-dom";
import {React, useState, useEffect } from 'react';
import { FormTemplate } from '../../custom-ui-components';
import { LoadingSpinner } from '../LoadingPage';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { compareObjects, subjects } from 'nextdoortutor';

const EditLesson = function(props) {
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [deletingLesson, setDeletingLesson] = useState(false);
    const [deletedLesson, setDeletedLesson] = useState(false);
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const tutors = props.tutors;
    

    const location = useLocation();
    const extraProps = location.state;
    if (extraProps == null) {
        return <Navigate to={"/Admin/Lessons"} />
    }
    const lessonProps = extraProps.lessonProps;

    if (tutors == null || tutors == "loading") {
        return <LoadingSpinner height={props.height} />
    }
    else if (tutors == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    const editLessonTitle = <Text {...titleTextProps} >
        DOESNT WORK!!!!!!!! Edit lesson
    </Text>

    const deleteLessonButton = <Button 
        width={"fit-content"}
        onClick={(clickEvent) => {
            const controlClick = clickEvent.ctrlKey;
            if (controlClick != true) {
                setDeletePopUp(true);
            }
            else {
                deleteLesson();
            }
        }}
    >
        Delete Lesson
    </Button>

    const submitAnswers = async function(returnedAnswers) {
        try {
            setQuestionAnswers(returnedAnswers);
            const days = Object.entries(returnedAnswers.days);
            for (const day of days) {
                if (day[1] == true) {
                    const comparisonLesson = {...lessonProps};
                    delete comparisonLesson.createdAt;
                    delete comparisonLesson.__typename;
                    delete comparisonLesson._deleted;
                    delete comparisonLesson._lastChangedAt;
                    delete comparisonLesson.updatedAt;
                    delete comparisonLesson._version;


                    const newLesson = {};
                    newLesson.id = lessonProps.id;
                    newLesson.studentID = returnedAnswers.student.id;
                    newLesson.studentFirstNames = returnedAnswers.student.label.split(", ")[0];
                    newLesson.studentLastName = returnedAnswers.student.label.split(", ")[1];
                    newLesson.tutorID = returnedAnswers.tutor.id;
                    newLesson.tutorFirstNames = returnedAnswers.tutor.label.split(", ")[0];
                    newLesson.tutorLastName = returnedAnswers.tutor.label.split(", ")[1];
                    newLesson.subject = returnedAnswers.subject;
                    newLesson.lessonDay = day[0];
                    newLesson.startTime = returnedAnswers.lessonTime.startTime;
                    newLesson.endTime = returnedAnswers.lessonTime.endTime;
                    newLesson.lessonType = returnedAnswers.lessonType;
                    if (returnedAnswers.lessonType != "Online") {
                        newLesson.address = returnedAnswers.lessonLocation.address1stLine;
                        newLesson.postcode = returnedAnswers.lessonLocation.postcode;
                    }
                    else {
                        newLesson.address = null;
                        newLesson.postcode = null;
                    }

                    if (!compareObjects(comparisonLesson, newLesson)) {
                        const newLessonModel = await APIFunctions.updateLesson(newLesson, "admin");
                        console.log(newLessonModel);
                    }
                    else {
                        throw "No change to lesson"
                    }
                }
            }
        }
        catch (error) {
            if (error == "No change to lesson") {
                throw error;
            }
            throw {
                message: "Error updating lesson",
                error: error
            };
        }
    }

    const exitForm = function () {
        setRedirect(<Navigate to={"/Admin/Lessons"} />);
    }

    const editLessonForm = function () {
        const questions = [];

        const studentOption = {label: lessonProps.studentFirstNames + ", " + lessonProps.studentLastName, id:lessonProps.studentID}
        questions.push({
            id: "student", 
            label: "Student:",
            description: "Cannot change the student for an existing lesson",
            type: "autocomplete",
            required: true,
            disabled: true,
            defaultValue: studentOption, 
        });
        
        const tutorOption = {label: lessonProps.tutorFirstNames + ", " + lessonProps.tutorLastName, id:lessonProps.tutorID};
        const tutorOptions = [];
        for (const tutor of tutors) {
            const blankTutorOption = {};
            blankTutorOption.id = tutor.id;
            blankTutorOption.label = tutor.name;
            tutorOptions.push(blankTutorOption);
        }
        questions.push({
            id: "tutor", 
            label: "Tutor:",
            description: "Which tutor will be teaching the lesson",
            type: "autocomplete",
            required: true,
            defaultValue: tutorOption,
        });
        
        questions.push({
            id: "subject", 
            label: "Subject:",
            type: "dropdown",
            options: [...subjects],
            disabled: true,
            description: "Cannot change the subject for an existing lesson",
            defaultValue: lessonProps.subject,
            validations: [{type: "ValidSubject"}], 
        });
        
        const daysDefaultValue = {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false
        }
        daysDefaultValue[lessonProps.lessonDay] = true;
        questions.push({
            id: "days", 
            label: "Lesson Days:",
            type: "checkbox",
            description: "(Choosing multiple days creates multiple copies of the lesson on different days)",
            options: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            required: true,
            defaultValue: daysDefaultValue,
            validations: [{type: "ValidDays"}]
        });

        const defaultTime = {
            startTime: lessonProps.startTime,
            endTime: lessonProps.endTime,
        }
        questions.push({
            id: "lessonTime", 
            label: "Lesson Time:",
            type: "timerange",
            required: true, 
            validations: [{type: "ValidTimeRange"}], 
            defaultValue: defaultTime
        });

        questions.push({
            id: "lessonType", 
            label: "Lesson Type:",
            type: "radio",
            description: "How will the lesson be taught",
            options: ["In-Person", "Online"],
            required: true, 
            validations: [{type: "Radio"}],
            defaultValue: lessonProps.lessonType
        });


        let defaultValue = {label: lessonProps.postcode, address1stLine: lessonProps.address, postcode: lessonProps.postcode}
        let locationOptions = [defaultValue];
        questions.push({
            id: "lessonLocation", 
            label: "Lesson Location:",
            type: "existingornewaddress",
            description: "Choose either the existing lesson address or add a new one",
            options: locationOptions,
            required: true,
            defaultValue: defaultValue,
            onlyShow: {id: "lessonType", answers: ["In-Person"]}
        });

        const createNewLessonForm = <FormTemplate {...props} submitAnswers={submitAnswers} existingAnswers={questionAnswers} questions={questions} okButtonFunction={exitForm} />

        return createNewLessonForm;
    }

    const deleteLesson = async function () {
        setDeletingLesson(true);
        const newLesson = {};
        newLesson.id = lessonProps.id;
        newLesson.deleted = true;
        console.log(newLesson);
        const newLessonModel = await APIFunctions.updateLesson(newLesson, "admin");
        console.log(newLessonModel);
        setDeletingLesson(false);
        setDeletedLesson(true);
    }
    
    const deleteText = "Are you sure you want to delete this lesson?";
    const deletePopUpComponent = <PopUpTemplate text={deleteText} setPopUpVisibility={setDeletePopUp} yesButtonFunction={deleteLesson} noButtonFunction={function(){}} showXButton={true} />
    const deletingText = "Deleting lesson...";
    const deletingLessonPopUp = <PopUpTemplate text={deletingText} setPopUpVisibility={setDeletingLesson} showXButton={false} />
    const deletedLessonText = "Lesson deleted";
    const deletedLessonPopUp = <PopUpTemplate text={deletedLessonText} okButtonFunction={exitForm} setPopUpVisibility={setDeletedLesson} showXButton={false} />
    
    return (
        <div className='AdminEditLessonPage'>
            <Flex direction={"column"} alignItems={"center"} gap={"4vh"}>
                {redirect}
                {editLessonTitle}
                {deleteLessonButton}
                {editLessonForm()}
                {deletePopUp && deletePopUpComponent}
                {deletingLesson && deletingLessonPopUp}
                {deletedLesson && deletedLessonPopUp}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default EditLesson;