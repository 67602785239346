import {React, useState } from 'react';
import { Flex, Text } from '@aws-amplify/ui-react';
import { FormTemplate } from '../../custom-ui-components';
import { Navigate } from 'react-router-dom';

const AddNewStudent = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    const user = props.user;

    const [questionAnswers, setQuestionAnswers] = useState({});
    const [redirect, setRedirect] = useState(null);

    const submitAnswers = async function(returnedAnswers) {
        setQuestionAnswers(returnedAnswers);
        try {
            const newStudentModel = await APIFunctions.parentAddStudent(returnedAnswers);
            console.log("Successfully added a new student");
        }
        catch (error) {
            throw {
                message: "Error adding a new student",
                error: error
            };
        }
    }

    const exitForm = function () {
        setRedirect(<Navigate to={"/Parent"} />);
    }


    const titleText = <Text {...titleTextProps}  >
        Add a new student
    </Text>
    
    const registerAsStudentForm = function () {
        const questions = [];
    
        questions.push({
            id: "firstNames", 
            label: "Student's First Name(s):", 
            type: "text", 
            required: true, 
            validations: [{type: "Name"}], 
            defaultValue: null, 
            placeholder: "Enter name"
        });
        
        questions.push({
            id: "lastName", 
            label: "Student's Last Name:", 
            type: "text", 
            required: true, 
            validations: [{type: "Name"}], 
            defaultValue: null, 
            placeholder: "Enter name"
        });
    
        const yearGroupOptions = ["Year 13", "Year 12", "Year 11", "Year 10", "Year 9", "Year 8", "Year 7", "Year 6", "Year 5", "Year 4", "Year 3", "Year 2", "Year 1", "Reception"];
        questions.push({
            id: "yearGroup", 
            label: "Student's Year Group:", 
            type: "dropdown", 
            required: true, 
            options: yearGroupOptions,
            validations: [{type: "Dropdown"}], 
            defaultValue: null
        });
    
        const addAStudentForm = <FormTemplate {...props} submitAnswers={submitAnswers} existingAnswers={questionAnswers} questions={questions} okButtonFunction={exitForm} />
    
        return addAStudentForm;
    }

    return (
        <div className='addAStudentForm'>
            <Flex 
                direction={"column"}
                alignItems={"center"}
            >   
                {titleText}
                {registerAsStudentForm()}
                {redirect}
            </Flex>
        </div>    
    );
}

export default AddNewStudent;