import { Button, Flex, Icon, Text } from '@aws-amplify/ui-react';
import {React, useEffect, useState } from 'react';
import { DropDownQuestionTemplate } from '../../custom-ui-components';
import AutoCompleteTemplate from '../../custom-ui-components/form-components/AutoCompleteTemplate';
import CheckBoxesTemplate from '../../custom-ui-components/form-components/CheckBoxesTemplate';
import TimeRangeTemplate from '../../custom-ui-components/form-components/TimeRangeTemplate';
import LessonTemplate from '../../custom-ui-components/LessonTemplate';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import {MdCached} from 'react-icons/md';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { Link } from 'react-router-dom';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { subjects } from 'nextdoortutor';

const DeletedLessons = function(props) {
    const width = props.width;
    const lessons = props.deletedLessons;
    const tutorDictionary = props.tutorDictionary;
    const studentDictionary = props.studentDictionary;
    const titleTextProps = {...props.titleTextProps};
    const standardTextProps = {...props.standardTextProps};
    const APIFunctions = props.APIFunctions;
    //Get the current user to pass to subsequent functions
    const user = props.user;

    const noFilters = {Day:false, Time:false, Subject:false, Tutor:false, Student:false}
    const [studentNames, setStudentNames] = useState(null);
    const [tutorNames, setTutorNames] = useState(null);
    const [lessonFilters, setLessonFilters] = useState(noFilters);
    const [showFilters, setShowFilters] = useState(false);
    const showNoDaysValue = {Monday: false, Tuesday: false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false};
    const [shownDays, setShownDays] = useState(showNoDaysValue);
    const [shownSubject, setShownSubject] = useState("");
    const [shownTutor, setShownTutor] = useState(null);
    const [shownStudent, setShownStudent] = useState(null);
    const [shownTimeRange, setShownTimeRange] = useState({startTime:"00:00", endTime: "23:59"});
    const [numRefreshes, setNumRefreshes] = useState(0);
    const [recoverPopUp, setRecoverPopUp] = useState(false);
    const [recoveringLesson, setRecoveringLesson] = useState(false);
    const [recoveredLesson, setRecoveredLesson] = useState(false);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [deletingLesson, setDeletingLesson] = useState(false);
    const [deletedLesson, setDeletedLesson] = useState(false);
    const [currentLesson, setCurrentLesson] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);

    useEffect(() => {
        if (lessons == "loading" || studentDictionary == "loading" || tutorDictionary == "loading") {
            return;
        }
        else if (lessons == "error" || studentDictionary == "error" || tutorDictionary == "error") {
            return;
        }
        else if (lessons != null && studentDictionary != null && tutorDictionary != null) {
            const usedStudents = {};
            const usedTutors = {};
            for (const lesson of lessons) {
                let studentIDs = lesson.studentIDs;
                if (studentIDs == null) {
                    studentIDs = [lesson.studentID];
                }
                for (const studentID of studentIDs) {
                    const studentModel = studentDictionary[studentID];
                    usedStudents[studentID] = studentModel;
                }
                const tutorID = lesson.tutorID;
                const tutorModel = tutorDictionary[tutorID];
                usedTutors[tutorID] = tutorModel;
            }
            const usedStudentsArray = Object.values(usedStudents);
            const usedTutorsArray = Object.values(usedTutors);

            const newStudentNames = [];
            for (const student of usedStudentsArray) {
                newStudentNames.push({id:student.id, label: (student.firstNames + " " + student.lastName)});
            }

            const newTutorNames = []
            for (const tutor of usedTutorsArray) {
                newTutorNames.push({id:tutor.id, label: (tutor.firstNames + " " + tutor.lastName)});
            }
            setStudentNames(newStudentNames);
            setTutorNames(newTutorNames);
        }
    }, [props.studentDictionary, props.tutorDictionary, lessons]);


    const permanentlyDelete = async function (currentLessonProps) {
        try {
            if (currentLessonProps == null) {
                currentLessonProps = currentLesson;
            }
            setDeletingLesson(true);
            const newLesson = {};
            newLesson.id = currentLessonProps.id;
            newLesson.deleteLessonPermanently = true;
            const deleteSuccess = await APIFunctions.updateLesson(newLesson, "admin");
            console.log(deleteSuccess);
            setDeletingLesson(false);
            setDeletedLesson(true);
        }
        catch (error) {
            console.log(error);
            setDeletePopUp(false);
            setDeletingLesson(false);
            setDeletedLesson(false);
            if (typeof(error) == "string") {
                setErrorPopUp(error);
            }
            else {
                setErrorPopUp("Error permanently deleting lesson");
            }
        }
    }

    const askPermanentlyDelete = function (currentLessonProps, clickEvent) {
        setDeletedLesson(false);
        setRecoveredLesson(false);
        setRecoverPopUp(false);
        setCurrentLesson(currentLessonProps);
        const controlClick = clickEvent.ctrlKey;
        if (controlClick != true) {
            setDeletePopUp(true);
        }
        else {
            permanentlyDelete(currentLessonProps)
        }
    }

    const askRecover = function (currentLessonProps, clickEvent) {
        setRecoveredLesson(false);
        setDeletedLesson(false);
        setDeletePopUp(false);
        setCurrentLesson(currentLessonProps);
        const controlClick = clickEvent.ctrlKey;
        if (controlClick != true) {
            setRecoverPopUp(true);
        }
        else {
            recoverLesson(currentLessonProps)
        }
    }


    if (tutorDictionary == null || studentDictionary == null) {
        return <LoadingSpinnerPage {...props} height={props.height} />
    }
    if (tutorDictionary == "loading" || studentDictionary == "loading") {
        return <LoadingSpinnerPage {...props} height={props.height} />
    }
    else if (tutorDictionary == "error" || studentDictionary == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    let lessonUIs = [];
    if (lessons != null && lessons != "loading") {
        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

        for (let dayIndex = 0; dayIndex < days.length; dayIndex = dayIndex + 1) {
            const currentDay = days[dayIndex];
            const dayLessonUIs = [];
            for (const lesson of lessons) {
                if (lesson.lessonDay == currentDay) {
                    const UILesson = <LessonTemplate {...props} key={lesson.id} lessonID={lesson.id} lessonTemplateType={"deleted"} deleteLesson={askPermanentlyDelete} askRecover={askRecover} />
                    let showLesson = true;
                    if (!(shownSubject == lesson.subject || shownSubject == "" || lessonFilters.Subject == false)) {
                        showLesson = showLesson && false;
                    }
                    else if (!(shownTutor == null || shownTutor.id == lesson.tutorID || lessonFilters.Tutor == false)) {
                        showLesson = showLesson && false;
                    }
                    else if (shownStudent != null && lessonFilters.Student != false) {
                        let studentIDs = null;
                        if (lesson.studentID != null) {
                            studentIDs = [lesson.studentID];
                        }
                        else {
                            studentIDs = lesson.studentIDs;
                        }
        
                        let foundStudent = false;
                        for (const studentID of studentIDs) {
                            if (shownStudent.id == studentID) {
                                foundStudent = true;
                                break;
                            }
                        }
                        
                        if (foundStudent == false) {
                            showLesson = false;
                        }
                    }
                    else if (lessonFilters.Time == true) {
                        const startTime = lesson.startTime;
                        const startHour = parseInt(startTime.split(":")[0]);
                        const startMinute = parseInt(startTime.split(":")[1]);
                        let startInt = (startHour * 60) + startMinute;
                        const endTime = lesson.endTime;
                        const endHour = parseInt(endTime.split(":")[0]);
                        const endMinute = parseInt(endTime.split(":")[1]);
                        let endInt = (endHour * 60) + endMinute;
                        if (!(shownTimeRange == null || (shownTimeRange.startInt <= startInt && shownTimeRange.endInt >= startInt))) {
                            showLesson = showLesson && false
                        }
                    }
                    if (showLesson == true) {
                        dayLessonUIs.push(UILesson);
                    }
                }
            }
            
            if (shownDays[currentDay] == true || lessonFilters.Day == false || lessonFilters.Day == undefined) {
                lessonUIs.push(...dayLessonUIs);
            }
        }
    }
    else {
        lessonUIs = <LoadingSpinner />
    }

    const titleText = <Text {...titleTextProps} >
        Deleted lessons
    </Text>

    const lessonsFoundText = <Text {...standardTextProps} fontSize={"18px"}>
        Lessons found: {lessonUIs.length}
    </Text>

    

    let showFiltersText = "Show Filters";
        if (showFilters == true) {
            showFiltersText = "Hide Filters"
        }
    const showFiltersButton = <Button
        key={"ShowFiltersButton"}
        onClick={() => {
            const previousLessonFilters = {...lessonFilters};
            if (showFilters == true) {
                setShowFilters(false);
            }
            else {
                setShowFilters(true);
            }
        }}
    >
        {showFiltersText}
    </Button>

    let refreshButtonDisabled = false;
    if (numRefreshes >= 10) {
        refreshButtonDisabled = true
    }
    const refreshButton = <Button
        key={"RefreshButton"}
        onClick={() => {
            //Prevent API spam by limiting the user to 10 refreshes (Resets when the page is reloaded)
            if (!refreshButtonDisabled) {
                props.refreshLessons();
                props.refreshUsers();
                setNumRefreshes(numRefreshes + 1);
            }
        }}
        isDisabled={refreshButtonDisabled}
    >
        <Icon
            key={"refreshIcon"}
            width={"25px"}
            height={"25px"}
            as={MdCached}
        />
    </Button>

    const getFilterFlex = function () {
        const mainFilterTextProps = {...standardTextProps}
        const filterTextProps = {...mainFilterTextProps}
        mainFilterTextProps.fontSize = "20px"
        filterTextProps.fontSize = "18px";
        
        const checkboxTextProps = {...standardTextProps}
        checkboxTextProps.fontSize = "16px"

        let filterDirection = "row";
        if (width < 900) {
            filterDirection = "column"
        }

        const filterMenu = <CheckBoxesTemplate
            questionLabelProps = {mainFilterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "FilterMenu"
            label = "Filter by:"
            options = {["Day", "Time", "Subject", "Tutor", "Student"]}
            defaultValue = {lessonFilters}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newFilters = {...selectedAnswers}
                setLessonFilters(newFilters);
            }}
            direction = {filterDirection}
        />

        const dayFilters = <CheckBoxesTemplate
            questionLabelProps = {filterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "DayFilter"
            label = "Show days:"
            options = {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
            defaultValue = {shownDays}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newDayFilters = {...selectedAnswers}
                setShownDays(newDayFilters);
            }}
            direction = {filterDirection}
        />

        const subjectFilter = <DropDownQuestionTemplate
            questionLabelProps = {filterTextProps}
            id = "SubjectFilter"
            label = "Subject:"
            options = {[...subjects]}
            defaultValue = {shownSubject}
            placeHolder = "Choose a subject"
            handleChange = {(questionID, selectedAnswer, errors) => {
                setShownSubject(selectedAnswer);
            }}
            direction = {filterDirection}
        />
        
        const tutorFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "TutorFilter"
            label = "Tutor:"
            options = {tutorNames}
            defaultValue = {shownTutor}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer === undefined) {
                    selectedAnswer = false
                }
                setShownTutor(selectedAnswer);
            }}
            direction = {filterDirection}
        />
        
        const studentFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "StudentFilter"
            label = "Student:"
            options = {studentNames}
            defaultValue = {shownStudent}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer === undefined) {
                    selectedAnswer = false
                }
                setShownStudent(selectedAnswer);
            }}
            direction = {filterDirection}
        /> 

        const timeFilter = <TimeRangeTemplate
            questionLabelProps = {filterTextProps}
            id = "TimeFilter"
            label = "Start time range:"
            label1={""}
            label2={""}
            defaultValue = {{startTime:shownTimeRange.startTime, endTime:shownTimeRange.endTime}}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                const startTime = selectedAnswer.startTime;
                const startHour = parseInt(startTime.split(":")[0]);
                const startMinute = parseInt(startTime.split(":")[1]);
                let startInt = (startHour * 60) + startMinute;
                const endTime = selectedAnswer.endTime;
                const endHour = parseInt(endTime.split(":")[0]);
                const endMinute = parseInt(endTime.split(":")[1]);
                let endInt = (endHour * 60) + endMinute;
                
                if (!(startInt >= 0)) {
                    startInt = null;
                }
                if (!(endInt >= 0)) {
                    endInt = null;
                }
                setShownTimeRange({startTime:startTime, endTime:endTime, startInt:startInt, endInt:endInt});
            }}
            direction = {filterDirection}
        />

        const filterFlex = <Flex alignItems={"center"} gap={"1vh"} direction={"column"}>
            {filterMenu}
            {lessonFilters.Day && dayFilters}
            {lessonFilters.Subject && subjectFilter}
            {lessonFilters.Tutor && tutorFilter}
            {lessonFilters.Student && studentFilter}
            {lessonFilters.Time && timeFilter}
        </Flex>

        return filterFlex;
    }

    const filterFlex = getFilterFlex();

    const normalLessonsButton = <Link to={"/Admin/Lessons"} style={{textDecoration: 'none'}}><Button
        key={"normalLessonsButton"}
    >
        Manage current lessons
    </Button></Link>

    const recoverLesson = async function (currentLessonProps) {
        try {
            if (currentLessonProps == null) {
                currentLessonProps = currentLesson;
            }
            console.log("Recovering lesson");
            setRecoveringLesson(true);
            const newLesson = {};
            newLesson.id = currentLessonProps.id;
            newLesson.deleted = false;
            const newLessonModel = await APIFunctions.updateLesson(newLesson, "admin");
            console.log(newLessonModel);
            setRecoveringLesson(false);
            setRecoveredLesson(true);
        }
        catch (error) {
            console.log("Error recovering lesson");
            setRecoveringLesson(false);
            setRecoveredLesson(false);
            if (typeof(error) == "string") {
                setErrorPopUp(error);
            }
            else {
                setErrorPopUp("Error recovering deleted lesson");
            }
        }
    }

    const recoverText = "Are you sure you want to recover this lesson?";
    const recoverPopUpComponent = <PopUpTemplate text={recoverText} setPopUpVisibility={setRecoverPopUp} yesButtonFunction={recoverLesson} noButtonFunction={function(){}} showXButton={true} />
    const recoveringText = "Recovering lesson...";
    const recoveringLessonPopUp = <PopUpTemplate text={recoveringText} setPopUpVisibility={setRecoveringLesson} showXButton={false} />
    const recoveredLessonText = "Lesson recovered";
    const recoveredLessonPopUp = <PopUpTemplate text={recoveredLessonText} okButtonFunction={function(){}} setPopUpVisibility={setRecoveredLesson} showXButton={true} />

    const deleteText = "Are you sure you want to permanently delete this lesson?";
    const deletePopUpComponent = <PopUpTemplate text={deleteText} setPopUpVisibility={setDeletePopUp} yesButtonFunction={permanentlyDelete} noButtonFunction={function(){}} showXButton={true} />
    const deletingText = "Permanently deleting lesson...";
    const deletingLessonPopUp = <PopUpTemplate text={deletingText} setPopUpVisibility={setDeletingLesson} showXButton={false} />
    const deletedLessonText = "Lesson permanently deleted";
    const deletedLessonPopUp = <PopUpTemplate text={deletedLessonText} okButtonFunction={function(){}} setPopUpVisibility={setDeletedLesson} showXButton={true} />

    const errorPopUpComponent = <PopUpTemplate text={errorPopUp} okButtonFunction={function(){}} setPopUpVisibility={setErrorPopUp} showXButton={true} />
    
    return (
        <div className='AdminLessonsPage'>
            <Flex alignItems={"center"} gap={"1vh"} direction={"column"} marginBottom={"4vh"}>
                {titleText}
                <Flex gap={"2vh"} alignItems={"center"} direction={"column"}>
                    {normalLessonsButton}
                    {showFiltersButton}
                    {showFilters && filterFlex}
                    <Flex direction={"column"} alignItems={"center"}>
                        <Flex alignItems={"center"}>
                            {lessonsFoundText}
                            {refreshButton}
                        </Flex>
                        <Flex direction={"column"} alignItems={"center"} gap={"7vh"}>
                            {lessonUIs}
                        </Flex>
                    </Flex>
                </Flex>
                {recoverPopUp && recoverPopUpComponent}
                {recoveringLesson && recoveringLessonPopUp}
                {recoveredLesson && recoveredLessonPopUp}
                {deletePopUp && deletePopUpComponent}
                {deletingLesson && deletingLessonPopUp}
                {deletedLesson && deletedLessonPopUp}
                {(errorPopUp != false) && errorPopUpComponent}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default DeletedLessons;