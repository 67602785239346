import * as React from "react";
import { Flex, SelectField, Text, TextField } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import OptionsTemplate from "./OptionsTemplate";
import { compareObjects } from "nextdoortutor";

const ExistingOrNewAddressTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    const options = props.options || [];
    const givenKey = props.passedKey;

    const passedClearParent = props.clearParent;

    let newAddressOptionExists = false;
    for (const option of options) {
      if (option.label === "New Address") {
        newAddressOptionExists = true;
      }
      else if (option.label == null) {
        option.label = option.postcode;
      }
    }
    if (newAddressOptionExists === false) {
      options.push({label:"New Address", address1stLine:"", postcode:""});
    }

    let extraDetailsText = props.extraDetailsText;
    if (extraDetailsText == null) {
      extraDetailsText = "Enter new address";
    }
    let defaultValue = props.defaultValue;

    if (defaultValue == null || defaultValue == "New Address") {
      if (defaultValue == "New Address" || options.length == 0) {
        defaultValue = {
          label: "New Address",
          address1stLine: "",
          postcode: ""
        };
      }
      else {
        defaultValue = {
          label: options[0].label,
          address1stLine: options[0].address1stLine,
          postcode: options[0].postcode,
          geocode: options[0].geocode
        };
      }
    }
    else if (typeof(defaultValue) == "string") {
      defaultValue = {
        label: "New Address",
        address1stLine: "",
        postcode: ""
      };
    }
    else {
      defaultValue = {
        label: defaultValue.postcode,
        address1stLine: defaultValue.address1stLine,
        postcode: defaultValue.postcode
      };
    }

    const questionValidations = props.validations;
    let addressValidationExists = false;
    for (let validation of questionValidations) {
      if (validation.type === "ValidAddress") {
        addressValidationExists = true;
      }
    }
    if (addressValidationExists === false) {
      questionValidations.push ({"type":"ValidAddress"});
    }

    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;

    //Set state variables used to store the current state and any errors
    const [selectedAnswer, setSelectedAnswer] = useState(defaultValue.label);
    const [questionAnswer, setQuestionAnswer] = useState(defaultValue);
    const [newAddressChosen, setNewAddressChosen] = useState(false);
    const [errors, setErrors] = useState({questionError:""});
    const [addressErrors, setAddressErrors] = useState({questionError:""});
    const [postcodeErrors, setpostcodeErrors] = useState({questionError:""});
    const [clearParent, setClearParent] = useState({});

    if (passedClearParent != null && !compareObjects(passedClearParent, clearParent)) {
      setClearParent(passedClearParent);
      setQuestionAnswer(defaultValue);
      setSelectedAnswer(defaultValue.label);
      if (defaultValue.label == "New Address") {
        setNewAddressChosen(true);
      }
      else {
        setNewAddressChosen(false);
      }
      const validationResult = validateField(defaultValue, questionValidations);
      const questionHasError = validationResult.hasError;
      setErrors({
        questionHasError: questionHasError,
        questionError: validationResult.errorMessage
      });
    }

    useEffect(() => {
      const validationResult = validateField(questionAnswer, questionValidations);
      const questionHasError = validationResult.hasError;
      setErrors({
        questionHasError: questionHasError,
        questionError: validationResult.errorMessage
      });
      if (defaultValue.label == "New Address") {
        setNewAddressChosen(true);
      }
      else {
        setNewAddressChosen(false);
      }
      props.handleChange(questionID, questionAnswer, questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>

    let addressLabelText = <Text {...labelTextProps}>
      {"1st Line of Address:"}
    </Text>
    let postcodeLabelText = <Text {...labelTextProps}>
      {"Postcode:"}
    </Text>

    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>
    
    let errorText = <Text {...errorTextProps}>
      {errors.questionError}
    </Text>
    let addressErrorText = <Text {...errorTextProps}>
      {addressErrors.questionError}
    </Text>
    let postcodeErrorText = <Text {...errorTextProps}>
      {postcodeErrors.questionError}
    </Text>

    const extraDetailsTextObject = <Text {...labelTextProps}>
      {extraDetailsText}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    
    if (errors.errorMessage === null) {
      setErrors({
        questionError:""
      });
    }

    const getGivenAddress = function (inputLabel) {
      const blankAddress = {
        address1stLine: "",
        postcode: "",
        geocode: null
      }
      for (const option of options) {
        const optionLabel = option.label;
        if (inputLabel == optionLabel) {
          blankAddress.address1stLine = option.address1stLine;
          blankAddress.postcode = option.postcode;
          blankAddress.geocode = option.geocode;
          return blankAddress;
        }
      }
      return blankAddress;
    }
    
    const localHandleChange = function (input, answer) {
      if (input == "dropdown") {
        if (answer != "New Address") {
          setNewAddressChosen(false);
          const givenAddress = getGivenAddress(answer);
          const givenAddress1stLine = givenAddress.address1stLine;
          const givenPostcode = givenAddress.postcode;
          const givenGeocode = givenAddress.geocode;
          const newQuestionAnswer = {label: answer, address1stLine: givenAddress1stLine, postcode: givenPostcode, geocode: givenGeocode};
          setQuestionAnswer(newQuestionAnswer);

          const validationResult = validateField(newQuestionAnswer, questionValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;
          setErrors({
            questionHasError:questionHasError, 
            questionError:questionErrorMessage,
          });

          const addressValidationResult = validateField(answer, [{"type":"Required", "questionType":"text"}, {"type":"ValidAddress1stLine"}]);
          const addressFieldHasError = addressValidationResult.hasError;
          const addressFieldErrorMessage = addressValidationResult.errorMessage;
          setAddressErrors({questionHasError:addressFieldHasError, questionError:addressFieldErrorMessage});

          const postcodeValidationResult = validateField(answer, [{"type":"Required", "questionType":"text"}, {"type":"ValidPostcode"}]);
          const postcodeFieldHasError = postcodeValidationResult.hasError;
          const postcodeFieldErrorMessage = postcodeValidationResult.errorMessage;
          setpostcodeErrors({questionHasError:postcodeFieldHasError, questionError:postcodeFieldErrorMessage});

          const submittedAnswer = {...newQuestionAnswer};
          try {
            submittedAnswer.postcode = postcodeValidationResult.standardPostcode;
          } catch {}
          props.handleChange(questionID, submittedAnswer, questionHasError);
        }
        else {
          //If the new address dropdown option is chosen, set the answers accordingly
          //Only reset them if new address wasn't already selected though
          if (newAddressChosen == false) {
            setNewAddressChosen(true);
            const newQuestionAnswer = {label: "New Address", address1stLine: "", postcode: ""};
            setQuestionAnswer(newQuestionAnswer);
            const validationResult = validateField(newQuestionAnswer, questionValidations);
            const questionHasError = validationResult.hasError;
            const questionErrorMessage = validationResult.errorMessage;
            setErrors({
              questionHasError:questionHasError, 
              questionError:questionErrorMessage,
            });
            props.handleChange(questionID, newQuestionAnswer, questionHasError);
          }
        }
      }
      else if (input == "address1stLine") {
        const newQuestionAnswer = {label: questionAnswer.label, address1stLine: answer, postcode: questionAnswer.postcode, geocode: questionAnswer.geocode};
        setQuestionAnswer(newQuestionAnswer);
        const validationResult = validateField(answer, [{"type":"Required", "questionType":"text"}, {"type":"ValidAddress1stLine"}]);
        const fieldHasError = validationResult.hasError;
        const fieldErrorMessage = validationResult.errorMessage;
        setAddressErrors({questionHasError:fieldHasError, questionError:fieldErrorMessage});

        const questionValidationResult = validateField(newQuestionAnswer, questionValidations);
        const questionHasError = questionValidationResult.hasError;
        const questionErrorMessage = questionValidationResult.errorMessage;
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage,
        });
        props.handleChange(questionID, newQuestionAnswer, questionHasError);
      }
      else if (input == "postcode") {
        const newQuestionAnswer = {label: questionAnswer.label, address1stLine: questionAnswer.address1stLine, postcode: answer, geocode: questionAnswer.geocode};
        setQuestionAnswer(newQuestionAnswer);
        const validationResult = validateField(answer, [{"type":"Required", "questionType":"text"}, {"type":"ValidPostcode"}]);
        const fieldHasError = validationResult.hasError;
        const fieldErrorMessage = validationResult.errorMessage;
        setpostcodeErrors({questionHasError:fieldHasError, questionError:fieldErrorMessage, errorField: null});

        const questionValidationResult = validateField(newQuestionAnswer, questionValidations);
        const questionHasError = questionValidationResult.hasError;
        const questionErrorMessage = questionValidationResult.errorMessage;
        setErrors({
          questionHasError:questionHasError, 
          questionError:questionErrorMessage,
        });

        const submittedAnswer = {...newQuestionAnswer};
        try {
          submittedAnswer.postcode = validationResult.standardPostcode;
        } catch {}

        props.handleChange(questionID, submittedAnswer, questionHasError);
      }
    }

    const optionLabels = [];
    for (const option of options) {
      const optionLabel = option.label;
      if (optionLabel != null) {
        optionLabels.push(optionLabel);
      }
    }
    const optionComponents = OptionsTemplate(optionLabels, "dropdown");

    //Generate the radio button field component
    const dropDownField = <SelectField
      placeholder={"-Select an option-"}
      value={selectedAnswer}
      size={"medium"}
      isDisabled={answersSuccessfullySubmitted}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentValue = e.target.value;
        setSelectedAnswer(currentValue);
        localHandleChange("dropdown", currentValue);
      }} 
    >
      {optionComponents}
    </SelectField>

    
    let address1stLineField = <TextField
        placeholder={"Enter 1st address line"}
        value={questionAnswer.address1stLine}
        size={"medium"}
        isDisabled={answersSuccessfullySubmitted || !newAddressChosen}

        //Runs when it detects a change in the input field
        onChange={(e) => {
          const currentValue = e.target.value;
          localHandleChange("address1stLine", currentValue);
        }}
    />

    let postcodeField = <TextField
        placeholder={"Enter postcode"}
        value={questionAnswer.postcode}
        size={"medium"}
        isDisabled={answersSuccessfullySubmitted || !newAddressChosen}

        //Runs when it detects a change in the input field
        onChange={(e) => {
          const currentValue = e.target.value;
          localHandleChange("postcode", currentValue);
        }}
    />
    
    let labelGap = "3vh";
    let errorGap = "2vh";
    if (questionAnswer.label == null || questionAnswer.label == "") {
      postcodeField = null;
      address1stLineField = null;
      addressLabelText = null;
      postcodeLabelText = null;
      addressErrorText = null;
      postcodeErrorText = null;
      labelGap = "0vh";
      errorGap= "0vh";
    }
    else if (questionAnswer.label == "New Address") {
      errorText = null;
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      selectedAnswer,
      <div className="RadioGroupField">
          <Flex direction={"column"} gap={errorGap} key={givenKey}>
            <Flex direction={"column"} gap={"0.5vh"}>
              <Flex alignItems={"center"}>
                {renderedRequiredAsterisk}
                {labelText}
              </Flex>
              {descriptionText}
              <Flex direction={"column"} alignItems={"center"} gap={labelGap}> 
                {dropDownField}
                <Flex direction={"column"} gap={labelGap} alignItems={"center"}>
                  <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
                    <Flex direction={"column"} gap={"0vh"} alignItems={"center"}>
                      {addressLabelText}
                      {address1stLineField}
                    </Flex>
                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                      {addressErrorText}
                    </Flex>
                  </Flex>
                  <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
                    <Flex direction={"column"} gap={"0vh"} alignItems={"center"}>
                      {postcodeLabelText}
                      {postcodeField}
                    </Flex>
                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                      {postcodeErrorText}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
              {errorText}
            </Flex>
          </Flex>
      </div>
    );
  }
  catch (error) {
    throw "ExistingOrNewAddressTemplate Error: " + error;
  }
}

export default ExistingOrNewAddressTemplate;