import { React, useEffect, useState } from "react";
import { Autocomplete, Button, CheckboxField, Flex, Grid, Icon, SearchField, SelectField, SwitchField, Text, TextField } from "@aws-amplify/ui-react";
import { LoadingSpinner, LoadingSpinnerPage } from "../LoadingPage";
import {MdArrowRight, MdArrowDropDown, MdCached, MdOpenInNew } from 'react-icons/md';
import CheckBoxesTemplate from "../../custom-ui-components/form-components/CheckBoxesTemplate";
import { Link } from "react-router-dom";
import BackButton from "../../custom-ui-components/BackButton";
import { OptionsTemplate } from "../../custom-ui-components";

const ForceAppUpdate = function(props) {
	const titleTextProps = props.titleTextProps;
	const standardTextProps = props.standardTextProps;
	const APIFunctions = props.APIFunctions;
	const userConnectionsDictionary = props.userConnections;
	const tutorDictionary = props.tutorDictionary;
	const studentDictionary = props.studentDictionary;
	const parentDictionary = props.parentDictionary;

	const titleText = <Text {...titleTextProps}>Active Users</Text>
	const backButton = <BackButton {...props} defaultBack={"/Admin"} />

	const [userConnectionUIs, setUserConnectionUIs] = useState(<LoadingSpinner {...props} />);
	const [extraDetails, setExtraDetails] = useState({});
	const [selectedConnections, setSelectedConnections] = useState({});
	const [allSelected, setAllSelected] = useState(false);
	const [reloadButton, setReloadButton] = useState(null);
	const [selectAllButton, setSelectAllButton] = useState(null);
	const [forceUpdateFlex, setForceUpdateFlex] = useState(null);
	const [pathnameInput, setPathnameInput] = useState(null);
	const [navigatePathname, setNavigatePathname] = useState(null);
	const [externalLinkWarning, setExternalLinkWarning] = useState(null);
	const [searchInput, setSearchInput] = useState(null);
	const [search, setSearch] = useState(null);
	const [userNames, setUserNames] = useState(null);
	const [connectionUserIDs, setConnectionUserIDs] = useState(null);
	const [selectedUserIDs, setSelectedUserIDs] = useState(null);

	//Get user connections
	useEffect(() => {
		if (Object.keys(userConnectionsDictionary).length == 0) {
			APIFunctions.getUserConnections("all");
			setUserConnectionUIs(<LoadingSpinner {...props} />);
		}
	}, [userConnectionsDictionary, APIFunctions]);

	//Set connection user IDs
	useEffect(() => {
		if (userConnectionsDictionary == null) {
			return;
		}
		const newConnectionUserIDs = {};
		for (const [userID, userConnections] of Object.entries(userConnectionsDictionary)) {
			for (const [connectionID] of Object.entries(userConnections)) {
				newConnectionUserIDs[connectionID] = userID;
			}
		}
		setConnectionUserIDs(newConnectionUserIDs);
	}, [userConnectionsDictionary]);

	//Set selected userIDs
	useEffect(() => {
		if (selectedConnections == null || userConnectionsDictionary == null) {
			return;
		}
		const newSelectedUserIDsObject = {};
		const newSelectedConnections = {...selectedConnections};
		for (const [connectionID] of Object.entries(selectedConnections)) {
			if (connectionUserIDs[connectionID] == null) {
				delete newSelectedConnections[connectionID];
				continue;
			}
			const userID = connectionUserIDs[connectionID];
			newSelectedUserIDsObject[userID] = true;
		}
		const newSelectedUserIDs = Object.keys(newSelectedUserIDsObject);
		setSelectedUserIDs(newSelectedUserIDs);
		if (Object.keys(newSelectedConnections).length != Object.keys(selectedConnections).length) {
			setSelectedConnections(newSelectedConnections);
		}
	}, [selectedConnections, userConnectionsDictionary]);

	//Set user names
	useEffect(() => {
		if (userConnectionsDictionary == null || tutorDictionary == null || studentDictionary == null || parentDictionary == null) {
			return;
		}

		const userIDs = Object.keys(userConnectionsDictionary);
		const newUserNames = {};
		for (const userID of userIDs) {
			let userName = userID;
			const userRoles = {
				Student: false,
				Tutor: false,
				Parent: false
			}
			const parentModel = parentDictionary[userID];
			if (parentModel != null) {
				userRoles.Parent = true;
				userName = parentModel.firstNames + " " + parentModel.lastName;
			}
			const studentModel = studentDictionary[userID];
			if (studentModel != null) {
				userRoles.Student = true;
				userName = studentModel.firstNames + " " + studentModel.lastName;
			}
			const tutorModel = tutorDictionary[userID];
			if (tutorModel != null) {
				userRoles.Tutor = true;
				userName = tutorModel.firstNames + " " + tutorModel.lastName;
			}

			let roleString = "(";
			let totalRoles = 0;
			for (const [role, value] of Object.entries(userRoles)) {
				if (value == true) {
					totalRoles = totalRoles + 1;
					roleString = roleString + role + ", ";
				}
			}
			if (totalRoles > 0) {
				roleString = roleString.slice(0, -2);
				roleString = roleString + ")";
				userName = userName + " " + roleString;
			}
			newUserNames[userID] = userName;
		}
		setUserNames(newUserNames);
	}, [userConnectionsDictionary, tutorDictionary, studentDictionary, parentDictionary]);

	//UIs
	useEffect(() => {
		if (userNames == null || userConnectionsDictionary == null) {
			return;
		}
		const connectionUIs = [];
		for (const [userID, userConnections] of Object.entries(userConnectionsDictionary)) {
			let userConnectionUIs = null;
			const userConnectionsArray = Object.entries(userConnections);
			if (extraDetails[userID] == true) {
				userConnectionUIs = [];
				for (const [connectionID, connection] of userConnectionsArray) {
					const startTime = connection.startTime;
					const startTimeDateObject = new Date(startTime);
					let hours = startTimeDateObject.getHours();
					if (hours < 10) {
						hours = "0" + hours;
					}
					let minutes = startTimeDateObject.getMinutes();
					if (minutes < 10) {
						minutes = "0" + minutes;
					}
					let startTimeString = hours + ":" + minutes;
					if (startTimeDateObject.toDateString() != new Date().toDateString()) {
						let date = startTimeDateObject.getDate();
						if (date < 10) {
							date = "0" + date;
						}
						let month = startTimeDateObject.getMonth() + 1;
						if (month < 10) {
							month = "0" + month;
						}
						let year = startTimeDateObject.getFullYear();
						startTimeString = date + "/" + month + "/" + year + " " + startTimeString;
					}
					
					let extraDetailsFlex = null;
					let connectionArrowDirection = MdArrowRight;
					if (extraDetails[connectionID] == true) {
						connectionArrowDirection = MdArrowDropDown;
						extraDetailsFlex = <Flex direction={"column"} gap={"5px"} maxWidth={"400px"}>
							<Text>ConnectionID: {connectionID}</Text>
							<Text>User agent: {connection.userAgent}</Text>
						</Flex>
					}

					const connectionArrow = <Icon
						as={connectionArrowDirection}
						width={"30px"}
						height={"30px"}
						style={{cursor: "pointer"}}
						position={"absolute"}
						right={"10px"}
						onClick={() => {
							const newExtraDetails = {...extraDetails};
							if (newExtraDetails[connectionID] == null || newExtraDetails[connectionID] == false) {
								newExtraDetails[connectionID] = true;
							}
							else {
								newExtraDetails[connectionID] = false;
							}
							setExtraDetails(newExtraDetails);
						}}
					/>

					const connectionUI = <Flex
						backgroundColor={"#34d5eb"}
						borderRadius={"5px"}
						key={connectionID}
						alignItems={"left"}
						padding={"5px"}
						direction={"column"}
					>
						<Flex justifyContent={"center"} position={"relative"} paddingRight={"50px"}>
							<CheckboxField 
								size="large"
								checked={selectedConnections[connectionID] == true}
								onChange={(event) => {
									const checked = event.target.checked;
									const newSelectedConnections = {...selectedConnections};
									if (checked) {
										newSelectedConnections[connectionID] = true;
									}
									else {
										delete newSelectedConnections[connectionID];
									}
									setSelectedConnections(newSelectedConnections);
								}}
							/>
							<Text>Start time: {startTimeString}</Text>
							<Text>Page visible: {connection.pageVisibility}</Text>
							<Text>Active: {JSON.stringify(connection.active)}</Text>
							{connectionArrow}
						</Flex>
						{extraDetailsFlex}
					</Flex>
					userConnectionUIs.push(connectionUI);
				}
			}

			const userName = userNames[userID] || userID;
			
			if (search != null && search != "" && !userName.split(" ").join("").toLowerCase().includes(search)) {
				continue;
			}

			let numUserConnectionsText = userConnectionsArray.length + " connection";
			if (userConnectionsArray.length == 0 || userConnectionsArray.length> 1) {
				numUserConnectionsText = numUserConnectionsText + "s";
			}

			let userExtraDetailsFlex = null;
			let userArrowDirection = MdArrowRight;
			if (extraDetails[userID] == true) {
				userArrowDirection = MdArrowDropDown;
				userExtraDetailsFlex = <Flex direction={"column"} gap={"5px"} maxWidth={"400px"}>
					<Text>UserID: {userID}</Text>
				</Flex>
			}

			const userArrow = <Icon
				as={userArrowDirection}
				width={"30px"}
				height={"30px"}
				style={{cursor: "pointer"}}
				position={"absolute"}
				right={"10px"}
				onClick={() => {
					const newExtraDetails = {...extraDetails};
					if (newExtraDetails[userID] == null || newExtraDetails[userID] == false) {
						newExtraDetails[userID] = true;
					}
					else {
						newExtraDetails[userID] = false;
					}
					setExtraDetails(newExtraDetails);
				}}
			/>
			
			let allUserConnectionsSelected = true;
			for (const [connectionID] of userConnectionsArray) {
				if (selectedConnections[connectionID] != true) {
					allUserConnectionsSelected = false;
					break;
				}
			}

			const userUI = <Flex
				direction={"column"}
				backgroundColor={"#bbeefc"}
				borderRadius={"5px"}
				key={userID}
				padding={"10px"}
				border={"2px solid black"}
				width={"90vw"}
				maxWidth={"550px"}
			>
				<Flex alignItems={"center"} position={"relative"} paddingRight={"50px"}>
					<CheckboxField 
						size="large"
						checked={allUserConnectionsSelected}
						onChange={(event) => {
							const checked = event.target.checked;
							const newSelectedConnections = {...selectedConnections};
							if (checked) {
								for (const [connectionID] of userConnectionsArray) {
									newSelectedConnections[connectionID] = true;
								}
							}
							else {
								for (const [connectionID] of userConnectionsArray) {
									delete newSelectedConnections[connectionID];
								}
							}
							setSelectedConnections(newSelectedConnections);
						}}
					/>
					<Text>{userName}:</Text>
					<Text>{numUserConnectionsText}</Text>
					{userArrow}
				</Flex>
				{userExtraDetailsFlex}
				{userConnectionUIs}
			</Flex>	
			connectionUIs.push(userUI);
		}
		setUserConnectionUIs(connectionUIs);
	}, [userConnectionsDictionary, extraDetails, selectedConnections, search, userNames, APIFunctions]);

	//Check all selected
	useEffect(() => {
		for (const [userID, userConnections] of Object.entries(userConnectionsDictionary)) {
			for (const [connectionID] of Object.entries(userConnections)) {
				if (selectedConnections[connectionID] != true) {
					setAllSelected(false);
					return;
				}
			}
		}
		setAllSelected(true);
	}, [userConnectionsDictionary, selectedConnections]);

	//Select all button
	useEffect(() => {
		setSelectAllButton(
			<Button
				onClick={() => {
					let newSelectedConnections = {...selectedConnections};
					if (!allSelected) {
						for (const [userID, userConnections] of Object.entries(userConnectionsDictionary)) {
							for (const [connectionID] of Object.entries(userConnections)) {
								newSelectedConnections[connectionID] = true;
							}
						}
					}
					else {
						newSelectedConnections = {};
					}
					setSelectedConnections(newSelectedConnections);
				}}
				children={!allSelected && "Select all" || "Deselect all"}
			/>
		);
	}, [allSelected, userConnectionsDictionary, selectedConnections]);

	//Reload button and pathname input
	useEffect(() => {
		setReloadButton(
			<Button
				key={"refreshButton"}
				style={{cursor: "pointer"}}
				onClick={() => {
					setUserConnectionUIs(<LoadingSpinner {...props} />);
					APIFunctions.getUserConnections("all");
				}}
			>
				<Flex alignItems={"center"}>
					<Text>Reload connections</Text>
					<Icon
						width={"25px"}
						height={"25px"}
						as={MdCached}
					/>
				</Flex>
			</Button>
		)

		setPathnameInput(<TextField 
			width={"80%"}
			placeholder="Navigation path e.g. /Tutor/MyLessons"
			onChange={(event) => {
				let textInput = event.target.value;
				textInput = textInput.replace("https://app.nextdoortutor.co.uk", "");
				textInput = textInput.replace("app.nextdoortutor.co.uk", "");
				if (textInput != null && textInput != "" && !textInput.startsWith("/") && !textInput.startsWith("http")) {
					textInput = "/" + textInput;
				}
				setNavigatePathname(textInput);
			}}
		/>);
	}, [APIFunctions]);

	//Search input
	useEffect(() => {
		if (userNames == null) {
			return;
		}
		const userNamesArray = Object.values(userNames);
		const userNameOptions = OptionsTemplate(userNamesArray, "autocomplete")

		setSearchInput(<Autocomplete
			width={"100%"}
			placeholder={"Search"}
			onChange={(event) => {
				let newSearch = event.target.value;
				newSearch = newSearch.split(" ").join("").toLocaleLowerCase();
				setSearch(newSearch);
			}}
			onClear={() => {
				setSearch(null);
			}}
			onSelect={(event) => {
				let newSearch = event.label;
				newSearch = newSearch.split(" ").join("").toLocaleLowerCase();
				setSearch(newSearch);
			}}
			options={userNameOptions}
		/>);
	}, [userNames]);

	//Force update flex
	useEffect(() => {
		const selectedConnectionsArray = Object.keys(selectedConnections);
		const forceReloadButton = <Button
			disabled={selectedConnectionsArray.length == 0}
			onClick={() => {
				APIFunctions.forceAppUpdate(null, selectedConnectionsArray, "reload", selectedUserIDs);
			}}
		>
			<Flex alignItems={"center"}>
				<Text>Force reload</Text>
				<Icon as={MdCached} width={"25px"} height={"25px"}/>
			</Flex>
		</Button>
		
		const navigateData = {
			command: "navigate",
			pathname: navigatePathname
		}
		const forceNavigateButton = <Button
			disabled={selectedConnectionsArray.length == 0 || navigatePathname == null || navigatePathname == "" || !navigatePathname.includes("/")}
			onClick={() => {
				APIFunctions.forceAppUpdate(null, selectedConnectionsArray, navigateData);
			}}
		>
			<Flex alignItems={"center"}>
				<Text>Force navigate</Text>
				<Icon as={MdOpenInNew } width={"25px"} height={"25px"}/>
			</Flex>
		</Button>

		setForceUpdateFlex(<Flex>
			{forceReloadButton}
			{forceNavigateButton}
		</Flex>);
	}, [selectedConnections, selectedUserIDs, navigatePathname, APIFunctions]);

	//External link warning
	useEffect(() => {
		if (navigatePathname != null && navigatePathname.startsWith("http")) {
			setExternalLinkWarning(
				<Text 
					color={"red"}
					position={"absolute"}
					bottom={"-30px"}
				>
					External links are dangerous!
				</Text>
			);
		}
		else if (externalLinkWarning != null) {
			setExternalLinkWarning(null);
		}
	}, [navigatePathname]);

	return (
		<Flex direction={"column"} gap={"4vh"} alignItems={"center"} >
			{titleText}
			<Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
				<Flex alignItems={"center"} position={"relative"}>
					{backButton} 
					{reloadButton}
				</Flex>
				<Flex direction={"column"} alignItems={"center"} position={"relative"}>
					<Flex>
						{selectAllButton}
						{forceUpdateFlex}
					</Flex>
					{pathnameInput}
					{externalLinkWarning}
				</Flex>
				
			</Flex>
			<Flex direction={"column"} gap={"3vh"} alignItems={"center"}>
				{searchInput}
				{userConnectionUIs}
			</Flex>
		</Flex>
	);
}

export default ForceAppUpdate;