import { Button, Flex, Icon, SwitchField, Text } from '@aws-amplify/ui-react';
import {React, useState, useEffect } from 'react';
import { AppNavButton, DropDownQuestionTemplate } from '../../custom-ui-components';
import AutoCompleteTemplate from '../../custom-ui-components/form-components/AutoCompleteTemplate';
import CheckBoxesTemplate from '../../custom-ui-components/form-components/CheckBoxesTemplate';
import TimeRangeTemplate from '../../custom-ui-components/form-components/TimeRangeTemplate';
import LessonTemplate from '../../custom-ui-components/LessonTemplate';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import {MdCached, MdSouth, MdNorth} from 'react-icons/md';
import { Link, Navigate, useLocation, useNavigate, useHistory } from 'react-router-dom';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import CalendarTemplate from '../../custom-ui-components/CalendarViewTemplate';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { timeIntToString } from '../../App';
import { compareObjects, subjects } from 'nextdoortutor';

const AdminLessons = function(props) {
    const width = props.width;
    const lessons = props.adminLessons;
    const deletedLessons = props.adminDeletedLessons;
    const tutorDictionary = props.tutorDictionary;
    const studentDictionary = props.studentDictionary;
    const parentDictionary = props.parentDictionary;
    const titleTextProps = {...props.titleTextProps};
    const standardTextProps = {...props.standardTextProps};
    const APIFunctions = props.APIFunctions;
    const [errorPopUp, setErrorPopUp] = useState(false);
    //Get the current user to pass to subsequent functions
    const user = props.user;

    const noFilters = {Day:false, Time:false, Subject:false, Tutor:false, Student:false, Parent:false, "Show Expired": false};
    const [studentNames, setStudentNames] = useState(null);
    const [tutorNames, setTutorNames] = useState(null);
    const [parentNames, setParentNames] = useState(null);

    const [lessonFilters, setLessonFilters] = useState(noFilters);
    const [showFilters, setShowFilters] = useState(false);
    const showNoDaysValue = {Monday: false, Tuesday: false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false};
    const [shownDays, setShownDays] = useState(showNoDaysValue);
    const [shownSubject, setShownSubject] = useState("");
    const [shownTutor, setShownTutor] = useState(null);
    const [shownStudent, setShownStudent] = useState(null);
    const [shownParent, setShownParent] = useState(null);
    //const showNoTimesValue = {Monday: false, Tuesday: false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false};
    const [shownTimeRange, setShownTimeRange] = useState({startTime:"00:00", endTime: "23:59"});
    const [sortBy, setSortBy] = useState("Sort by lesson time");
    const [sortOrder, setSortOrder] = useState("down");
    const [numRefreshes, setNumRefreshes] = useState(0);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [deletingLesson, setDeletingLesson] = useState(false);
    const [deletedLesson, setDeletedLesson] = useState(false);
    const [currentLesson, setCurrentLesson] = useState(false);
    const [view, setView] = useState("list");
    const [redirect, setRedirect] = useState(null);
    const [loadedFromURL, setLoadedFromURL] = useState(false);
    const [lessonUIs, setLessonUIs] = useState(null);
    const [calendarDetails, setCalendarDetails] = useState(null);


    const location = useLocation();
    const navigate = useNavigate();

    //Set names
    useEffect(() => {
        if (lessons == null || studentDictionary == null || tutorDictionary == null || parentDictionary == null) {
            return;
        }
        else if (lessons == "loading" || studentDictionary == "loading" || tutorDictionary == "loading" || parentDictionary == "loading") {
            return;
        }
        else if (lessons == "error" || studentDictionary == "error" || tutorDictionary == "error" || parentDictionary == "error") {
            return;
        }
        else if (lessons != null && studentDictionary != null && tutorDictionary != null && parentDictionary != null) {
            const usedStudents = {};
            const usedTutors = {};
            const usedParents = {};
            for (const lesson of lessons) {
                let studentIDs = lesson.studentIDs;
                if (studentIDs == null) {
                    studentIDs = [lesson.studentID];
                }
                for (const studentID of studentIDs) {
                    const studentModel = studentDictionary[studentID];
                    usedStudents[studentID] = studentModel;
                    if (studentModel.parentID != null) {
                        usedParents[studentModel.parentID] = parentDictionary[studentModel.parentID];
                    }
                }
                const tutorID = lesson.tutorID;
                const tutorModel = tutorDictionary[tutorID];
                usedTutors[tutorID] = tutorModel;
            }
            const usedStudentsArray = Object.values(usedStudents);
            const usedTutorsArray = Object.values(usedTutors);
            const usedParentsArray = Object.values(usedParents);

            const newStudentNames = [];
            for (const student of usedStudentsArray) {
                newStudentNames.push({id:student.id, label: (student.firstNames + " " + student.lastName)});
            }

            const newTutorNames = []
            for (const tutor of usedTutorsArray) {
                newTutorNames.push({id:tutor.id, label: (tutor.firstNames + " " + tutor.lastName)});
            }

            const newParentNames = []
            for (const parent of usedParentsArray) {
                newParentNames.push({id:parent.id, label: (parent.firstNames + " " + parent.lastName)});
            }
            setStudentNames(newStudentNames);
            setTutorNames(newTutorNames);
            setParentNames(newParentNames);
        }
    }, [studentDictionary, tutorDictionary, parentDictionary, lessons]);

    //Set initial search parameters from the URL
    useEffect(() => {
        if (tutorDictionary == null || studentDictionary == null || parentDictionary == null || loadedFromURL == true) {
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const sortByParam = queryParams.get("sortBy");
        if (sortByParam == "time") {
            setSortBy("Sort by lesson time");
        }
        else if (sortByParam == "startDate") {
            setSortBy("Sort by start date");
        }
        else if (sortByParam == "createdAt") {
            setSortBy("Sort by creation date");
        }
        const sortOrderParam = queryParams.get("sortOrder");
        if (sortOrderParam != null) {
            setSortOrder(sortOrderParam);
        }
        const viewParam = queryParams.get("view");
        if (viewParam != null) {
            setView(viewParam);
        }
        const showFiltersParam = queryParams.get("showFilters");
        if (showFiltersParam != null) {
            try {
                const newShowFilters = JSON.parse(showFiltersParam);
                setShowFilters(newShowFilters);
            } catch {}
        }
        const newLessonFilters = {...noFilters};
        const dayParam = queryParams.get("Day");
        if (dayParam != null) {
            try {
                const newShownDays = {...showNoDaysValue};
                const shownDaysList = dayParam.split("-");
                for (const day of shownDaysList) {
                    if (showNoDaysValue[day] != null) {
                        newShownDays[day] = true;
                    }
                }
                setShownDays(newShownDays);
                newLessonFilters.Day = true;
            } catch {}
        }
        const timeParam = queryParams.get("Time");
        if (timeParam != null) {
            try {
                const [startTimeInt, endTimeInt] = timeParam.split("-");
                const startTime = timeIntToString(startTimeInt);
                const endTime = timeIntToString(endTimeInt);
                setShownTimeRange({startTime: startTime, endTime: endTime, startInt: startTimeInt, endInt: endTimeInt});
                newLessonFilters.Time = true;
            } catch {}
        }
        const subjectParam = queryParams.get("Subject");
        if (subjectParam != null && subjectParam != "") {
            setShownSubject(subjectParam);
            newLessonFilters.Subject = true;
        }
        const tutorParam = queryParams.get("Tutor");
        if (tutorParam != null && tutorParam != "") { 
            try {
                const tutorModel = tutorDictionary[tutorParam];
                setShownTutor({id: tutorParam, label: tutorModel.firstNames + " " + tutorModel.lastName});
                newLessonFilters.Tutor = true;
            } catch {}
        }
        const studentParam = queryParams.get("Student");
        if (studentParam != null && studentParam != "") {
            try {
                const studentModel = studentDictionary[studentParam];
                setShownStudent({id: studentParam, label: studentModel.firstNames + " " + studentModel.lastName});
                newLessonFilters.Student = true;
            } catch {}
        }
        const parentParam = queryParams.get("Parent");
        if (parentParam != null && parentParam != "") {
            try {
                const parentModel = parentDictionary[parentParam];
                setShownParent({id: parentParam, label: parentModel.firstNames + " " + parentModel.lastName});
                newLessonFilters.Parent = true;
            } catch {}
        }

        setLessonFilters(newLessonFilters);
        setLoadedFromURL(true);
    }, [tutorDictionary, studentDictionary, parentDictionary, loadedFromURL, window.location.search]);

    //Updates the URL with the current search parameters
    useEffect(() => {
        if (loadedFromURL == false) {
            return;
        }
        const queryParams = new URLSearchParams();
        if (sortBy != null) {
            let sortByShort = null;
            if (sortBy == "Sort by lesson time") {
                sortByShort = "time";
            }
            else if (sortBy == "Sort by start date") {
                sortByShort = "startDate";
            }
            else if (sortBy == "Sort by creation date") {
                sortByShort = "createdAt";
            }    
            queryParams.set("sortBy", sortByShort);
        }
        queryParams.set("sortOrder", sortOrder);
        queryParams.set("view", view);
        queryParams.set("showFilters", showFilters);
        for (const [filterName, filterValue] of Object.entries(lessonFilters)) {
            if (filterValue == true) {
                if (filterName == "Day" && shownDays != null) {
                    let dayString = "";
                    let totalDays = 0;
                    for (const day in shownDays) {
                        if (shownDays[day] == true) {
                            dayString = dayString + day + "-";
                            totalDays = totalDays + 1;
                        }
                    }
                    dayString = dayString.slice(0, -1);
                    if (totalDays > 0) {
                        queryParams.set(filterName, dayString);
                    }
                }
                else if (filterName == "Time" && shownTimeRange != null) {
                    queryParams.set(filterName, shownTimeRange.startInt + "-" + shownTimeRange.endInt);
                }
                else if (filterName == "Subject" && shownSubject != null && shownSubject != "") {
                    queryParams.set(filterName, shownSubject);
                }
                else if (filterName == "Tutor" && shownTutor != null && shownTutor.id != null) {
                    queryParams.set(filterName, shownTutor.id);
                }
                else if (filterName == "Student" && shownStudent != null && shownStudent.id != null) {
                    queryParams.set(filterName, shownStudent.id);
                }
                else if (filterName == "Parent" && shownParent != null && shownParent.id != null) {
                    queryParams.set(filterName, shownParent.id);
                }
            }
        }
        navigate(
            {   pathname: location.pathname,
                search: queryParams.toString()
            }
        );
    }, [lessonFilters, shownDays, shownSubject, shownTutor, shownStudent, shownParent, shownTimeRange, sortBy, sortOrder, view, showFilters, loadedFromURL]);

    const askDeleteLesson = function (currentLessonProps, clickEvent) {
        const controlClick = clickEvent.ctrlKey;
        setCurrentLesson(currentLessonProps);
        if (controlClick != true) {
            setDeletePopUp(true);
        }
        else {
            deleteLesson(currentLessonProps);
        }
    }

    //Generate lesson UIs
    useEffect(() => {
        if (lessons == null || studentDictionary == null || deletedLessons == null) {
            setLessonUIs(<LoadingSpinner />);
            return;
        }

        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const calendar = {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: []
        };
        let newLessonUIs = [];
        for (let dayIndex = 0; dayIndex < days.length; dayIndex = dayIndex + 1) {
            const currentDay = days[dayIndex];
            let shownLessons = [];
            for (const lesson of lessons) {
                if (lessonFilters["Show Expired"] != true && lesson.endDate != null && new Date(lesson.endDate).valueOf() < new Date().valueOf()) {
                    continue;
                }
                if (lesson.lessonDay == currentDay) {
                    let showLesson = true;
                    if (!(shownSubject == lesson.subject || shownSubject == "" || lessonFilters.Subject == false)) {
                        showLesson = false;
                    }
                    else if (!(shownTutor == null || shownTutor.id == null || shownTutor.id == lesson.tutorID || lessonFilters.Tutor == false)) {
                        showLesson = false;
                    }
                    else if (shownStudent != null && shownStudent.id != null && lessonFilters.Student != false) {
                        let studentIDs = null;
                        if (lesson.studentID != null) {
                            studentIDs = [lesson.studentID];
                        }
                        else {
                            studentIDs = lesson.studentIDs;
                        }
        
                        let foundStudent = false;
                        for (const studentID of studentIDs) {
                            if (shownStudent.id == studentID) {
                                foundStudent = true;
                                break;
                            }
                        }
                        
                        if (foundStudent == false) {
                            showLesson = false;
                        }
                    }
                    else if (shownParent != null && shownParent.id != null && lessonFilters.Parent != false) {
                        let studentIDs = null;
                        if (lesson.studentID != null) {
                            studentIDs = [lesson.studentID];
                        }
                        else {
                            studentIDs = lesson.studentIDs;
                        }
        
                        let foundParent = false;
                        for (const studentID of studentIDs) {
                            const studentModel = studentDictionary[studentID];
                            const parentID = studentModel.parentID;
                            if (shownParent.id == parentID && parentID != null) {
                                foundParent = true;
                                break;
                            }
                        }
                        
                        if (foundParent == false) {
                            showLesson = false;
                        }
                    }
                    else if (lessonFilters.Time == true) {
                        const startTime = lesson.startTime;
                        const startHour = parseInt(startTime.split(":")[0]);
                        const startMinute = parseInt(startTime.split(":")[1]);
                        let startInt = (startHour * 60) + startMinute;
                        const endTime = lesson.endTime;
                        const endHour = parseInt(endTime.split(":")[0]);
                        const endMinute = parseInt(endTime.split(":")[1]);
                        let endInt = (endHour * 60) + endMinute;
                        if (!(shownTimeRange == null || (shownTimeRange.startInt <= startInt && shownTimeRange.endInt >= startInt))) {
                            showLesson = showLesson && false
                        }
                    }
                    if (showLesson == true) {
                        shownLessons.push(lesson);
                    }
                }
            }

            const dayLessonUIs = [];
            const dayCalendarEvents = [];
            if (sortBy == "Sort by lesson time") {
                shownLessons = shownLessons.sort((a, b) => {
                    const aStart = a.startTime;
                    const aStartHour = parseInt(aStart.split(":")[0]);
                    const aStartMinute = parseInt(aStart.split(":")[1]);
                    const aStartInt = (aStartHour * 60) + aStartMinute;
                    const bStart = b.startTime;
                    const bStartHour = parseInt(bStart.split(":")[0]);
                    const bStartMinute = parseInt(bStart.split(":")[1]);
                    const bStartInt = (bStartHour * 60) + bStartMinute;
                    return aStartInt - bStartInt;
                });
            }
            
            
            for (const lesson of shownLessons) {
                const passingProps = {...props}
                lesson.sortBy = sortBy;
                lesson.sortOrder = sortOrder;
                passingProps.lessonDetails = lesson;
                const UILesson = <LessonTemplate 
                    {...passingProps}
                    userType={"admin"}
                    key={lesson.id}
                    lessonID={lesson.id}
                    deleteLesson={askDeleteLesson} 
                />
                dayLessonUIs.push(UILesson);

                const eventDescription = ["Student: " + lesson.studentFirstNames + " " + lesson.studentLastName];
                eventDescription.push("Tutor: " + lesson.tutorFirstNames + " " + lesson.tutorLastName);
                const calendarEvent = {title: lesson.subject, description: eventDescription, startTime: lesson.startTime, endTime: lesson.endTime, passingToClickProps:lesson};
                dayCalendarEvents.push(calendarEvent);
            }
            
            if (compareObjects(showNoDaysValue, shownDays) || shownDays[currentDay] == true || lessonFilters.Day == false || lessonFilters.Day == undefined) {
                newLessonUIs.push(...dayLessonUIs);
                calendar[currentDay].push(...dayCalendarEvents);
            }
        }
        if (sortBy == "Sort by creation date") {
            newLessonUIs.sort((a, b) => {
                const lessona = a.props.lessonDetails;
                const lessonb = b.props.lessonDetails;
                if (sortOrder == "down") {
                    return new Date(lessona.createdAt).valueOf() - new Date(lessonb.createdAt).valueOf();
                }
                else {
                    return new Date(lessonb.createdAt).valueOf() - new Date(lessona.createdAt).valueOf();
                }
            });
        }
        else if (sortBy == "Sort by start date") {
            newLessonUIs.sort((a, b) => {
                const lessona = a.props.lessonDetails;
                const lessonb = b.props.lessonDetails;
                if (sortOrder == "down") {
                    return new Date(lessona.startDate).valueOf() - new Date(lessonb.startDate).valueOf();
                }
                else {
                    return new Date(lessonb.startDate).valueOf() - new Date(lessona.startDate).valueOf();
                }
            });
        }

        setLessonUIs(newLessonUIs);
        setCalendarDetails(calendar);
    }, [lessons, studentDictionary, lessonFilters, shownDays, shownSubject, shownTutor, shownStudent, shownParent, shownTimeRange, sortBy, sortOrder, APIFunctions]);

    if (tutorDictionary == null || studentDictionary == null || parentDictionary == null || lessons == null || loadedFromURL == false) {
        return <LoadingSpinnerPage {...props} height={props.height} />
    }
    else if (tutorDictionary == "loading" || studentDictionary == "loading" || parentDictionary == "loading" || lessons == "loading") {
        return <LoadingSpinnerPage {...props} height={props.height} />
    }
    else if (tutorDictionary == "error" || studentDictionary == "error" || lessons == "error" || deletedLessons == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    

    const navigateToLesson = function (lessonProps, fromLocation) {
        lessonProps.fromLocation = fromLocation;
        const toLocation = "/Admin/ViewLesson?lessonID=" + lessonProps.id;
        console.log(toLocation);
        setRedirect(<Navigate to={toLocation} state={lessonProps}/>);
    }

    
    const titleText = <Text {...titleTextProps} >
        View all lessons
    </Text>

    let showFiltersText = "Show Filters";
        if (showFilters == true) {
            showFiltersText = "Hide Filters"
        }
    const showFiltersButton = <Button
        //width={width}
        text={showFiltersText}
        children={showFiltersText}
        key={"ShowFiltersButton"}
        onClick={() => {
            const previousLessonFilters = {...lessonFilters};
            if (showFilters == true) {
                setShowFilters(false);
            }
            else {
                setShowFilters(true);
            }
        }}
    />

    let refreshButtonDisabled = false;
    if (numRefreshes >= 10) {
        refreshButtonDisabled = true
    }
    const refreshButton = <Button
        key={"RefreshButton"}
        onClick={() => {
            //Prevent API spam by limiting the user to 10 refreshes (Resets when the page is reloaded)
            if (!refreshButtonDisabled) {
                props.refreshLessons();
                props.refreshUsers();
                setNumRefreshes(numRefreshes + 1);
            }
        }}
        isDisabled={refreshButtonDisabled}
    >
        <Icon
            key={"refreshIcon"}
            width={"25px"}
            height={"25px"}
            as={MdCached}
        />
    </Button>

    let deletedLessonsButton = <Link to={"/Admin/DeletedLessons"} style={{textDecoration: 'none'}}><Button
        key={"deletedLessonsButton"}
    >
        Manage deleted lessons
    </Button></Link>
    if (props.deletedLessons != null) {
        if (props.deletedLessons.length == 0) {
            deletedLessonsButton = null;
        }
    }
    
    let filterDirection = "row";
    if (width < 900) {
        filterDirection = "column"
    }
    const getFilterFlex = function () {
        const mainFilterTextProps = {...standardTextProps}
        const filterTextProps = {...mainFilterTextProps}
        mainFilterTextProps.fontSize = "20px"
        filterTextProps.fontSize = "18px";
        
        const checkboxTextProps = {...standardTextProps}
        checkboxTextProps.fontSize = "16px"

        const filterMenu = <CheckBoxesTemplate
            questionLabelProps = {mainFilterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "FilterMenu"
            label = "Filter by:"
            options = {["Day", "Time", "Subject", "Tutor", "Student", "Parent", "Show Expired"]}
            defaultValue = {lessonFilters}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newFilters = {...selectedAnswers}
                setLessonFilters(newFilters);
            }}
            direction = {filterDirection}
        />

        const dayFilters = <CheckBoxesTemplate
            questionLabelProps = {filterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "DayFilter"
            label = "Show days:"
            options = {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
            defaultValue = {shownDays}
            handleChange = {(questionID, selectedAnswers, errors) => {
                let newDayFilters = {...selectedAnswers}
                setShownDays(newDayFilters);
            }}
            direction = {filterDirection}
        />

        const subjectFilter = <DropDownQuestionTemplate
            questionLabelProps = {filterTextProps}
            id = "SubjectFilter"
            label = "Subject:"
            options = {[...subjects]}
            defaultValue = {shownSubject}
            placeHolder = "Choose a subject"
            handleChange = {(questionID, selectedAnswer, errors) => {
                setShownSubject(selectedAnswer);
            }}
            direction = {filterDirection}
        />
        
        
        const tutorFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "TutorFilter"
            label = "Tutor:"
            options = {tutorNames}
            defaultValue = {shownTutor}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer === undefined) {
                    selectedAnswer = false
                }
                setShownTutor(selectedAnswer);
            }}
            direction = {filterDirection}
        />
        
        const studentFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "StudentFilter"
            label = "Student:"
            options = {studentNames}
            defaultValue = {shownStudent}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer === undefined) {
                    selectedAnswer = false
                }
                setShownStudent(selectedAnswer);
            }}
            direction = {filterDirection}
        />

        const parentFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "ParentFilter"
            label = "Parent:"
            options = {parentNames}
            defaultValue = {shownParent}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer === undefined) {
                    selectedAnswer = false
                }
                setShownParent(selectedAnswer);
            }}
            direction = {filterDirection}
        /> 

        const timeFilter = <TimeRangeTemplate
            questionLabelProps = {filterTextProps}
            id = "TimeFilter"
            label = "Start time range:"
            label1={""}
            label2={""}
            defaultValue = {{startTime:shownTimeRange.startTime, endTime:shownTimeRange.endTime}}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                const startTime = selectedAnswer.startTime;
                const startHour = parseInt(startTime.split(":")[0]);
                const startMinute = parseInt(startTime.split(":")[1]);
                let startInt = (startHour * 60) + startMinute;
                const endTime = selectedAnswer.endTime;
                const endHour = parseInt(endTime.split(":")[0]);
                const endMinute = parseInt(endTime.split(":")[1]);
                let endInt = (endHour * 60) + endMinute;
                
                if (!(startInt >= 0)) {
                    startInt = null;
                }
                if (!(endInt >= 0)) {
                    endInt = null;
                }
                setShownTimeRange({startTime: startTime, endTime: endTime, startInt: startInt, endInt: endInt});
            }}
            direction = {filterDirection}
        />

        const filterFlex = <Flex alignItems={"center"} gap={"1vh"} direction={"column"}>
            {filterMenu}
            {lessonFilters.Day && dayFilters}
            {lessonFilters.Subject && subjectFilter}
            {lessonFilters.Tutor && tutorFilter}
            {lessonFilters.Student && studentFilter}
            {lessonFilters.Parent && parentFilter}
            {lessonFilters.Time && timeFilter}
        </Flex>

        return filterFlex;
    }

    const filterFlex = getFilterFlex();

    const toggleSortDirection = function () {
        if (sortOrder === "down") {
            setSortOrder("up");
        }
        else {
            setSortOrder("down");
        }
    }

    const iconSize = "40";
    let iconMD = MdNorth;
    if (sortOrder === "down") {
        iconMD = MdSouth;
    }
    let orderIcon = null;
    if (view == "list") { 
        orderIcon = <Button 
            style={{cursor:"pointer"}} 
            onClick={toggleSortDirection}
            width={iconSize + "px"}
            height={iconSize + "px"}
            justifyContent={"center"}
            alignItems={"center"}
            
        >
            <Icon
                key={"orderChangeButton"}
                width={"30px"}
                height={"30px"}
                as={iconMD}
            />
        </Button>
    }

    let sortByDropDown = null;
    if (view == "list") { 
        sortByDropDown = <DropDownQuestionTemplate
            id = {"sortBy"}
            key={"sortBy" + sortBy}
            options = {["Sort by lesson time", "Sort by start date", "Sort by creation date"]}
            defaultValue = {sortBy}
            placeHolder = {null}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer != null) {
                    setSortBy(selectedAnswer);
                }
            }}
            direction = {filterDirection}
        />
    }

    const deleteLesson = async function (currentLessonProps) {
        try {
            if (currentLessonProps == null) {
                currentLessonProps = currentLesson;
            }
            setDeletingLesson(true);
            const newLesson = {};
            newLesson.id = currentLessonProps.id;
            newLesson.deleted = true;
            console.log(newLesson);
            const newLessonModel = await APIFunctions.updateLesson(newLesson, "admin");
            console.log(newLessonModel);
            setDeletingLesson(false);
            setDeletedLesson(true);
        }
        catch (error) {
            try {
                console.log(error.response.data);
            }
            catch {
                console.log(error);
            }
            setDeletePopUp(false);
            setDeletingLesson(false);
            setDeletedLesson(false);
            if (typeof(error) == "string") {
                setErrorPopUp(error);
            }
            else {
                setErrorPopUp("Error deleting lesson");
            }
        }
    }

    let switchPosition = false;
    if (view == "calendar") {
        switchPosition = true;
    }
    const viewTypeSwitch = <SwitchField
        isChecked={switchPosition}
        onChange={(e) => {
            if (view == "list") {
                setView("calendar");
            }
            else {
                setView("list");
            }
        }} 
    />

    const viewType1Text = <Text {...standardTextProps}>List view</Text>
    const viewType2Text = <Text {...standardTextProps}>Calendar view</Text>
    const viewTypeFlex = <Flex alignItems={"center"}>
        {viewType1Text}
        {viewTypeSwitch}
        {viewType2Text}
    </Flex>

    if (view == "calendar") {
        lessonUIs = <CalendarTemplate {...props} items={calendarDetails} onClick={navigateToLesson}/>;
    }
    
    const deleteText = "Are you sure you want to delete this lesson?";
    const deletePopUpComponent = <PopUpTemplate text={deleteText} setPopUpVisibility={setDeletePopUp} yesButtonFunction={deleteLesson} noButtonFunction={function(){}} showXButton={true} />
    const deletingText = "Deleting lesson...";
    const deletingLessonPopUp = <PopUpTemplate text={deletingText} setPopUpVisibility={setDeletingLesson} showXButton={false} />
    const deletedLessonText = "Lesson deleted";
    const deletedLessonPopUp = <PopUpTemplate text={deletedLessonText} okButtonFunction={function(){}} setPopUpVisibility={setDeletedLesson} showXButton={false} />

    const errorPopUpComponent = <PopUpTemplate text={errorPopUp} okButtonFunction={function(){}} setPopUpVisibility={setErrorPopUp} showXButton={true} />

    const lessonsFoundText = <Text {...standardTextProps} fontSize={"18px"}>
        Lessons found: {lessonUIs.length}
    </Text>

    return (
        <div className='AdminLessonsPage'>
            <Flex alignItems={"center"} gap={"1vh"} direction={"column"} marginBottom={"4vh"}>
                {redirect}
                {titleText}
                <Flex gap={"2vh"} alignItems={"center"} direction={"column"}>
                    {deletedLessonsButton}
                    {viewTypeFlex}
                    <Flex alignItems={"center"}>
                        {showFiltersButton}
                        {sortByDropDown}
                        {orderIcon}
                    </Flex>
                    {showFilters && filterFlex}
                    <Flex direction={"column"} alignItems={"center"}>
                        <Flex alignItems={"center"}>
                            {lessonsFoundText}
                            {refreshButton}
                            
                        </Flex>
                        <Flex direction={"column"} alignItems={"center"} gap={"7vh"}>
                            {lessonUIs}
                        </Flex>
                    </Flex>
                </Flex>
                {deletePopUp && deletePopUpComponent}
                {deletingLesson && deletingLessonPopUp}
                {deletedLesson && deletedLessonPopUp}
                {(errorPopUp != false) && errorPopUpComponent}
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default AdminLessons;