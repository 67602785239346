import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { Button, Flex, Grid, Icon, ScrollView, SwitchField, Text } from '@aws-amplify/ui-react';
import {React, useState, useEffect } from 'react';
import AutoCompleteTemplate from '../../custom-ui-components/form-components/AutoCompleteTemplate';
import CheckBoxesTemplate from '../../custom-ui-components/form-components/CheckBoxesTemplate';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { priceValuetoString } from '../../App';
import { ImCross } from 'react-icons/im';
import { MdGridView, MdList, MdSouth, MdNorth } from "react-icons/md";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { DropDownQuestionTemplate, TextFieldTemplate } from '../../custom-ui-components';
import TutorSubjectsTemplate from '../../custom-ui-components/form-components/TutorQualificationsTemplate';
import SubjectsTemplate from '../../custom-ui-components/form-components/SubjectsTemplate';
import { compareObjects, subjects } from 'nextdoortutor';

const ViewUsers = function(props) {
    const width = props.width;
    const tutors = props.tutors;
    const students = props.students;
    const parents = props.parents;
    const tutorDictionary = props.tutorDictionary;
    const studentDictionary = props.studentDictionary;
    const parentDictionary = props.parentDictionary;
    const userDictionary = props.allUsers;
    const titleTextProps = {...props.titleTextProps};
    const standardTextProps = {...props.standardTextProps};
    const APIFunctions = props.APIFunctions;

    //const location = useLocation();
    //const extraProps = location.state;
    let defaultSortBy = "Sort by first name";
    let defaultSortDirection = 1;
    let redirectUserID = null;
    // if (extraProps != null) {
    //     defaultSortBy = extraProps.sortBy;
    //     defaultSortDirection = extraProps.sortDirection;
    //     redirectUserID = extraProps.redirectUserID;
    // }

    const blankUserTypes = {"Tutor": false, "Student": false, "Parent": false, "Managed Student": false, "None": false};
    const blankUserFilters = {"User Type": false, "Email": false, "Name": false, "ID": false};
    const blankFilters = {
        userFilters: {"User Type": true, "Email": false, "Name": false, "ID": false, "Subjects": false, "Postcode": false},
        shownUserTypes: {"Tutor": true, "Student": true, "Parent": true, "Managed Student": true, "None": false},
        shownName: {},
        shownEmail: {},
        shownID: {},
        shownPostcode: null,
        shownSubjects: [],
    };

    const defaultFilters = {...blankFilters};

    const [userFilters, setUserFilters] = useState({...defaultFilters.userFilters});
    const [showFilters, setShowFilters] = useState(false);
    const [showResetButton, setShowResetButton] = useState(false);
    const [filterMenuIteration, setFilterMenuIteration] = useState(0);
    const [shownSubjects, setShownSubjects] = useState([...defaultFilters.shownSubjects]);
    const [shownName, setShownName] = useState({...defaultFilters.shownName});
    const [shownPostcode, setShownPostcode] = useState(defaultFilters.shownPostcode);
    const [shownEmail, setShownEmail] = useState({...defaultFilters.shownEmail});
    const [shownID, setShownID] = useState({...defaultFilters.shownID});
    const [shownUserTypes, setShownUserTypes] = useState({...defaultFilters.shownUserTypes});
    const [redirect, setRedirect] = useState(null);
    const [hover, setHover] = useState({});
    const [nameOptions, setNameOptions] = useState([]);
    const [emailOptions, setEmailOptions] = useState([]);
    const [IDOptions, setIDOptions] = useState([]);
    const [userUIs, setUserUIs] = useState(null);
    const [shownUserUIs, setShownUserUIs] = useState(null);
    const [gettingUsers, setGettingUsers] = useState(true);
    const [updatingFilter, setUpdatingFilter] = useState(false);
    const [userClick, setUserClick] = useState(null);
    const [viewType, setViewType] = useState("grid");
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [sortOrder, setSortOrder] = useState(defaultSortDirection);
    const [loadedFromURL, setLoadedFromURL] = useState(false);

    const useMousePosition = () => {
        const [mousePosition, setMousePosition] = useState({ x: null, y: null });
        useEffect(() => {
          const updateMousePosition = ev => {
            setMousePosition({ x: ev.clientX, y: ev.clientY });
          };
          window.addEventListener('mousemove', updateMousePosition);
          return () => {
            window.removeEventListener('mousemove', updateMousePosition);
          };
        }, []);
        return mousePosition;
    };

    const navigate = useNavigate();

    //Set initial search parameters from the URL
    useEffect(() => {
        if (tutorDictionary == null || studentDictionary == null || parentDictionary == null || userDictionary == null || userDictionary == "loading" || loadedFromURL == true) {
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const sortByParam = queryParams.get("sortBy");
        if (sortByParam == "firstName") {
            setSortBy("Sort by first name");
        }
        else if (sortByParam == "lastName") {
            setSortBy("Sort by last name");
        }
        else if (sortByParam == "credit") {
            setSortBy("Sort by credit");
        }
        else if (sortByParam == "createdAt") {
            setSortBy("Sort by sign up date");
        }
        const sortOrderParam = queryParams.get("sortOrder");
        if (sortOrderParam != null && sortOrderParam != "") {
            console.log(sortOrderParam);
            console.log(typeof sortOrderParam);
            try {
                const newSortOrder = parseInt(sortOrderParam, 10);
                console.log(newSortOrder);
                console.log(typeof newSortOrder);
                setSortOrder(newSortOrder);
            } catch (error) {
                console.log(error);
            }
        }
        const viewParam = queryParams.get("view");
        if (viewParam != null) {
            setViewType(viewParam);
        }
        const showFiltersParam = queryParams.get("showFilters");
        if (showFiltersParam != null) {
            try {
                const newShowFilters = JSON.parse(showFiltersParam);
                setShowFilters(newShowFilters);
            } catch {}
        }
        const newUserFilters = {...blankUserFilters};
        const nameParam = queryParams.get("Name");
        if (nameParam != null && nameParam != "") { 
            try {
                let tutorModel = tutorDictionary[nameParam];
                let studentModel = studentDictionary[nameParam];
                let parentModel = parentDictionary[nameParam];
                let name = null;
                if (tutorModel != null) {
                    name = tutorModel.firstNames + ", " + tutorModel.lastName;
                }
                else if (studentModel != null) {
                    name = studentModel.firstNames + ", " + studentModel.lastName;
                }
                else if (parentModel != null) {
                    name = parentModel.firstNames + ", " + parentModel.lastName;
                }
                setShownName({id: nameParam, label: name});
                newUserFilters.Name = true;
            } catch {}
        }
        const emailParam = queryParams.get("Email");
        if (emailParam != null && emailParam != "") { 
            try {
                let userModel = userDictionary[emailParam];
                const userAttributes = userModel.Attributes || [];
                let email = null;
                for (const attribute of userAttributes) {
                    if (attribute.Name == "email") {
                        email = attribute.Value;
                    }
                }
                setShownEmail({id: emailParam, label: email});
                newUserFilters.Email = true;
            } catch (error) {
                console.log(error);
            }
        }
        const idParam = queryParams.get("ID");
        if (idParam != null && idParam != "") { 
            try {
                setShownID({id: idParam, label: idParam});
                newUserFilters.ID = true;
            } catch {}
        }
        const postcodeParam = queryParams.get("Postcode");
        if (postcodeParam != null && postcodeParam != "") {
            setShownPostcode(postcodeParam);
            newUserFilters.Postcode = true;
        }
        const userTypeParam = queryParams.get("UserType");
        if (userTypeParam != null && userTypeParam != "") {
            try {
                const userTypeArray = userTypeParam.split("-");
                const newUserTypes = {...blankUserTypes};
                for (let userType of userTypeArray) {
                    if (userType == "ManagedStudent") {
                        userType = "Managed Student";
                    }
                    
                    if (newUserTypes[userType] != null) {
                        newUserTypes[userType] = true;
                    }
                }
                setShownUserTypes(newUserTypes);
                newUserFilters["User Type"] = true;
            } catch {}
        }
        const subjectsParam = queryParams.get("Subjects");
        if (subjectsParam != null && subjectsParam != "") {
            try {
                const subjectArray = subjectsParam.split("-");
                setShownSubjects(subjectArray);
                newUserFilters.Subjects = true;
            } catch {}
        }

        setUserFilters(newUserFilters);
        setLoadedFromURL(true);
    }, [tutorDictionary, studentDictionary, parentDictionary, userDictionary, window.location.search]);

    //Updates the URL with the current search parameters
    useEffect(() => {
        if (loadedFromURL == false) {
            return;
        }

        const queryParams = new URLSearchParams();
        if (sortBy != null) {
            let sortByShort = null;
            if (sortBy == "Sort by first name") {
                sortByShort = "firstName";
            }
            else if (sortBy == "Sort by last name") {
                sortByShort = "lastName";
            }
            else if (sortBy == "Sort by credit") {
                sortByShort = "credit";
            }
            else if (sortBy == "Sort by sign up date") {
                sortByShort = "createdAt";
            }
            queryParams.set("sortBy", sortByShort);
        }
        queryParams.set("sortOrder", sortOrder);
        queryParams.set("view", viewType);
        queryParams.set("showFilters", showFilters);
        for (const [filterName, filterValue] of Object.entries(userFilters)) {
            if (filterValue == true) {
                if (filterName == "Name" && shownName != null && shownName.id != null) {
                    queryParams.set(filterName, shownName.id);
                }
                else if (filterName == "Email" && shownEmail != null && shownEmail.id != null) {
                    queryParams.set(filterName, shownEmail.id);
                }
                else if (filterName == "ID" && shownID != null && shownID.id != null) {
                    queryParams.set(filterName, shownID.id);
                }
                else if (filterName == "Postcode" && shownPostcode != null && shownPostcode != "") {
                    queryParams.set(filterName, shownPostcode);
                }
                else if (filterName =="User Type" && shownUserTypes != null) {
                    try {
                        let userTypeString = "";
                        let totalTypes = 0;
                        for (let userType in shownUserTypes) {
                            if (shownUserTypes[userType] == true) {
                                if (userType == "Managed Student") {
                                    userType = "ManagedStudent";
                                }
                                userTypeString = userTypeString + userType + "-";
                                totalTypes = totalTypes + 1;
                            }
                        }
                        userTypeString = userTypeString.slice(0, -1);
                        if (totalTypes > 0) {
                            queryParams.set("UserType", userTypeString);
                        }
                    } catch {}
                }
                else if (filterName =="Subjects" && shownSubjects != null) {
                    try {
                        let subjectsString = "";
                        let totalSubjects = 0;
                        for (const subject of shownSubjects) {
                            subjectsString = subjectsString + subject + "-";
                            totalSubjects = totalSubjects + 1;
                        }
                        subjectsString = subjectsString.slice(0, -1);
                        if (totalSubjects > 0) {
                            queryParams.set(filterName, subjectsString);
                        }
                    } catch {}
                }
            }
        }
        navigate(
            {search: "?" + queryParams.toString()}
        );
    }, [showFilters, userFilters, shownSubjects, shownPostcode, shownName, shownEmail, shownID, sortBy, shownUserTypes, sortOrder, loadedFromURL]);


    useEffect(() => {
        if (props.allUsers == null || props.allUsersObject == "error") {
            console.log("Getting all users from database");
            props.APIFunctions.adminGetUsers();
        }
        if (tutors != null && students != null && parents != null && userDictionary != null && userDictionary != "loading" && userDictionary != "error") {
            getUserUIs();
        }
        else {
            setGettingUsers(false);
        }
    }, [APIFunctions, tutors, students, parents, userDictionary, props.allUsers, props.allUsersObject]);

    const sortUsers = function (users) {
        const userArray = Object.entries(users);
        // const newUsers = [];
        // for (let userIndex = 0; userIndex < userArray.length; userIndex = userIndex + 1) {
        //     const userID = userArray[userIndex][0]
        //     const user = userArray[userIndex][1];
        //     const userGroups = user.groups;
        //     if (userGroups == null || userGroups.length == 0) {
        //         newUsers.push(user);
        //         userArray.splice(userIndex, 1);
        //         userIndex = userIndex - 1
        //     }
        // }

        const sortedArray = userArray.sort((a, b) => {
            let sortValue = null;
            try {
                sortValue = a[1].lastName.localeCompare(b[1].lastName);
            } catch {
                if (a[1].lastName == null && b[1].lastName == null) {
                    sortValue = a[0].localeCompare(b[0]);
                }
                else if (a[1].lastName == null) {
                    sortValue = 1;
                }
                else if (b[1].lastName == null) {
                    sortValue = -1;
                }
                // if (a[1].lastName != null || b[1].lastName != null) {
                //     console.log(sortValue);
                //     console.log(a);
                //     console.log(b);
                // }
            }
            return sortValue;
        });

        return sortedArray;
    };
    
    if (userClick != null && redirect == null) {
        const filterStates = {
            showFilters: showFilters,
            userFilters: userFilters,
            shownSubjects: shownSubjects,
            shownPostcode: shownPostcode,
            shownName: shownName,
            shownEmail: shownEmail,
            shownID: shownID,
            shownUserTypes: shownUserTypes
        };
        
        setRedirect(<Navigate 
            to={"/Admin/ViewUser?id=" + userClick.name[0]} 
            state={{
                user: userClick.name, 
                passedUsers: userClick.nameArray, 
            }}
        />);
    };

    const getUserUIs = async function () {
        console.log("Getting user UIs");
        setGettingUsers(true);
        const names = {};
        for (const user of Object.values(userDictionary)) {
            try {
                names[user.Username] = {...names[user.Username]}
                names[user.Username].firstNames = null;
                names[user.Username].lastName = null;
                names[user.Username].enabled = user.Enabled;
                names[user.Username].createdAt = user.UserCreateDate;
                names[user.Username].modifiedAt = user.UserLastModifiedDate;
                //console.log(names[user.Username]);
                const userAttributes = user.Attributes;
                for (const attribute of userAttributes) {
                    if (attribute.Name == "email") {
                        names[user.Username].email = attribute.Value;
                    }
                    else if (attribute.Name == "custom:addressList") {
                        names[user.Username].addressList = attribute.Value;
                    }
                }
            }
            catch (error) {
                console.log(error);
                continue;
            }
        }
        for (const tutor of tutors) {
            try {
                //console.log(names[tutor.id].createdAt);
                names[tutor.id] = {...names[tutor.id]}
                names[tutor.id].firstNames = tutor.firstNames;
                names[tutor.id].lastName = tutor.lastName;
                names[tutor.id].phoneNumber = tutor.phoneNumber;
                names[tutor.id].postcode = tutor.address.postcode;
                names[tutor.id].gender = tutor.gender;
                names[tutor.id].signUpWhereFrom = tutor.signUpWhereFrom;
                let existingCredit = names[tutor.id].credit || 0;
                const tutorCredit = tutor.credit || 0;
                names[tutor.id].credit = existingCredit + parseFloat(tutorCredit, 10);

                const tutorQualifications = tutor.qualifications || {};
                names[tutor.id].qualifications = tutorQualifications;
                let subjectText = "Teaches: ";
                for (const [subjectName, subjectQualifications] of Object.entries(tutorQualifications)) {
                    for (const [qualificationType, qualificationDetails] of Object.entries(subjectQualifications)) {
                        
                        subjectText = subjectText + subjectName + " (" + qualificationType + ": " + qualificationDetails.result + "), ";
                    }
                }

                subjectText = subjectText.substring(0, subjectText.length - 2);
                names[tutor.id].tutorSubjects = subjectText;
                names[tutor.id].userIsTutor = true;
                try {
                    names[tutor.id].groups.push("Tutor");
                }
                catch {
                    names[tutor.id].groups = ["Tutor"];
                }
            }
            catch (error) {
                console.log(error);
                continue;
            }
        }
        for (const parent of parents) {
            try {
                names[parent.id] = {...names[parent.id]}
                names[parent.id].firstNames = parent.firstNames;
                names[parent.id].lastName = parent.lastName;
                names[parent.id].phoneNumber = parent.phoneNumber;
                names[parent.id].userIsParent = true;
                names[parent.id].signUpWhereFrom = parent.signUpWhereFrom;
                const existingCredit = names[parent.id].credit || 0;
                const parentCredit = parent.lessonCredits || 0;
                names[parent.id].credit = existingCredit + parseFloat(parentCredit, 10);
                try {
                    names[parent.id].groups.push("Parent");
                }
                catch {
                    names[parent.id].groups = ["Parent"];
                }
            }
            catch (error) {
                console.log(error);
                continue;
            }
        }
        for (const student of students) {
            names[student.id] = {...names[student.id]}
            names[student.id].firstNames = student.firstNames;
            names[student.id].lastName = student.lastName;
            names[student.id].phoneNumber = student.phoneNumber;
            names[student.id].hasParent = (student.parentID != null);
            names[student.id].parentID = student.parentID;
            names[student.id].signUpWhereFrom = student.signUpWhereFrom;
            if (student.parentID == null) {
            const existingCredit = names[student.id].credit || 0;
            const studentCredit = student.lessonCredits || 0;
            names[student.id].credit = existingCredit + parseFloat(studentCredit, 10);
            }
            if (names[student.id].createdAt == null) {
                names[student.id].createdAt = student.createdAt;
            }
            
            if (student.parentID != null) {
                names[student.id].userIsManagedStudent = true;
                try {
                    names[student.id].groups.push("Managed Student");
                }
                catch {
                    names[student.id].groups = ["Managed Student"];
                }
            }
            else {
                names[student.id].userIsStudent = true;
                try {
                    names[student.id].groups.push("Student");
                }
                catch {
                    names[student.id].groups = ["Student"];
                }
            }
        }

        const nameArray = sortUsers(names);

        const newNameOptions = [];
        const newEmailOptions = [];
        const newIDOptions = [];
        for (const nameObject of nameArray) {
            const id = nameObject[0];
            const object = nameObject[1];
            if (!(object.lastName == null) && !(object.firstNames == null)) {
                newNameOptions.push({
                    id: id,
                    label: (object.firstNames + ", " + object.lastName)
                });
            }
            newEmailOptions.push({
                id: id,
                label: (object.email)
            });
            newIDOptions.push({
                id: id,
                label: (id)
            });
        }
        setNameOptions([...newNameOptions]);
        setEmailOptions([...newEmailOptions]);
        setIDOptions([...newIDOptions]);

        const newUserUIs = [];
        for (const name of nameArray) {
            const userID = name[0];
            const userObject = name[1];
            const firstNames = userObject.firstNames;
            const lastName = userObject.lastName;
            let userName = null;
            if (firstNames != null && lastName != null) {
                userName = firstNames + " " + lastName;
            }
            else if (firstNames != null) {
                userName = firstNames;
            }
            else if (lastName != null) {
                userName = lastName;
            }
            const userEmail = userObject.email;
            const tutorSubjects = userObject.tutorSubjects;
            const qualifications = userObject.qualifications;
            const postcode = userObject.postcode;
            const userPhoneNumber = userObject.phoneNumber;
            const hasParent = userObject.hasParent;
            const userGroups = userObject.groups;
            const userIsTutor = userObject.userIsTutor || false;
            const userIsStudent = userObject.userIsStudent || false;
            const userIsManagedStudent = userObject.userIsManagedStudent || false;
            const userIsParent = userObject.userIsParent || false;
            const userIsAdmin = userObject.userIsAdmin || false;
            const userIsDeveloper = userObject.userIsDeveloper || false;
            const signUpWhereFrom = userObject.signUpWhereFrom;
            const createdAt = userObject.createdAt;
            const credit = userObject.credit;
            
            let userTypesText = "";
            try {
                for (let userTypeIndex = 0; userTypeIndex < userGroups.length; userTypeIndex = userTypeIndex + 1) {
                    userTypesText = userTypesText + userGroups[userTypeIndex];
                    if (userTypeIndex < userGroups.length - 1) {
                        userTypesText = userTypesText + ", ";
                    }
                }
            }
            catch {
                userTypesText = null;
            }

            let userNameText = null;
            if (userName != null) {
                userNameText = <Text {...standardTextProps} fontSize={"18px"} key={"user" + userID}>{userName}</Text>
            }

            let userEmailText = null;
            if (userEmail != null) {
                userEmailText = <Text {...standardTextProps} fontSize={"18px"} key={"userEmail" + userID}>{userEmail}</Text>
            }

            let userTypeTextUI = null;
            if (userTypesText != null) {
                userTypeTextUI = <Text {...standardTextProps} fontSize={"18px"} key={"userType" + userID}>{userTypesText}</Text>
            }

            let userCreditTextUI = null;
            if (credit != null) {
                userCreditTextUI = <Text {...standardTextProps} fontSize={"18px"} key={"userCredit" + userID}>{priceValuetoString(credit)}</Text>
            }

            if (redirectUserID == userID) {
                setUserClick({
                    name: name,
                    nameArray: nameArray
                });
            }

            const userUI = <Flex
                width={"fit-content"}
                backgroundColor={"#48e873"} 
                borderRadius={"1vh"} 
                paddingLeft={"1vw"}
                paddingRight={"1vw"}
                paddingTop={"1vh"}
                paddingBottom={"1vh"}
                style={{cursor: "pointer"}}
                key={userID + "Flex"}
                justifyContent={"center"}
                direction={"column"}
                gap={"1vh"}
                onMouseEnter={() => {
                    setHover({
                        id: userID, 
                        name: userName, 
                        phoneNumber: userPhoneNumber, 
                        email: userEmail, 
                        tutorSubjects: tutorSubjects, 
                        postcode: postcode, 
                        hasParent: hasParent, 
                        createdAt: createdAt
                    });
                }}
                onMouseLeave={() => {
                    setHover({});
                }}
                onClick={() => {
                    setUserClick({
                        name: name,
                        nameArray: nameArray
                    });
                }}
            >
                {userNameText}
                {userEmailText}
                {userTypeTextUI}
                {userCreditTextUI}
                <Text {...standardTextProps} fontSize={"12px"} key={"userID" + userID}>{userID}</Text>
            </Flex>

            const user = {
                id: userID,
                firstNames: firstNames,
                lastName: lastName,
                email: userEmail,
                tutorSubjects: tutorSubjects,
                qualifications: qualifications,
                postcode: postcode,
                phoneNumber: userPhoneNumber,
                hasParent: hasParent,
                groups: userGroups,
                userIsStudent: userIsStudent,
                userIsManagedStudent: userIsManagedStudent,
                userIsParent: userIsParent,
                userIsTutor: userIsTutor,
                userIsAdmin: userIsAdmin,
                userIsDeveloper: userIsDeveloper,
                signUpWhereFrom: signUpWhereFrom,
                credit: credit,
                createdAt: createdAt
            }
            newUserUIs.push({
                userUI: userUI, 
                props: user
            });
        }
        setGettingUsers(false);
        setUserUIs([...newUserUIs]);
        await updateFilter({userUIs: newUserUIs});
        console.log("Got user UIs");
    }

    const updateFilter = async function(filterProps = {}, newSortDirection = sortOrder) {
        setShownUserUIs(null);
        setUpdatingFilter(true);

        let filters = userFilters;
        if (filterProps.userFilters != null) {
            filters = filterProps.userFilters;
        }
        let name = shownName || {};
        if (filterProps.shownName != null) {
            name = filterProps.shownName;
        }
        let email = shownEmail || {};
        if (filterProps.shownEmail != null) {
            email = filterProps.shownEmail;
        }
        let id = shownID || {};
        if (filterProps.shownID != null) {
            id = filterProps.shownID;
        }
        let userTypes = shownUserTypes;
        if (filterProps.shownUserTypes != null) {
            userTypes = filterProps.shownUserTypes;
        }
        let users = userUIs;
        if (filterProps.userUIs != null) {
            users = filterProps.userUIs;
        }
        let postcode = shownPostcode;
        if (filterProps.shownPostcode != null) {
            postcode = filterProps.shownPostcode;
        }
        let subjects = shownSubjects;
        if (filterProps.shownSubjects != null) {
            subjects = filterProps.shownSubjects;
        }
        
        const newShownUserUIs = [];
        
        let showStudents = true;
        let showManagedStudents = true;
        let showParents = true;
        let showTutors = true;
        let showNoneUsers = true;
        if (filters["User Type"] == true && userTypes["Student"] != true) {
            showStudents = false;
        }
        if (filters["User Type"] == true && userTypes["Managed Student"] != true) {
            showManagedStudents = false;
        }
        if (filters["User Type"] == true && userTypes["Parent"] != true) {
            showParents = false;
        }
        if (filters["User Type"] == true && userTypes["Tutor"] != true) {
            showTutors = false;
        }
        if (filters["User Type"] == true && userTypes["None"] != true) {
            showNoneUsers = false;
        }

        users = users.sort((a, b) => {
            const aProps = a.props;
            const bProps = b.props;
            if (sortBy == "Sort by first name") {
                const aFirstName = aProps.firstNames;
                const bFirstName = bProps.firstNames;
                if (aFirstName == null && bFirstName == null) {
                    return 0;
                }
                else if (aFirstName == null) {
                    return 1;
                }
                else if (bFirstName == null) {
                    return -1;
                }
                return aFirstName.localeCompare(bFirstName) * newSortDirection;
            }
            else if (sortBy == "Sort by last name") {
                const aLastName = aProps.lastName;
                const bLastName = bProps.lastName;
                if (aLastName == null && bLastName == null) {
                    return 0;
                }
                else if (aLastName == null) {
                    return 1;
                }
                else if (bLastName == null) {
                    return -1;
                }
                return aLastName.localeCompare(bLastName) * newSortDirection;
            }
            else if (sortBy == "Sort by credit") {
                const aCredit = aProps.credit;
                const bCredit = bProps.credit;
                if (aCredit == null && bCredit == null) {
                    return 0;
                }
                else if (aCredit == null) {
                    return 1;
                }
                else if (bCredit == null) {
                    return -1;
                }
                return (bCredit - aCredit) * newSortDirection;
            }
            else if (sortBy == "Sort by sign up date") {
                const aCreatedAt = aProps.createdAt;
                const bCreatedAt = bProps.createdAt;
                if (aCreatedAt == null && bCreatedAt == null) {
                    return 0;
                }
                else if (aCreatedAt == null) {
                    return 1;
                }
                else if (bCreatedAt == null) {
                    return -1;
                }
                return (new Date(bCreatedAt) - new Date(aCreatedAt)) * newSortDirection;
            }
        });

        for (const user of users) {
            const userProps = user.props;
            const isNoneUser = !(userProps.userIsStudent) && !(userProps.userIsManagedStudent) && !(userProps.userIsParent) && !(userProps.userIsTutor) && !(userProps.userIsAdmin) && !(userProps.userIsDeveloper);
            
            let validUserType = true;
            if (showStudents || showManagedStudents || showParents || showTutors || showNoneUsers) {
                validUserType = false;
                if (showStudents == true && userProps.userIsStudent == true) {
                    validUserType = true;
                }
                if (showManagedStudents == true && userProps.userIsManagedStudent == true) {
                    validUserType = true;
                }
                if (showParents == true && userProps.userIsParent == true) {
                    validUserType = true;
                }
                if (showTutors == true && userProps.userIsTutor == true) {
                    validUserType = true;
                }
                if (showNoneUsers == true && isNoneUser == true) {
                    validUserType = true;
                }
            }
            
            let validName = true
            if (filters["Name"] == true && name.id != null && userProps.id != name.id) {
                validName = false;
            }

            let validEmail = true
            if (filters["Email"] == true && email.id != null && userProps.id != email.id) {
                validEmail = false;
            }

            let validID = true
            if (filters["ID"] == true && id.id != null && userProps.id != id.id) {
                validID = false;
            }
            
            let validPostcode = true;
            if (filters["Postcode"] == true && postcode != "" && postcode != null) {
                if (userProps.postcode == null) {
                    validPostcode = false;
                }
                else if (postcode.length > userProps.postcode.length) {
                    validPostcode = false;
                }
                else {
                    const comparisonPostcode = userProps.postcode.substring(0, postcode.length);
                    if (postcode.toUpperCase() != comparisonPostcode.toUpperCase()) {
                        validPostcode = false;
                    }
                }
            }

            let validSubjects = true;
            if (filters["Subjects"] == true && subjects != []) {
                if (userProps.qualifications == null) {
                    validSubjects = false;
                }
                else {
                    const userQualifications = userProps.qualifications || {};
                    for (const shownSubject of subjects) {
                        if (userQualifications[shownSubject] == null) {
                            validSubjects = false;
                            break;
                        }
                    }
                }
            }
            

            const showUser = validUserType && validName && validEmail && validID && validPostcode && validSubjects;

            if (showUser == true) {
                newShownUserUIs.push(user.userUI);
            }
        }

        setShownUserUIs([...newShownUserUIs]);
        setUpdatingFilter(false);

        const currentFilters = {
            userFilters: filters,
            shownUserTypes: userTypes,
            shownName: name,
            shownEmail: email,
            shownID: id,
            shownPostcode: postcode,
            shownSubjects: shownSubjects
        };
        
        const filtersChanged = compareObjects(currentFilters, blankFilters);
        if (filtersChanged && showResetButton == true) {
            setShowResetButton(false);   
        }
        else if (!filtersChanged && showResetButton == false) {
            setShowResetButton(true); 
        }
    };

    const hoverNameText = <Text {...standardTextProps} fontSize={"15px"}>{hover.name}</Text>
    const hoverEmailText = <Text {...standardTextProps} fontSize={"15px"}>{hover.email}</Text>
    const hoverPhoneNumberText = <Text {...standardTextProps} fontSize={"15px"}>{hover.phoneNumber}</Text>
    let hoverSubjectsText = <Text {...standardTextProps} fontSize={"15px"}>{hover.tutorSubjects}</Text>
    if (hover.tutorSubjects == null) {
        hoverSubjectsText = null;
    }
    let hoverPostcodeText = <Text {...standardTextProps} fontSize={"15px"}>{hover.postcode}</Text>
    if (hover.postcode == null) {
        hoverPostcodeText = null;
    }
    let hasParentText = null;
    if (hover.hasParent == true) {
        hasParentText = <Text {...standardTextProps} fontSize={"15px"}>Student managed by parent</Text>
    }
    const hoverCreatedAtText = <Text {...standardTextProps} fontSize={"15px"}>Created: {new Date(hover.createdAt).toUTCString()}</Text>

    const hoverComponent = <Flex 
        backgroundColor={"#069dd4"} 
        borderRadius={"1vh"}
        //width={"fit-content"}
        maxWidth={"300px"}
        paddingLeft={"1vw"}
        paddingRight={"1vw"}
        paddingTop={"1vh"}
        paddingBottom={"1vh"}
        height={"fit-content"}
        position="fixed"
        top={useMousePosition().y - 30}
        left={useMousePosition().x + 10}
        style={{
            zIndex:5
            }}
        direction={"column"}
        gap={"0.5vh"}
    >
        {hoverNameText}
        {hasParentText}
        {hoverEmailText}
        {hoverPhoneNumberText}
        {hoverPostcodeText}
        {hoverSubjectsText}
        {hoverCreatedAtText}
    </Flex>

    if (tutors == null || students == null || parents == null || userDictionary == null) {
        return <LoadingSpinnerPage {...props} height={props.height} />
    }
    else if (tutors == "loading" || students == "loading" || parents == "loading" || userDictionary == "loading") {
        return <LoadingSpinnerPage {...props} height={props.height} />
    }
    else if (tutors == "error" || students == "error" || parents == "error" || userDictionary == "error") {
        return <ErrorLoadingPage {...props}/>
    }

    const allUsers = Object.values(userDictionary);

    if (userUIs == null && gettingUsers == false) {
        getUserUIs();
    }
    if (userUIs == null) {
        return <LoadingSpinnerPage {...props} height={props.height} />
    }

    let usersFoundText = null;
    if (shownUserUIs != null) {
        usersFoundText = <Text {...standardTextProps} fontSize={"18px"}>
            Users found: {shownUserUIs.length}
        </Text>
    }
    
    const titleText = <Text {...titleTextProps} >
        View all users
    </Text>

    let showFiltersText = "Show Filters";
        if (showFilters == true) {
            showFiltersText = "Hide Filters"
        }
    const showFiltersButton = <Button
        key={"ShowFiltersButton"}
        onClick={() => {
            if (showFilters == true) {
                setShowFilters(false);
            }
            else {
                setShowFilters(true);
            }
        }}
        backgroundColor={"#ffffff"}
    >
        {showFiltersText}
    </Button>

    const resetFilters = function () {
        setUserFilters({...blankFilters.userFilters});
        setShownUserTypes({...blankFilters.shownUserTypes});
        setShownName({...blankFilters.shownName});
        setShownPostcode(blankFilters.shownPostcode);
        setShownSubjects([...blankFilters.shownSubjects]);
        setShownEmail({...blankFilters.shownEmail});
        setShownID({...blankFilters.shownID});
        setFilterMenuIteration(filterMenuIteration + 1);
        updateFilter({userFilters: {...blankFilters.userFilters}, shownUserTypes: {...blankFilters.shownUserTypes}});
    };

    let resetFiltersButton = <Icon
        key={"ResetFiltersButton"}
        onClick={resetFilters}
        width={"30px"}
        height={"30px"}
        as={ImCross}
        color={"#ff0000"}
        position={"absolute"}
        right={"-40px"}
        top={"10px"}
        style={{cursor:"pointer"}}
    />

    if (!showResetButton){
        resetFiltersButton = null;
    }

    let filterDirection = "row";
    if (width < 900) {
        filterDirection = "column"
    }
    const sortByDropDown = <DropDownQuestionTemplate
        id = {"sortBy"}
        key={"sortBy" + sortBy}
        options = {["Sort by first name", "Sort by last name", "Sort by credit", "Sort by sign up date"]}
        defaultValue = {sortBy}
        placeHolder = {null}
        handleChange = {(questionID, selectedAnswer, errors) => {
            if (selectedAnswer != null) {
                setSortBy(selectedAnswer);
                updateFilter(userFilters);
                if (selectedAnswer != sortBy) {
                    setSortOrder(defaultSortDirection);
                }
            }
        }}
        direction = {filterDirection}
    />
    
    let iconMD = MdNorth;
    if (sortOrder == 1) {
        iconMD = MdSouth;
    }
    const sortDirectionButton = <Button 
        style={{cursor:"pointer"}} 
        onClick={() => {
            setSortOrder(-sortOrder);
            updateFilter(userFilters, -sortOrder);
        }}
        width={"40px"}
        height={"40px"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"absolute"}
        right={"-50px"}
    >
        <Icon
            key={"orderChangeButton"}
            width={"30px"}
            height={"30px"}
            as={iconMD}
        />
    </Button>
    
    const getFilterFlex = function () {
        const mainFilterTextProps = {...standardTextProps}
        const filterTextProps = {...mainFilterTextProps}
        mainFilterTextProps.fontSize = "20px"
        filterTextProps.fontSize = "18px";
        
        const checkboxTextProps = {...standardTextProps}
        checkboxTextProps.fontSize = "16px"

        let filterDirection = "row";
        if (width < 900) {
            filterDirection = "column"
        }

        const filterMenu = <CheckBoxesTemplate
            questionLabelProps = {mainFilterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = {"FilterMenu"}
            key= {"FilterMenu" + filterMenuIteration}
            label = "Filter by:"
            options = {["Name", "Email", "User Type", "ID", "Subjects", "Postcode"]}
            defaultValue = {userFilters}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newFilters = {...selectedAnswers}
                setUserFilters(newFilters);
                updateFilter({userFilters: newFilters});
            }}
            direction = {filterDirection}
        />

        const subjectFilter = <SubjectsTemplate
            questionLabelProps = {filterTextProps}
            id = "SubjectFilter"
            label = "Subjects:"
            options = {[...subjects]}
            defaultValue = {shownSubjects}
            placeHolder = "Choose a subject"
            handleChange = {(questionID, selectedAnswer, errors) => {
                setShownSubjects(selectedAnswer);
                updateFilter({shownSubjects: selectedAnswer});
            }}
            direction = {filterDirection}
        />
        

        const nameFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "NameFilter"
            label = "Name:"
            options = {nameOptions}
            defaultValue = {shownName}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer.id === undefined) {
                    selectedAnswer = {};
                }
                setShownName(selectedAnswer);
                updateFilter({shownName: selectedAnswer});
            }}
            direction = {filterDirection}
        />

        const postcodeFilter = <TextFieldTemplate
            questionLabelProps = {filterTextProps}
            id = "PostcodeFilter"
            label = "Postcode:"
            defaultValue = {shownPostcode}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                setShownPostcode(selectedAnswer);
                updateFilter({shownPostcode: selectedAnswer});
            }}
            direction = {filterDirection}
        />

        const emailFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "EmailFilter"
            label = "Email:"
            options = {emailOptions}
            defaultValue = {shownEmail}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer.id === undefined) {
                    selectedAnswer = {}
                }
                setShownEmail(selectedAnswer);
                updateFilter({shownEmail: selectedAnswer});
            }}
            direction = {filterDirection}
        />

        const IDFilter = <AutoCompleteTemplate
            questionLabelProps = {filterTextProps}
            id = "IDFilter"
            label = "ID:"
            options = {IDOptions}
            defaultValue = {shownID}
            questionErrorProps = {{position:"absolute", style:{display:"none"}}}
            handleChange = {(questionID, selectedAnswer, errors) => {
                if (selectedAnswer.id === undefined) {
                    selectedAnswer = {}
                }
                setShownID(selectedAnswer);
                updateFilter({shownID: selectedAnswer});
            }}
            direction = {filterDirection}
        />
        
        const userTypeFilter = <CheckBoxesTemplate
            questionLabelProps = {filterTextProps}
            checkboxTextProps = {checkboxTextProps}
            id = "UserTypeFilter"
            key = {"UserTypeFilter" + filterMenuIteration}
            label = "User Type:"
            options = {["Tutor", "Student", "Parent", "Managed Student", "None"]}
            defaultValue = {shownUserTypes}
            handleChange = {(questionID, selectedAnswers, errors) => {
                const newUserTypes = {...selectedAnswers}
                setShownUserTypes(newUserTypes);
                updateFilter({shownUserTypes: newUserTypes});
            }}
            direction = {filterDirection}
        />

        const filterFlex = <Flex alignItems={"center"} gap={"1vh"} direction={"column"}>
            {filterMenu}
            {userFilters.Subjects && subjectFilter}
            {userFilters.Name && nameFilter}
            {userFilters.Email && emailFilter}
            {userFilters.ID && IDFilter}
            {userFilters["User Type"] && userTypeFilter}
            {userFilters.Postcode && postcodeFilter}
        </Flex>

        return filterFlex;
    }

    const filterFlex = getFilterFlex();
    
    if (updatingFilter == true || shownUserUIs == null) {
        if (shownUserUIs != "loading") {
            setShownUserUIs("loading");
        }
    }
    
    let outputUserUIs = shownUserUIs;
    if (shownUserUIs == "loading") {
        outputUserUIs = <LoadingSpinner />
    }

    const swapView = function() {
        if (viewType == "grid") {
            setViewType("column");
        }
        else {
            setViewType("grid");
        }
    }

    let viewButton = <Icon
        key={"GridViewButton"}
        onClick={swapView}
        width={"30px"}
        height={"30px"}
        as={MdGridView}
        color={"#000000"}
        style={{cursor:"pointer"}}
        position={"absolute"}
        left={"-40px"}
    />

    if (viewType == "column") {
        viewButton = <Icon
            key={"ListViewButton"}
            onClick={swapView}
            width={"30px"}
            height={"30px"}
            as={MdList}
            color={"#000000"}
            style={{cursor:"pointer"}}
            position={"absolute"}
            left={"-40px"}
        />
    }
    
    let UIFlex = <Flex direction={"column"} alignItems={"center"} gap={"3vh"}>
        {outputUserUIs}
    </Flex>
    if (width >= 600 && viewType == "grid") {
        UIFlex = <Flex width={"90vw"} alignItems={"center"} justifyContent={"center"} wrap={"wrap"}>
            {outputUserUIs}
        </Flex>
    }
    if (width < 600) {
        viewButton = null;
    }

    return (
        <div className='AdminUsersPage'>
            <Flex alignItems={"center"} gap={"1vh"} direction={"column"} marginBottom={"4vh"}>
                {redirect}
                {titleText}
                {(hover.name != null) && hoverComponent}
                <Flex gap={"2vh"} alignItems={"center"} direction={"column"}>
                    <Flex direction={"column"} gap={"1vh"}>
                        <Flex direction={"column"}  justifyContent={"center"} position={"relative"}>
                            <Flex alignItems={"center"}>
                                {sortByDropDown}
                                {sortDirectionButton}
                            </Flex>
                            {viewButton}
                        </Flex>
                        <Flex direction={"column"}  justifyContent={"center"} position={"relative"}>
                            {showFiltersButton}
                            {resetFiltersButton}
                        </Flex>
                        
                    </Flex>
                    {showFilters && filterFlex}
                    <Flex direction={"column"} alignItems={"center"}>
                        <Flex alignItems={"center"}>
                            {usersFoundText}
                        </Flex>
                        {UIFlex}
                    </Flex>
                </Flex>
            </Flex>
            {redirect}
        </div>
    );
}

export default ViewUsers;